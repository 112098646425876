import React from "react";
import CommonSection from "../../components/site/CommanSection";
import NavBar from "../../components/site/Navbar";
import Footer from "../../components/site/Footer";
import BlogList from "../../components/site/BlogList";

function Blogs() {
  return (
    <div>
      <NavBar />
      <CommonSection title={"Blogs"} />
      {/* <section className="bg-gray-100"> */}
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center">
            <BlogList />
          </div>
        </div>
      {/* </section> */}
      <Footer />
    </div>
  );
}

export default Blogs;

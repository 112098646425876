import React from 'react';

const Rating = ({ title, rating }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 m-2">
      <h3 className="text-center font-semibold">{title}</h3>
      <p className="text-center text-2xl text-blue-500">{rating}/5</p>
    </div>
  );
};

const Mismatch = ({ title, percentage }) => {
    return (
      <div className="bg-white shadow-md rounded-lg p-4 m-2 w-full sm:w-1/2">
        <h3 className="text-center font-semibold text-sm">{title}</h3>
        <div className="w-full bg-gray-200 rounded-full h-4 mt-2">
          <div className="bg-blue-500 h-4 rounded-full" style={{ width: `${percentage}%` }}></div>
        </div>
        <p className="text-center mt-2">{percentage}%</p>
      </div>
    );
  };
  
  export { Rating, Mismatch };
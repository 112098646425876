import React from 'react';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';

const statuses = [
  'Assigned', 'StartFromGarage', 'ReachedPickup', 'TripInProgress', 'ReachedDrop',
  'TripCompleted', 'ManualFulfill', 'CANCELLED', 'NotBoarded', 'ForceClose'
];

function UpdateTripStatusModalAdmin({ isOpen, onClose, trip }) {
  if (!isOpen) return null;

  console.log("UpdateTripStatusModalAdmin:trip:",trip);

  const updateStatus = async (newStatus) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_BASE_URLB}/bookings/driverstatus/updatebookingtrip/tripstatus`, {
        tripId: trip.tripId,
        bookingId: trip.bookId,
        tripStatus: newStatus,
        updateBy: JSON.parse(localStorage.getItem('user'))?.sub || null
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
        toast.success('Trip status updated successfully');
        // onClose(); // You might want to consider if you want to close the modal immediately after update
      }
    } catch (error) {
      console.error('Failed to update trip status:', error);
      toast.error('Failed to update trip status');
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
        
        {/* Close button in the upper right corner */}
        <span className="absolute top-0 right-0 p-4">
          <button onClick={onClose} className="text-2xl">&times;</button>
        </span>

        <h2 className="text-center font-bold text-lg">Update Trip Status</h2>

        {/* Status table */}
        <table className="min-w-full border-collapse mt-4">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2 border">S.No</th>
              <th className="px-4 py-2 border">Status</th>
              <th className="px-4 py-2 border">Action</th>
            </tr>
          </thead>
          <tbody>
            {statuses.map((status, index) => (
              <tr key={index}>
                <td className="px-4 py-2 border text-center">{index + 1}</td>
                <td className="px-4 py-2 border">{status}</td>
                <td className="px-4 py-2 border">
                  <button 
                    onClick={() => updateStatus(status)}
                    className={`w-full text-white rounded ${status === trip.tripStatus ? 'bg-green-500' : 'bg-red-500'}`}>
                    Update
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Close button at the bottom center */}
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
          <button 
            onClick={onClose} 
            className="bg-gray-500 text-white px-4 py-2 rounded-full hover:bg-gray-700">
            Close
          </button>
        </div>

        <ToastContainer />
      </div>
    </div>
  );
}

export default UpdateTripStatusModalAdmin;

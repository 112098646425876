import React from "react";
import { Link } from "react-router-dom";
import {
  FaTelegram,
  FaXTwitter,
  FaFacebook,
  FaInstagram,
  FaArrowRight,
  FaLock,
} from "react-icons/fa6";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-fixblue text-white font-sans text-[14px] py-2">
      <div className="container mx-auto grid grid-cols-2 lg:grid-cols-4 lg:px-20 md:grid-cols-2 gap-8 p-2">
        {/* Company Section */}
        <div className="px-2 mt-2">
          <h2 className="inline-block text-[1.1rem] mb-2 border-b-2 border-fixwhite font-fixopen uppercase">
            Company
          </h2>
          <ul className="leading-8">
            <li className="flex items-center hover:ml-2">
              <FaArrowRight className="mr-2" />
              <Link to={"/about"}>About Us</Link>
            </li>
            <li className="flex items-center hover:ml-2">
              <FaArrowRight className="mr-2" />
              <Link to={"/contact"}>Contact Us</Link>
            </li>
            <li className="flex items-center hover:ml-2">
              <FaArrowRight className="mr-2" />
              <Link to={"/policy"}>Privacy Policy</Link>
            </li>
            <li className="flex items-center hover:ml-2">
              <FaArrowRight className="mr-2" />
              <Link to={"/terms"}>Terms & Condition</Link>
            </li>
          </ul>
        </div>

        {/* Useful Links Section */}
        <div className="px-2 mt-2">
          <h2 className="inline-block text-[1.1rem] mb-2 border-b-2 border-fixwhite font-fixopen uppercase">
            Useful Links
          </h2>
          <ul className="leading-8">
            <li className="flex items-center hover:ml-2">
              <FaArrowRight className="mr-2" />
              <Link to={"/account"}>My Account</Link>
            </li>
            <li className="flex items-center hover:ml-2">
              <FaArrowRight className="mr-2" />
              <Link to={"/blogs"}>Blogs</Link>
            </li>
            <li className="flex items-center hover:ml-2">
              <FaArrowRight className="mr-2" />
              <Link to={"/b2b"}>B2B</Link>
            </li>
            <li className="flex items-center hover:ml-2">
              <FaArrowRight className="mr-2" />
              <Link to={"/operator"}>Operator</Link>
            </li>
          </ul>
        </div>

        {/* Pay Safety with Us Section */}
        <div className="px-2 mt-2">
          <h2 className="inline-block text-[1.1rem] mb-4 border-b-2 border-fixwhite font-fixopen uppercase">
            Pay Safety
          </h2>
          <div className="flex items-center justify-center text-md">
            <FaLock className="text-fixgold mr-2 text-lg" />
            <p className="text-sm">Payments are secured with SSL encryption.</p>
          </div>

          <div className="bg-fixwhite mt-4">
            <img
              src="/images/Payments.png"
              alt="payment"
              className="min-w-full"
            />
          </div>
        </div>

        {/* Contact Info Section */}
        <div className="px-2 my-2">
          <h2 className="inline-block text-[1.1rem] mb-4 border-b-2 border-fixwhite font-fixopen uppercase">
            Contact us
          </h2>
          <p className="mb-2 flex items-center hover:ml-2 cursor-pointer">
            <FaArrowRight className="mr-2" />
            +91 9087654321
          </p>
          <p className="mb-2 flex items-center hover:ml-2 cursor-pointer">
            <FaArrowRight className="mr-2" />
            info@fixryde.com
          </p>
          <div className="my-2">
            <div className="flex space-x-2">
              <Link
                to="/twitter"
                className="flex items-center justify-center h-8 w-8 border-2 border-fixwhite rounded-full hover:text-fixgold hover:border-fixgold"
              >
                <FaXTwitter />
              </Link>
              <Link
                to="/facebook"
                className="flex items-center justify-center h-8 w-8 border-2 border-fixwhite rounded-full hover:text-fixgold hover:border-fixgold"
              >
                <FaFacebook />
              </Link>
              <Link
                to="/telegram"
                className="flex items-center justify-center h-8 w-8 border-2 border-fixwhite rounded-full hover:text-fixgold hover:border-fixgold"
              >
                <FaTelegram />
              </Link>
              <Link
                to="/instagram"
                className="flex items-center justify-center h-8 w-8 border-2 border-fixwhite rounded-full hover:text-fixgold hover:border-fixgold"
              >
                <FaInstagram />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <hr />
      {/* Copyright Section */}
      <div className=" text-center text-[1rem] p-2">
        &copy; 2022 - {currentYear} Fixryde. All Rights Reserved.
      </div>

      {/* WhatsApp logo */}
      <a
        href="https://wa.me/yourwhatsappnumber"
        className="fixed bottom-5 right-2 md:right-5 z-40"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/images/Whatsapp.png"
          alt="WhatsApp"
          className="w-10 md:w-12 rounded-md"
        />
      </a>
    </footer>
  );
}

export default Footer;

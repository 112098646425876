// src/components/vendor/VehicleMiniVendor.jsx
import React, { Fragment, useState } from "react";
import AssignDriverModal from "./AssignDriverModal";

function VehicleMiniVendor({ vehicle }) {
  const [isAssignDriverModalOpen, setIsAssignDriverModalOpen] = useState(false);

  if (!vehicle) {
    return null;
  }

  const {
    driverPhoneNumber,
    vendorPhoneNumber,
    imageSrc,
    brandName,
    model,
    vehicleNumber,
    vehicleType,
    bgColor,
    checkupStatus,
  } = vehicle;
  
  const driverPhoneColor = driverPhoneNumber ? 'text-green-500' : 'text-red-500';

  return (
    <Fragment>
      <div className="max-w-sm bg-zinc-100 p-4 text-fixblack">
        {/* Vehicle details */}
        <div className="flex flex-wrap items-center justify-between">
          <img
            src={imageSrc}
            alt="VehicleImg"
            className="rounded-full w-10 h-10"
          />
        </div>
        <hr className="bg-fixblack h-0.5 my-2" />

        <div className="flex flex-col flex-wrap justify-between py-1">
          <p className="text-fixblack">
            <b>Brand:</b> {brandName}
          </p>
          <span>
            <b>Model:</b> {model}
          </span>
        </div>
        <div className="flex flex-wrap items-center justify-between py-2">
          <p className="text-fixblack">
            <b>Vehicle Number:</b> {vehicleNumber}
          </p>
          <span>
            <b>Type:</b> {vehicleType}
          </span>
          <span className={driverPhoneColor}>
            <b>Driver Ph:</b> {driverPhoneNumber || 'Not Assigned'}
          </span>
        </div>
        <div className="flex items-center justify-between py-2">
          <button className={`${bgColor} rounded-sm px-2 py-1 text-fixwhite`}>
            {checkupStatus}
          </button>
        </div>

        {/* Button to open AssignDriverModal */}
        <div className="flex justify-end">
          <button
            onClick={() => setIsAssignDriverModalOpen(true)}
            className="border-2 border-gray-300 rounded px-2 py-1 m-2 w-full bg-white font-bold text-center"
          >
            View Assign Driver
          </button>
        </div>
      </div>

      {/* Render the modal conditionally */}
      {isAssignDriverModalOpen && (
        <AssignDriverModal
          onClose={() => setIsAssignDriverModalOpen(false)}
          vehicleNumber={vehicleNumber}
          assignedDriverPhoneNumber={driverPhoneNumber}
          vendorPhoneNumber={vendorPhoneNumber}
        />
      )}
    </Fragment>
  );
}

export default VehicleMiniVendor;

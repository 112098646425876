import React from 'react';

const Badge = ({ title, description, image }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 m-2">
      <img src={image} alt={title} className="w-16 h-16 mx-auto" />
      <h3 className="text-center font-semibold">{title}</h3>
      <p className="text-center text-sm text-gray-600">{description}</p>
    </div>
  );
};

export default Badge;

import React from 'react';
import { useState, Fragment } from "react";
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // To decode the JWT token and extract user role

const ProtectedRoute = ({ children, allowedRoles }) => {
  const token = localStorage.getItem('accessToken');
  console.log("token:ProtectedRoute");
  console.log(token);
  const [directrole, setDirectrole] = useState("");

  if (!token) {
    // If no token, redirect to login page
    return <Navigate to="/user/login" />;
  }

  try {
    // Decode the JWT to get the user's role
    const decodedToken = jwtDecode(token);
    const userRole = decodedToken.role; // Assuming your JWT has a 'role' field
    console.log("userRole:" + userRole , decodedToken);

    if (!allowedRoles.includes(userRole)) {
      switch (userRole) {
        case 'ROLE_ADMIN':
          return <Navigate to="/admin/dashboard" />;
        case 'ROLE_VENDOR':
          // return <Navigate to="/vendor/dashboard" />;
          return <Navigate to="/vendor/alltrip" />;
        case 'ROLE_EMPLOYEE':
          return <Navigate to="/employee/dashboard" />;
        case 'ROLE_DRIVER':
          return <Navigate to="/driver/dashboard" />;
        case 'ROLE_USER':
          return <Navigate to="/user/dashboard" />;
        default:
          return <Navigate to="/home" />;
      }
    }

    return children;
  } catch (error) {
    console.error('Invalid token or error during decoding', error);
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;

import React, { Fragment, useState, useEffect } from "react";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    'ngrok-skip-browser-warning': 'true' // Skip ngrok warning page
  }
});

const AddRate = () => {
  const [sourceDistricts, setSourceDistricts] = useState([]);
  const [destinationDistricts, setDestinationDistricts] = useState([]);

  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [farePerKm, setFarePerKm] = useState(0);
  const [vendors, setVendors] = useState([]);
  const [states, setStates] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [serviceAreas, setServiceAreas] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [vendor, setVendor] = useState("");
  const [partnerType, setPartnerType] = useState("Retail");
  const [state, setState] = useState("");
  const [priceType, setPriceType] = useState("");
  const [sourceDistrict, setSourceDistrict] = useState("");
  const [destinationDistrict, setDestinationDistrict] = useState("");
  const [tripType, setTripType] = useState("");
  const [vehicleCategory, setVehicleCategory] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [vehicleSubCategory, setVehicleSubCategory] = useState("");
  const [ratePlan, setRatePlan] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [extraFarePerKm, setExtraFarePerKm] = useState("");
  const [waitingChargesPerHour, setWaitingChargesPerHour] = useState("");
  const [freeWaitingMinutes, setFreeWaitingMinutes] = useState("");
  const [driverBeta, setDriverBeta] = useState("");

  const [sourceState, setSourceState] = useState('');
  const [destinationState, setDestinationState] = useState('');
  const [tripTypes, setTripTypes] = useState([]);
  const [fetchedFareData, setFetchedFareData] = useState([]); // Store all fetched data

  const fuelTypes = [
    "Any", "Petrol", "Diesel", "Electric", "Hybrid", "CNG", "LPG",
    "Hydrogen", "Oxygen", "Methane", "Natural GAS"
  ];

  useEffect(() => {
    api.get(`${process.env.REACT_APP_API_BASE_URLE}/drivervehicle/vendors/allapproved`)
      .then(response => {
        setVendors(response.data);
        console.log("Approved Vendor List:");
        console.log(response.data);
      })
      .catch(error => {
        console.error("Error fetching vendors:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedVendor) {
      api.get(`${process.env.REACT_APP_API_BASE_URLE}/drivervehicle/vendors/servicearea?vendorUserid=${selectedVendor}`)
        .then(response => {
          console.log("States list fetched:");
          console.log(response.data);
          setServiceAreas(response.data);
          const uniqueStates = [...new Set(response.data.map(area => area.sourceStateName))];
          setStates(uniqueStates);
        })
        .catch(error => {
          console.error("Error fetching service areas:", error);
        });

      api.get(`${process.env.REACT_APP_API_BASE_URLE}/drivervehicle/vehicle/allapproved?vendorUserId=${selectedVendor}`)
        .then(response => {
          setVehicles(response.data);
          console.log("Approved Vehicle List:");
          console.log(response.data);
        })
        .catch(error => {
          console.error("Error fetching vehicles:", error);
        });
    }
  }, [selectedVendor]);

  const fetchSearchedFare = async (vendorUserid, state, tripType) => {
    try {
      const params = {
        vendorUserid,
        states: state,
        tripTypes: tripType,
      };

      const response = await api.get('/drivervehicle/fares/searchedfare', { params });

      console.log("Fetched Searched Fare Data:", response.data);

      // Store all fetched fare data
      setFetchedFareData(response.data);
    } catch (error) {
      console.error("Error fetching searched fare:", error);
    }
  };

  const handleVendorChange = (e) => {
    setStates([]);
    setServiceAreas([]);
    setVehicles([]);
    setSourceState('');
    setDestinationState('');
    setTripTypes([]);
    setState('');
    setTripType('');
    setSourceDistrict('');
    setDestinationDistrict('');
    setVehicleCategory('');
    setVehicleModel('');
    setFuelType('');
    setVehicleSubCategory('');
    setRatePlan('');
    setFromDate('');
    setToDate('');
    setFarePerKm(0);
    setExtraFarePerKm('');
    setWaitingChargesPerHour('');
    setFreeWaitingMinutes('');
    setDriverBeta('');
    setFetchedFareData([]); // Reset fetched data when vendor changes

    const newVendor = e.target.value;
    setSelectedVendor(newVendor);
  };

  const handleStateChange = (selectedState) => {
    setState(selectedState);

    const filteredTripTypes = serviceAreas
      .filter(area => area.sourceStateName === selectedState)
      .map(area => area.tripType);

    setTripTypes(filteredTripTypes);

    if (selectedVendor && selectedState && tripType) {
      fetchSearchedFare(selectedVendor, selectedState, tripType);
    }
  };

  const handleTripTypeChange = (e) => {
    const selectedTripType = e.target.value;
    setTripType(selectedTripType);

    if (selectedVendor && state && selectedTripType) {
      fetchSearchedFare(selectedVendor, state, selectedTripType);
    }
  };

  // Effect to update fare data based on the selected rate plan
  useEffect(() => {
    if (fetchedFareData && ratePlan) {
      const selectedFare = fetchedFareData.find(fare => fare.ratePlan === ratePlan);

      if (selectedFare) {
        setFarePerKm(selectedFare.farePerKm || 0);
        setExtraFarePerKm(selectedFare.extraFarePerKm || 0);
        setWaitingChargesPerHour(selectedFare.waitingChargesPerHour || 0);
        setFreeWaitingMinutes(selectedFare.freeWaitingMinutes || '');
        setDriverBeta(selectedFare.driverBeta || '');
        setFromDate(selectedFare.fromDate || '');
        setToDate(selectedFare.toDate || '');
      } else {
        // Reset fields if no fare is found for the selected rate plan
        setFarePerKm(0);
        setExtraFarePerKm(0);
        setWaitingChargesPerHour(0);
        setFreeWaitingMinutes('');
        setDriverBeta('');
        setFromDate('');
        setToDate('');
      }
    }
  }, [fetchedFareData, ratePlan]);


  const handlefuelTypeChange = (e) => {
    setFuelType(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case 'partnerType': setPartnerType(value); break;
      case 'state': handleStateChange(value); break;
      case 'tripType': setTripType(value); break;
      case 'priceType': setPriceType(value); break;
      case 'sourceState': setSourceState(value); break;
      case 'destinationState': setDestinationState(value); break;
      case 'sourceDistrict': setSourceDistrict(value); break;
      case 'destinationDistrict': setDestinationDistrict(value); break;
      // case 'vehicleCategory': setVehicleCategory(value); break;
      // case 'vehicleModel': setVehicleModel(value); break;
      case 'vehicleCategory':
        setVehicleCategory(value);
        if (value === "Any") {
          setVehicleModel("Any");
        } else if (vehicleModel === "Any") {
          setVehicleModel("");
        }
        break;
      case 'vehicleModel':
        if (vehicleCategory !== "Any") {
          setVehicleModel(value);
        }
        break;
      case 'fuelType': setFuelType(value); break;
      case 'vehicleSubCategory': setVehicleSubCategory(value); break;
      case 'ratePlan': setRatePlan(value); break;
      case 'fromDate': setFromDate(value); break;
      case 'toDate': setToDate(value); break;
      case 'farePerKm': setFarePerKm(value); break;
      case 'extraFarePerKm': setExtraFarePerKm(value); break;
      case 'waitingChargesPerHour': setWaitingChargesPerHour(value); break;
      case 'freeWaitingMinutes': setFreeWaitingMinutes(value); break;
      case 'driverBeta': setDriverBeta(value); break;
      default: break;
    }
  };

  const handlePriceChange = (e) => {
    setFarePerKm(e.target.value);
  };

  useEffect(() => {
    if (states && states.length > 0) {
      api.get(`${process.env.REACT_APP_API_BASE_URLE}/drivervehicle/fares/districtlist`, {
        params: { states: states.join(',') }
      })
        .then(response => {
          console.log("District Lists:");
          console.log(response);
          setSourceDistricts(response.data);
          setDestinationDistricts(response.data);
        })
        .catch(error => {
          console.error('Error fetching districts:', error);
        });
    }
  }, [states]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      vendorUserid: selectedVendor,
      partnerType,
      state,
      tripType,
      priceType,
      sourceState,
      destinationState,
      sourceDistrict,
      destinationDistrict,
      vehicleCategory,
      vehicleModel,
      fuelType,
      vehicleSubCategory,
      ratePlan,
      fromDate,
      toDate,
      farePerKm,
      extraFarePerKm,
      waitingChargesPerHour,
      freeWaitingMinutes,
      driverBeta,
    };
    console.log('Form Data:', formData);
    api.post(`${process.env.REACT_APP_API_BASE_URLE}/drivervehicle/fares/assignrate`, formData)
      .then(response => {
        console.log("Response received:", response);
        toast.success("Fare added successfully.");
      })
      .catch(error => {
        console.error("Error assigning rate:", error);
      });
  };

  return (
    <Fragment>
      <ToastContainer />
      <AdminSidebar
        sidebarToggle={sidebarToggle}
        setSidebarToggle={setSidebarToggle}
      />
      <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? 'ml-0' : 'ml-48'}`}>
        <AdminNavbar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
        />
        <div className="bg-zinc-200 min-h-screen p-4">
          <div className="bg-white shadow-lg p-4 rounded-lg">
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <div className="text-gray-700">
                <h1 className="text-xl font-semibold">All Rates</h1>
                <span>{new Date().toLocaleDateString()}</span>
              </div>
            </div>
          </div>
          <div className="p-6 bg-white my-4">
            <div className="mb-6">
              <h1 className="text-2xl font-semibold">Add Rate</h1>
              <p className="text-sm text-gray-600">Rates in INR</p>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
                <div>
                  <h2 className="text-lg font-semibold">Vendor Details</h2>
                  <div className="grid grid-cols-1 gap-4">
                    <div className="flex flex-col">
                      <label>Vendor *</label>
                      <select className="p-2 border rounded" name="vendor" value={selectedVendor} onChange={handleVendorChange}>
                        <option>Select Vendor</option>
                        {vendors.map(vendor => (
                          <option key={vendor.vendorUserid} value={vendor.vendorUserid}>
                            {vendor.vendorUserid}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col">
                      <label>Partner Type *</label>
                      <select className="p-2 border rounded" name="partnerType" value={partnerType} onChange={handleChange} disabled>
                        {/* <option>Select Type</option> */}
                        <option value="Retail">Retail</option>
                        <option value="Business">Business</option>
                      </select>
                    </div>
                    <div className="flex flex-col">
                      <label>State List*</label>
                      <select className="p-2 border rounded" name="state" value={state} onChange={handleChange}>
                        <option>Select State</option>
                        {states.map(state => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col">
                      <label>Trip Type *</label>
                      <select className="p-2 border rounded" name="tripType" value={tripType} onChange={handleTripTypeChange}>
                        <option>Select Trip Type</option>
                        {tripTypes.map(type => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>

                    {tripType === "RentalDrive" && (
                      <div className="flex flex-col">
                        <label>Package Type *</label>
                        <select className="p-2 border rounded" name="priceType" value={priceType} onChange={handleChange}>
                          <option>Select Package Type</option>
                          <option value="4 hrs 40 kms">4 Hours 40 Kilometers</option>
                          <option value="5 hrs 50 kms">5 Hours 50 Kilometers</option>
                          <option value="6 hrs 60 kms">6 Hours 60 Kilometers</option>
                          <option value="7 hrs 70 kms">7 Hours 70 Kilometers</option>
                          <option value="8 hrs 80 kms">8 Hours 80 Kilometers</option>
                          <option value="9 hrs 90 kms">9 Hours 90 Kilometers</option>
                          <option value="10 hrs 100 kms">10 Hours 100 Kilometers</option>
                          <option value="11 hrs 110 kms">11 Hours 110 Kilometers</option>
                          <option value="12 hrs 120 kms">12 Hours 120 Kilometers</option>
                        </select>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div>
                      <h2 className="text-lg font-semibold">Route Details</h2>
                      <div className="grid grid-cols-1 gap-4">
                        {(tripType === "OnewayTrip" || tripType === "RoundTrip" || tripType === "RentalDrive") && (
                          <div className="flex flex-col">
                            <label>Source State*</label>
                            <select className="p-2 border rounded" name="sourceState" value={sourceState} onChange={handleChange}>
                              <option>Select State</option>
                              {states.map(state => (
                                <option key={state} value={state}>
                                  {state}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        {(tripType === "OnewayTrip" || tripType === "RoundTrip" || tripType === "RentalDrive") && (
                          <div className="flex flex-col">
                            <label>Destination State*</label>
                            <select className="p-2 border rounded" name="destinationState" value={destinationState} onChange={handleChange}>
                              <option>Select State</option>
                              {states.map(state => (
                                <option key={state} value={state}>
                                  {state}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        {(tripType === "AirportPickup" || tripType === "AirportDrop") && (
                          <div className="flex flex-col">
                            <label>Source District*</label>
                            <select className="p-2 border rounded" name="sourceDistrict" value={sourceDistrict} onChange={handleChange}>
                              <option>Select City</option>
                              {sourceDistricts.map(district => (
                                <option key={district} value={district}>{district}</option>
                              ))}
                            </select>
                          </div>
                        )}
                        {(tripType === "AirportPickup" || tripType === "AirportDrop") && (
                          <div className="flex flex-col">
                            <label>Destination District*</label>
                            <select className="p-2 border rounded" name="destinationDistrict" value={destinationDistrict} onChange={handleChange}>
                              <option>Select City</option>
                              {destinationDistricts.map(district => (
                                <option key={district} value={district}>{district}</option>
                              ))}
                            </select>
                          </div>
                        )}
                        <div className="flex flex-col">
                          <label>Vehicle Category *</label>
                          <select className="p-2 border rounded" name="vehicleCategory" value={vehicleCategory} onChange={handleChange}>
                            <option>Select Vehicle Category</option>
                            {/* <option>Any</option> */}
                            {vehicles.map(vehicle => (
                              <option key={vehicle.id} value={vehicle.vehicleCategory}>
                                {vehicle.vehicleCategory}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="flex flex-col">
                          <label>Vehicle Model *</label>
                          <select
                            className="p-2 border rounded"
                            name="vehicleModel"
                            value={vehicleModel}
                            onChange={handleChange}
                            disabled={vehicleCategory === "Any"}
                          >
                            <option>Select Vehicle Model</option>
                            <option>Any</option>
                            {/* {vehicles.map(vehicle => (
                              <option key={vehicle.id} value={vehicle.modelType}>
                                {vehicle.modelType}
                              </option>
                            ))} */}
                          </select>
                        </div>

                        <div className="flex flex-col">
                          <label>Fuel Type *</label>
                          <select className="p-2 border rounded" name="fuelType" value={fuelType} onChange={handlefuelTypeChange}>
                            {fuelTypes.map((type, index) => (
                              <option key={index} value={type}>{type}</option>
                            ))}
                          </select>
                        </div>
                        <div className="flex flex-col">
                          <label>Vehicle SubCategory *</label>
                          <select className="p-2 border rounded" name="vehicleSubCategory" value={vehicleSubCategory} onChange={handleChange}>
                            <option>Select SubCategory</option>
                            <option value="Basic">Basic</option>
                          </select>
                        </div>
                        <div className="flex flex-col">
                          <label>Rate Plan *</label>
                          <select className="p-2 border rounded" name="ratePlan" value={ratePlan} onChange={handleChange}>
                            <option>Select Rate Plan</option>
                            <option value="Holiday">Holiday</option>
                            <option value="WeekDays">WeekDays</option>
                            <option value="General">General</option>
                          </select>
                        </div>
                        {(ratePlan === "Holiday" || ratePlan === "WeekDays") && (
                          <div className="flex flex-col">
                            <label>From Date *</label>
                            <input type="date" className="p-2 border rounded bg-gray-200" name="fromDate" value={fromDate} onChange={handleChange} />
                          </div>
                        )}
                        {(ratePlan === "Holiday" || ratePlan === "WeekDays") && (
                          <div className="flex flex-col">
                            <label>To Date *</label>
                            <input type="date" className="p-2 border rounded bg-gray-200" name="toDate" value={toDate} onChange={handleChange} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <h2 className="text-lg font-semibold">Fare Details</h2>
                      <div className="flex flex-col">
                        <label>
                          <input
                            type="radio"
                            name="fareType"
                            className="mr-2"
                            defaultChecked
                          />
                          Fixed Fare / km
                        </label>
                        <div className="bg-gray-100 p-4 rounded mb-4">
                          <p className="text-gray-600">Fare insights</p>
                          <p className="text-xs text-gray-500">
                            chances of booking
                          </p>
                        </div>
                        <div className="bg-gray-100 p-4 rounded mb-4">
                          <p>Price Range</p>
                          <input
                            className="rounded-lg overflow-hidden appearance-none bg-fixblue h-3 w-128"
                            type="range"
                            min="0"
                            max="100"
                            step="0.1"
                            value={farePerKm}
                            onChange={handlePriceChange}
                          />
                          <p>Selected Price: ₹{farePerKm}</p>
                        </div>
                        <div className="flex flex-col mb-4">
                          <label>Fare /Km *</label>
                          <input
                            type="text"
                            className="p-2 border rounded"
                            placeholder="Enter Fare Per Km"
                            name="farePerKm"
                            value={farePerKm}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="flex flex-col mb-4">
                          <label>Extra Fare /Km *</label>
                          <input
                            type="text"
                            className="p-2 border rounded"
                            placeholder="Enter Extra Fare Per Km"
                            name="extraFarePerKm"
                            value={extraFarePerKm}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="flex flex-col mb-4">
                          <label>Waiting Charges / Hour *</label>
                          <input
                            type="text"
                            className="p-2 border rounded"
                            placeholder="Enter Waiting Charges Per Hour"
                            name="waitingChargesPerHour"
                            value={waitingChargesPerHour}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="flex flex-col mb-4">
                          <label>Free Waiting Minutes *</label>
                          <select className="p-2 border rounded" name="freeWaitingMinutes" value={freeWaitingMinutes} onChange={handleChange}>
                            <option>Select Waiting Minutes</option>
                            <option value="30">30 Minutes</option>
                            <option value="45">45 Minutes</option>
                            <option value="60">60 Minutes</option>
                          </select>
                        </div>
                        {(tripType === "OnewayTrip" || tripType === "RoundTrip" || tripType === "RentalDrive") && (
                          <div className="flex flex-col mb-4">
                            <label>Driver Allowance / Beta *</label>
                            <input
                              type="text"
                              className="p-2 border rounded"
                              placeholder="Enter Driver Beta"
                              name="driverBeta"
                              value={driverBeta}
                              onChange={handleChange}
                            />
                          </div>
                        )}
                        <div className="bg-gray-100 p-4 rounded">
                          <p className="font-semibold">Base Distance</p>
                          <p className="text-xs text-gray-500">01 Km</p>
                          <p className="font-semibold my-2">EXTRA CHARGES</p>
                          <div className="flex flex-col">
                            <div className="flex justify-between text-xs">
                              <span>Airport Toll</span>
                              <span>₹</span>
                            </div>
                            <div className="flex justify-between text-xs">
                              <span>Parking Charges</span>
                              <span>₹</span>
                            </div>
                            <div className="flex justify-between text-xs">
                              <span>Driver Allowance</span>
                              <span>₹</span>
                            </div>
                            <div className="flex justify-between text-xs">
                              <span>Night Charges</span>
                              <span>₹</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <button type="submit" className="bg-fixblue text-white px-4 py-2 rounded">
                  SAVE
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddRate;

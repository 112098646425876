// src/components/admin/AdminSidebar.jsx

import React, { useState, useEffect } from "react";
import { FaTachometerAlt, FaChevronDown } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const AdminSidebar = ({ sidebarToggle, setSidebarToggle }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const location = useLocation();

  // Menu configuration with possible sub-menus
  const menuItems = [
    {
      title: "Dashboard",
      icon: <FaTachometerAlt />,
      path: "/admin/dashboard",
    },
    {
      title: "Rate & Inventory",
      subMenus: [
        { title: "Rate Rank & Edit", path: "/admin/rate" },
        { title: "Add Rates", path: "/admin/addrate" },
        { title: "Eagle View", path: "/admin/eagleview" },
      ],
    },
    {
      title: "Main Area",
      subMenus: [
        { title: "Fleet Panel", path: "/admin/fleet" },
        { title: "My Vendors", path: "/admin/myvendors" },
        { title: "My DriversList", path: "/admin/mydriverslist" },
        { title: "My Vehicles", path: "/admin/myvehicles" },
        { title: "ViewVendor", path: "/admin/viewvendor" },
        { title: "ViewDriver", path: "/admin/viewdriver" },
        { title: "ViewVehicle", path: "/admin/viewvehicle" },
        { title: "ServiceArea", path: "/admin/servicearea" },
        { title: "Covid Safety", path: "/admin/covid" },
      ],
    },
    {
      title: "Permission Area",
      subMenus: [
        { title: "RegisterAll", path: "/admin/registerall" },
        { title: "Admin", path: "/admin/adminallow" },
        { title: "Vendor", path: "/admin/vendorallow" },
        { title: "Driver", path: "/admin/driverallow" },
        { title: "Employee", path: "/admin/employeeallow" },
        { title: "User", path: "/admin/userallow" },
        { title: "Vehicle", path: "/admin/vehicleallow" },
      ],
    },
    {
      title: "Invoicing",
      subMenus: [
        // { title: "User Invoice", path: "/admin/invoice" },
        { title: "Vendor Invoice", path: "/admin/vendorinvoice" },
        { title: "View Invoice", path: "/admin/vendorinvoiceview" },
      ],
    },
    {
      title: "Book & Trip",
      subMenus: [
        { title: "B2C List", path: "/admin/bookingdashboard" },
        { title: "B2B List", path: "#b2b-booklist" },
        // { title: "B2B Book", path: "#b2b-book" },
      ],
    },
    {
      title: "Trips Details",
      subMenus: [
        { title: "Trip Panel", path: "/admin/trip" },
        { title: "All Trip", path: "/admin/alltrip" },
      ],
    },
    // {
    //   title: "Performance",
    //   subMenus: [
    //     { title: "Client Feedback", path: "/admin/feedback" },
    //     { title: "Client Reviews", path: "/admin/reviews" },
    //     { title: "Fleet Performance", path: "/admin/fleetperform" },
    //     { title: "Audit Performance", path: "/admin/auditperform" },
    //   ],
    // },
    {
      title: "Support",
      subMenus: [{ title: "Help & Support", path: "/admin/help" }],
    },
    // {
    //   title: "Add More",
    //   subMenus: [{ title: "Bar chart", path: "#bar-chart" }],
    // },
  ];

  // Function to determine which main menu should be active based on the current path
  const determineActiveMenu = () => {
    const currentPath = location.pathname;
    const active = menuItems.find((menu) =>
      menu.subMenus
        ? menu.subMenus.some((sub) => sub.path === currentPath)
        : menu.path === currentPath
    );
    if (active) {
      setActiveMenu(active.title);
    } else {
      setActiveMenu(null);
    }
  };

  // Set active menu based on current path on mount and when location changes
  useEffect(() => {
    determineActiveMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // Handle main menu toggle
  const handleMainMenuClick = (menuTitle) => {
    setActiveMenu((prevMenu) => (prevMenu === menuTitle ? null : menuTitle));
  };

  // Close the sidebar when a link is clicked (for mobile)
  const handleLinkClick = () => {
    if (window.innerWidth < 768) {
      setSidebarToggle(false);
    }
  };

  // Determine if a link is active based on the current path
  const isActive = (path) => location.pathname === path;

  // Sidebar class based on sidebarToggle
  // const sidebarClass = `w-48 bg-gray-800 h-full fixed px-4 py-6 transition-all duration-300 ${
  const sidebarClass = `w-48 bg-gray-800 h-full fixed transition-all duration-300 ${
    sidebarToggle ? "-left-full" : "left-0 overflow-y-auto"
  }`;

  const sectionTitleClass =
    "text-xs uppercase text-gray-800 font-bold tracking-wider";

  const linkClass =
    "block py-2 pl-3 pr-4 text-sm text-gray-300 hover:bg-fixblue hover:text-white rounded-lg";

  return (
    <>
      {/* Sidebar */}
      <div
        className={sidebarClass}
        aria-label="Sidebar"
      >
        {/* Header */}
        <div className="flex items-center justify-center mb-6 mt-4">
          <FaTachometerAlt color="white" size={20} className="mr-3" />
          <h1 className="text-lg font-semibold text-white">
            <Link to="/admin/dashboard" onClick={handleLinkClick}>
              Dashboard
            </Link>
          </h1>
        </div>

        {/* Menu Sections */}
        <nav className="px-4">
          {menuItems.map((menu, index) => (
            <div key={index} className="mb-4">
              {menu.subMenus ? (
                <>
                  <button
                    onClick={() => handleMainMenuClick(menu.title)}
                    className={`flex items-center justify-between w-full py-2 px-3 text-white text-sm font-medium text-left hover:bg-gray-700 hover:text-fixgold rounded-lg focus:outline-none focus:ring-2 focus:ring-fixblue ${
                      activeMenu === menu.title ? "bg-gray-700" : ""
                    }`}
                    aria-expanded={activeMenu === menu.title}
                  >
                    <span>{menu.title}</span>
                    <FaChevronDown
                      className={`transition-transform ${
                        activeMenu === menu.title ? "transform rotate-180" : ""
                      }`}
                    />
                  </button>
                  {/* Sub-Menu Items */}
                  <ul
                    className={`mt-2 pl-4 space-y-1 ${
                      activeMenu === menu.title ? "block" : "hidden"
                    }`}
                  >
                    {menu.subMenus.map((sub, subIndex) => (
                      <li key={subIndex}>
                        <Link
                          to={sub.path}
                          className={`block py-2 px-3 text-sm rounded-lg hover:bg-fixblue hover:text-white ${
                            isActive(sub.path)
                              ? "bg-fixblue text-white"
                              : "text-gray-300"
                          }`}
                          onClick={handleLinkClick}
                        >
                          {sub.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <Link
                  to={menu.path}
                  className={`flex items-center py-2 px-3 text-sm rounded-lg hover:bg-fixblue hover:text-white ${
                    isActive(menu.path)
                      ? "bg-fixblue text-white"
                      : "text-gray-300"
                  }`}
                  onClick={handleLinkClick}
                >
                  {menu.icon && (
                    <span className="mr-3 text-lg">{menu.icon}</span>
                  )}
                  {menu.title}
                </Link>
              )}
            </div>
          ))}
        </nav>
      </div>
    </>
  );
};

export default AdminSidebar;

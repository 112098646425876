import React from "react";
import PropTypes from "prop-types";
import { FaMapMarkerAlt, FaCalendarAlt, FaUser, FaCarAlt, FaIdCard } from "react-icons/fa";
import { Link } from "react-router-dom";

const TripCard = ({ trip }) => {
  const {
    booking: { bookId, pickupLocation, fare, bookingDate, bookingTime, status },
    tripDetails: { driverId, tripStatus, vehicleNumber },
  } = trip;

  return (
    <section className="bg-white rounded-lg shadow-md overflow-hidden">
      {/* Header Section */}
      <header className="bg-gradient-to-r from-fixblue to-fixred text-white px-6 py-2">
        <div className="flex flex-col justify-between cursor-pointer items-center sm:flex-row">
          <h2 className="text-lg font-semibold">{pickupLocation}</h2>
          <FaCarAlt className="text-4xl" />
        </div>
      </header>

      {/* Details Section */}
      <section className="p-4">
        <div className="flex flex-col space-y-2">
          <div className="flex items-center">
            <FaCalendarAlt className="text-gray-600 mr-2" />
            <div className="text-sm">
              <p className="text-gray-600">Date</p>
              <p>{bookingDate}</p>
              <p>{bookingTime}</p>
            </div>
          </div>
          <div className="flex items-center">
            <FaMapMarkerAlt className="text-gray-600 mr-2" />
            <div className="text-sm">
              <p className="text-gray-600">Pickup</p>
              <p>{pickupLocation}</p>
            </div>
          </div>
          <div className="flex items-center">
            <FaUser className="text-gray-600 mr-2" />
            <div className="text-sm">
              <p className="text-gray-600">Driver</p>
              <p className="text-sm">{driverId}</p>
            </div>
          </div>
          <div className="flex items-center">
            <FaIdCard className="text-gray-600 mr-2" />
            <div className="text-sm">
              <p className="text-gray-600">Vehicle</p>
              <p className="text-sm">{vehicleNumber}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Fare and Distance */}
      <section className="flex justify-between px-4 bg-gray-200">
        <div className="text-gray-800 font-bold">₹ {fare}</div>
        <div className="text-gray-600">Book-ID: {bookId}</div>
      </section>

      {/* Divider */}
      <hr className="border-t border-gray-400" />
      {/* Footer Section */}
      <footer className="flex justify-between px-6 py-2 text-sm text-gray-600">
        <div>{status}</div>
        <Link className="font-semibold">{tripStatus}</Link>
      </footer>
    </section>
  );
};

TripCard.propTypes = {
  trip: PropTypes.object.isRequired,
};

export default TripCard;

import React from 'react';
import VendorDashboard from '../pages/vendorPages/VendorDashboard';
import VendorAllow from '../pages/vendorPages/VendorAllow';
// eslint-disable-next-line
import VendorLogin from '../pages/vendorPages/VendorLogin';
import VendorInvoice from '../pages/vendorPages/VendorInvoice';
import MyPerformance from '../pages/vendorPages/myperformance/MyPerformance';
import MyTripPanel from '../pages/vendorPages/mytrip/MyTripPanel';
import MyVehiclesVendor from '../pages/vendorPages/myfleet/MyVehiclesVendor';
import MyDriversVendor from '../pages/vendorPages/myfleet/MyDriversVendor';
import VehicleFormVendor from '../pages/vendorPages/VehicleFormVendor';
import DriverFormVendor from '../pages/vendorPages/DriverFormVendor';
import VendorRegister from '../pages/vendorPages/VendorRegister';
import MyAllTrip from '../pages/vendorPages/mytrip/MyAllTrip';
import MyTripDetails from '../pages/vendorPages/mytrip/MyTripDetails';
import VendorRateDashboard from '../pages/vendorPages/rate&inventory/VendorRateDashboard';
import VendorAddRate from '../pages/vendorPages/rate&inventory/VendorAddRate';
import VendorEditRate from '../pages/vendorPages/rate&inventory/VendorEditRate';
import VendorInvoiceView from '../pages/vendorPages/VendorInvoiceView';
// import VendorAllTrip from '../pages/'

const vendorRoutes=[
//{path:'/vendor/login',element:<VendorLogin />},
{path:'/vendor/dashboard' , element: <VendorDashboard /> },
{path:'/vendor/myvehicles' , element: <MyVehiclesVendor /> },
{path:'/vendor/vehicleform', element: <VehicleFormVendor /> },
{path:'/vendor/driverform', element: <DriverFormVendor /> },
{path:'/vendor/mydrivers' , element: <MyDriversVendor /> },
// {path:'/vendor/invoice' , element: <VendorInvoice /> },
{path:'/vendor/invoiceview' , element: <VendorInvoiceView /> },
{path:'/vendor/mytrippanel' , element: <MyTripPanel /> },
{path:'/vendor/myperformance' , element: <MyPerformance /> },
{path:'/vendor/vendorallow' , element:<VendorAllow />},
{path:'/vendor/register' , element:<VendorRegister />},
{path:'/vendor/rate', element: <VendorRateDashboard /> },
{path:'/vendor/addrate', element: <VendorAddRate /> },
{path:'/vendor/alltrip', element: <MyAllTrip /> },
{path:'/vendor/editrate', element: <VendorEditRate /> },
{path:'/vendor/tripdetail', element: <MyTripDetails /> }

];

export default vendorRoutes;
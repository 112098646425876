import React, { useState } from 'react'
import Toast from '../../components/common/Toast';

function DemoToast() {
    const [toasts, setToasts] = useState([]);

  const showToast = (message) => {
    setToasts([...toasts, { id: Date.now(), message }]);
  };

  const closeToast = (id) => {
    setToasts(toasts.filter((toast) => toast.id !== id));
  };
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-200">
    <h1 className="text-3xl font-bold mb-4">Toast Notification Example</h1>

    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      onClick={() => showToast('This is a toast message!')}
    >
      Show Toast
    </button>

    <div className="absolute top-0 right-0 mb-4 mr-4">
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          message={toast.message}
          onClose={() => closeToast(toast.id)}
        />
      ))}
    </div>
  </div>
  )
}

export default DemoToast
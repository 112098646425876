import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Create an Axios instance with the custom header
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    'ngrok-skip-browser-warning': 'true', // Skip ngrok warning page
    'Content-Type': 'application/json'
  }
});

function VehicleData({ columns, tableName }) {
    // Define alternative words and colors for status and approval
    const statusWords = ['active', 'inactive'];
    const statusColors = ['bg-green-600', 'bg-red-600'];
    const approvalWords = ['approved', 'not approved'];
    const approvalColors = ['bg-green-600', 'bg-red-600'];

    // State to hold fetched data and track status and approval
    const [data, setData] = useState([]);
    const [status, setStatus] = useState([]);
    const [approval, setApproval] = useState([]);

    // Fetch vehicle data when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`/drivervehicle/accountactivation/vehicle`);
                const fetchedData = response.data;
                console.log("fetchedData", fetchedData);  // Log to check data

                setData(fetchedData);
                setStatus(fetchedData.map(vehicle => vehicle.active ? 0 : 1));
                setApproval(fetchedData.map(vehicle => vehicle.approved ? 0 : 1));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Function to handle status button click
    const handleStatusButtonClick = async (rowIndex, vehicleId) => {
        const newStatusIndex = (status[rowIndex] + 1) % statusWords.length;
        const newStatus = newStatusIndex === 0; // true for active, false for inactive

        try {
            await api.put(`/drivervehicle/accountactivation/vehicle/update`, null, {
                params: {
                    vehicleId,
                    activate: newStatus
                }
            });
            setStatus(prevStatus => {
                const updatedStatus = [...prevStatus];
                updatedStatus[rowIndex] = newStatusIndex;
                return updatedStatus;
            });

            // Display the appropriate toast message based on the new status
            if (newStatus) {
                toast.success('Vehicle Activated');
            } else {
                toast.warning('Vehicle Deactivated');
            }
        } catch (error) {
            toast.error('Unable to Update Vehicle Status');
            console.error('Error updating status:', error);
        }
    };

    // Function to handle approval button click
    const handleApprovalButtonClick = async (rowIndex, vehicleId) => {
        const newApprovalIndex = (approval[rowIndex] + 1) % approvalWords.length;
        const newApproval = newApprovalIndex === 0; // true for approved, false for not approved

        try {
            await api.put(`/drivervehicle/accountactivation/vehicle/update`, null, {
                params: {
                    vehicleId,
                    approve: newApproval
                }
            });
            setApproval(prevApproval => {
                const updatedApproval = [...prevApproval];
                updatedApproval[rowIndex] = newApprovalIndex;
                return updatedApproval;
            });

            // Display the appropriate toast message based on the new approval status
            if (newApproval) {
                toast.success('Vehicle Approved');
            } else {
                toast.warning('Vehicle Disapproved');
            }
        } catch (error) {
            toast.error('Unable to Update Vehicle Approval');
            console.error('Error updating approval:', error);
        }
    };

    return (
        <div className="container mx-auto">
            <ToastContainer />
            <h2 className="text-2xl font-semibold mb-4">{tableName}</h2> {/* Dynamic table name */}
            <div className="overflow-x-auto">
                <table className="table-auto">
                    <thead>
                        <tr>
                            {columns.map((column, index) => (
                                <th key={index} className="px-4 py-2">{column}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, rowIndex) => (
                            <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-gray-200' : 'bg-white'}>
                                {columns.map((column, columnIndex) => (
                                    <td key={columnIndex} className="border px-4 py-2">
                                        {column === 'Status' ? (
                                            <button className={`font-semibold px-3 py-1 rounded-md ${statusColors[status[rowIndex]]} text-white`} onClick={() => handleStatusButtonClick(rowIndex, row.id)}>
                                                {statusWords[status[rowIndex]]}
                                            </button>
                                        ) : column === 'Approval' ? (
                                            <button className={`font-semibold px-3 py-1 rounded-md ${approvalColors[approval[rowIndex]]} text-white`} onClick={() => handleApprovalButtonClick(rowIndex, row.id)}>
                                                {approvalWords[approval[rowIndex]]}
                                            </button>
                                        ) : column === 'Driver' ? (
                                            row.driverPhoneNumber ? row.driverPhoneNumber : 'No Driver'
                                        ) : column === 'Vehicle Number' ? (
                                            row.vehicleNumber
                                        ) : column === 'Model' ? (
                                            row.modelType
                                        ) : column === 'Category(Type)' ? (
                                            row.vehicleCategory
                                        ) : column === 'Vendor' ? (
                                            row.vendorPhoneNumber
                                        ) : (
                                            row[column.replace(" ", "").toLowerCase()] // Access the row data dynamically based on column names
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

// PropTypes to ensure correct usage
VehicleData.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    tableName: PropTypes.string.isRequired,
};

export default VehicleData;

import React from "react";

function MiniDetails({ tripPanelHeading, viewMoreHeading, miniData }) {
  return (
    <div className="p-2 w-full">
      <div className="flex justify-between mb-2 p-2">
        <h2 className="text-gray-800 text-sm font-bold uppercase">
          {tripPanelHeading}
        </h2>
        <a
          href="#view-more"
          className="text-fixblue text-sm font-bold uppercase"
        >
          {viewMoreHeading}
        </a>
      </div>
      <hr className="border-t-1 border-gray-300 mb-4" />
      <div className="grid grid-cols-2 gap-2">
        {miniData.map((data, index) => (
          <div
            key={index}
            className={`bg-${data.color}-400 text-white rounded-lg p-2 text-center`}
          >
            <h3 className="text-sm font-semibold">{data.heading}</h3>
            <p className="text-lg font-bold">{data.data}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MiniDetails;

import UserPanel from '../pages/userPages/UserPanel';
import BookingHistory from '../pages/userPages/BookingHistory';
import PaymentOptions from '../pages/userPages/PaymentOptions';
import PaymentPage from '../pages/userPages/PaymentPage';
import ConfirmationPage from '../pages/userPages/ConfirmationPage';

const userRoutes = [
 
  {
    path: '/user/dashboard',
    element: <UserPanel />
  },
  {
    path: '/user/history',
    element: <BookingHistory />
  },
  {path:'/user/payment' ,
    element: <PaymentPage />
},
  {path:'/user/payoption' ,
    element: <PaymentOptions />
  },
  {path:'/user/confirm' ,
    element: <ConfirmationPage />
  },
];

export default userRoutes;

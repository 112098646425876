import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import EmployeeNavbar from '../../components/site/EmployeeNavbar';
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
// import DirectAssignDetails from './DirectAssignDetails';
// import DriverAssignDetails from './DriverAssignDetails';
import DirectAssignDetails from '../../employeePages/DirectAssignDetails';
import DriverAssignDetails from '../../employeePages/DriverAssignDetails';
import { Link } from 'react-router-dom';
import {
  RiFileCopy2Line,
  RiMap2Fill,
  RiWhatsappFill,
  RiFlightTakeoffLine,
  RiCoupon3Line,
  RiMoneyDollarCircleLine,
} from 'react-icons/ri';
import {
  FaUser,
  FaPhone,
  FaEnvelope,
  FaCarSide,
  FaClipboardList,
  FaArrowRight,
} from 'react-icons/fa';
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditTripDetails() {
  const location = useLocation();
  const { state } = location;
  const [sidebarToggle, setSidebarToggle] = useState(false);

  const navigate = useNavigate();
  const [showDirectAssignModal, setShowDirectAssignModal] = useState(false);
  const [showDriverAssignModal, setShowDriverAssignModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);

  const [userId, setUserId] = useState(state?.userId || "");
  const [driverId, setDriverId] = useState(state?.driverId || "");
  const [bookingId, setBookingId] = useState(state?.bookId || "");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [driverMobileNumber, setDriverMobileNumber] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverNotes, setDriverNotes] = useState(state?.driverNotes || "");
  const [paymentTransactionId, setPaymentTransactionId] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [tripAssignedByVendor, setTripAssignedByVendor] = useState(state?.additionalData?.tripAssignedByVendor);
  const [updateby, setUpdateby] = useState(state?.updateby);
  const [fareData, setFareData] = useState([]);
  const [filteredFareData, setFilteredFareData] = useState([]);

  const [previousTime, setPreviousTime] = useState('');
  const [timeChanged, setTimeChanged] = useState(false);

  // Added useState hooks for all other variables
  const [tripType, setTripType] = useState(state?.tripType || "");
  const [pickupLocation, setPickupLocation] = useState(state?.pickupLocation || "");
  const [dropLocation, setDropLocation] = useState(state?.dropLocation || "");
  const [bookingDate, setBookingDate] = useState(state?.bookingDate || "");
  const [bookingTime, setBookingTime] = useState(state?.bookingTime || "");
  const [passengerCount, setPassengerCount] = useState(state?.passengerCount || "");
  const [luggageCount, setLuggageCount] = useState(state?.luggageCount || "");
  const [carrier, setCarrier] = useState(state?.carrier || false);
  const [vehicleType, setVehicleType] = useState(state?.vehicleType || "");
  const [distanceKm, setDistanceKm] = useState(state?.distanceKm || "");
  const [tripDuration, setTripDuration] = useState(state?.tripDuration || "");
  const [paymentType, setPaymentType] = useState(state?.paymentType || "");
  const [ourFare, setOurFare] = useState(state?.fare || 0);
  const [actualFare, setActualFare] = useState(state?.fare || 0);
  const [operatorFare, setOperatorFare] = useState(state?.operatorFare || 0);
  const [profitPercent, setProfitPercent] = useState(0);
  const [profitAmount, setProfitAmount] = useState(0.00);

  const [gst, setGst] = useState("");
  const [flightNumber, setFlightNumber] = useState("");
  const [promoCheckbox, setPromoCheckbox] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoValueCheckbox, setPromoValueCheckbox] = useState(false);
  const [promoValue, setPromoValue] = useState("");
  const [adminNotes, setAdminNotes] = useState("");

  // New state variables for paidAmount and remainingAmount
  const [paidAmount, setPaidAmount] = useState(state?.paidAmount || 0);
  const [remainingAmount, setRemainingAmount] = useState(state?.remainingAmount || 0);

  const [selectedCategory, setSelectedCategory] = useState(state?.vehicleType || "");
  const [selectedFare, setSelectedFare] = useState(null);
  const [selectedVendorIndex, setSelectedVendorIndex] = useState(null);

  const [sourceLatitude, setSourceLatitude] = useState(state?.sourceLatitude);
  const [sourceLongitude, setSourceLongitude] = useState(state?.sourceLongitude);
  const [destinationLatitude, setDestinationLatitude] = useState(state?.destinationLatitude);
  const [destinationLongitude, setDestinationLongitude] = useState(state?.destinationLongitude);

  const googleMapsLink = `https://www.google.com/maps/dir/?api=1&origin=${sourceLatitude},${sourceLongitude}&destination=${destinationLatitude},${destinationLongitude}`;
  useEffect(() => {
    if (state) {
      console.log('selectedVendor from DirectAssign:',selectedVendor);
      // Prepopulate form fields with the data from state
      console.log('Received trip data:', state);
      fetchUserData();
      fetchDriverData();
      fetchPaymentData();

      const profitMapping = {
        "AirportPickup": 10,
        "AirportDrop": 10,
        "OnewayTrip": 15,
        "RoundTrip": 15,
        "RentalDrive": 20
      };

      // Set profitPercent and calculate profitAmount if selectedFare is available
      if (tripType && selectedFare?.totalFare) {
        const newProfitPercent = profitMapping[tripType] || 0;
        setProfitPercent(newProfitPercent);

        // Calculate the new profit amount based on the total fare and profit percentage
        const newProfitAmount1 = (newProfitPercent / 100) * selectedFare.totalFare;
        const newProfitAmount = (selectedFare.totalFare - newProfitAmount1)
        setProfitAmount(newProfitAmount.toFixed(2));
        setActualFare(selectedFare.totalFare);
      } else {
        const newProfitPercent = profitMapping[tripType] || 0;
        setProfitPercent(newProfitPercent);

        // Calculate the new profit amount based on the total fare and profit percentage
        const newProfitAmount1 = (newProfitPercent / 100) * state?.fare;
        const newProfitAmount = (state?.fare - newProfitAmount1)
        setProfitAmount(newProfitAmount.toFixed(2));
        setActualFare(state?.fare);
      }

    }
  }, [state]);

  useEffect(() => {
    if (bookingTime !== previousTime) {
      console.log(`Travel time changed from ${previousTime} to ${bookingTime}`);
      setTimeChanged(true);
      setPreviousTime(bookingTime);
    } else {
      setTimeChanged(false);
    }
  }, [bookingTime, previousTime]);

  useEffect(() => {
    if (fareData.length > 0) {
      const newFilteredFareData = fareData.filter(vendor => vendor.vehicleCategory === selectedCategory);
      setFilteredFareData(newFilteredFareData);
    }
  }, [selectedCategory, fareData]);

  useEffect(() => {
    const profitMapping = {
      "AirportPickup": 10,
      "AirportDrop": 10,
      "OnewayTrip": 15,
      "RoundTrip": 15,
      "RentalDrive": 20
    };

    // Set profitPercent and calculate profitAmount if selectedFare is available
    if (tripType && selectedFare?.totalFare) {
      const newProfitPercent = profitMapping[tripType] || 0;
      setProfitPercent(newProfitPercent);

      // Calculate the new profit amount based on the total fare and profit percentage
      const newProfitAmount1 = (newProfitPercent / 100) * selectedFare.totalFare;
      const newProfitAmount = (selectedFare.totalFare - newProfitAmount1)
      setProfitAmount(newProfitAmount.toFixed(2));
      setActualFare(selectedFare.totalFare);
    } else {
      const newProfitPercent = profitMapping[tripType] || 0;
      setProfitPercent(newProfitPercent);

      // Calculate the new profit amount based on the total fare and profit percentage
      const newProfitAmount1 = (newProfitPercent / 100) * state?.fare;
      const newProfitAmount = (state?.fare - newProfitAmount1)
      setProfitAmount(newProfitAmount.toFixed(2));
      setActualFare(state?.fare);
    }
  }, [selectedFare, actualFare, selectedVendorIndex]);


  const ItemWithTitle = ({ title, content, icon: Icon, iconColor }) => (
    <div className="mb-2">
      <h2 className="text-sm font-bold flex items-center">
        {Icon && <Icon className={`mr-2 ${iconColor}`} />}
        {title}
      </h2>
      <p className="text-gray-700">{content}</p>
    </div>
  );

  const ItemWithMultipleLines = ({ title, lines, icon: Icon, iconColor }) => (
    <div className="mb-2">
      <h2 className="text-sm font-bold flex items-center">
        {Icon && <Icon className={`mr-2 ${iconColor}`} />}
        {title}
      </h2>
      {lines.map((line, index) => (
        <p key={index} className="text-gray-700">
          {line}
        </p>
      ))}
    </div>
  );

  // Updated TripButton Component to accept color and icon
  const TripButton = ({ name, icon: Icon, onClick, color, disabled }) => (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`text-white text-sm font-bold py-2 px-4 rounded transition duration-300 flex items-center justify-center shadow-md hover:shadow-lg ${color} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
      {Icon && <Icon className="mr-2" />}
      {name}
    </button>
  );

  const fetchAutoBidFare = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URLE}/drivervehicle/fares/autobidfare`,
        {
          tripType: tripType,
          sourceLatitude: state?.sourceLatitude,
          sourceLongitude: state?.sourceLongitude,
          destinationLatitude: state?.destinationLatitude,
          destinationLongitude: state?.destinationLongitude,
          bookingDate: bookingDate,
          bookingTime: bookingTime,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'ngrok-skip-browser-warning': 'true',
          },
        }
      );

      const { fareDetailsList = [] } = response.data;

      console.log("fareDetailsList:", fareDetailsList);
      setFareData(fareDetailsList);


    } catch (error) {
      console.error("Error fetching auto bid fare:", error);
    }
  };

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URLE}/bookings/customerprofile`, {
        params: { userId },
        headers: {
          "Authorization": `Bearer ${token}`,
          'ngrok-skip-browser-warning': 'true',
          "Content-Type": "application/json"
        }
      });

      const fetchedProfileData = response.data;
      setFullName((fetchedProfileData.fullName || '').replace(/\bnull\b/g, '').trim());
      setEmail(fetchedProfileData.email || "user@example.com");
      setMobileNumber(fetchedProfileData.mobileNumber || "");

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleBookingTimeChanged = async () => {
    if (timeChanged) {
      toast.warn("Booking Time not changed");
      return;
    }

    const changeBookingTimeRequest = {
      bookingId: bookingId,
      newBookingTime: bookingTime,
      driverNotes: driverNotes,
      adminNotes: adminNotes,
      flightNumber: flightNumber,
      updateBy: JSON.parse(localStorage.getItem('user'))?.sub || null
    };

    console.log("changeBookingTimeRequest:");
    console.log(changeBookingTimeRequest);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URLB}/bookings/changebookingtime`,
        changeBookingTimeRequest,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'ngrok-skip-browser-warning': 'true',
          },
        }
      );

      console.log(response.data);
      toast.success(response.data);
    } catch (error) {
      console.error("Error Booking TIme Change:", error);
      toast.error("Unable to Change Booking TIme:", error);
    }
  };

  const handleOperatorAssign = async () => {
    if (!selectedFare) {
      toast.warn("Please select a fare before assigning an operator.");
      return;
    }

    const token = localStorage.getItem("accessToken");
    // console.log(token);
    const operatorAssignPayload = {
      bookingId: bookingId,
      actualFare: actualFare,
      ourFare: ourFare,
      tripType: tripType,
      bookingDate: bookingDate,
      bookingTime: bookingTime,
      token: token,
      vendorUserid: selectedFare.vendorUserid,
      destinationDistrict: selectedFare.destinationDistrict,
      destinationState: selectedFare.destinationState || null,
      driverBeta: selectedFare.driverBeta || null,
      extraFarePerKm: selectedFare.extraFarePerKm,
      farePerKm: selectedFare.farePerKm,
      freeWaitingMinutes: selectedFare.freeWaitingMinutes,
      partnerType: selectedFare.partnerType,
      sourceDistrict: selectedFare.sourceDistrict,
      sourceState: selectedFare.sourceState || null,
      state: selectedFare.state,
      vehicleCategory: selectedFare.vehicleCategory,
      waitingChargesPerHour: selectedFare.waitingChargesPerHour,
      vendorName: selectedFare.vendorName,
      companyName: selectedFare.companyName,
      vehicleId: selectedFare.vehicleId,
    };

    console.log("payload: Selected");
    console.log(operatorAssignPayload);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URLB}/bookings/operatorassign`,
        operatorAssignPayload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'ngrok-skip-browser-warning': 'true',
          },
        }
      );

      console.log(response.data);
      toast.success(response.data);
    } catch (error) {
      console.error("Error assigning operator:", error);
      toast.error("Error assigning operator:", error);
      // Handle error
    }
  };


  const fetchPaymentData = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URLE}/bookings/tripbookings/trips/paymentbookingid`, {
        params: { bookingId },
        headers: {
          "Authorization": `Bearer ${token}`,
          'ngrok-skip-browser-warning': 'true',
          "Content-Type": "application/json"
        }
      });

      const fetchedPaymentData = response.data;
      setPaymentTransactionId(fetchedPaymentData.transactionId || "");
      setPaymentMethod(fetchedPaymentData.paymentMethod || "");

      // Assuming fetchedPaymentData contains paidAmount and remainingAmount
      setPaidAmount(fetchedPaymentData.paidAmount || 0);
      setRemainingAmount(fetchedPaymentData.remainingAmount || 0);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchDriverData = async () => {
    try {

      let formattedMobileNumber = driverId;

      if (driverId.length === 12 && driverId.startsWith('91')) {
        // Remove '91' if it's already there
        formattedMobileNumber = driverId;
      } else if (driverId.length === 10) {
        // Add '91' if it's a 10-digit number
        // console.log("dont have 91");
        formattedMobileNumber = `91${driverId}`;
      }

      
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URLE}/drivervehicle/drivers/details`, {
        params: { mobileNumber: formattedMobileNumber },
        headers: {
          "Authorization": `Bearer ${token}`,
          'ngrok-skip-browser-warning': 'true',
          "Content-Type": "application/json"
        }
      });

      const fetchedDriverDetail = response.data;
      setDriverName((fetchedDriverDetail.name || '').replace(/\bnull\b/g, '').trim());
      setDriverMobileNumber(fetchedDriverDetail.mobileNumber || "");

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleButtonClick = (note) => {
    setDriverNotes((prevNotes) => `${prevNotes}${prevNotes ? '\n' : ''}${note}`);
  };

  // ** New Handler Functions **

  // Function to generate Customer Message Template
  const generateCustomerMessage = () => {
    return `Dear Fixryde Customer,

Your booking has been confirmed with the following details:

Booking ID: ${bookingId}
Booking Date: ${bookingDate}
Time: ${bookingTime}
Pickup Location: ${pickupLocation}
Drop Location: ${dropLocation}
Total Fare: ₹ ${actualFare}
Paid Amount: ₹ ${paidAmount}
Amount Remaining: ₹ ${remainingAmount}

Please pay the remaining amount to the driver.

Enjoy your journey with Fixryde!!`;
  };

  // Function to generate Driver Message Template
  const generateDriverMessage = () => {
    return `Hello ${driverName},

You have a new trip assigned. Here are the details:

Booking ID: ${bookingId}
Customer Name: ${fullName}
Customer Phone: ${mobileNumber}
Booking Date: ${bookingDate}
Time: ${bookingTime}
Pickup Location: ${pickupLocation}
Drop Location: ${dropLocation}
Total Fare: ₹ ${actualFare}
Paid Amount: ₹ ${paidAmount}
Amount Remaining: ₹ ${remainingAmount}

Please ensure to collect the remaining amount from the customer,
And ensure timely pickup and drop-off.

Thank you for choosing Fixryde! Safe driving.`;
  };

  // Function to copy text to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success('Trip details copied to clipboard!');
      })
      .catch((err) => {
        console.error('Could not copy text: ', err);
      });
  };

  // Function to send WhatsApp message
  const sendWhatsAppMessage = (mobile, message) => {
    if (!mobile) {
      toast.warn('Mobile number is not available.');
      return;
    }
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${mobile}?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };

  const openMaps = () => {
    window.open(googleMapsLink, '_blank');
  };

  const handleDriverAssignClose = (assignedData) => {
    if (assignedData && assignedData.selectedDriver) {
      setSelectedDriver(assignedData.selectedDriver);
      setBookingId(assignedData.bookId);
      // setTripAssignedByVendor(true);
    }
    setShowDriverAssignModal(false);
  };

  const handleDirectAssignClose = (assignedData) => {
    if (assignedData && assignedData.selectedVendor) {
      setSelectedVendor(assignedData.selectedVendor);
      setBookingId(assignedData.bookId);
      // setTripAssignedByVendor(true);
    }
    setShowDirectAssignModal(false);
  };

  return (
    <Fragment>
      <ToastContainer />
      {/* <div className="bg-gray-100 min-h-screen"> */}

      <div className="flex">
        <AdminSidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
        <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
          <AdminNavbar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
          {/* Section 1: Customer Information */}
          {/* <div className="bg-white shadow-md rounded-md p-6 mb-8"> */}
          <div className={`shadow-md rounded-md p-6 mb-8 ${tripAssignedByVendor ? 'bg-white' : 'bg-red-100'}`}>
            <h3 className="mb-6 text-xl font-semibold flex items-center justify-between">
              <div className="flex items-center">
                <FaClipboardList className="text-blue-500 mr-2" />
                <b>Request Id:</b> {bookingId} <b>, Status : </b> {state?.status}
              </div>
              <div className="flex items-center">
                <div className={`text-sm ${tripAssignedByVendor ? 'text-green-500' : 'text-red-500'} mr-4`}>
                  {tripAssignedByVendor ? 'Assigned by Vendor' : 'Not Assigned'}
                </div>
                <div className="text-sm text-gray-600">
                  <b>Updated By:</b> {updateby}
                </div>
              </div>
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-6">
              {/* Trip Type */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Trip Type</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={tripType}
                  // onChange={(e) => setTripType(e.target.value)}
                  placeholder="Trip Type"
                  readOnly
                />
              </div>
              {/* Customer Name */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Customer Name</label>
                <div className="flex items-center border border-gray-300 rounded-md px-3 py-2 focus-within:ring-2 focus-within:ring-blue-500 transition duration-300">
                  <FaUser className="text-blue-500 mr-2" />
                  <input
                    type="text"
                    className="w-full focus:outline-none"
                    value={fullName}
                    // onChange={(e) => setFullName(e.target.value)}
                    placeholder="Customer Name"
                    readOnly
                  />
                </div>
              </div>
              {/* Email ID */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Email ID</label>
                <div className="flex items-center border border-gray-300 rounded-md px-3 py-2 focus-within:ring-2 focus-within:ring-blue-500 transition duration-300">
                  <FaEnvelope className="text-red-500 mr-2" />
                  <input
                    type="email"
                    className="w-full focus:outline-none"
                    value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                    placeholder="Customer Email"
                    readOnly
                  />
                </div>
              </div>
              {/* Phone Number */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Phone Number</label>
                <div className="flex items-center border border-gray-300 rounded-md px-3 py-2 focus-within:ring-2 focus-within:ring-blue-500 transition duration-300">
                  <FaPhone className="text-green-500 mr-2" />
                  <input
                    type="tel"
                    className="w-full focus:outline-none"
                    value={mobileNumber}
                    // onChange={(e) => setMobileNumber(e.target.value)}
                    placeholder="Customer Phone"
                    readOnly
                  />
                </div>
              </div>
              {/* Action Icons */}
              <div className="flex items-center justify-center">
                <div className="flex gap-4">
                  {/* Customer Copy Button */}
                  <RiFileCopy2Line
                    className="text-orange-500 text-3xl hover:text-orange-600 transition duration-300 cursor-pointer"
                    title="Copy Customer Details"
                    onClick={() => copyToClipboard(generateCustomerMessage())}
                  />
                  {/* Customer WhatsApp Button */}
                  <RiWhatsappFill
                    className="text-green-500 text-3xl hover:text-green-600 transition duration-300 cursor-pointer"
                    title="Send Customer Details via WhatsApp"
                    onClick={() => sendWhatsAppMessage(mobileNumber, generateCustomerMessage())}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Section 2: Trip Details */}
          <div className="bg-blue-50 shadow-md rounded-md p-6 mb-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
              {/* Pickup Location */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Pickup *</label>
                <div className="flex items-center border border-blue-300 rounded-md px-3 py-2 focus-within:ring-2 focus-within:ring-blue-500 transition duration-300">
                  <FaArrowRight className="text-purple-500 mr-2" />
                  <input
                    type="text"
                    className="w-full focus:outline-none"
                    value={pickupLocation}
                    // onChange={(e) => setPickupLocation(e.target.value)}
                    placeholder="Pickup Location"
                    readOnly
                  />
                </div>
              </div>
              {/* Drop Location */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Drop *</label>
                <div className="flex items-center border border-blue-300 rounded-md px-3 py-2 focus-within:ring-2 focus-within:ring-blue-500 transition duration-300">
                  <FaArrowRight className="text-purple-500 mr-2 transform rotate-180" />
                  <input
                    type="text"
                    className="w-full focus:outline-none"
                    value={dropLocation}
                    // onChange={(e) => setDropLocation(e.target.value)}
                    placeholder="Drop Location"
                    readOnly
                  />
                </div>
              </div>
              {/* Travel Map */}
              <div className="flex flex-col items-center justify-center">
                <label className="mb-2 font-medium">Travel Map</label>
                <div className="border border-blue-300 p-6 rounded-md flex items-center justify-center hover:shadow-lg transition duration-300 cursor-pointer" onClick={openMaps}>
                  <RiMap2Fill className="text-green-500 text-5xl" />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {/* Travel Date */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Date Of Journey</label>
                <input
                  type="date"
                  className="border border-blue-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                  value={bookingDate}
                  onChange={(e) => setBookingDate(e.target.value)}
                />
              </div>
              {/* Travel Time */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Travel Time</label>
                <input
                  type="time"
                  className="border border-blue-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                  value={bookingTime}
                  onChange={(e) => setBookingTime(e.target.value)}
                />
              </div>
              {/* Customer Count */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Customer Count</label>
                <input
                  type="number"
                  className="border border-blue-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                  value={passengerCount}
                  onChange={(e) => setPassengerCount(e.target.value)}
                  placeholder="Count"
                />
              </div>
              {/* Luggage Count */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Luggage Count</label>
                <input
                  type="number"
                  className="border border-blue-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                  value={luggageCount}
                  onChange={(e) => setLuggageCount(e.target.value)}
                  placeholder="Count"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Date Of Booking</label>
                <input
                  type="text"
                  className="w-full focus:outline-none"
                  value={state?.createdt}
                  placeholder="DOB"
                  readOnly
                />
              </div>
              {state?.status === 'CANCELLED' && (
                <>
                  <div className="flex flex-col">
                    <label className="mb-1 font-medium">Date Of Cancel</label>
                    <input
                      type="text"
                      className="w-full focus:outline-none"
                      value={state?.updatedt}
                      placeholder="DOC"
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-1 font-medium">Cancel Reason</label>
                    <input
                      type="text"
                      className="w-full focus:outline-none"
                      value={state?.reason}
                      placeholder="Cancel Reason"
                      readOnly
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Section 3: Additional Details */}
          <div className="bg-green-50 shadow-md rounded-md p-6 mb-8">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-6">
              {/* Carrier */}
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2 leading-tight"
                  checked={carrier}
                  onChange={(e) => setCarrier(e.target.checked)}
                />
                <label className="font-medium">Carrier</label>
              </div>
              {/* Vehicle Type */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Vehicle Type</label>
                <div className="flex items-center border border-green-300 rounded-md px-3 py-2 focus-within:ring-2 focus-within:ring-green-500 transition duration-300">
                  <FaCarSide className="text-indigo-500 mr-2" />
                  <input
                    type="text"
                    className="w-full focus:outline-none"
                    value={vehicleType}
                    // onChange={(e) => setVehicleType(e.target.value)}
                    placeholder="Vehicle Type"
                    readOnly
                  />
                </div>
              </div>
              {/* Distance */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Distance</label>
                <input
                  type="text"
                  className="border border-green-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300"
                  value={distanceKm}
                  // onChange={(e) => setDistanceKm(e.target.value)}
                  placeholder="Distance"
                  readOnly
                />
              </div>
              {/* Duration */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Duration</label>
                <input
                  type="text"
                  className="border border-green-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300"
                  value={tripDuration}
                  // onChange={(e) => setTripDuration(e.target.value)}
                  placeholder="Duration"
                  readOnly
                />
              </div>
              {/* Transaction ID */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Transaction ID</label>
                <input
                  type="text"
                  className="border border-green-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300"
                  value={paymentTransactionId}
                  // onChange={(e) => setPaymentTransactionId(e.target.value)}
                  placeholder="Transaction ID"
                  readOnly
                />
              </div>
              {/* Payment Type */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Payment Type</label>
                <input
                  type="text"
                  className="border border-green-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300"
                  value={paymentType}
                  // onChange={(e) => setPaymentType(e.target.value)}
                  placeholder="Payment Type"
                  readOnly
                />
              </div>
            </div>
          </div>

          {/* Section 4: Fare Details */}
          <div className="bg-yellow-50 shadow-md rounded-md p-6 mb-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-6">
              {/* Our Fare */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Our Fare</label>
                <input
                  type="number"
                  className="border border-yellow-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                  value={ourFare}
                  // onChange={(e) => setOurFare(e.target.value)}
                  // placeholder="Our Fare"
                  readOnly
                />
              </div>
              {/* Actual Fare */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Actual Fare</label>
                <input
                  type="number"
                  className="border border-yellow-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                  value={actualFare}
                  onChange={(e) => setActualFare(e.target.value)}
                  placeholder="Actual Fare"
                />
              </div>
              {/* Operator Fare */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Operator Fare</label>
                <input
                  type="number"
                  className="border border-yellow-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                  value={profitAmount}
                  // onChange={(e) => setOperatorFare(e.target.value)}
                  placeholder="Operator Fare"
                  readOnly
                />
              </div>
              {/* Profit (%) */}
              <div className="flex flex-col">
                <label className="mb-1 font-medium">Profit (%)</label>
                <input
                  type="number"
                  className="border border-yellow-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                  value={profitPercent}
                  readOnly
                // onChange={(e) => setProfitPercent(e.target.value)}
                // placeholder="Profit (%)"
                />
              </div>

              <div className="flex flex-col">
                <label className="mb-1 font-medium">Paid:</label>
                <input
                  type="number"
                  className="border border-yellow-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                  value={paidAmount.toFixed(2)}
                  readOnly
                />
              </div>

              <div className="flex flex-col">
                <label className="mb-1 font-medium">To Collect:</label>
                <input
                  type="number"
                  className="border border-yellow-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                  value={remainingAmount.toFixed(2)}
                  readOnly
                />
              </div>

            </div>
          </div>

          {/* Section 5: Notes and Additional Info */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            {/* Driver Notes */}
            <div className="bg-purple-50 p-4 rounded-md shadow-md">
              <label className="font-bold flex items-center">
                <FaClipboardList className="text-blue-500 mr-2" />
                Driver Notes
              </label>
              <textarea
                className="w-full border border-purple-300 rounded-md px-3 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-300"
                rows="4"
                value={driverNotes}
                onChange={(e) => setDriverNotes(e.target.value)}
                placeholder="Enter Driver Notes"
              ></textarea>
              <div className="flex flex-wrap gap-2 mt-3">
                <TripButton name="Baby On Board" color="bg-blue-500 hover:bg-blue-600" onClick={() => handleButtonClick("Baby On Board")} />
                <TripButton name="Reach On-time" color="bg-green-500 hover:bg-green-600" onClick={() => handleButtonClick("Reach On-time")} />
                <TripButton name="Patient On Board" color="bg-yellow-500 hover:bg-yellow-600" onClick={() => handleButtonClick("Patient On Board")} />
                <TripButton name="On-time Reach" color="bg-purple-500 hover:bg-purple-600" onClick={() => handleButtonClick("On-time Reach")} />
                <TripButton name="Welcome Board Required" color="bg-red-500 hover:bg-red-600" onClick={() => handleButtonClick("Welcome Board Required")} />
              </div>
            </div>
            {/* Promo and Flight Info */}
            <div className="bg-gray-50 p-4 rounded-md shadow-md">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* GST */}
                <div className="flex flex-col">
                  <label className="mb-1 font-medium">GST</label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-300"
                    value={gst}
                    // onChange={(e) => setGst(e.target.value)}
                    placeholder="GST"
                    readOnly
                  />
                </div>
                {/* Flight Number */}
                <div className="flex flex-col">
                  <label className="mb-1 font-medium">Flight Number</label>
                  <div className="flex items-center border border-gray-300 rounded-md px-3 py-2 focus-within:ring-2 focus-within:ring-gray-500 transition duration-300">
                    <RiFlightTakeoffLine className="text-orange-500 mr-2" />
                    <input
                      type="text"
                      className="w-full focus:outline-none"
                      value={flightNumber}
                      onChange={(e) => setFlightNumber(e.target.value)}
                      placeholder="Flight Number"
                      // readOnly
                    />
                  </div>
                </div>
              </div>
              {/* Promo Code */}
              <div className="flex items-center mt-4">
                <input
                  type="checkbox"
                  id="promoCheckbox"
                  className="mr-2"
                  checked={promoCheckbox}
                  onChange={(e) => setPromoCheckbox(e.target.checked)}
                />
                <label htmlFor="promoCheckbox" className="font-medium">
                  Promo Code
                </label>
              </div>
              <div className="flex items-center mt-2">
                <RiCoupon3Line className="text-red-500 mr-2" />
                <input
                  type="text"
                  className="border border-gray-300 rounded-md px-3 py-2 mr-2 focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-300"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                  placeholder="Code"
                  disabled={!promoCheckbox}
                />
                <TripButton name="Apply" color="bg-blue-500 hover:bg-blue-600" disabled={!promoCheckbox} />
              </div>
              <div className="flex items-center mt-4">
                <input
                  type="checkbox"
                  id="promoValueCheckbox"
                  className="mr-2"
                  checked={promoValueCheckbox}
                  onChange={(e) => setPromoValueCheckbox(e.target.checked)}
                />
                <label htmlFor="promoValueCheckbox" className="font-medium">
                  Promo Value
                </label>
              </div>
              <div className="flex items-center mt-2">
                <RiMoneyDollarCircleLine className="text-green-500 mr-2" />
                <input
                  type="text"
                  className="border border-gray-300 rounded-md px-3 py-2 mr-2 focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-300"
                  value={promoValue}
                  onChange={(e) => setPromoValue(e.target.value)}
                  placeholder="Value"
                  disabled={!promoValueCheckbox}
                />
                <TripButton name="Apply" color="bg-green-500 hover:bg-green-600" disabled={!promoValueCheckbox} />
              </div>
            </div>
            {/* Admin Notes */}
            <div className="bg-red-50 p-4 rounded-md shadow-md">
              <h2 className="text-lg font-bold mb-2 flex items-center">
                <FaClipboardList className="text-blue-500 mr-2" />
                Admin Notes
              </h2>
              <textarea
                className="w-full border border-red-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-red-500 transition duration-300"
                rows="6"
                value={adminNotes}
                onChange={(e) => setAdminNotes(e.target.value)}
                placeholder="Booking mode"
              ></textarea>
            </div>
          </div>

          {/* Section 6: Booking Details */}
          <div className="bg-white shadow-md rounded-md p-6 mb-8">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-4">
              {/* Coupon Code */}
              <div className="border border-gray-200 p-4 rounded-md hover:shadow-lg transition duration-300">
                <ItemWithTitle
                  title="Coupon Code"
                  content={state?.additionalData?.couponCode || 'FIX123'}
                  icon={RiCoupon3Line}
                  iconColor="text-red-500"
                />
              </div>
              {/* Coupon Value */}
              <div className="border border-gray-200 p-4 rounded-md hover:shadow-lg transition duration-300">
                <ItemWithTitle
                  title="Coupon Value"
                  content={state?.additionalData?.couponValue || '100'}
                  icon={RiMoneyDollarCircleLine}
                  iconColor="text-green-500"
                />
              </div>
              {/* Vehicle Details */}
              <div className="border border-gray-200 p-4 rounded-md hover:shadow-lg transition duration-300">
                <ItemWithMultipleLines
                  title="Vehicle Details"
                  lines={
                    [
                      `Vehicle Type: ${vehicleType} 's Any Model`,
                      `Vehicle No: ${state?.additionalData?.vehicleNumber}`,
                    ]
                  }
                  icon={FaCarSide}
                  iconColor="text-indigo-500"
                />
              </div>
              {/* Driver Details */}
              <div className="border border-gray-200 p-4 rounded-md hover:shadow-lg transition duration-300">
                <ItemWithMultipleLines
                  title="Driver Details"
                  lines={
                    [
                      `${driverName}`,
                      `${driverMobileNumber}`,
                    ]
                  }
                  icon={FaUser}
                  iconColor="text-blue-500"
                />
              </div>
              {/* Action Icons for Driver */}
              <div className="border border-gray-200 p-4 rounded-md flex items-center justify-center hover:shadow-lg transition duration-300">
                <div className="flex items-center gap-4">
                  {/* Driver Copy Button */}
                  <RiFileCopy2Line
                    className="text-orange-500 text-3xl hover:text-orange-600 transition duration-300 cursor-pointer"
                    title="Copy Driver Details"
                    onClick={() => copyToClipboard(generateDriverMessage())}
                  />
                  {/* Driver WhatsApp Button */}
                  <RiWhatsappFill
                    className="text-green-500 text-3xl hover:text-green-600 transition duration-300 cursor-pointer"
                    title="Send Driver Details via WhatsApp"
                    onClick={() => sendWhatsAppMessage(driverMobileNumber, generateDriverMessage())}
                  />
                </div>
              </div>
              {/* Source URL */}
              <div className="border border-gray-200 p-4 rounded-md hover:shadow-lg transition duration-300">
                <h2 className="text-sm font-bold mb-2 flex items-center">
                  <FaClipboardList className="text-blue-500 mr-2" />
                  Source URL
                </h2>
                <p className="text-blue-500 hover:underline">
                  <Link to={state?.additionalData?.sourceUrl || ''}>
                    Fixryde
                  </Link>
                </p>
              </div>
            </div>

            {/* Extra Fare Details */}
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-6">
              <div className="border border-gray-200 p-4 rounded-md hover:shadow-lg transition duration-300">
                <ItemWithTitle
                  title="Toll Fare"
                  content={state?.additionalData?.tollFare || '0.00'}
                  icon={RiMoneyDollarCircleLine}
                  iconColor="text-green-500"
                />
              </div>
              <div className="border border-gray-200 p-4 rounded-md hover:shadow-lg transition duration-300">
                <ItemWithTitle
                  title="State Tax Fare"
                  content={state?.additionalData?.stateTaxFare || '0.00'}
                  icon={RiMoneyDollarCircleLine}
                  iconColor="text-green-500"
                />
              </div>
              <div className="border border-gray-200 p-4 rounded-md hover:shadow-lg transition duration-300">
                <ItemWithTitle
                  title="Hill Charge"
                  content={state?.additionalData?.hillCharge || '0.00'}
                  icon={RiMoneyDollarCircleLine}
                  iconColor="text-green-500"
                />
              </div>
            </div>

            {/* Get Auto Fit Fare Button */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
              <div className="flex justify-start mt-6">
                <div className="flex flex-row items-center mb-4 space-x-4"> {/* Change to flex-row and add space-x-4 for spacing */}
                  <label className="font-medium mr-4">Vehicle Type:</label> {/* Add margin to the label for spacing */}
                  {["Sedan", "Hatchback", "SUV", "Others", "Innova", "Innova Crysta"].map(category => (
                    <div key={category} className="flex items-center">
                      <input
                        type="radio"
                        value={category}
                        checked={selectedCategory === category}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        className="mr-2"
                      />
                      <label>{category}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex justify-end mt-6">
                <TripButton name="Get Auto Fit Fare" color="bg-blue-500 hover:bg-blue-600" onClick={fetchAutoBidFare} />
              </div>
            </div>


            {/* Vendor Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-6">
              {filteredFareData.map((vendor, index) => (
                <div
                  key={index}
                  className={`border border-gray-200 p-4 rounded-md shadow-md flex flex-col hover:shadow-lg transition duration-300 cursor-pointer ${selectedVendorIndex === index ? 'bg-yellow-100' : ''
                    }`}
                  onClick={() => {
                    setSelectedVendorIndex(index);
                    setSelectedFare(vendor); // Set selected fare on click
                  }}
                >
                  <h4 className="text-base font-semibold mb-2">
                    Vendor Name: {vendor.vendorName || 'Unknown'}
                  </h4>
                  <p className="text-sm mb-1">
                    Vendor Number: {vendor.vendorUserid}
                  </p>
                  <p className="text-lg font-bold text-green-600">
                    Price: ₹ {vendor.totalFare || 'N/A'}
                  </p>
                </div>
              ))}
            </div>

            {/* Action Buttons */}
            <div className="flex flex-wrap gap-4 justify-center mt-8">
              <TripButton
                name="Direct Assign"
                color="bg-blue-500 hover:bg-blue-600"
                onClick={() => setShowDirectAssignModal(true)}
              />

              <DirectAssignDetails
                show={showDirectAssignModal}
                onClose={handleDirectAssignClose}
                initialState={state}
              />
              <TripButton
                name="Driver Assign"
                color="bg-green-500 hover:bg-green-600"
                onClick={() => setShowDriverAssignModal(true)}
              />
              <DriverAssignDetails
                show={showDriverAssignModal}
                onClose={handleDriverAssignClose}
                initialState={state}
              />
              <TripButton
                name="(AutoBit)Operator Assign"
                color="bg-yellow-500 hover:bg-yellow-600"
                onClick={handleOperatorAssign}
              />
              <TripButton
                name="Update"
                color="bg-purple-500 hover:bg-purple-600"
                onClick={handleBookingTimeChanged}
              />
              <TripButton
                name="Cancel"
                color="bg-red-500 hover:bg-red-600"
                onClick={() => navigate(-1)}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default EditTripDetails;

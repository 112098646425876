import React, { useState, Fragment, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    'ngrok-skip-browser-warning': 'true'
  }
});

const EditRate = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [sidebarToggle, setSidebarToggle] = useState(false);

  // Attempt to retrieve fare from location or fallback to localStorage
  const locationFare = location.state?.fare;
  const storageFare = localStorage.getItem("edit_fare");
  const fare = locationFare || (storageFare ? JSON.parse(storageFare) : null);

  useEffect(() => {
    if (!fare) {
      toast.error("No fare data provided. Redirecting...");
      navigate('/admin/rate'); // Redirecting to dashboard or another safe page
    } else {
      console.log("Received fare data:", fare);
      // Store fare data in localStorage to persist across potential refreshes
      localStorage.setItem("edit_fare", JSON.stringify(fare));
    }
  }, [fare, navigate]);

  const [farePerKm, setFarePerKm] = useState(fare?.farePerKm || 0);
  const [extraFarePerKm, setExtraFarePerKm] = useState(fare?.extraFarePerKm || 0);
  const [waitingChargesPerHour, setWaitingChargesPerHour] = useState(fare?.waitingChargesPerHour || 0);
  const [freeWaitingMinutes, setFreeWaitingMinutes] = useState(fare?.freeWaitingMinutes || 0);
  const [driverBeta, setDriverBeta] = useState(fare?.driverBeta ?? '');

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      const response = await api.put('/drivervehicle/fares/update', {
        id: fare.id,
        farePerKm,
        extraFarePerKm,
        waitingChargesPerHour,
        freeWaitingMinutes,
        driverBeta,
      });

      if (response.status === 200) {
        toast.success("Rate updated successfully");
        localStorage.removeItem("edit_fare"); // Clear stored fare data on successful update
        // navigate('/admin/rate'); // Navigate back to RateDashboard after success
      }
    } catch (error) {
      console.error("Error updating rate:", error);
      toast.error("Unable to update rate, rate is not present.");
    }
  };

  if (!fare) return null; // Guard clause to prevent rendering until data is ready

  return (
    <Fragment>
      <ToastContainer />
      <AdminSidebar
        sidebarToggle={sidebarToggle}
        setSidebarToggle={setSidebarToggle}
      />
      <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
        <AdminNavbar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
        />
        <div className="bg-zinc-100 min-h-screen p-4">
          <div className="bg-fixwhite p-4 my-4">
            <div className="mb-6">
            <h1 className="text-xl font-semibold">Edit Rate</h1>
            <form onSubmit={handleUpdate}>
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                <div>
                  <h2 className="text-lg font-semibold">Vendor Details</h2>
                  <div className="grid grid-cols-1 gap-4">
                    <div className="flex flex-col">
                      <label>Vendor *</label>
                      <input
                        className="p-2 border rounded bg-gray-200"
                        name="vendor"
                        value={fare.vendorUserid}
                        readOnly
                      />
                    </div>
                    <div className="flex flex-col">
                      <label>Partner Type *</label>
                      <input
                        className="p-2 border rounded bg-gray-200"
                        name="partnerType"
                        value={fare.partnerType}
                        readOnly
                      />
                    </div>
                    <div className="flex flex-col">
                      <label>State *</label>
                      <input
                        className="p-2 border rounded bg-gray-200"
                        name="state"
                        value={fare.state}
                        readOnly
                      />
                    </div>
                    <div className="flex flex-col">
                      <label>Trip Type *</label>
                      <input
                        className="p-2 border rounded bg-gray-200"
                        name="tripType"
                        value={fare.tripType}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div>
                      <h2 className="text-lg font-semibold">Route Details</h2>
                      <div className="grid grid-cols-1 gap-4">
                        {(fare.tripType === "OnewayTrip" || fare.tripType === "RoundTrip" || fare.tripType === "RentalDrive") && (
                          <div className="flex flex-col">
                            <label>Source State*</label>
                            <input
                              className="p-2 border rounded bg-gray-200"
                              name="sourceState"
                              value={fare.sourceState}
                              readOnly
                            />
                          </div>
                        )}
                        {(fare.tripType === "OnewayTrip" || fare.tripType === "RoundTrip" || fare.tripType === "RentalDrive") && (
                          <div className="flex flex-col">
                            <label>Destination State*</label>
                            <input
                              className="p-2 border rounded bg-gray-200"
                              name="destinationState"
                              value={fare.destinationState}
                              readOnly
                            />
                          </div>
                        )}
                        {(fare.tripType === "AirportPickup" || fare.tripType === "AirportDrop") && (
                          <div className="flex flex-col">
                            <label>Source District*</label>
                            <input
                              className="p-2 border rounded bg-gray-200"
                              name="sourceDistrict"
                              value={fare.sourceDistrict}
                              readOnly
                            />
                          </div>
                        )}
                        {(fare.tripType === "AirportPickup" || fare.tripType === "AirportDrop") && (
                          <div className="flex flex-col">
                            <label>Destination District*</label>
                            <input
                              className="p-2 border rounded bg-gray-200"
                              name="destinationDistrict"
                              value={fare.destinationDistrict}
                              readOnly
                            />
                          </div>
                        )}
                        <div className="flex flex-col">
                          <label>Vehicle Category *</label>
                          <input
                            className="p-2 border rounded bg-gray-200"
                            name="vehicleCategory"
                            value={fare.vehicleCategory}
                            readOnly
                          />
                        </div>
                        <div className="flex flex-col">
                          <label>Vehicle Model *</label>
                          <input
                            className="p-2 border rounded bg-gray-200"
                            name="vehicleModel"
                            value={fare.vehicleModel}
                            readOnly
                          />
                        </div>
                        <div className="flex flex-col">
                          <label>Fuel Type *</label>
                          <input
                            className="p-2 border rounded bg-gray-200"
                            name="fuelType"
                            value={fare.fuelType}
                            readOnly
                          />
                        </div>
                        <div className="flex flex-col">
                          <label>Vehicle SubCategory *</label>
                          <input
                            className="p-2 border rounded bg-gray-200"
                            name="vehicleSubCategory"
                            value={fare.vehicleSubCategory}
                            readOnly
                          />
                        </div>
                        <div className="flex flex-col">
                          <label>Rate Plan *</label>
                          <input
                            className="p-2 border rounded bg-gray-200"
                            name="ratePlan"
                            value={fare.ratePlan}
                            readOnly
                          />
                        </div>
                        {(fare.ratePlan === "Holiday" || fare.ratePlan === "WeekDays") && (
                          <div className="flex flex-col">
                            <label>From Date *</label>
                            <input
                              type="date"
                              className="p-2 border rounded bg-gray-200"
                              name="fromDate"
                              value={fare.fromDate || ''}
                              readOnly
                            />
                          </div>
                        )}
                        {(fare.ratePlan === "Holiday" || fare.ratePlan === "WeekDays") && (
                          <div className="flex flex-col">
                            <label>To Date *</label>
                            <input
                              type="date"
                              className="p-2 border rounded bg-gray-200"
                              name="toDate"
                              value={fare.toDate || ''}
                              readOnly
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <h2 className="text-lg font-semibold">Fare Details</h2>
                      <div className="flex flex-col">
                        <div className="bg-gray-100 p-4 rounded mb-4">
                          <p>Price Range</p>
                          <input
                            className="rounded-lg overflow-hidden appearance-none bg-fixblue h-3 w-128"
                            type="range"
                            min="0"
                            max="100"
                            step="0.1"
                            value={farePerKm}
                            onChange={(e) => setFarePerKm(e.target.value)}
                          />
                          <p>Selected Price: ₹{farePerKm}</p>
                        </div>
                        <div className="flex flex-col mb-4">
                          <label>Fare /Km *</label>
                          <input
                            type="text"
                            className="p-2 border rounded"
                            placeholder="Enter Fare Per Km"
                            name="farePerKm"
                            value={farePerKm}
                            onChange={(e) => setFarePerKm(e.target.value)}
                          />
                        </div>
                        <div className="flex flex-col mb-4">
                          <label>Extra Fare /Km *</label>
                          <input
                            type="text"
                            className="p-2 border rounded"
                            placeholder="Enter Extra Fare Per Km"
                            name="extraFarePerKm"
                            value={extraFarePerKm}
                            onChange={(e) => setExtraFarePerKm(e.target.value)}
                          />
                        </div>
                        <div className="flex flex-col mb-4">
                          <label>Waiting Charges / Hour *</label>
                          <input
                            type="text"
                            className="p-2 border rounded"
                            placeholder="Enter Waiting Charges Per Hour"
                            name="waitingChargesPerHour"
                            value={waitingChargesPerHour}
                            onChange={(e) => setWaitingChargesPerHour(e.target.value)}
                          />
                        </div>
                        <div className="flex flex-col mb-4">
                          <label>Free Waiting Minutes *</label>
                          <select
                            className="p-2 border rounded"
                            name="freeWaitingMinutes"
                            value={freeWaitingMinutes}
                            onChange={(e) => setFreeWaitingMinutes(e.target.value)}
                          >
                            <option>Select Waiting Minutes</option>
                            <option value="30">30 Minutes</option>
                            <option value="45">45 Minutes</option>
                            <option value="60">60 Minutes</option>
                          </select>
                        </div>
                        {(fare.tripType === "OnewayTrip" || fare.tripType === "RoundTrip" || fare.tripType === "RentalDrive") && (
                          <div className="flex flex-col mb-4">
                            <label>Driver Allowance / Beta *</label>
                            <input
                              type="text"
                              className="p-2 border rounded"
                              placeholder="Enter Driver Beta"
                              name="driverBeta"
                              value={driverBeta}
                              onChange={(e) => setDriverBeta(e.target.value)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <button type="submit" className="bg-fixblue text-white px-4 py-2 rounded">
                  Update Rate
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      </div>
    </Fragment>
  );
};

export default EditRate;

import React, { Fragment, useState } from "react";
import Footer from "../../components/site/Footer";
import Navbar from "../../components/site/Navbar";

const PaymentOptions = () => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("upi");

  return (
    <Fragment>
      <Navbar />
      <div className="bg-gray-100 p-6 min-h-screen flex justify-center">
        <div className="flex flex-col md:flex-row w-full max-w-6xl bg-white shadow-lg rounded-lg overflow-hidden">
          {/* Left Section - Price Summary */}
          <div className="bg-fixblack text-white w-full md:w-1/3 p-4">
            <h2 className="text-xl font-bold">FixRyde</h2>
            <p className="mt-2 text-sm">Price Summary</p>
            <p className="text-3xl font-bold mt-4">₹ 2,450</p>

            <div className="mt-6">
              <p className="text-sm">Using as +91 1234567890</p>
            </div>

            <div className="mt-4 bg-green-500 text-white py-2 px-4 rounded-lg cursor-pointer">
              <p>Offers on Amazon Pay</p>
            </div>

            <div className="mt-6 flex items-center">
              <p className="text-xs">Secured by</p>
              <img
                src="/images/razorpay.png"
                alt="Razorpay"
                className="ml-2 w-20"
              />
            </div>
          </div>

          {/* Right Section - Payment Methods */}
          <div className="w-full md:w-2/3 p-6">
            <h3 className="text-lg font-bold text-center">Payment Options</h3>
            {/* Recommended Section */}
            <div className="mt-4">
              <h4 className="text-gray-600 font-semibold">Recommended</h4>

              {/* Payment Method List */}
              <div className="flex flex-col space-y-4 mt-4">
                {/* UPI */}
                <button
                  className={`flex items-center p-3 rounded-lg border ${
                    selectedPaymentMethod === "upi"
                      ? "border-green-500"
                      : "border-gray-300"
                  }`}
                  onClick={() => setSelectedPaymentMethod("upi")}
                >
                  <span className="text-sm text-gray-700 font-semibold flex-grow">
                    UPI
                  </span>
                  <img
                    src="/images/upi.png"
                    alt="UPI"
                    className="ml-2 w-24"
                  />
                </button>

                {/* Cards */}
                <button
                  className={`flex items-center p-3 rounded-lg border ${
                    selectedPaymentMethod === "cards"
                      ? "border-green-500"
                      : "border-gray-300"
                  }`}
                  onClick={() => setSelectedPaymentMethod("cards")}
                >
                  <span className="text-sm text-gray-700 font-semibold flex-grow">
                    Cards
                  </span>
                  <img
                    src="/images/credit-card.jpg"
                    alt="Cards"
                    className="ml-2 w-24 h-8"
                  />
                </button>

                {/* Netbanking */}
                <button
                  className={`flex items-center p-3 rounded-lg border ${
                    selectedPaymentMethod === "netbanking"
                      ? "border-green-500"
                      : "border-gray-300"
                  }`}
                  onClick={() => setSelectedPaymentMethod("netbanking")}
                >
                  <span className="text-sm text-gray-700 font-semibold flex-grow">
                    Netbanking
                  </span>
                  <img
                    src="/images/emi.jpg"
                    alt="Netbanking"
                    className="ml-2 w-24 h-10"
                  />
                </button>

                {/* EMI */}
                <button
                  className={`flex items-center p-3 rounded-lg border ${
                    selectedPaymentMethod === "emi"
                      ? "border-green-500"
                      : "border-gray-300"
                  }`}
                  onClick={() => setSelectedPaymentMethod("emi")}
                >
                  <span className="text-sm text-gray-700 font-semibold flex-grow">
                    EMI
                  </span>
                  <img
                    src="/images/visa.png"
                    alt="EMI"
                    className="ml-2 w-24"
                  />
                </button>

                {/* Wallet */}
                <button
                  className={`flex items-center p-3 rounded-lg border ${
                    selectedPaymentMethod === "wallet"
                      ? "border-green-500"
                      : "border-gray-300"
                  }`}
                  onClick={() => setSelectedPaymentMethod("wallet")}
                >
                  <span className="text-sm text-gray-700 font-semibold flex-grow">
                    Wallet
                  </span>
                  <img
                    src="/images/wallet.jpg"
                    alt="Wallet"
                    className="ml-2 w-20"
                  />
                </button>
              </div>
            </div>

            {/* Available Offers */}
            <div className="mt-6">
              <h4 className="text-gray-600 font-semibold">Available Offers</h4>
              <p className="text-gray-500 mt-2">
                Upto ₹40 cashback on Amazon Pay Wallet
              </p>
            </div>

            {/* UPI QR Code */}
            {selectedPaymentMethod === "upi" && (
              <div className="mt-6">
                <h4 className="text-gray-600 font-semibold">UPI QR</h4>
                <div className="mt-4 flex items-center">
                  <img
                    src="/images/qrcode.jpg"
                    alt="UPI QR Code"
                    className="w-48 h-48 object-cover"
                  />
                  <div className="ml-4">
                    <p className="text-sm text-gray-600">
                      Scan with any UPI app
                    </p>
                    <div className="flex space-x-4 mt-2">
                      <img src="/images/gpay.png" alt="App1"  className="w-16"/>
                      <img src="/images/paytm.png" alt="App2"  className="w-16"/>
                      <img src="/images/phonepe.png" alt="App3" className="w-16"/>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default PaymentOptions;

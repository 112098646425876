import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  FaTelegram,
  FaTwitter,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa";

import Navbar from "../../components/site/Navbar";
import Footer from "../../components/site/Footer";
import CommonSection from "../../components/site/CommanSection";

const socialLinks = [
  {
    url: "#",
    icon: FaFacebook,
  },
  {
    url: "#",
    icon: FaInstagram,
  },
  {
    url: "#",
    icon: FaTelegram,
  },
  {
    url: "#",
    icon: FaTwitter,
  },
];

function Contact() {
  return (
    <Fragment>
      <Navbar />
      <CommonSection title="Contact" />
      <section className="bg-gray-200 py-16 text-fixblue">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h6 className="font-bold text-2xl mb-4 text-center">
                Get In Touch
              </h6>
              <form>
                <div className="mb-2">
                  <input
                    placeholder="Your Name"
                    type="text"
                    className="w-full p-1"
                  />
                </div>
                <div className="mb-2">
                  <input
                    placeholder="Email"
                    type="email"
                    className="w-full p-1"
                  />
                </div>
                <div className="mb-2">
                  <textarea
                    rows="3"
                    placeholder="Message"
                    className="w-full p-2"
                  ></textarea>
                </div>
                <button
                  className="bg-fixblue hover:bg-fixgold text-fixwhite p-2 w-full rounded-sm"
                  type="submit"
                >
                  Send Message
                </button>
              </form>
            </div>
            <div>
              <h6 className="font-bold text-2xl mb-4 mt-3 text-center">
                Contact Information
              </h6>
              <div className="text-center text-md">
                <p className="mb-2">123 Right Street, velacherry, Chennai</p>
                <p className="mb-2 flex items-center gap-2 justify-center">
                  <span className="font-semibold">Phone:</span>
                  <span>+91 9087654321</span>
                </p>
                <p className="mb-2 flex items-center gap-2 justify-center">
                  <span className="font-semibold">Email:</span>
                  <span>fixryde@gmail.com</span>
                </p>
              </div>
              <div className="my-6">
                <h6 className="font-bold m-6 text-2xl text-center underline">
                  Follow Us
                </h6>
                <div className="flex gap-2 justify-center">
                  {socialLinks.map((item, index) => (
                    <Link
                      to={item.url}
                      key={index}
                      className="inline-flex items-center justify-center h-10 w-10 border-2 border-fixblue rounded-full mr-1 hover:text-fixgold hover:border-fixgold"
                    >
                      <item.icon />
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default Contact;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DriverAssignDetails = ({ show, onClose, initialState }) => {
    const [driverData, setDriverData] = useState([]);
    const [filteredDriverData, setFilteredDriverData] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(null);

    // State for filters
    const [searchDriverName, setSearchDriverName] = useState("");
    const [searchDriverPhoneNumber, setSearchDriverPhoneNumber] = useState("");
    const [searchVehicleName, setSearchVehicleName] = useState("");
    const [searchVehicleNumber, setSearchVehicleNumber] = useState("");

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items per page

    // Fetch Driver Data on Component Load
    useEffect(() => {
        if (show) {
            fetchDriverData();
        }
    }, [show]);

    const fetchDriverData = async () => {
        try {
            const vendorUserId = initialState.vendorUserid || ''
            console.log(vendorUserId);
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URLE}/drivervehicle/vendors/driverassignlist`,
                {
                    vendorId: vendorUserId, // Send vendorId to get drivers list
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'ngrok-skip-browser-warning': 'true',
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log("DriverAssignResponse:");
            console.log(response.data);
            setDriverData(response.data);
            setFilteredDriverData(response.data);
        } catch (error) {
            console.error("Error fetching driver data:", error);
        }
    };

    // Filter data based on search inputs
    const filterDriverData = () => {
        let filtered = driverData;

        // Filter based on Driver Name
        if (searchDriverName) {
            filtered = filtered.filter(driver =>
                driver.driverName.toLowerCase().includes(searchDriverName.toLowerCase())
            );
        }

        // Filter based on Phone Number
        if (searchDriverPhoneNumber) {
            filtered = filtered.filter(driver =>
                driver.driverId.includes(searchDriverPhoneNumber)
            );
        }

        // Filter based on Vehicle Name
        if (searchVehicleName) {
            filtered = filtered.filter(driver =>
                driver.vehicleCategory.toLowerCase().includes(searchVehicleName.toLowerCase())
            );
        }

        // Filter based on Vehicle Number
        if (searchVehicleNumber) {
            filtered = filtered.filter(driver =>
                driver.vehicleNumber.includes(searchVehicleNumber)
            );
        }

        setFilteredDriverData(filtered);
        setCurrentPage(1); // Reset to the first page when filtering
    };

    // Automatically filter data when any filter input changes
    useEffect(() => {
        filterDriverData();
    }, [searchDriverName, searchDriverPhoneNumber, searchVehicleName, searchVehicleNumber]);

    // Reset filters and show all data
    const handleResetFilters = () => {
        setSearchDriverName("");
        setSearchDriverPhoneNumber("");
        setSearchVehicleName("");
        setSearchVehicleNumber("");
        setFilteredDriverData(driverData); // Show all records
        setCurrentPage(1); // Reset to the first page
    };

    // Pagination logic
    const paginatedData = filteredDriverData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredDriverData.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleAssignClick = async () => {
        if (!selectedDriver) {
            toast.warn("Please select a driver to assign.");
            return;
        }

        console.log(selectedDriver);

        const requestPayload = {
            bookingId: initialState.bookId || '',
            vendorId: initialState.vendorUserid || '',
            driverId: selectedDriver.driverId,
            vehicleNumber: selectedDriver.vehicleNumber,
            vehicleId: selectedDriver.id,
            updateBy: JSON.parse(localStorage.getItem('user'))?.sub || null,
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URLB}/bookings/assign/driverassignbooking`,
                requestPayload,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'ngrok-skip-browser-warning': 'true',
                        'Content-Type': 'application/json'
                    }
                }
            );
            toast.success("Driver assigned successfully!");
            onClose({ selectedDriver, bookId: initialState.bookId });
        } catch (error) {
            console.error("Error assigning driver:", error);
            toast.error(error.response.data);
        }
    };

    if (!show) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white w-full max-w-4xl rounded-lg shadow-lg p-6 relative">
                <button
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
                    onClick={() => onClose({ selectedDriver, bookId: initialState.bookId })}
                >
                    &#x2715;
                </button>
                <h2 className="text-2xl font-semibold mb-6">Assign Driver</h2>

                <div className="mb-4 flex flex-col md:flex-row gap-4 items-center">
                    <input
                        type="text"
                        placeholder="Search Driver Name"
                        value={searchDriverName}
                        onChange={(e) => setSearchDriverName(e.target.value)}
                        className="p-2 border border-gray-300 rounded-md w-full md:w-1/5"
                    />
                    <input
                        type="text"
                        placeholder="Search Driver Phone Number"
                        value={searchDriverPhoneNumber}
                        onChange={(e) => setSearchDriverPhoneNumber(e.target.value)}
                        className="p-2 border border-gray-300 rounded-md w-full md:w-1/5"
                    />
                    <input
                        type="text"
                        placeholder="Search Vehicle Name"
                        value={searchVehicleName}
                        onChange={(e) => setSearchVehicleName(e.target.value)}
                        className="p-2 border border-gray-300 rounded-md w-full md:w-1/5"
                    />
                    <input
                        type="text"
                        placeholder="Search Vehicle Number"
                        value={searchVehicleNumber}
                        onChange={(e) => setSearchVehicleNumber(e.target.value)}
                        className="p-2 border border-gray-300 rounded-md w-full md:w-1/5"
                    />

                    {/* Reset button */}
                    <button
                        onClick={handleResetFilters}
                        className="p-2 border border-gray-300 bg-blue-500 text-white rounded-md hover:bg-red-600 w-full md:w-1/5"
                    >
                        Reset
                    </button>
                </div>


                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr className="w-full bg-gray-100 border-b">
                                <th className="py-3 px-4 text-left">Driver Name</th>
                                <th className="py-3 px-4 text-left">Phone Number</th>
                                <th className="py-3 px-4 text-left">Vehicle Name</th>
                                <th className="py-3 px-4 text-left">Vehicle Number</th>
                            </tr>
                        </thead>

                        <tbody>
                            {paginatedData.map((driver, index) => (
                                <tr
                                    key={index}
                                    className={`cursor-pointer ${selectedDriver?.id === driver.id ? "bg-yellow-100" : "hover:bg-gray-50"} transition`}
                                    onClick={() => setSelectedDriver(driver)}
                                >
                                    <td className="py-2 px-4 border-b">{driver.driverName}</td>
                                    <td className="py-2 px-4 border-b">{driver.driverId}</td>
                                    <td className="py-2 px-4 border-b">{driver.vehicleCategory}</td>
                                    <td className="py-2 px-4 border-b">{driver.vehicleNumber}</td>
                                </tr>
                            ))}
                        </tbody>


                    </table>
                </div>

                {/* Pagination Controls */}
                <div className="mt-4 flex justify-center">
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="px-4 py-2 mx-2 border rounded-md bg-gray-200"
                    >
                        Previous
                    </button>
                    <span className="mx-4">Page {currentPage} of {totalPages}</span>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="px-4 py-2 mx-2 border rounded-md bg-gray-200"
                    >
                        Next
                    </button>
                </div>

                <div className="mt-6 flex justify-end gap-4">
                    <button
                        onClick={() => onClose({ selectedDriver, bookId: initialState.bookId })}
                        className="bg-gray-500 text-white p-2 rounded-md hover:bg-gray-600"
                    >
                        Close
                    </button>
                    <button
                        onClick={handleAssignClick}
                        className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600"
                    >
                        Assign
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DriverAssignDetails;

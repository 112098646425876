import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import { FaArchway, FaCarAlt, FaCarSide, FaCity, FaSearch, FaSync } from "react-icons/fa";
import VenDash from "../../../components/vendor/VenDash";
import VenProgress from "../../../components/vendor/VenProgress";
import { FaArrowsLeftRight, FaBuilding, FaCar } from "react-icons/fa6";

const TripPanel = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [selectedOption, setSelectedOption] = useState("today");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [fromDate, setFromDate] = useState(new Date().toISOString().split("T")[0]);
  const [toDate, setToDate] = useState(new Date().toISOString().split("T")[0]);
  const [dashboardData, setDashboardData] = useState({
    TotalTrips: 0,
    AirportDrive: 0,
    OutstationDrive: 0,
    RentalDrive: 0,
    B2B: 0,
    B2C: 0,
    Upcoming: 0,
    Ongoing: 0,
    Completed: 0,
    Cancelled: 0,
    ForceClose: 0,
    ManualFulfill: 0,
  });

  const userData = JSON.parse(localStorage.getItem("user"));
  const vendorUserid = userData?.sub || "vendor123";

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URLB}/bookings/dashboard/admin`, {
        params: { fromdate: fromDate, todate: toDate, vendorUserid },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "ngrok-skip-browser-warning": "true",
        },
      });
      setDashboardData(response.data.counts);
      console.log("MyTripPanel:Response:", response);
      console.log("MyTripPanel:fromDate:", fromDate);
      console.log("MyTripPanel:toDate:", toDate);
      console.log("MyTripPanel:vendorUserid:", vendorUserid);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <Fragment>
      <div className="flex">
        <AdminSidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
        <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
          <AdminNavbar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
          <div className={`min-h-screen px-4 transition-all duration-300 ease-in-out bg-zinc-200`}>
            <div className="bg-white shadow-lg p-4 rounded-lg">
              <div className="flex flex-col sm:flex-row items-center justify-between">
                <div className="text-gray-800 text-sm mb-2 sm:mb-1">
                  <h1 className="text-md font-extrabold font-sans">Trip Panel</h1>
                  <span>{new Date().toLocaleDateString()}</span>
                </div>
                <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
                  <div className="flex items-center space-x-2">
                    <input
                      type="date"
                      className="p-2 border border-gray-300 rounded-md"
                      value={fromDate}
                      onChange={handleFromDateChange}
                    />
                    <span><FaArrowsLeftRight className="w-4 h-4" /></span>
                    <input
                      type="date"
                      className="p-2 border border-gray-300 rounded-md"
                      value={toDate}
                      onChange={handleToDateChange}
                    />
                  </div>
                  <div className="flex space-x-4 mt-2 sm:mt-0 cursor-pointer">
                    <button
                      onClick={fetchDashboardData}
                      className="p-2 border border-gray-300 rounded-md flex items-center justify-center"
                    >
                      <FaSearch className="w-4 h-4" />
                    </button>
                    <button className="p-2 border border-gray-300 rounded-md flex items-center justify-center">
                      <FaSync className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-center mt-5">
              <VenDash
                title="Total Trip"
                countno={dashboardData.TotalTrips}
                color="yellow"
                icon={<FaBuilding />}
              />
              <VenDash
                title="AirportDrive"
                countno={dashboardData.AirportDrive}
                color="red"
                icon={<FaArchway />}
              />
              <VenDash
                title="OutstationDrive"
                countno={dashboardData.OutstationDrive}
                color="green"
                icon={<FaCar />}
              />
              <VenDash
                title="RentalDrive"
                countno={dashboardData.RentalDrive}
                color="teal"
                icon={<FaCarSide />}
              />
            </div>
            <hr className="my-4 border-b-2 border-black" />
            <div className="flex flex-wrap items-center justify-center mt-5">
              <VenDash
                title="Corporate(Booking)"
                countno={dashboardData.B2B}
                color="orange"
                icon={<FaBuilding />}
              />
              <VenDash
                title="Local(Booking)"
                countno={dashboardData.B2C}
                color="blue"
                icon={<FaArchway />}
              />
            </div>
            <hr className="my-4 border-b-2 border-black" />
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 m-2">
              <VenProgress color="blue" titleData="Upcoming" headingData={dashboardData.Upcoming} spanData="Booking" />
              <VenProgress color="orange" titleData="Ongoing" headingData={dashboardData.Ongoing} spanData="Booking" />
              <VenProgress color="green" titleData="Completed" headingData={dashboardData.Completed} spanData="Booking" />
              <VenProgress color="red" titleData="Cancelled" headingData={dashboardData.Cancelled} spanData="Booking" />
              <VenProgress color="yellow" titleData="ForceClose" headingData={dashboardData.ForceClose} spanData="Booking" />
              <VenProgress color="teal" titleData="ManualFulfill" headingData={dashboardData.ManualFulfill} spanData="Booking" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TripPanel;

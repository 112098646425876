import React, { Fragment, useState } from "react";
import ReCAPTCHA from 'react-recaptcha';
import Footer from "../../components/site/Footer";
import Navbar from "../../components/site/Navbar";
import CommonSection from "../../components/site/CommanSection";
function Operator() {
  const [option, setOption] = useState("");

  const handleOptionChange = (e) => {
    setOption(e.target.value);
  };

  
  // capcha
  const [isVerified, setVerified] = useState(false);

  const handleVerify = (response) => {
    if (response) {
      setVerified(true);
    }
  };

  return (
    <Fragment>
      <Navbar />
      <CommonSection title={"Become An Operator"} />
      <div className="container-fulid mx-auto">
        <div className="bg-fixryde bg-bottom bg-cover bg-no-repeat flex flex-col justify-center">
          <div className="flex items-center justify-center mb-12 mt-2 mx-2">
            <div className="w-full max-w-sm">
              <form className="shadow-lg rounded-lg bg-white">
                <div className="h-1 bg-fixblue rounded-t-md"></div>
                <h3 className="text-center py-2 text-[1rem] font-fixopen font-bold">Join Our Family Now</h3>
                <hr />
                <div className="px-10">
                  <label
                    className="block text-fixblack text-sm font-bold my-2"
                    htmlFor="companyName"
                  >
                    Company name *
                  </label>
                  <input
                    className="border w-full h-10 px-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-fixgold rounded-md"
                    id="companyName"
                    type="text"
                    placeholder="Enter your company name"
                  />
                </div>
                <div className="px-10">
                  <label
                    className="block text-fixblack text-sm font-bold my-2"
                    htmlFor="personName"
                  >
                    Contact Person Name *
                  </label>
                  <input
                    className="border w-full h-10 px-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-fixgold rounded-md"
                    id="personName"
                    type="text"
                    placeholder="Contact Person Name"
                  />
                </div>
                <div className="px-10">
                  <label
                    className="block text-fixblack text-sm font-bold my-2"
                    htmlFor="phone"
                  >
                    Phone number *
                  </label>
                  <input
                    className="border w-full h-10 px-2 text-fixblack leading-tight hover:outline-none focus:outline-none focus:ring-1 focus:ring-fixgold rounded-md"
                    id="phone"
                    type="tel"
                    placeholder="Enter phone number"
                  />
                </div>
                <div className="px-10">
                  <label
                    className="block text-fixblack text-sm font-bold my-2"
                    htmlFor="email"
                  >
                    Email ID *
                  </label>
                  <input
                    className="border w-full h-10 px-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-fixgold rounded-md"
                    id="email"
                    type="email"
                    placeholder="Enter your email id"
                  />
                </div>
                <div className="px-10">
                  <label
                    className="block text-fixblack text-sm font-bold my-2"
                    htmlFor="area"
                  >
                   Operating Area
                  </label>
                  <select
                    className="border w-full h-10 px-2  focus:ring-1 focus:ring-fixgold rounded-md"
                    id="area"
                    value={option}
                    onChange={handleOptionChange}
                  >
                    <option>Select</option>
                    <option value={'chennai'}>Chennai</option>
                    <option value={'krishnagiri'}>Krishnagiri</option>
                    <option value={'vellore'}>Vellore</option>
                    <option value={'maduri'}>Maduri</option>
                  </select>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <h1 className="text-xl font-bold my-1">I am not a robot</h1>
                  <ReCAPTCHA sitekey="YOUR_SITE_KEY" onChange={handleVerify} />
                  {isVerified && (
                    <p className="text-green-500 mt-1">
                      Verification successful!
                    </p>
                  )}
                </div>
                <div className="px-10 py-2">
                <div className="py-2 flex justify-center">
                  <button
                    className="border-2 w-full border-fixblue border-solid bg-fixblue text-white font-bold py-2 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Register
                  </button>
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Operator;

// src/utils/tripUtils.js

import axios from 'axios';

// Parses location using Google Geocoder
export const parseLocation = async (location) => {
  return new Promise((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: location }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const city = results[0].address_components.find(component => component.types.includes("locality"))?.long_name || "";
        const district = results[0].address_components.find(component => component.types.includes("administrative_area_level_3"))?.long_name || "";
        const state = results[0].address_components.find(component => component.types.includes("administrative_area_level_1"))?.long_name || "";
        const finalDistrict = city || district;
        const details = {
          formatted_address: results[0].formatted_address,
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng(),
          city: city,
          state: state,
          country: results[0].address_components.find(component => component.types.includes("country"))?.long_name || "",
          district: finalDistrict
        };
        resolve(details);
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
        reject(status);
      }
    });
  });
};

// Calculates distance using Google Distance Matrix
export const calculateDistance = (sourceInput, destinationInput) => {
  return new Promise((resolve, reject) => {
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [sourceInput],
        destinations: [destinationInput],
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK' && response.rows[0] && response.rows[0].elements[0]) {
          const distance = response.rows[0].elements[0].distance.text;
          resolve(distance);
        } else {
          console.error('Distance Matrix request failed due to ' + status);
          reject(status);
        }
      }
    );
  });
};

// Checks if the input location is an airport
export const checkIfAirport = (input) => {
  if (!input) return false;
  return input.toLowerCase().includes("airport");
};

// Requests base fare from the API
export const baseKmRequest = async (searchDetails) => {
  const { sourceDetails, destinationDetails, tripType, distance, date, time, packageType, redate, retime } = searchDetails;

  const passengerCount = searchDetails.passengerCount || 1;
  const luggageCount = searchDetails.luggageCount || 1;

  const distanceKm = parseFloat(distance.replace(" km", ""));
  const formattedTime = `${time}:00`;

  try {
    const tripEnquiryData = {
      sourceAddress: sourceDetails.formatted_address,
      sourceDistrict: sourceDetails.district,
      sourceState: sourceDetails.state,
      destinationAddress: destinationDetails.formatted_address,
      destinationDistrict: destinationDetails.district,
      destinationState: destinationDetails.state,
      sourceLatitude: sourceDetails.latitude,
      sourceLongitude: sourceDetails.longitude,
      destinationLatitude: destinationDetails.latitude,
      destinationLongitude: destinationDetails.longitude,
      tripType: tripType,
      distanceKm: distanceKm,
      bookingDate: date,
      bookingTime: formattedTime,
      passengerCount: passengerCount,
      luggageCount: luggageCount,
    };

    // Include return dates if RoundTrip
    if (tripType === 'RoundTrip' && redate && retime) {
      tripEnquiryData.returnBookingDate = redate;
      // tripEnquiryData.returnBookingTime = `${retime}:00`;
      tripEnquiryData.returnBookingTime = formattedTime;
    }

    const accessToken = localStorage.getItem("accessToken");
    const userData = JSON.parse(localStorage.getItem("user"));

    if (accessToken && userData) {
      const { id, sub: userMobileNumber } = userData;
      tripEnquiryData.userId = id;
      tripEnquiryData.userMobileNumber = userMobileNumber;

      console.log("tripEnquiry:tripUtils.js:", tripEnquiryData);

      await axios.post(`${process.env.REACT_APP_API_BASE_URLB}/bookings/tripenquiry`, tripEnquiryData, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`
        }
      });

      console.log("Trip enquiry details stored successfully");

    } else {
      console.log("User is not authenticated");
      return;
    }

    const baseFareRequestData = {
      tripType,
      packageType,
      sourceDistrict: sourceDetails.district,
      destinationDistrict: destinationDetails.district,
      sourceState: sourceDetails.state,
      destinationState: destinationDetails.state,
      sourceLatitude: sourceDetails.latitude,
      sourceLongitude: sourceDetails.longitude,
      destinationLatitude: destinationDetails.latitude,
      destinationLongitude: destinationDetails.longitude,
      bookingDate: date,
      bookingTime: formattedTime,
      // Include return dates if RoundTrip
      returnBookingDate: tripType === 'RoundTrip' ? redate : null,
      returnBookingTime: tripType === 'RoundTrip' ? formattedTime : null,
      passengerCount: passengerCount,
      luggageCount: luggageCount,
    };

    console.log("baseFareRequest:tripUtils.js:", baseFareRequestData);

    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URLE}/drivervehicle/fares/basefare`, baseFareRequestData);

    if (response && response.data) {
      console.log("baseFareResponse(data):tripUtils.js:", response.data);
      return response.data;
    } else {
      throw new Error('Invalid response from the server');
    }
  } catch (error) {
    console.error("Error during baseKmRequest:", error);
    return null;
  }
};

// Formats date to 'YYYY-MM-DD'
export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

// Gets minimum and maximum selectable dates
export const getMinMaxDates = () => {
  const minDate = new Date();
  const maxDate = new Date();
  const currentHour = minDate.getHours();

  if (currentHour >= 22) {
    minDate.setDate(minDate.getDate() + 1);
  }
  minDate.setHours(0, 0, 0, 0);
  const minDateString = formatDate(minDate);

  maxDate.setMonth(maxDate.getMonth() + 1);
  const maxDateString = formatDate(maxDate);

  return { minDateString, maxDateString };
};

// Formats time to 24-hour format
export const formatTimeTo24Hour = async (timeString) => {
  return new Promise((resolve) => {
    const [timePart, modifier] = timeString.split(' ');
    let [hours, minutes] = timePart.split(':').map(Number);

    if (modifier === 'PM' && hours < 12) {
      hours += 12; // Convert PM to 24-hour format
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0; // Midnight case
    }

    // Resolve with the formatted time including seconds
    resolve(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`);
  });
};

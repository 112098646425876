import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { FaDownload, FaEye, FaTimes, FaCheck } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminSidebar from '../../../components/admin/AdminSidebar';
import AdminNavbar from '../../../components/admin/AdminNavbar';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URLE,
    headers: {
        'ngrok-skip-browser-warning': 'true'
    }
});

function AdminVendorInvoice() {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [invoiceData, setInvoiceData] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState('');
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const [isGenerateDisabled, setIsGenerateDisabled] = useState(false);
    const [invoiceViewData, setInvoiceViewData] = useState(null);
    const [isSettleModalOpen, setIsSettleModalOpen] = useState(false);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [vendorPaymentReferenceId, setVendorPaymentReferenceId] = useState('');

    useEffect(() => {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(today.getMonth() - 3);

        // setFromDate(today.toISOString().split('T')[0]);
        // setToDate(today.toISOString().split('T')[0]);

        document.getElementById('fromDate').setAttribute('min', threeMonthsAgo.toISOString().split('T')[0]);
        document.getElementById('fromDate').setAttribute('max', today.toISOString().split('T')[0]);
    }, []);

    useEffect(() => {
        api.get(`${process.env.REACT_APP_API_BASE_URLE}/drivervehicle/vendors/allapproved`)
            .then(response => {
                if (Array.isArray(response.data)) {
                    setVendors(response.data);
                } else {
                    console.error("Unexpected data format for vendors:", response.data);
                    toast.error("Failed to load vendors - unexpected data format");
                }
            })
            .catch(error => {
                console.error("Error fetching vendors:", error);
                toast.error("Failed to load vendors");
            });
    }, []);

    const setDateRange = (selectedDate) => {
        const date = new Date(selectedDate);
        const day = date.getDay();

        if (day >= 1 && day <= 4) {
            const monday = new Date(date.setDate(date.getDate() - (day - 1)));
            const thursday = new Date(monday);
            thursday.setDate(monday.getDate() + 3);

            setFromDate(monday.toISOString().split('T')[0]);
            setToDate(thursday.toISOString().split('T')[0]);
        } else if (day === 5 || day === 6 || day === 0) {
            const friday = new Date(date.setDate(date.getDate() - ((day + 2) % 7)));
            const sunday = new Date(friday);
            sunday.setDate(friday.getDate() + 2);

            setFromDate(friday.toISOString().split('T')[0]);
            setToDate(sunday.toISOString().split('T')[0]);
        }

        setIsGenerateDisabled(false);
    };

    const handleFetchInvoices = async () => {
        if (selectedVendor && fromDate && toDate) {
            fetchInvoices();
        } else {
            toast.error("Please select a vendor and Date Range");
        }
    };

    const handleGenerateInvoice = async () => {
        if (!selectedVendor) {
            toast.error("Please select a vendor");
            return;
        }

        const existingInvoices = await fetchInvoices();
        if (existingInvoices && existingInvoices.length > 0) {
            toast.info("Invoice already generated. You can view or settle it.");
            setIsGenerateDisabled(true);
            return;
        }

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URLB}/bookings/invoices/vendor/generate/newmodel`,
                {
                    params: {
                        vendorId: selectedVendor,
                        fromDate: fromDate,
                        toDate: toDate,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        "ngrok-skip-browser-warning": "true",
                    },
                }
            );
            setInvoiceData(response.data);
            fetchInvoices();
            console.log("ADMIN:AdminVendorInvoice:Invoice:", response.data);
        } catch (error) {
            console.error('Error fetching invoice:', error);
            toast.error("Failed to fetch invoice data");
        }
    };

    const fetchInvoices = async () => {
        if (!selectedVendor) return;

        try {
            const response = await api.get(
                `${process.env.REACT_APP_API_BASE_URLE}/bookings/invoices/vendor/splitted/invoicelist/date`,
                {
                    params: {
                        vendorUserid: selectedVendor,
                        fromDate: fromDate,
                        toDate: toDate
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                    },
                }
            );
            setInvoiceData(response.data);
            console.log("setInvoiceData:already present:", response.data);
            setIsGenerateDisabled(response.data.length > 0);
            setInvoiceViewData(null);
            return response.data;
        } catch (error) {
            console.error('Error fetching invoices:', error);
            toast.error("Failed to fetch invoices");
            return null;
        }
    };

    const formatDateTime = (dateTime) => {
        const date = new Date(dateTime);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? String(hours).padStart(2, '0') : '12';

        return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
    };

    const handleView = async (invoiceId) => {
        try {
            const response = await api.get(
                `/bookings/invoices/vendor/generate/newmodel/view`,
                {
                    params: {
                        invoiceId: invoiceId
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                }
            );
            setInvoiceViewData(response.data);
            console.log("Invoice View Data:", response.data);
        } catch (error) {
            console.error('Error fetching invoice:', error);
            toast.error("Failed to fetch invoice data");
        }
    };

    const handleSettle = (invoiceId) => {
        setSelectedInvoiceId(invoiceId);
        setIsSettleModalOpen(true);
    };

    const handleSettlePayment = async () => {
        try {
            const response = await api.post(
                `/bookings/invoices/vendor/settle`,
                {
                    invoiceId: selectedInvoiceId,
                    invoiceStatus: "PAID",
                    vendorPaymentReferenceId: vendorPaymentReferenceId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                }
            );
            toast.success("Invoice settled successfully");
            fetchInvoices();
        } catch (error) {
            console.error('Error settling invoice:', error);
            toast.error("Failed to settle invoice");
        }
    };

    return (
        <Fragment>
            <ToastContainer />
            <AdminSidebar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
            />
            <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? 'ml-0' : 'ml-48'}`}>
                <AdminNavbar
                    sidebarToggle={sidebarToggle}
                    setSidebarToggle={setSidebarToggle}
                />
                <div className="bg-gradient-to-r from-blue-50 to-gray-100 min-h-screen p-6">
                    <div className="bg-white shadow-lg rounded-lg p-6">
                        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Generate and View Invoices</h2>

                        <div className="mb-6">
                            <label className="text-lg font-medium text-gray-700">Vendor:</label>
                            <select
                                className="w-full p-3 mt-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={selectedVendor}
                                onChange={(e) => setSelectedVendor(e.target.value)}
                            >
                                <option value="">Select Vendor</option>
                                {vendors.map((vendor) => (
                                    <option key={vendor.vendorUserid} value={vendor.vendorUserid}>
                                        {vendor.vendorUserid}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="flex gap-4 mb-6">
                            <div className="w-1/2">
                                <label className="block text-lg font-medium text-gray-700">From Date</label>
                                <input
                                    id="fromDate"
                                    type="date"
                                    value={fromDate}
                                    onChange={(e) => setDateRange(e.target.value)}
                                    className="w-full p-3 mt-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="w-1/2">
                                <label className="block text-lg font-medium text-gray-700">To Date</label>
                                <input
                                    type="date"
                                    value={toDate}
                                    readOnly
                                    className="w-full p-3 mt-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                        </div>
                        <div className="flex gap-4 mb-6">
                            <button
                                onClick={handleGenerateInvoice}
                                className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition-colors"
                                disabled={isGenerateDisabled}
                            >
                                Generate Invoice
                            </button>
                            <button
                                onClick={handleFetchInvoices}
                                className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition-colors"
                            >
                                Check Invoice
                            </button>
                        </div>

                        <div className="mt-6">
                            {invoiceData.length > 0 ? (
                                <table className="w-full table-auto border-collapse mt-4">
                                    <thead>
                                        <tr className="text-left text-sm font-semibold text-gray-600 bg-gray-200">
                                            <th className="py-3 px-4">Invoice ID</th>
                                            <th className="py-3 px-4">Vendor ID</th>
                                            <th className="py-3 px-4">Date Range</th>
                                            <th className="py-3 px-4">Created @</th>
                                            <th className="py-3 px-4">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoiceData.map((invoice) => (
                                            <tr key={invoice.invoiceId} className="border-b text-gray-700">
                                                <td className="py-3 px-4">{invoice.invoiceId}</td>
                                                <td className="py-3 px-4">{invoice.vendorId}</td>
                                                <td className="py-3 px-4">{`${invoice.fromDate} - ${invoice.toDate}`}</td>
                                                <td className="py-3 px-4">{formatDateTime(invoice.createdAt)}</td>
                                                <td className="py-3 px-4 space-x-2">
                                                    <button
                                                        onClick={() => handleView(invoice.invoiceId)}
                                                        className="inline-flex items-center text-green-600 hover:text-green-800 font-medium"
                                                    >
                                                        <FaEye className="mr-1" /> View
                                                    </button>
                                                    <button
                                                        onClick={() => handleSettle(invoice.invoiceId)}
                                                        className="inline-flex items-center text-blue-600 hover:text-blue-800 font-medium"
                                                    >
                                                        <FaDownload className="mr-1" /> Settle
                                                    </button>
                                                    {invoice.vendorPaymentReferenceId && invoice.status === 'PAID' && (
                                                        <div className="inline-flex items-center text-green-600 font-medium">
                                                            <FaCheck className="mr-1" /> Already settled
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="text-center text-lg text-gray-600 mt-6">No invoices found.</p>
                            )}
                        </div>

                        {/* Invoice View Section */}
                        {invoiceViewData && (
                            <div id="invoice">
                                <div className="bg-white shadow-md rounded-lg p-6 mt-8">
                                    <div className="flex justify-between items-center border-b py-2">
                                        <div className='flex-col space-x-4 gap-2'>
                                            <h1 className="text-2xl font-bold text-fixblue">Fixryde Invoice</h1>
                                            <p className="text-gray-600"><b>Invoice ID:</b> {invoiceViewData.invoiceId}</p>
                                            <p className="text-gray-600"><b>Payment Reference ID:</b> {invoiceViewData.vendorPaymentReferenceId}</p>
                                            <p className="text-gray-600"><b>Invoice Date Range:</b> {invoiceViewData.startDate} to {invoiceViewData.endDate}</p>
                                            <p className="text-gray-600"><b>Invoice Generated At:</b> {new Date(invoiceViewData.generatedAt).toLocaleString()}</p>
                                        </div>
                                        <div className="flex flex-col sm:flex-row space-x-2 gap-2">
                                        <button
                                            onClick={() => {
                                                const input = document.getElementById('invoice', 'invoiceView');
                                                html2canvas(input).then((canvas) => {
                                                    const imgData = canvas.toDataURL('image/png');
                                                    const pdf = new jsPDF('landscape', 'pt', 'a4');
                                                    const pdfWidth = pdf.internal.pageSize.getWidth();
                                                    const pdfHeight = pdf.internal.pageSize.getHeight();
                                                    const imgWidth = canvas.width;
                                                    const imgHeight = canvas.height;
                                                    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
                                                    const newImgWidth = imgWidth * ratio;
                                                    const newImgHeight = imgHeight * ratio;
                                                    pdf.addImage(imgData, 'image', (pdfWidth - newImgWidth) / 2, 0, newImgWidth, newImgHeight);
                                                    pdf.save(`${invoiceViewData.invoiceId}.pdf`);
                                                });
                                            }}
                                            className="flex items-center bg-fixgold text-white py-2 px-4 rounded-full"
                                        >
                                            <FaDownload className="mr-2" />  PDF
                                        </button>
                                        </div>
                                    </div>
                                    <div id="invoiceView">
                                        <div className="my-4 flex justify-between">
                                            {/* Vendor Information */}
                                            <div className="w-1/2">
                                                <h2 className="text-2xl font-bold text-fixblue">Vendor Information</h2>
                                                <p><b>Company Name:</b> {invoiceViewData.vendorInfo.companyName}</p>
                                                <p><b>Email:</b> {invoiceViewData.vendorInfo.companyMail}</p>
                                                <p><b>Phone:</b> {invoiceViewData.vendorInfo.alternateMobileNumber}</p>
                                                <p><b>Address:</b> {invoiceViewData.vendorInfo.address}, {invoiceViewData.vendorInfo.city}, {invoiceViewData.vendorInfo.state}, {invoiceViewData.vendorInfo.country}, {invoiceViewData.vendorInfo.pincode}</p>
                                                <p><b>GST Number:</b> {invoiceViewData.vendorInfo.companyGstNumber}</p>
                                            </div>

                                            {/* FixRyde Company Information */}
                                            <div className="w-1/2">
                                                <h2 className="text-2xl font-bold text-fixblue">FixRyde Company Information</h2>
                                                <p><b>Company Name:</b> FixRyde Company</p>
                                                <p><b>Email:</b> fixrydetravels@gmail.com</p>
                                                <p><b>Phone:</b> 9988776601</p>
                                                <p><b>Address:</b> Gandhi Street 1st Floor, Chennai, Tamil Nadu, India, 600113</p>
                                                <p><b>GST Number:</b> fixrydeGST</p>
                                            </div>
                                        </div>
                                        <div className="my-4">
                                            <h2 className="text-2xl font-bold text-fixblue">Booking Details</h2>
                                            <h2 className="text-2xl font-bold text-fixblue">_______________</h2>
                                            <div className="overflow-x-auto">
                                                <table className="min-w-full bg-white">
                                                    <thead>
                                                        <tr className='bg-gray-200'>
                                                            <th className="px-4 py-2">Booking ID</th>
                                                            <th className="px-4 py-2">Trip Type</th>
                                                            <th className="px-4 py-2">Booking Cost</th>
                                                            <th className="px-4 py-2">Online Paid</th>
                                                            <th className="px-4 py-2">Cash Collection</th>
                                                            <th className="px-4 py-2">Commission</th>
                                                            <th className="px-4 py-2">Payout</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {invoiceViewData.bookingDetails.length > 0 ? (
                                                            invoiceViewData.bookingDetails.map((booking, index) => (
                                                                <tr key={index}>
                                                                    <td className="border px-4 py-2">{booking.bookingId}</td>
                                                                    <td className="border px-4 py-2">{booking.tripType}</td>
                                                                    <td className="border px-4 py-2">INR ₹ {booking.cost.toFixed(2)}</td>
                                                                    <td className="border px-4 py-2">INR ₹ {booking.amountPaid.toFixed(2)}</td>
                                                                    <td className="border px-4 py-2">INR ₹ {booking.remainingAmount.toFixed(2)}</td>
                                                                    <td className="border px-4 py-2">INR ₹ {booking.commission.toFixed(2)}</td>
                                                                    <td className="border px-4 py-2">INR ₹ {booking.payout.toFixed(2)}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6" className="border px-4 py-2 text-center text-gray-600">
                                                                    No bookings found
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="mt-6 text-right">
                                            <h2 className="text-s text-fixblue">Online Paid: INR ₹ {invoiceViewData.amountPaid.toFixed(2)}</h2>
                                            <h2 className="text-s text-fixblue">Cash Collection: INR ₹ {invoiceViewData.remainingAmount.toFixed(2)}</h2>
                                            <h2 className="text-s text-fixblue">Total Commission: INR ₹ {invoiceViewData.totalCommission.toFixed(2)}</h2>
                                            <h2 className="text-xl font-bold text-fixblue">Total Payout: INR ₹ {invoiceViewData.payout.toFixed(2)}</h2>
                                            <h2 className="text-s text-fixblue">Total Booking Amount: INR ₹ {invoiceViewData.totalAmount.toFixed(2)}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Settle Modal */}
                        {isSettleModalOpen && (
                            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                                <div className="bg-white rounded-lg p-6 w-1/3 relative">
                                    <button
                                        className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                                        onClick={() => setIsSettleModalOpen(false)}
                                    >
                                        <FaTimes />
                                    </button>
                                    <h2 className="text-xl font-semibold mb-4">Settle Invoice</h2>
                                    <div className="flex items-center space-x-4">
                                        <input
                                            type="text"
                                            placeholder="Vendor Payment Reference ID"
                                            value={vendorPaymentReferenceId}
                                            onChange={(e) => setVendorPaymentReferenceId(e.target.value)}
                                            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                        <button
                                            onClick={handleSettlePayment}
                                            className="py-3 px-6 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition-colors"
                                        >
                                            Paid
                                        </button>
                                    </div>
                                    <button
                                        className="mt-4 py-2 px-4 bg-gray-300 text-gray-700 font-semibold rounded-lg hover:bg-gray-400 transition-colors mx-auto block"
                                        onClick={() => setIsSettleModalOpen(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AdminVendorInvoice;

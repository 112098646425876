import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi'; 

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 
  const [userFirstLetter, setUserFirstLetter] = useState(''); 
  const navigate = useNavigate(); // For navigation

  // Check for token and user info in localStorage when the component mounts
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const userData = JSON.parse(localStorage.getItem("user"));
    
    if (accessToken && userData ) {
      if (userData.role === 'ROLE_DRIVER' && userData.verifycd === false && userData.active === false) {
        if (userData.verifycd === false && userData.active === false ) {
          console.log("User Blocked, Please contact FixRyde customer care!");
        } else {
          console.log("You Are Registered as Driver, Please use FixRyde Driver App to login!");
        }
        setIsLoggedIn(false);
        handleLogoutBlocked();
      } else {
        setIsLoggedIn(true);
        console.log("Login Successfull");
      }
    }

    if (userData && userData.name) {
      const firstLetter = userData.name.charAt(0).toUpperCase(); // Extract first letter of the user's name
      setUserFirstLetter(firstLetter);
    } else {
      setUserFirstLetter('C'); // Default to 'U' if no user or name is available
    }
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    navigate("/"); // Redirect to home page after logout
  };

  const handleLogoutBlocked = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
  };

  const handleProfileClick = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown visibility
  };

  const handleNavigateToHistory = () => {
    setIsDropdownOpen(false); // Close dropdown
    navigate("/user/history"); // Navigate to history page
  };

  return (
    <nav className="bg-fixblue shadow-lg">
      <div className="container mx-auto p-4 flex justify-between items-center">
        {/* Brand Logo */}
        <div className="text-white font-bold text-3xl hover:text-gray-200">
          {/* <Link to="/">FixRyde</Link> */}
          <Link to="/"><img
                  src="/images/Logo_1.png"
                  alt="Logo"
                  className="h-8 w-auto lg:h-10"
                /></Link>
        </div>

        {/* Desktop Menu */}
        <div className="hidden md:flex space-x-8 text-white text-lg justify-center items-center">
          <Link to="/" className="hover:text-gray-300 transition duration-300">Home</Link>
          <Link to="/about" className="hover:text-gray-300 transition duration-300">About</Link>
          <Link to="/blogs" className="hover:text-gray-300 transition duration-300">Blogs</Link>
          <Link to="/operator" className="hover:text-gray-300 transition duration-300">Operator</Link>
          <Link to="/contact" className="hover:text-gray-300 transition duration-300">Contact</Link>
          
          {/* Conditional rendering for login/profile based on the token */}
          {!isLoggedIn ? (
            <Link
              to="/user/login"
              className="bg-white text-fixblue font-semibold py-2 px-4 rounded-md shadow hover:bg-gray-100 transition duration-300"
            >
              Login
            </Link>
          ) : (
            <div className="relative">
              {/* Button with first letter inside a circle */}
              <button
                onClick={handleProfileClick}
                className="bg-white text-fixblue font-semibold w-10 h-10 rounded-full flex items-center justify-center shadow hover:bg-fixred hover:text-white transition duration-300"
              >
                {userFirstLetter}
              </button>

              {/* Dropdown for Desktop */}
              {isDropdownOpen && (
                <ul className="absolute right-0 mt-2 bg-fixblue text-white py-2 px-4 border border-white rounded-lg shadow-lg">
                  <li
                    className="mb-2 hover:text-gray-300 cursor-pointer"
                    onClick={handleNavigateToHistory}
                  >
                    History
                  </li>
                  <li
                    className="hover:text-gray-300 cursor-pointer"
                    onClick={handleLogout}
                  >
                    Logout
                  </li>
                </ul>
              )}
            </div>
          )}
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            {isOpen ? <FiX className="w-8 h-8" /> : <FiMenu className="w-8 h-8" />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-indigo-500">
          <Link to="/" className="block px-4 py-3 text-white hover:bg-fixblue transition duration-300">Home</Link>
          <Link to="/about" className="block px-4 py-3 text-white hover:bg-fixblue transition duration-300">About</Link>
          <Link to="/blogs" className="block px-4 py-3 text-white hover:bg-fixblue transition duration-300">Blogs</Link>
          <Link to="/operator" className="block px-4 py-3 text-white hover:bg-fixbluetransition duration-300">Operator</Link>
          <Link to="/contact" className="block px-4 py-3 text-white hover:bg-fixblue transition duration-300">Contact</Link>
          
          {/* Conditional rendering for login/profile in mobile */}
          {!isLoggedIn ? (
            <Link to="/user/login" className="block px-4 py-3 text-indigo-600 bg-white hover:bg-gray-100 rounded-md transition duration-300">
              Login
            </Link>
          ) : (
            <>
              <button
                onClick={handleProfileClick}
                className="w-10 h-10 text-fixblue bg-white hover:bg-fixred hover:text-white rounded-full flex items-center justify-center transition duration-300"
              >
                {userFirstLetter}
              </button>
              {isDropdownOpen && (
                <div className="px-4 py-3 bg-indigo-600 text-white">
                  <ul className="space-y-2">
                    <li
                      className="hover:text-gray-300 cursor-pointer"
                      onClick={handleNavigateToHistory}
                    >
                      History
                    </li>
                    <li
                      className="hover:text-gray-300 cursor-pointer"
                      onClick={handleLogout}
                    >
                      Logout
                    </li>
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;

import React from 'react';

const Filters = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-6">
      <select className="border p-2 rounded bg-blue-200">
        <option>Retail</option>
        <option>Corporate</option>
      </select>
      <select className="border p-2 rounded bg-blue-200">
        <option>Airport</option>
        <option>City</option>
      </select>
      <select className="border p-2 rounded bg-blue-200">
        <option>Airport Pick</option>
        <option>Drop</option>
      </select>
      <select className="border p-2 rounded bg-blue-200">
        <option>basic</option>
        <option>premium</option>
      </select>
      <select className="border p-2 rounded bg-blue-200">
        <option>Goa</option>
        <option>Mumbai</option>
      </select>
      <select className="border p-2 rounded bg-blue-200">
        <option>All</option>
        <option>Specific</option>
      </select>
    </div>
  );
}

export default Filters;

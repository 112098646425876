import React, { useState } from "react";
import { Fragment } from "react";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";

function DriverCheckup() {
    const [sidebarToggle,setSidebarToggle] = useState("false");
  return (
    <Fragment>
      <AdminSidebar
        sidebarToggle={sidebarToggle}
        setSidebarToggle={setSidebarToggle}
      />
      <div
        className={`flex-1 transition-all duration-300 ${
          sidebarToggle ? "ml-0" : "ml-48"
        }`}
      >
        <AdminNavbar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
        />
        <div className="bg-gray-200">
          <div className="container mx-auto">
            <div className="bg-fixwhite shadow-md p-6">
              <div className="flex items-center justify-between gap-2">
                <p className="text-[1rem] font-bold">Driver Chechup</p>
                <div className="inline-flex items-center justify-center">
                  <button className="border-[1px] border-fixblue text-fixblue font-bold bg-fixwhite border-l-4 px-4 py-1 uppercase">
                    Edit Details
                  </button>
                  <button className="text-fixwhite bg-fixblue border-fixblue font-bold border-[1px] botder-r-4 px-4 py-1 uppercase">
                    Save
                  </button>
                </div>
              </div>
              <hr className="my-2 border-[0.5px] border-fixblack" />
              <div className="flex flex-col">
                <img
                  src="/images/ava-2.jpg"
                  alt="driverImg"
                  className="rounded-full w-10 h-10"
                />
                <p className="text-[1rem] my-2">
                  <b>Name:</b> Rani <span>( 6+ years Exprienced )</span>
                </p>
                <button className="max-w-xs bg-green-400 text-fixwhite rounded-sm px-2 py-1">
                  Checkup Completed
                </button>
                <span className="text-gray-600 mt-2">
                  Last Audited : 30+ day ago
                </span>
              </div>
            </div>
            <div className="bg-fixwhite shadow-md p-6 my-4">
              <h3 className="font-bold text-[1rem] mb-4">Driver Amenities</h3>
              <p className="text-xs mb-1">
                Select values which are applicable{" "}
              </p>
              <span className="text-gray-700">
                Last processed: 100 days ago
              </span>
              <div className="font-[1rem] my-6">
                <h5 className="font-bold text-sm">
                  Is the driver neatly dressed?
                </h5>
                <div className="inline-flex items-center m-2 gap-4">
                  <input type="radio" id="d1" name="dressed" />
                  <label htmlFor="d1">Yes</label>
                  <input type="radio" id="d2" name="dressed" />
                  <label htmlFor="d2">No</label>
                </div>
              </div>
              <div className="font-[1rem] my-6">
                <h5 className="font-bold text-sm">
                  Does the driver have a display card in the vehicle?
                </h5>
                <div className="inline-flex items-center m-2 gap-4">
                  <input type="radio" id="c1" name="card" />
                  <label htmlFor="c1">Yes</label>
                  <input type="radio" id="c2" name="card" />
                  <label htmlFor="c2">No</label>
                </div>
              </div>
              <div className="font-[1rem] my-6">
                <h5 className="font-bold text-sm">
                  Is the driver Carrying his Driver's Licence?
                </h5>
                <div className="inline-flex items-center m-2 gap-4">
                  <input type="radio" id="l1" name="licenced" />
                  <label htmlFor="l1">Yes</label>
                  <input type="radio" id="l2" name="licenced" />
                  <label htmlFor="l2">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default DriverCheckup;

import React, { Fragment, useState, useEffect } from "react";
import VendorNavbar from "../../../components/vendor/VendorNavbar";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaCar, FaPlus, FaFilter } from "react-icons/fa";
import DriverMiniVendor from "../../../components/vendor/DriverMiniVendor";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URLE,
    headers: {
        'ngrok-skip-browser-warning': 'true',
        'Content-Type': 'application/json'
    }
});

const MyDriversVendor = () => {
    const [tab, setTab] = useState("all");
    const [drivers, setDrivers] = useState([]);
    const [filteredDrivers, setFilteredDrivers] = useState([]);
    const [showFiltersPanel, setShowFiltersPanel] = useState(false);
    const [driverOption, setDriverOption] = useState("all");
    const [vendorId, setVendorId] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isUserData, setIsUserData] = useState(false);
    const [isUserDataDone, setIsUserDataDone] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        if (!isUserData) {
            setIsUserData(true);
        };
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userData = JSON.parse(localStorage.getItem("user"));
                console.log("MyDriversVendor:userData:", userData);
                setVendorId(userData.sub);
                setIsUserData(true);
                setIsUserDataDone(true);
            } catch (error) {
                console.error('Error fetching User:', error);
            }
        };

        if (isUserData) {
            fetchUserData();
        };
    }, [isUserData]);


    const handleTabChange = (type) => {
        setTab(type);
        filterDrivers(type);
    };

    useEffect(() => {
        const fetchDrivers = async () => {
            console.log()
            try {
                const response = await api.get(`/drivervehicle/drivers/vendordriver?vendorId=${vendorId}`);
                const driverData = response.data;
                console.log("MyDriversVendor:driverData:", driverData);

                setDrivers(driverData);
                setFilteredDrivers(driverData);
            } catch (error) {
                console.error('Error fetching drivers:', error);
            }
        };

        if (isUserDataDone) {
            fetchDrivers();
        };
    }, [isUserDataDone]);

    const filterDrivers = (type) => {
        if (type === "all") {
            setFilteredDrivers(drivers);
        } else {
            setFilteredDrivers(drivers.filter(driver => driver.checkupStatus.toLowerCase() === type));
        }
    };

    return (
        <Fragment>
            <VendorNavbar onToggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
            {/* Main content */}
            <div className={`min-h-screen p-4 transition-all duration-300 ease-in-out`}>
                <div className="bg-zinc-200 min-h-screen p-2">
                    <div className="bg-white shadow-lg p-2 rounded-lg">
                        <div className="flex flex-col justify-between sm:flex-row items-center">
                            <div className="text-gray-700">
                                <h1 className="text-xl font-semibold">My Drivers</h1>
                                <span>{new Date().toLocaleDateString()}</span>
                            </div>
                            <div className="flex items-center mt-4 sm:mt-0">
                                <input
                                    type="text"
                                    placeholder="Search Driver"
                                    className="bg-gray-100 px-4 py-2 rounded-l-lg focus:outline-none"
                                />
                                <Link
                                    to="/vendor/driverform"
                                    className="bg-blue-500 inline-flex items-center text-white px-2 py-2 rounded-r-lg focus:outline-none"
                                >
                                    <FaPlus className="mr-2" /> ADD DRIVER
                                </Link>
                            </div>
                        </div>
                        <hr className="my-2" />
                        <div className="flex gap-4 items-center justify-center text-sm">
                            {["all", "active", "blocked", "pending"].map((type) => (
                                <button
                                    key={type}
                                    className={`cursor-pointer inline-flex items-center ${tab === type
                                        ? "text-blue-500 underline"
                                        : "hover:bg-red-500 hover:text-white px-2 py-1 rounded-sm text-gray-700"
                                        }`}
                                    onClick={() => handleTabChange(type)}
                                >
                                    <FaCar className="mr-1" />
                                    {type.charAt(0).toUpperCase() + type.slice(1)}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="container mx-auto">
                        <div className="m-2 flex justify-between items-center">
                            <div className="relative flex-1 sm:w-auto">
                                <select
                                    value={driverOption}
                                    onChange={(e) => setDriverOption(e.target.value)}
                                    className=" bg-white border border-gray-300 text-gray-700 py-1 px-2  rounded leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 sm:w-auto"
                                >
                                    <option value="all">All Drivers</option>
                                    <option value="driverlist">Driver List</option>
                                    <option value="progress">Reg in Progress</option>
                                    <option value="available">Available</option>
                                    <option value="deactivated">Driver Deactivated</option>
                                    <option value="incabs">Reg On Incabs</option>
                                    <option value="ontrip">On Trip</option>
                                    <option value="loggedout">Logged Out</option>
                                </select>
                            </div>

                            <button
                                className="bg-white border border-gray-300 rounded-md px-4 py-1 flex items-center space-x-2 focus:outline-none"
                                onClick={() => setShowFiltersPanel(!showFiltersPanel)}
                            >
                                <FaFilter className="text-gray-500" />
                                <span>Filters</span>
                            </button>

                            {showFiltersPanel && (
                                <div className="absolute right-1 bg-gray-100 border border-gray-300 shadow-lg rounded-md z-10">
                                    <div className="px-4 py-2">
                                        <h4 className="text-[1rem] font-semibold mb-1">Filters</h4>
                                        {/* Implement filter options here */}
                                        <div className="flex justify-end mt-2">
                                            <button
                                                className="bg-fixblue w-full text-white px-4 py-2 rounded-lg"
                                                onClick={() => setShowFiltersPanel(false)}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <hr className="my-2 border-b-[1px] border-fixblack" />

                    <div className="container mx-auto">
                        <div className="flex justify-center">
                            <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                                {filteredDrivers.map((driver, index) => (
                                    <DriverMiniVendor
                                        key={index}
                                        driver={{
                                            driverName: driver.name,
                                            driverPhoneNumber: driver.mobileNumber,
                                            imageSrc: "/images/employee.jpg",
                                            experience: driver.experience,
                                            driverId: driver.id,
                                            userApproved: driver.userApproved,
                                            bgColor: driver.checkupStatus === "Active" ? "bg-green-500" : driver.checkupStatus === "Blocked" ? "bg-red-500" : "bg-yellow-500",
                                            checkupStatus: driver.checkupStatus
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default MyDriversVendor;

import React from 'react';
import { FaRegCalendarAlt, FaCarAlt, FaMapMarkerAlt, FaCheckCircle } from 'react-icons/fa';

const HowItWork = () => {
    const steps = [
        { icon: FaRegCalendarAlt, heading: '1.Start Booking', description: 'Begin by selecting destination and dates.' },
        { icon: FaCarAlt, heading: '2.Choose Vehicle', description: 'Browse vehicles and choose one.' },
        { icon: FaMapMarkerAlt, heading: '3.Track Ride', description: 'Track your ride in real-time.' },
        { icon: FaCheckCircle, heading: '4.Enjoy Journey', description: 'Relax and enjoy the journey.' }
    ];

    return (
        <section className="py-6 text-fixblue">
            <div className="container mx-auto px-4">
                <div className="text-center mb-6">
                    <h6 className="text-red-500 text-lg font-bold">How It Works</h6>
                    <h2 className="text-3xl font-bold ">Discover the Process</h2>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2">
                    {steps.map((step, index) => (
                        <div key={index} className="flex flex-col items-center justify-between p-6 bg-gray-200 rounded-lg shadow-lg">
                            <div className="flex items-center justify-center w-16 h-16 rounded-full bg-red-500 text-fixwhite mb-6">
                                <step.icon className="h-8 w-8" />
                            </div>
                            <h3 className="text-xl font-semibold mb-2">{step.heading}</h3>
                            <p className="text-fixblack text-center">{step.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default HowItWork;

import React, { Fragment } from 'react';

function SpecialOffer({ imageSrc, heading, paragraph, buttonText, valid }) {
    return (
        <Fragment>

            <div className="max-w-sm border border-gray-300 rounded overflow-hidden shadow-lg">
                <img className="w-full min-h-sm object-cover" src={imageSrc} alt="Offer" />
                <div className="py-6 px-4  bg-fixwhite">
                    <h2 className="font-serif text-xl mb-2 text-center text-fixblack">{heading}</h2>
                    <p className="text-gray-900 text-base leading-snug font-sans">{paragraph}</p>
                </div>
                <div className="p-4 bg-gray-100 flex justify-between items-center border-t border-gray-300"> {/* Added border-t class */}
                    <span className="text-gray-700 text-sm">Valid until - {valid}</span>
                    <button className="bg-fixblue hover:bg-fixgold hover:underline text-fixwhite font-bold py-2 px-4 rounded text-sm">
                        {buttonText}
                    </button>
                </div>
            </div>

            
        </Fragment>
    );
}

export default SpecialOffer;

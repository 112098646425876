// src/pages/userPages/UserPanel.js

import React, { Fragment, useMemo, useState } from 'react';
import Navbar from '../../components/site/Navbar';
import Footer from '../../components/site/Footer';
import { RiMoneyRupeeCircleFill } from 'react-icons/ri';
import { FaCircleCheck, FaHeartCircleCheck, FaBuilding, FaCarSide, FaStar, FaClock, FaRoad } from 'react-icons/fa6';
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Main from '../sitePages/Main';
import { useSelector } from 'react-redux';
import { formatTimeTo24Hour } from '../../utils/tripUtils';

const UserPanel = () => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.trip);

  const { fareDetailsList } = userData.baseKmResponses || {};

  const formatDuration = (durationInMinutes) => {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.round(durationInMinutes % 60);

    const formattedHours = hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
    const formattedMinutes = minutes > 0 ? `${minutes} min${minutes > 1 ? 's' : ''}` : '';

    return `${formattedHours} ${formattedMinutes}`.trim();
  };

  const [vehicleFilter, setVehicleFilter] = useState('Any');

  const processFareDetails = (vehicleFilter) => {
    if (!fareDetailsList || fareDetailsList.length === 0) return { results: [], quotes: [] };

    let filteredFares = fareDetailsList;

    if (vehicleFilter && vehicleFilter !== 'Any') {
      filteredFares = fareDetailsList.filter(
        (fare) => fare.vehicleCategory.toLowerCase() === vehicleFilter.toLowerCase()
      );
    }

    // Create a shallow copy of the array before sorting
    const sortedFares = [...filteredFares].sort((a, b) => a.totalFare - b.totalFare);

    const fareCount = sortedFares.length;
    const results = [];

    if (fareCount >= 1) {
      // Lowest Price
      const lowestFare = sortedFares[0];
      results.push({
        id: 1,
        title: 'Lowest Price',
        company: lowestFare.vendorName || '--',
        price: lowestFare.totalFare,
        rating: 4.2,
        color: 'red',
        Icon: RiMoneyRupeeCircleFill,
      });
    }

    if (fareCount >= 2) {
      // Medium Price
      const mediumIndex = Math.floor(fareCount / 2);
      const mediumFare = sortedFares[mediumIndex];
      results.push({
        id: 2,
        title: 'Medium Price',
        company: mediumFare.vendorName || '--',
        price: mediumFare.totalFare,
        rating: 4.2,
        color: 'blue',
        Icon: FaHeartCircleCheck,
      });
    }

    if (fareCount >= 3) {
      // Highest Price
      const highestFare = sortedFares[fareCount - 1];
      results.push({
        id: 3,
        title: 'Highest Price',
        company: highestFare.vendorName || '--',
        price: highestFare.totalFare,
        rating: 4.2,
        color: 'green',
        Icon: FaCircleCheck,
      });
    }

    const quotes = sortedFares.map((fare, index) => ({
      id: index + 1,
      company: fare.vendorName || '--',
      vendorUserid: fare.vendorUserid || '--',
      vehicle: `${fare.vehicleCategory}'s Any Model`,
      vehiclecat: fare.vehicleCategory,
      vehicleId: fare.vehicleId,
      rating: 4.2,
      duration: formatDuration(fare.duration),
      price: fare.totalFare,
      extra: `₹ ${fare.extraFarePerKm}/Km after ${fare.maxValue} Kms`,
      inclusions: `${fare.maxValue} km, Toll charges`,
      exclusions: 'GST, State taxes, Driver charges',
    }));

    return { results, quotes };
  };

  const { results, quotes } = useMemo(
    () => processFareDetails(vehicleFilter),
    [fareDetailsList, vehicleFilter]
  );

  const colorMap = {
    red: 'bg-red-500',
    blue: 'bg-blue-500',
    green: 'bg-green-500',
    gray: 'bg-gray-500',
  };

  const handleBookNow = async (quote) => {

    const bookingData = {
      userId: JSON.parse(localStorage.getItem('user'))?.id || null,
      vehicleType: quote.vehiclecat,
      pickupLocation: userData.sourceDetails.formatted_address,
      dropLocation: userData.destinationDetails.formatted_address,
      fare: quote.price,
      status: 'PENDING',
      tripDuration: quote.duration,
      sourceLatitude: userData.sourceDetails.latitude,
      sourceLongitude: userData.sourceDetails.longitude,
      destinationLatitude: userData.destinationDetails.latitude,
      destinationLongitude: userData.destinationDetails.longitude,
      paidAmount: 0,
      remainingToBePaid: quote.price,
      paymentType: 'Not Paid',
      tripType: userData.tripType,
      distanceKm: parseFloat(userData.distance.replace(' km', '')),
      bookingDate: userData.date,
      bookingTime: userData.time,
      passengerCount: userData.passengerCount || 1,
      luggageCount: userData.luggageCount || 1,
      vendorUserid: quote.vendorUserid,
      businessType: 'B2C',
      vehicleId: quote.vehicleId,
      payableAmount: quote.price,
    };

    if (bookingData.bookingTime.includes('AM') || bookingData.bookingTime.includes('PM')) {
      bookingData.bookingTime = `${await formatTimeTo24Hour(bookingData.bookingTime)}:00`;
      console.log("12hours:", bookingData.bookingTime);
    } else {
      bookingData.bookingTime += ":00";
      console.log("24hours:", bookingData.bookingTime);
    }

    try {
      const accessToken = localStorage.getItem('accessToken');

      if (accessToken) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URLB}/bookings/create`,
          bookingData,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response && response.data) {
          // alert('Booking confirmed successfully!');
          const bookingData1 = response.data;
          console.log('Booking Response:', response.data);
          navigate('/user/payment', { state: { bookingData1 } });
        } else {
          throw new Error('Failed to confirm the booking.');
        }
      } else {
        alert('User is not authenticated. Please log in.');
      }
    } catch (error) {
      console.error('Error during booking:', error);

      if (error.response && error.response.data) {
        alert(`An error occurred: ${error.response.data.error}. Please Refresh the page, and try again.`);
      } else {
        alert('An error occurred while confirming your booking. Please Refresh the page, and try again.');
      }
    }
  };

  const vehicleImages = {
    Hatchback: '/images/Hatchback.png',
    Sedan: '/images/Sedan.png',
    SUV: '/images/SUV.png',
    Innova: '/images/SUV.png',
    'Innova Crysta': '/images/SUV.png',
    Any: '/images/Others.png',
  };

  // Updated QuoteCard Component
  const QuoteCard = ({ quote }) => {
    const [activeTab, setActiveTab] = useState('inclusions');

    const vehicleImage = vehicleImages[quote.vehiclecat] || vehicleImages['Any'];

    return (
      <div
        key={quote.id}
        className="border border-gray-300 rounded-lg shadow-lg p-2 m-2 transition transform hover:-translate-y-1 hover:shadow-2xl"
      >
        <div className="flex flex-col md:flex-row items-center">
          {/* Vehicle Image */}
          <div className="w-full md:w-1/5 flex justify-center mb-4 md:mb-0">
            <img
              src={vehicleImage}
              alt={quote.vehicle}
              className="w-24 h-24 object-contain"
            />
          </div>

          {/* Quote Details */}
          <div className="w-full md:w-4/5 md:pl-4">
            <div className="flex justify-between items-center mb-2">
              <div>
                {/* Company Name with Icon */}
                <div className="flex items-center">
                  <FaBuilding className="w-4 h-4 text-gray-600 mr-2" />
                  <h4 className="text-base font-semibold">{quote.company}</h4>
                </div>
                {/* Vehicle Type with Icon */}
                <div className="flex items-center mt-1">
                  <FaCarSide className="w-4 h-4 text-gray-600 mr-2" />
                  <p className="text-sm text-gray-600">{quote.vehicle}</p>
                </div>
                {/* Rating with Icon */}
                <div className="flex items-center mt-2">
                  <FaStar className="w-4 h-4 text-yellow-500 mr-1" />
                  <span className="flex text-yellow-500">
                    {[...Array(Math.round(quote.rating))].map((_, i) => (
                      <FaStar key={i} className="w-3 h-3 fill-current" />
                    ))}
                  </span>
                  <span className="ml-2 text-sm text-gray-600">{quote.rating}</span>
                </div>
              </div>
              <div className="text-right">
                <p className="text-xl font-bold text-green-600">₹ {quote.price}</p>
                <button
                  className="mt-2 bg-green-500 hover:bg-green-600 text-white px-3 py-1 rounded transition duration-300"
                  onClick={() => handleBookNow(quote)}
                >
                  Book Now
                </button>
              </div>
            </div>

            {/* Trip Details */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-3">
              <div className="flex items-center">
                <FaClock className="w-4 h-4 text-blue-500 mr-2" />
                <p className="text-sm text-gray-600">Trip Duration: {quote.duration}</p>
              </div>
              <div className="flex items-center">
                <FaRoad className="w-4 h-4 text-red-500 mr-2" />
                <p className="text-sm text-gray-600">Extra Km Fare: {quote.extra}</p>
              </div>
            </div>

            {/* Tabs */}
            <div className="border-t border-gray-200 pt-3">
              <div className="flex">
                <button
                  className={`flex-1 py-1 text-center flex items-center justify-center ${activeTab === 'inclusions'
                    ? 'border-b-2 border-green-500 text-green-500'
                    : 'text-gray-600'
                    }`}
                  onClick={() => setActiveTab('inclusions')}
                >
                  <FaCheckCircle className="w-4 h-4 mr-1" />
                  Inclusions
                </button>
                <button
                  className={`flex-1 py-1 text-center flex items-center justify-center ${activeTab === 'exclusions'
                    ? 'border-b-2 border-green-500 text-green-500'
                    : 'text-gray-600'
                    }`}
                  onClick={() => setActiveTab('exclusions')}
                >
                  <FaTimesCircle className="w-4 h-4 mr-1" />
                  Exclusions
                </button>
              </div>
              <div className="mt-3 text-gray-700">
                {activeTab === 'inclusions' ? (
                  <div>
                    <h5 className="font-bold text-base mb-2">
                      <FaCheckCircle className="w-4 h-4 inline-block mr-2 text-green-500" />
                      Inclusions
                    </h5>
                    <p className="text-sm">{quote.inclusions}</p>
                  </div>
                ) : (
                  <div>
                    <h5 className="font-bold text-base mb-2">
                      <FaTimesCircle className="w-4 h-4 inline-block mr-2 text-red-500" />
                      Exclusions
                    </h5>
                    <p className="text-sm">{quote.exclusions}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <Navbar />
      <div className="flex justify-center items-center min-h-screen">
        <div className="container mx-auto text-[13px]">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-1">
            <div className="lg:col-span-12">
              <div className="w-full bg-ryde bg-bottom bg-cover bg-no-repeat items-center p-4">
                <Main />
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-12 gap-1">
                <div className="lg:col-span-8">
                  {/* Updated Results Cards */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 mt-2 p-2">
                    {results.map((result) => (
                      <div
                        className={`p-1 rounded-lg shadow-lg text-white ${colorMap[result.color]
                          } flex flex-col items-center justify-center transform transition duration-300 hover:scale-105`}
                        key={result.id}
                      >
                        <h3 className="text-base font-semibold mb-2">{result.title}</h3>
                        <result.Icon size={36} style={{ textAlign: 'center' }} />
                        <p className="mt-2 text-sm">{result.company}</p>
                        <p className="text-xl font-bold mt-2">₹{result.price}</p>
                        <div className="flex items-center mt-2">
                          <span className="flex text-yellow-500">
                            {[...Array(Math.round(result.rating))].map((_, i) => (
                              <FaStar key={i} className="w-3 h-3 fill-current" />
                            ))}
                          </span>
                          <span className="ml-2 text-sm">{result.rating}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="lg:col-span-4 p-2 mt-2">
                  {/* Enhanced Filter Component */}
                  <div className="bg-gray-100 p-3 rounded-lg shadow-md">
                    <div className="mb-3">
                      <label className="block text-center font-bold text-lg mb-2">
                        Taxi Type
                      </label>
                      <select
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-sm"
                        value={vehicleFilter}
                        onChange={(e) => setVehicleFilter(e.target.value)}
                      >
                        <option value="Any">Any</option>
                        <option value="Hatchback">Hatchback</option>
                        <option value="Sedan">Sedan</option>
                        <option value="SUV">SUV</option>
                        <option value="Innova">Innova</option>
                        <option value="Innova Crysta">Innova Crysta</option>
                      </select>
                    </div>
                    {/* Remove the Apply Filters button if unnecessary */}
                    <button
                      className="w-full bg-green-500 hover:bg-green-600 text-white py-1 rounded-md transition duration-300 text-sm"
                      onClick={() => setVehicleFilter(vehicleFilter)}
                    >
                      Apply Filters
                    </button>
                  </div>
                </div>
                <div className="lg:col-span-12 p-2">
                  <div className="bg-white rounded shadow-xl">
                    <h3 className="text-lg font-semibold mb-1 p-2">All Best Quotes</h3>
                    <div>
                      {quotes.map((quote) => (
                        <QuoteCard
                          key={quote.id}
                          quote={quote}
                          handleBookNow={handleBookNow}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default UserPanel;

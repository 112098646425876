// src/pages/userPages/PaymentPage.js

import React, { Fragment, useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from "../../components/site/Navbar";
import Footer from "../../components/site/Footer";
import CancelModal from "../sitePages/CancelModal";
import Modal from "../../components/site/Modal";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModal from "../../components/ConfirmationModal";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    'ngrok-skip-browser-warning': 'true',
    'Content-Type': 'application/json'
  }
});

const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Destructure bookingData1 from location.state
  const { bookingData1: bookingDataFromState = {} } = location.state || {};

  // Retrieve bookingData1 from localStorage if not in location.state
  const bookingDataStored = JSON.parse(localStorage.getItem('bookingData1')) || {};

  // Determine the source of bookingData1
  const bookingData = Object.keys(bookingDataFromState).length > 0 ? bookingDataFromState : bookingDataStored;

  // If bookingDataFromState exists, store it in localStorage
  useEffect(() => {
    if (bookingData && Object.keys(bookingData).length > 0) {
      localStorage.setItem('bookingData1', JSON.stringify(bookingData));
    }
  }, [bookingData]);

  const userData = JSON.parse(localStorage.getItem("user"));
  const initialMobileNumber = userData?.sub || "";
  const initialUserId = userData?.id || "";
  const [userId] = useState(initialUserId);

  const [paymentOption, setPaymentOption] = useState("partial");
  const [isFareModalOpen, setIsFareModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const [fullName, setFullName] = useState(bookingData.fullName || "");
  const [email, setEmail] = useState(bookingData.email || "");
  const [mobileNumber, setMobileNumber] = useState(initialMobileNumber);
  const [driverNotes, setDriverNotes] = useState("");
  const [gender, setGender] = useState("male");
  const [isRazorpayLoaded, setIsRazorpayLoaded] = useState(false);

  // Timer state
  const [timeLeft, setTimeLeft] = useState(300);

  const [bookingCompleted, setBookingCompleted] = useState(false);

  const openFareModal = () => setIsFareModalOpen(true);
  const closeFareModal = () => setIsFareModalOpen(false);

  const openCancelModal = () => setIsCancelModalOpen(true);
  const closeCancelModal = () => setIsCancelModalOpen(false);

  // State for confirmation modal
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);

  // Ref to track if modal is open to prevent multiple pushState calls
  const isModalOpenRef = useRef(false);

  // Function to release held booking
  const releaseHeldBooking = useCallback(async (reason) => {
    console.log('releaseHeldBooking called with reason:', reason);
    const releasetoken = localStorage.getItem("accessToken");
    const data = {
      bookingId: bookingData.bookId,
      userMobileNumber: userId,
      reason: reason,
      releasetoken: releasetoken,
    };

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_BASE_URLB}/bookings/customerheld/release`, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      });
      console.log('Release booking response:', res.data);
      toast.info('Booking released due to: ' + reason);
      navigate('/user/dashboard', { state: { bookingData1: { ...bookingData, confirmationMessage: res.data.message, paymentStatus: 'Cancelled' } } });
    } catch (error) {
      console.error('Error releasing booking:', error);
      toast.error('Error releasing booking');
    }
  }, [bookingData, userId, navigate]);

  // Load Razorpay script dynamically
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => {
      setIsRazorpayLoaded(true);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Timer countdown effect
  useEffect(() => {
    // console.log('timeLeft:', timeLeft);
    let timerId;

    if (timeLeft > 0) {
      timerId = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else {
      releaseHeldBooking("Timer out");
    }
    return () => clearInterval(timerId);
  }, [timeLeft, releaseHeldBooking]);

  // Fetch user profile data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const response = await api.get(`/bookings/customerprofile`, {
          params: { userId },
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });

        const fetchedProfileData = response.data;

        setFullName((fetchedProfileData.fullName || '').replace(/\bnull\b/g, '').trim());
        setEmail(fetchedProfileData.email || "user@example.com");
        setMobileNumber(fetchedProfileData.mobileNumber || "");
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (initialLoad) {
      fetchData();
      setInitialLoad(false);
    }
  }, [initialLoad, userId]);

  // Handle browser refresh or tab close
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!bookingCompleted) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [bookingCompleted]);

  // Handle browser back button via popstate event
  useEffect(() => {
    const handlePopState = (event) => {
      if (!bookingCompleted && !isModalOpenRef.current) {
        // Show confirmation modal
        console.log('log2');
        window.history.pushState({ bookingData1: bookingData }, '', window.location.pathname);
        console.log('log3');
        setShowConfirmationModal(true);
        // Set the ref to indicate that modal is open
        isModalOpenRef.current = true;
        console.log('log4');
      }
    };

    console.log('log1');
    // Push the current state to the history stack
    window.history.pushState(null, '', window.location.pathname);

    window.addEventListener('popstate', handlePopState);
    console.log('log5');
    return () => {
      // window.removeEventListener('popstate', handlePopState);
      console.log('log6:remove');
    };
  }, [bookingCompleted, bookingData]);

  // Handle in-app navigation (e.g., clicking "Cancel Payment" button)
  const handleNavigation = (path) => {
    if (!bookingCompleted && !isModalOpenRef.current) {
      setPendingNavigation(path);
      setShowConfirmationModal(true);
      isModalOpenRef.current = true;
    } else {
      navigate(path);
    }
  };

  // Confirm navigation
  const handleConfirmNavigation = () => {
    setShowConfirmationModal(false);
    isModalOpenRef.current = false;

    if (pendingNavigation) {
      releaseHeldBooking("User confirmed navigation");
      navigate(pendingNavigation);
      setPendingNavigation(null);
    } else {
      // If no pending navigation, allow browser's back button
      releaseHeldBooking("User confirmed navigation");
      navigate(-1);
    }

    // Remove bookingData1 from localStorage
    localStorage.removeItem('bookingData1');
  };

  // Cancel navigation
  const handleCancelNavigation = () => {
    setShowConfirmationModal(false);
    setPendingNavigation(null);
    isModalOpenRef.current = false;
  };

  // Handle Backspace key outside input fields
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        const target = event.target;
        const isInput = target.tagName === 'INPUT' || target.tagName === 'TEXTAREA' || target.isContentEditable;

        if (!isInput && !isModalOpenRef.current) {
          setPendingNavigation(-1); // -1 indicates navigate back
          setShowConfirmationModal(true);
          isModalOpenRef.current = true;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Form validation state
  const isFormValid = fullName.trim() !== '' && email.trim() !== '' && mobileNumber.trim() !== '';

  const initiateRazorpayPayment = () => {
    if (!isFormValid) {
      alert("Please fill in all required fields.");
      return;
    }

    if (!isRazorpayLoaded) {
      alert("Razorpay SDK is still loading. Please try again in a moment.");
      return;
    }

    const amount = paymentOption === "partial" ? (bookingData.fare * 0.2 * 100).toFixed(2) : bookingData.fare * 100;

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount,
      currency: "INR",
      name: "FixRyde Payment",
      description: "Booking Payment",
      handler: async function (checkoutresponse) {
        await handlePaymentSuccess(checkoutresponse);
      },
      prefill: {
        name: fullName || "",
        email: email || "user@example.com",
        contact: mobileNumber || "",
      },
      theme: {
        color: "#3399cc"
      },
      modal: {
        ondismiss: function () {
          releaseHeldBooking("User cancelled payment");
        }
      }
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();

    // Handle payment failures
    rzp1.on('payment.failed', function (response) {
      releaseHeldBooking("Razorpay failed");
    });
  };

  const handlePaymentSuccess = async (checkoutresponse) => {
    console.log("checkoutresponse:", checkoutresponse);

    const paymentData = {
      bookingId: bookingData.bookId,
      transactionId: checkoutresponse.razorpay_payment_id,
      paymentMethod: "CARD",
      totalAmount: bookingData.fare,
      paidAmount: paymentOption === "partial" ? bookingData.fare * 0.2 : bookingData.fare,
      remainingAmount: paymentOption === "partial" ? bookingData.fare * 0.8 : 0,
      name: fullName || "",
      email: email || "user@example.com",
      contact: mobileNumber || "",
      driverNotes: driverNotes,
      gender: gender,
      userId,
    };

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_BASE_URLB}/bookings/confirmPayment`, paymentData, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        }
      });

      console.log('Payment confirmation response:', res.data);

      const updatedBookingData = {
        ...bookingData,
        name: fullName || "",
        email: email || "user@example.com",
        contact: mobileNumber || "",
        confirmationMessage: res.data.message,
        transactionId: res.data.transactionId,
        paymentMethod: res.data.paymentMethod,
        totalAmount: res.data.totalAmount,
        paidAmount: res.data.paidAmount,
        remainingAmount: res.data.remainingAmount,
        tripId: res.data.tripId,
        tripOtp: res.data.tripOtp,
        paymentStatus: res.data.paymentStatus
      };

      setBookingCompleted(true); // Set bookingCompleted to true
      navigate('/user/confirm', { state: { bookingData1: updatedBookingData } });

      // Remove bookingData1 from localStorage
      localStorage.removeItem('bookingData1');
    } catch (error) {
      console.error('Error confirming payment:', error);
      const message = Object.values(error.response.data).slice(0, 49).join('');

      const updatedBookingData = {
        ...bookingData,
        name: fullName || "",
        email: email || "user@example.com",
        contact: mobileNumber || "",
        confirmationMessage: message,
        paymentStatus: "Failed"
      };

      navigate('/user/confirm', { state: { bookingData1: updatedBookingData } });
      toast.warn('Payment failed. Please try again.');

      // Optionally remove bookingData1 if payment failed
      localStorage.removeItem('bookingData1');
    }
  };

  // Helper function to get mobileNumber from bookingData or initial value
  const mobileNumberFromState = (data, initial) => {
    return data.mobileNumber || initial || "";
  };

  // Format time in mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  // Calculate hue for color transition from green (120) to red (0)
  const hue = (timeLeft / 300) * 120;

  return (
    <Fragment>
      <ToastContainer />
      <Navbar />
      <div
        className="fixed top-77 right-2 p-2 rounded text-white font-semibold"
        style={{
          backgroundColor: `hsla(${hue}, 100%, 50%, 0.5)`,
          transition: 'background-color 1s linear',
        }} >
        <p className="text-sm font-semibold text-gray-700">
          Complete Payment within: {formatTime(timeLeft)}
        </p>
      </div>
      <div className="bg-gray-200 p-6 max-w-5xl mx-auto">

        <div className="flex flex-col md:flex-row">
          <div className="bg-white shadow-md p-4 rounded-lg w-full md:w-1/3">
            <div className="mb-4">
              <h3 className="text-gray-700 font-semibold">Request ID #{bookingData.bookId || '12345'}</h3>
              <h3 className="text-gray-700 font-semibold">Booking Date : {bookingData.bookingDate || '12345'}</h3>
              <h3 className="text-gray-700 font-semibold">Booking Time : {bookingData.bookingTime || '12345'}</h3>
              <h4 className="text-gray-600 font-semibold">Pickup : </h4>
              <p className="text-sm text-gray-500">{bookingData.pickupLocation || "Tharamani, Chennai, Tamil Nadu, India"}</p>
              <h4 className="text-gray-600 font-semibold">Drop : </h4>
              <p className="text-sm text-gray-500">{bookingData.dropLocation || "Vellore, Tamil Nadu, India"}</p>
            </div>
            <div className="flex items-center mb-4">
              <img
                src="/images/Sedan.png"
                alt="Car"
                className="w-14 h-15 object-cover rounded-lg"
              />
              <div className="ml-4">
                <p className="text-gray-700 font-semibold">{bookingData.vendorUserid || "ABC Taxi"}</p>
                <span className="text-fixred">★ 5</span>
                <p className="text-sm text-gray-500">{bookingData.vehicleType || "Dzire or Etios"}</p>
              </div>
            </div>
            <div className="text-sm text-gray-500 flex items-center">
              <p className="mr-4">{bookingData.passengerCount || 4} Passengers</p>
              <p>{bookingData.acAvailable ? "AC Available" : "No AC"}</p>
            </div>
          </div>

          <div className="bg-white shadow-md p-4 rounded-lg mt-6 md:mt-0 md:ml-4 w-full md:w-2/3">
            <div className="flex justify-between items-center">
              <h3 className="text-gray-700 font-semibold">
                Who's travelling on this trip?
              </h3>
              <button
                className="text-fixred font-fixopen text-sm"
                onClick={() => {
                  // Trigger confirmation modal for navigation
                  handleNavigation('/user/dashboard');
                }}
              >
                Cancel Payment
              </button>
            </div>
            <div className="mt-4 flex flex-col md:flex-row items-center gap-4">
              <div className="flex-1">
                <label className="block text-sm text-gray-500">Full name<span className="text-fixred"> *</span></label>
                <input
                  type="text"
                  className="mt-1 p-2 border border-gray-300 rounded w-full"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder="Enter your full name"
                  required
                />
              </div>
              <div className="flex items-center">
                <label className="text-sm text-gray-500 mr-2">Gender</label>
                <div className="flex space-x-4">
                  <label className="inline-flex items-top">
                    <input
                      type="radio"
                      value="male"
                      checked={gender === 'male'}
                      onChange={() => setGender('male')}
                      className="form-radio"
                    />
                    <span className="ml-2">Male</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      value="female"
                      checked={gender === 'female'}
                      onChange={() => setGender('female')}
                      className="form-radio"
                    />
                    <span className="ml-2">Female</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      value="others"
                      checked={gender === 'others'}
                      onChange={() => setGender('others')}
                      className="form-radio"
                    />
                    <span className="ml-2">Others</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-4 flex flex-col md:flex-row gap-4">
              <div className="flex-1">
                <label className="block text-sm text-gray-500">Email ID<span className="text-fixred"> *</span></label>
                <input
                  type="email"
                  className="mt-1 p-2 border border-gray-300 rounded w-full"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="flex-1">
                <label className="block text-sm text-gray-500">Mobile Number<span className="text-fixred"> *</span></label>
                <input
                  type="tel"
                  className="mt-1 p-2 border border-gray-300 rounded w-full"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  placeholder="Enter your mobile number"
                  required
                />
              </div>
            </div>

            <div className="mt-4">
              <label className="block text-sm text-gray-500">Driver Note</label>
              <input
                type="text"
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                value={driverNotes}
                onChange={(e) => setDriverNotes(e.target.value)}
                placeholder="Driver Notes"
              />
            </div>

            <div className="mt-6">
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-gray-700 font-semibold">
                    Amount payable
                  </h3>
                  <p className="text-sm text-gray-500">
                    Inclusive of tolls and taxes
                  </p>
                </div>
                <div className="flex flex-col mt-2">
                  <p className="text-lg font-bold">₹{bookingData.fare || 2450}</p>
                  <a
                    href="#"
                    onClick={(e) => { e.preventDefault(); openFareModal(); }}
                    className="text-blue-500 text-sm cursor-pointer"
                  >
                    Fare details
                  </a>
                </div>
                {isFareModalOpen && <Modal closeModal={closeFareModal} fullFare={bookingData.fare} />}
              </div>
              <div className="mt-4">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    openCancelModal();
                  }}
                  className="text-fixred font-fixopen text-sm"
                >
                  Cancellation Charges & Other Options
                </a>
                {isCancelModalOpen && <CancelModal isOpen={isCancelModalOpen} onClose={closeCancelModal} />}
              </div>

              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <div>
                    <label className="text-gray-700 font-semibold">
                      Promo code
                    </label>
                    <p className="text-sm text-gray-500">SpecialOffers</p>
                  </div>
                  <div className="flex mt-1 gap-2">
                    <input
                      type="text"
                      className="p-2 border border-gray-300 rounded-l w-full"
                      placeholder="Enter promo code"
                    />
                    <button className="bg-fixblue text-white px-4 py-2 rounded">
                      Apply
                    </button>
                  </div>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-1 lg:grid-cols-2 lg:gap-4">
                <div className="flex flex-col lg:items-start">
                  <p className="text-gray-700 font-semibold">Payment options</p>
                  <div className="flex items-center mt-2">
                    <input
                      type="radio"
                      id="partial"
                      name="payment"
                      value="partial"
                      checked={paymentOption === "partial"}
                      onChange={() => setPaymentOption("partial")}
                    />
                    <label htmlFor="partial" className="ml-2">
                      Pay ₹{(bookingData.fare * 0.2).toFixed(0) || 644} (20%) Now and rest to the driver
                    </label>
                  </div>
                  <div className="flex items-center mt-2">
                    <input
                      type="radio"
                      id="full"
                      name="payment"
                      value="full"
                      checked={paymentOption === "full"}
                      onChange={() => setPaymentOption("full")}
                    />
                    <label htmlFor="full" className="ml-2">
                      Pay ₹{bookingData.fare || 3220} (100%) Now
                    </label>
                  </div>
                </div>

                <div className="flex items-center justify-center lg:justify-end mt-6 lg:mt-0">
                  <button
                    className={`bg-${paymentOption === "partial" ? "fixblue" : "fixblack"} text-white px-4 py-2 rounded-lg ${!isFormValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={initiateRazorpayPayment}
                    disabled={!isFormValid}
                  >
                    {paymentOption === "partial" ? `PAY ₹${(bookingData.fare * 0.2).toFixed(0)}` : `PAY ₹${bookingData.fare}`}
                  </button>
                </div>
              </div>

              <div className="flex space-x-2 p-4">
                <img
                  src="/images/payments.png"
                  alt="payment"
                  className="w-half"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <ConfirmationModal
          message="Are you sure you want to leave? Your booking will be released."
          onConfirm={handleConfirmNavigation}
          onCancel={handleCancelNavigation}
        />
      )}
    </Fragment>
  );
};

export default PaymentPage;

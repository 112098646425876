import DriverLogin from "../pages/driverPages/DriverLogin";
import DriverProfile from "../pages/driverPages/DriverProfile";
import TripDashboard from "../pages/driverPages/TripDashboard";
import TripStage from "../pages/driverPages/TripStage";

const driverRoutes = [
    //{path:'/driverlogin' ,element:<DriverLogin />},
    {path:'/driver/profile' ,element:<DriverProfile />},
    {path:'/driver/trip' ,element:<TripDashboard />},
    {path:'/driver/tripstage' ,element:<TripStage />},
];

export default driverRoutes;

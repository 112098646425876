// src/components/vendor/AssignDriverModal.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Create an axios instance with base URL
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    'ngrok-skip-browser-warning': 'true',
    'Content-Type': 'application/json',
  },
});

function AssignDriverModal({ onClose, vehicleNumber, assignedDriverPhoneNumber, vendorPhoneNumber }) {
  const [drivers, setDrivers] = useState([]);
  const [currentAssignedDriverPhoneNumber, setCurrentAssignedDriverPhoneNumber] = useState(assignedDriverPhoneNumber);

  useEffect(() => {
    // Fetch the list of drivers
    const fetchDrivers = async () => {
      try {
        const response = await api.get(`/drivervehicle/drivers/vendordriver?vendorId=${vendorPhoneNumber}`);
        const fetchedDrivers = response.data;
        console.log("AssignDriverModal:response:", fetchedDrivers);

        // Filter drivers based on the specified conditions
        const filteredDrivers = fetchedDrivers.filter(driver => 
          driver.driverApproved === true &&
          driver.userApproved === true &&
          driver.active === true &&
          driver.checkupStatus === "Active"
        );

        setDrivers(filteredDrivers);
      } catch (error) {
        console.error('Error fetching drivers:', error);
        toast.error('Failed to fetch drivers');
      }
    };

    fetchDrivers();
  }, [vendorPhoneNumber]);

  const handleAssignDriver = async (driverMobileNumber) => {
    try {
      // Assign driver to vehicle
      const response = await api.post('/drivervehicle/vehicle/driver/assigndrivervehicle', {
        // vehicleNumber,
        // driverPhoneNumber: driverMobileNumber,
        // vendorPhoneNumber: vendorPhoneNumber,
        vehicleNumber,
        newDriverNumber: driverMobileNumber,
        vendorNumber: vendorPhoneNumber,
      });

      console.log("AssignDriverModal:response:",response);
      if (response.status === 200 || response.status === 201) {
        toast.success('Driver assigned successfully');

        // Update the assigned driver phone number in state
        setCurrentAssignedDriverPhoneNumber(driverMobileNumber);

        // Update the drivers list to reflect the change
        setDrivers((prevDrivers) =>
          prevDrivers.map((driver) =>
            driver.mobileNumber === driverMobileNumber
              ? { ...driver, assigned: true }
              : { ...driver }
          )
        );
      } else {
        toast.error('Failed to assign driver');
      }
    } catch (error) {
      console.error('Error assigning driver:', error);
      toast.error(error.response.data);
    }
  };

  const isDriverAssigned = (driverMobileNumber) => {
    return driverMobileNumber === currentAssignedDriverPhoneNumber;
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
        <ToastContainer />
      {/* Modal overlay */}
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>

      {/* Modal content */}
      <div className="bg-white rounded-lg p-4 z-10 max-w-3xl w-full relative">
        {/* Close button at the top right */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          ×
        </button>

        <h2 className="text-2xl font-semibold mb-4">Assign Driver</h2>

        {/* Drivers table */}
        <table className="min-w-full border">
          <thead>
            <tr>
              <th className="px-4 py-2 border">S.No</th>
              <th className="px-4 py-2 border">Driver Name</th>
              <th className="px-4 py-2 border">Mobile Number</th>
              <th className="px-4 py-2 border">Experience</th>
              <th className="px-4 py-2 border">Action</th>
            </tr>
          </thead>
          <tbody>
            {drivers.length === 0 ? (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  No drivers available for assignment.
                </td>
              </tr>
            ) : (
              drivers.map((driver, index) => (
                <tr key={driver.mobileNumber}>
                  <td className="px-4 py-2 border text-center">{index + 1}</td>
                  <td className="px-4 py-2 border">{driver.name}</td>
                  <td className="px-4 py-2 border">{driver.mobileNumber}</td>
                  <td className="px-4 py-2 border">{driver.experience} years</td>
                  <td className="px-4 py-2 border text-center">
                    {isDriverAssigned(driver.mobileNumber) ? (
                      <button className="bg-green-500 text-white px-4 py-1 rounded" disabled>
                        Assigned
                      </button>
                    ) : (
                      <button
                        className="bg-red-500 text-white px-4 py-1 rounded"
                        onClick={() => handleAssignDriver(driver.mobileNumber)}
                      >
                        Set Driver
                      </button>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        {/* Close button at the bottom center */}
        <div className="flex justify-center mt-4">
          <button onClick={onClose} className="bg-gray-300 px-4 py-2 rounded">
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default AssignDriverModal;

import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { storeUserData } from "../../route/Storage";  // Adjust based on your folder structure
import { isAuthenticated } from "../../route/Auth";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLA,  // Use Vendor API base URL
});

function VendorLogin() {
  const initialStateErrors = {
    email: { required: false },
    password: { required: false },
    custom_error: null,
  };

  const [errors, setErrors] = useState(initialStateErrors);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleInput = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let errors = initialStateErrors;
    let hasError = false;

    if (inputs.email === "") {
      errors.email.required = true;
      hasError = true;
    }
    if (inputs.password === "") {
      errors.password.required = true;
      hasError = true;
    }

    if (!hasError) {
      setLoading(true);
      try {
        const response = await api.post('/authentication/adminauth/login', inputs);
        
        const token = response.data.accessToken;
        const decodedToken = jwtDecode(token);

        localStorage.setItem("user", JSON.stringify(decodedToken));
        localStorage.setItem("accessToken", token);

        const accessToken = localStorage.getItem("accessToken");
        const userData = JSON.parse(localStorage.getItem("user"));

        if (isAuthenticated() && accessToken && userData) {
          if (userData.role !== 'ROLE_VENDOR') {
            if (!userData.verifycd && !userData.active) {
              toast.error("User Blocked, Please contact FixRyde customer care!");
            } else {
              toast.error(`You Are Registered as ${userData.role}, Please contact ADMIN to login!`);
            }
          } else {
            // navigate('/vendor/dashboard');  // Redirect to vendor dashboard
            navigate('/vendor/alltrip');  // Redirect to vendor dashboard
          }
        }
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 400 && err.response.status === 401) {
          setErrors({ ...errors, custom_error: "Invalid Credentials." });
        } else {
          toast.error(err.response.data.message);
        }
      } finally {
        setLoading(false);
      }
    }
    setErrors({ ...errors });
  };

  return (
    <Fragment>
      <ToastContainer />
      <div className="min-h-screen flex items-center justify-center bg-fixblack">
        <div className="max-w-md w-full mx-auto px-6 py-4 bg-white rounded-lg shadow-lg">
          <div className="text-center mb-4">
            {/* <img src="/images/vendorlogin.png" alt="Login Image" className="object-cover max-w-xs max-h-fit mx-auto" /> */}
            <img src="/images/Vendor.png" alt="Vendor Login" className="object-cover max-w-xs max-h-fit mx-auto" />
            <h1 className="text-2xl font-semibold text-gray-800">Vendor Login</h1>
            <p className="text-xs text-gray-600">Enter your credentials to access your account</p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="email"
                id="email"
                name="email"
                value={inputs.email}
                onChange={handleInput}
                className={`w-full rounded-lg py-3 px-4 bg-gray-100 focus:outline-none ${errors.email.required ? 'border-2 border-red-500' : ''}`}
                autoComplete="off"
                placeholder="Email"
              />
              {errors.email.required && (
                <span className="block text-xs text-red-500 mt-1">Email is required.</span>
              )}
            </div>

            <div>
              <input
                type="password"
                id="password"
                name="password"
                value={inputs.password}
                onChange={handleInput}
                className={`w-full rounded-lg py-3 px-4 bg-gray-100 focus:outline-none ${errors.password.required ? 'border-2 border-red-500' : ''}`}
                autoComplete="off"
                placeholder="Password"
              />
              {errors.password.required && (
                <span className="block text-xs text-red-500 mt-1">Password is required.</span>
              )}
              {errors.custom_error && (
                <span className="block text-xs text-red-500 mt-1">{errors.custom_error}</span>
              )}
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="bg-fixred text-white font-semibold rounded-lg py-3 px-4 w-full"
                disabled={loading}
              >
                {loading ? 'Logging in...' : 'Login'}
              </button>
            </div>
          </form>
          <div className="mt-4 text-center">
            {/* <a href="#" className="text-blue-500 hover:underline">Forgot Password?</a> */}
            <button onClick={() => {/* handle forgot password */}} className="text-blue-500 hover:underline">Forgot Password?</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default VendorLogin;

// src/pages/sitePages/Home.js

import React, { Fragment, useState } from "react";
import Navbar from "../../components/site/Navbar";
import Main from "./Main";
import SpecialOffer from "../../components/site/SpecialOffer";
import ReviewCard from "../../components/site/ReviewCard";
import Footer from "../../components/site/Footer";
import BlogList from "../../components/site/BlogList";
import { useNavigate } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import HowItWorks from "./HowItWork";

import { useDispatch } from 'react-redux';
import { setTripData } from '../../reducers/tripReducer';

// Import utility functions
import {
  parseLocation,
  calculateDistance,
  checkIfAirport,
  baseKmRequest,
} from '../../utils/tripUtils';
import LoadingRyde from "../../components/site/LoadingRyde";

function Home() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Handle Search Function
  const handleSearch = async (userData) => {
    setLoading(true);

    try {
      // Dispatch the trip data to the Redux store
      dispatch(setTripData(userData));

      // Navigate to the user dashboard
      navigate('/user/dashboard');
    } catch (error) {
      console.error('Error in handleSearch:', error);
      alert('Error processing search. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  const offers = [
    {
      imageSrc: "/images/Offer1.jpg",
      heading: "First Tour Package",
      paragraph: "Enjoy a 10% discount on first tour packages. Use code RYD10.",
      buttonText: "Book Now",
      valid: "13/05/2024",
    },
    {
      imageSrc: "/images/Offer2.jpg",
      heading: "Airport Pickup/Drop Discount",
      paragraph: "Get 15% off on your first airport pickup with code FIX15.",
      buttonText: "Book Now",
      valid: "24/06/2024",
    },
    {
      imageSrc: "/images/Offer3.jpg",
      heading: "Summer Offer Deal",
      paragraph: "Use code SUM5 for 5% off on summer trips.",
      buttonText: "Book Now",
      valid: "18/05/2024",
    },
  ];

  const reviews = [
    {
      name: "John",
      rating: 4,
      review:
        "Great experience using this cab service. The driver arrived promptly and was very courteous throughout the journey. Definitely recommended!",
    },
    {
      name: "Sarath",
      rating: 5,
      review:
        "Excellent service! Booking was easy, the vehicle was clean and comfortable, and the driver was friendly. Will use again for sure.",
    },
    {
      name: "Mubarak",
      rating: 3,
      review:
        "Overall a decent experience. The cab was fine, but the driver seemed a bit inexperienced and took a longer route than necessary. Could be improved.",
    },
  ];

  return (
    <Fragment>
      <Navbar />
     
      <div className="bg-ryde bg-bottom bg-cover bg-no-repeat flex flex-col justify-center lg:p-10 sm:p-4">
        <div className="text-fixwhite py-2 font-sans">
          <div className="max-w-lg mx-auto">
            <div className="text-center">
              <h2 className="text-3xl lg:text-4xl font-bold">
                Welcome to Fixryde
              </h2>
              <div className="flex items-center justify-center space-x-2">
                <span className="text-2xl">
                  <FaMapMarkerAlt className="text-fixred" />
                </span>
                <span className="text-lg font-semibold">
                  Start your journey today
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="m-2 font-semibold">
          <Main onSearch={handleSearch} />
        </div>
        {loading && <div className="text-center text-fixred">Loading...</div>}
      </div>
      <div className="container mx-auto mt-4">
        <h6 className="text-xl font-bold text-fixred text-center">
          Hot Offers
        </h6>
        <h2 className="text-2xl font-bold text-fixblue text-center">
          Check Out Our Latest Offers
        </h2>
        <div className="flex flex-wrap justify-center gap-6 mt-4">
          {offers.map((offer, index) => (
            <SpecialOffer key={index} {...offer} />
          ))}
        </div>
      </div>
      <div className="container mx-auto">
        <HowItWorks />
      </div>
      <div className="container mx-auto">
        <section className="py-4 text-center">
          <h6 className="text-xl font-bold text-fixred">Testimonials</h6>
          <h2 className="text-2xl font-bold text-fixblue">
            What Our Clients Say
          </h2>
          <div className="flex flex-wrap justify-center text-justify">
            {reviews.map((review, index) => (
              <ReviewCard key={index} {...review} />
            ))}
          </div>
        </section>
      </div>
      <div className="container mx-auto">
        <section className="p-2 sm:p-2">
          <h6 className="text-xl font-bold text-fixred text-center">
            Our Blogs
          </h6>
          <h2 className="text-2xl font-bold text-fixblue text-center">
            Latest Blog Posts
          </h2>
          <div className="mt-4">
            <BlogList />
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Home;

import React, { Fragment, useState, useEffect } from "react";
import axios from 'axios';
import TableAdmin from "../../../components/admin/TableAdmin";
import TableEnquiry from "../../../components/admin/TableEnquiry"; // New import for Enquiry Trips
import { FaArrowsLeftRight } from "react-icons/fa6";
import { FaSearch, FaSync } from "react-icons/fa";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BookingDashboardPanel = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [activeTab, setActiveTab] = useState('Accepted');
  const [acceptedBookings, setAcceptedBookings] = useState([]);
  const [cancelledBookings, setCancelledBookings] = useState([]);
  const [enquiryBookings, setEnquiryBookings] = useState([]); 
  const [filteredAcceptedBookings, setFilteredAcceptedBookings] = useState([]);
  const [filteredCancelledBookings, setFilteredCancelledBookings] = useState([]);
  const [filteredEnquiryBookings, setFilteredEnquiryBookings] = useState([]); 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchDateStart, setSearchDateStart] = useState("");
  const [searchDateEnd, setSearchDateEnd] = useState("");
  const [searchBookingId, setSearchBookingId] = useState("");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setSearchDateStart(today);
    setSearchDateEnd(today);

    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    try {
      const confirmedResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URLB}/bookings/confirmed`,
        {
          params: { status: 'CONFIRMED' },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'ngrok-skip-browser-warning': 'true'
          },
        }
      );
      const confirmedData = Array.isArray(confirmedResponse.data) ? confirmedResponse.data : [];
      const confirmedDataWithDateTime = confirmedData.map(booking => {
        const dateTime = `${booking.bookingDate} Time :${booking.bookingTime}`;
        return {
          ...booking,
          dateTime,
        };
      });
      setAcceptedBookings(confirmedDataWithDateTime);
      setFilteredAcceptedBookings(confirmedDataWithDateTime);

      const cancelledResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URLB}/bookings/notconfirmed`,
        {
          params: { status: 'NOT_CONFIRMED' },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'ngrok-skip-browser-warning': 'true'
          },
        }
      );
      const cancelledData = Array.isArray(cancelledResponse.data) ? cancelledResponse.data : [];
      const cancelledDataWithDateTime = cancelledData.map(booking => {
        const dateTime = `${booking.bookingDate} Time :${booking.bookingTime}`;
        return {
          ...booking,
          dateTime,
        };
      });
      setCancelledBookings(cancelledDataWithDateTime);
      setFilteredCancelledBookings(cancelledDataWithDateTime);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  const fetchEnquiryTrips = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URLB}/bookings/tripenquiries`,
        {
          params: {
            fromDate: `${searchDateStart}T00:00:00`,
            toDate: `${searchDateEnd}T23:59:59`
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'ngrok-skip-browser-warning': 'true'
          },
        }
      );
      setEnquiryBookings(response.data);
      setFilteredEnquiryBookings(response.data);
    } catch (error) {
      console.error("Error fetching enquiry trips:", error);
    }
  };

  useEffect(() => {
    // Filter the bookings when date or booking ID search criteria changes
    if (activeTab === 'Accepted' || activeTab === 'accepted') {
      filterBookings(acceptedBookings, 'accepted');
    } else if (activeTab === 'Cancelled' || activeTab === 'cancelled') {
      filterBookings(cancelledBookings, 'cancelled');
    } else if (activeTab === 'EnquiryTrips') {
      filterBookings(enquiryBookings, 'enquiry');
    }
  }, [searchDateStart, searchDateEnd, searchBookingId, activeTab]);

  const searchDateBookingEnquiry = () => {
    if (activeTab === 'EnquiryTrips') {
      fetchEnquiryTrips();
    }
  };

  const filterBookings = (bookings, type) => {
    let filtered = bookings;

    if (searchBookingId) {
      filtered = filtered.filter(booking => {
        const bookId = String(booking.bookId);
        return bookId.toLowerCase().includes(searchBookingId.toLowerCase());
      });
    } else if (searchDateStart && searchDateEnd) {
      const startDate = new Date(searchDateStart);
      const endDate = new Date(searchDateEnd);
      filtered = filtered.filter(booking => {
        const bookingDate = new Date(booking.bookingDate);
        return bookingDate >= startDate && bookingDate <= endDate;
      });
    }

    if (type === 'accepted') {
      setFilteredAcceptedBookings(filtered);
    } else if (type === 'cancelled') {
      setFilteredCancelledBookings(filtered);
    } else if (type === 'enquiry') {
      setFilteredEnquiryBookings(filtered);
    }

    setCurrentPage(1);
  };

  const handleClearFilters = () => {
    const today = new Date().toISOString().split('T')[0];
    setSearchDateStart(today);
    setSearchDateEnd(today);
    setSearchBookingId("");
    if (activeTab === 'Accepted' || activeTab === 'accepted') {
      setFilteredAcceptedBookings(acceptedBookings);
    } else if (activeTab === 'Cancelled' || activeTab === 'cancelled') {
      setFilteredCancelledBookings(cancelledBookings);
    } else if (activeTab === 'EnquiryTrips') {
      setFilteredEnquiryBookings(enquiryBookings);
    }
    setCurrentPage(1);
  };

  const tableFields = [
    { key: "bookId", label: "Book ID" },
    { key: "tripType", label: "Trip Type" },
    { key: "dateTime", label: "Date and Time" },
    { key: "pickupLocation", label: "Source" },
    { key: "dropLocation", label: "Destination" },
    { key: "vehicleType", label: "Vehicle Type" },
    { key: "paidAmount", label: "Amount" },
    { key: "paymentType", label: "Mode of Payment" },
    { key: "vendorUserid", label: "Vendor Name" },
    { key: "driverId", label: "Driver Number" },
    { key: "tripStatus", label: "Status" },
  ];

  const tableFieldsEnquiry = [
    { key: "enquiryId", label: "Enquiry ID" },
    { key: "tripType", label: "Trip Type" },
    { key: "bookingDate", label: "Booking Date" },
    { key: "bookingTime", label: "Booking Time" },
    { key: "sourceAddress", label: "Source" },
    { key: "destinationAddress", label: "Destination" },
    { key: "distanceKm", label: "Distance" },
    { key: "userMobileNumber", label: "Customer Mobile" },
    { key: "createdAt", label: "Created @" },
  ];

  const indexOfLastBooking = currentPage * itemsPerPage;
  const indexOfFirstBooking = indexOfLastBooking - itemsPerPage;
  const currentBookings = activeTab === 'Accepted'
    ? filteredAcceptedBookings.slice(indexOfFirstBooking, indexOfLastBooking)
    : activeTab === 'Cancelled'
      ? filteredCancelledBookings.slice(indexOfFirstBooking, indexOfLastBooking)
      : filteredEnquiryBookings.slice(indexOfFirstBooking, indexOfLastBooking);

  const renderPagination = () => (
    <div className="flex justify-center mt-4">
      <button
        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
        disabled={currentPage === 1}
        className="px-4 py-2 mx-2 border rounded-md bg-gray-200"
      >
        Previous
      </button>
      <span className="mx-4">Page {currentPage} of {Math.ceil((activeTab === 'Accepted' ? filteredAcceptedBookings.length : activeTab === 'Cancelled' ? filteredCancelledBookings.length : filteredEnquiryBookings.length) / itemsPerPage)}</span>
      <button
        onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil((activeTab === 'Accepted' ? filteredAcceptedBookings.length : activeTab === 'Cancelled' ? filteredCancelledBookings.length : filteredEnquiryBookings.length) / itemsPerPage)))}
        disabled={currentPage === Math.ceil((activeTab === 'Accepted' ? filteredAcceptedBookings.length : activeTab === 'Cancelled' ? filteredCancelledBookings.length : filteredEnquiryBookings.length) / itemsPerPage)}
        className="px-4 py-2 mx-2 border rounded-md bg-gray-200"
      >
        Next
      </button>
    </div>
  );

  const renderTabContent = (tab) => (
    <div className="container mx-auto" key={tab.tab}>
      <div className="bg-fixwhite px-4 py-2 shadow-lg text-[0.8rem]">
        <div className="flex flex-col sm:flex-row items-center justify-evenly">
          <h1 className="font-bold text-lg mb-2 sm:mb-0">{`${tab.label} (${tab.count})`}</h1>
          <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
            <div className="flex items-center space-x-2">
              <input
                type="date"
                className="p-2 border border-gray-300 rounded-md"
                value={searchDateStart}
                onChange={(e) => setSearchDateStart(e.target.value)}
              />
              <span><FaArrowsLeftRight className="w-4 h-4" /></span>
              <input
                type="date"
                className="p-2 border border-gray-300 rounded-md"
                value={searchDateEnd}
                onChange={(e) => setSearchDateEnd(e.target.value)}
              />
            </div>
            <div className="flex space-x-4 mt-2 sm:mt-0 cursor-pointer">
              {tab.tab === "EnquiryTrips" && (
                <button
                  className="p-2 border border-gray-300 rounded-md flex items-center justify-center"
                  onClick={searchDateBookingEnquiry}
                >
                  <FaSearch className="w-4 h-4" />
                </button>
              )}
              <button
                className="p-2 border border-gray-300 rounded-md flex items-center justify-center"
                onClick={handleClearFilters}
              >
                <FaSync className="w-4 h-4" />
              </button>
            </div>
            <input
              type="text"
              placeholder="Search by Booking ID"
              className="p-2 border border-gray-300 rounded-md mt-2 sm:mt-0"
              value={searchBookingId}
              onChange={(e) => setSearchBookingId(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-4 overflow-x-auto">
        {tab.tab === "EnquiryTrips" ? (
          <TableEnquiry data={currentBookings} fields={tableFieldsEnquiry} />
        ) : (
          <TableAdmin data={currentBookings} fields={tableFields} />
        )}
        {renderPagination()}
      </div>
    </div>
  );

  const tabData = [
    { tab: 'Accepted', label: 'Accepted Trips', count: filteredAcceptedBookings.length },
    { tab: 'Cancelled', label: 'Cancelled Trips', count: filteredCancelledBookings.length },
    { tab: 'EnquiryTrips', label: 'Enquiry Trips', count: filteredEnquiryBookings.length }
  ];

  return (
    <Fragment>
      <ToastContainer />
      <div className="flex">
        <AdminSidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
        <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
          <AdminNavbar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
          <div className="container mx-auto mt-2">
            <div className="bg-fixwhite p-2 shadow-lg">
              <div className="flex space-x-4 border-b-2 border-gray-200">
                {tabData.map((tab) => (
                  <button
                    key={tab.tab}
                    className={`py-2 px-4 ${activeTab === tab.tab ? 'border-b-2 border-fixgold text-fixgold uppercase font-bold' : 'text-gray-600 font-medium'}`}
                    onClick={() => handleTabClick(tab.tab)}
                  >
                    {`${tab.label} (${tab.count})`}
                  </button>
                ))}
              </div>
            </div>
          </div>
          {tabData.map((tab) => (
            activeTab === tab.tab && renderTabContent(tab)
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default BookingDashboardPanel;

import React, { Fragment, useState } from "react";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import DashCard from "../../../components/admin/DashCard";

function CovidSafety() {
  const [sidebarToggle, setSidebarToggle] = useState(false);

  const cardData = [
    { title: "Fully Vaccinated Drivers", data: "100" },
    { title: "Vaccination Pending", data: "22" },
    { title: "Partially Vaccinated", data: "00" },
  ];

  const driverData = [
    { name: "John Doe", mobile: "123-456-7890", status: "Vaccinated" },
    { name: "Jane Smith", mobile: "987-654-3210", status: "Not Vaccinated" },
    { name: "Michael Johnson", mobile: "555-666-7777", status: "Vaccinated" },
    { name: "Emily Davis", mobile: "111-222-3333", status: "Not Vaccinated" },
  ];

  return (
    <Fragment>
      <div className="flex">
      <AdminSidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
      <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
        <AdminNavbar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
        <div className="bg-zinc-200 min-h-screen p-4">
        <div className="bg-white shadow-lg p-4 rounded-lg mb-6">
            <div className="flex justify-between items-center">
              <h1 className="text-xl font-semibold text-gray-600">Covid Safety</h1>
              <span>{new Date().toLocaleDateString()}</span>
            </div>
          </div>
          <div className="container mx-auto">
            <div className="flex flex-wrap mt-5 justify-center items-center">
              {cardData.map((card, index) => (
                <DashCard key={index} title={card.title} data={card.data} />
              ))}
            </div>
            <hr className="my-4 border-b-2 border-black" />
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="shadow-lg bg-white p-4 rounded-lg">
                <h1 className="text-xl font-semibold text-center mb-4">Mark Drivers as Vaccinated</h1>
                <div className="space-y-4">
                  <label className="block text-md font-medium mb-2">
                    Selected Driver<span className="text-red-500 font-extrabold"> *</span>
                  </label>
                  <select
                    name="covid"
                    className="w-full p-2 border-gray-300 border-2 hover:border-blue-800 focus:outline-none focus:shadow-outline rounded-sm"
                  >
                    <option value="Driver">Driver</option>
                    <option value="Partner">Partner</option>
                  </select>
                  <label className="block text-md font-medium mb-2">
                    Upload Vaccinated Certificate<span className="text-red-500 font-extrabold"> *</span>
                  </label>
                  <input
                    type="file"
                    className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:bg-blue-100 file:text-fixblue hover:file:bg-blue-200"
                  />
                  <span className="text-xs text-gray-500">Please upload PDF file only</span>
                  <div className="grid grid-cols-6 gap-2 pb-6">
                    <input
                      type="submit"
                      value="Save"
                      className="col-span-6 md:col-span-3 bg-green-500 px-5 py-3 rounded-md text-white font-bold"
                    />
                    <input
                      type="reset"
                      value="Reset"
                      className="col-span-6 md:col-span-3 bg-red-500 px-5 py-3 rounded-md text-white font-bold"
                    />
                  </div>
                </div>
              </div>
              <div className="shadow-lg bg-white p-4 rounded-lg">
                <h1 className="text-xl font-semibold text-center mb-4">List Of Drivers</h1>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-400">
                    <thead className="bg-fixsilver">
                      <tr>
                        <th className="px-6 py-3 text-left text-sm font-medium text-gray-800 uppercase tracking-wider">
                          Driver Name
                        </th>
                        <th className="px-6 py-3 text-left text-sm font-medium text-gray-800 uppercase tracking-wider">
                          Mobile Number
                        </th>
                        <th className="px-6 py-3 text-left text-sm font-medium text-gray-800 uppercase tracking-wider">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {driverData.map((driver, index) => (
                        <tr
                          key={index}
                          className={`${index % 2 === 0 ? "bg-gray-100" : ""} hover:bg-gray-200`}
                        >
                          <td className="px-6 py-4 whitespace-nowrap">{driver.name}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{driver.mobile}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{driver.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Fragment>
    
  );
}

export default CovidSafety;

import React, { Fragment, useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useGoogleMaps } from "../../pages/sitePages/GoogleMapsProvider";
import AdminSidebar from "../../components/admin/AdminSidebar";
import AdminNavbar from "../../components/admin/AdminNavbar";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    "ngrok-skip-browser-warning": "true",
    "Content-Type": "application/json",
  },
});

const EagleViewPage = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [driverData, setDriverData] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState("");
  const isLoaded = useGoogleMaps();

  const mapContainerStyle = { height: "80vh", width: "100%" };
  const defaultCenter = { lat: 13.0827, lng: 80.2707 };

  useEffect(() => { fetchDrivers(); }, []);

  const fetchDrivers = async () => {
    try {
      const response = await api.get("/drivervehicle/drivers/eagleview");
      if (response.status === 200) setDriverData(response.data);
      else toast.error("Failed to fetch drivers.");
    } catch (error) {
      console.error("Error fetching driver data:", error);
      toast.error("Failed to fetch driver data.");
    }
  };

  const handleDriverSearch = (driverNumber) => {
    const driver = driverData.find(d => d.mobileNumber === driverNumber);
    setSelectedDriver(driver || null);
  };

  const handleVendorSearch = (vendorName) => {
    setSelectedVendor(vendorName);
    setSelectedDriver(null);
  };

  const handleClear = () => {
    setSelectedDriver(null);
    setSelectedVendor("");
  };

  return (
    <Fragment>
      <ToastContainer />
      <div className="flex">
        <AdminSidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
        <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
          <AdminNavbar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
          <div className="p-4">
            <div className="mb-4">
              <div className="flex space-x-4">
                <input type="text" placeholder="Find with driver number" className="border border-gray-300 rounded-md px-4 py-2" onChange={(e) => handleDriverSearch(e.target.value)} />
                <input type="text" placeholder="Find with vendor name" className="border border-gray-300 rounded-md px-4 py-2" onChange={(e) => handleVendorSearch(e.target.value)} />
                <button className="bg-fixblue text-white px-4 py-2 rounded-md" onClick={handleClear}>Clear</button>
              </div>
            </div>
            {isLoaded ? (
              <GoogleMap mapContainerStyle={mapContainerStyle} zoom={10} center={defaultCenter}>
                {driverData.map((driver, index) => {
                  if ((selectedDriver && driver.mobileNumber !== selectedDriver.mobileNumber) || (selectedVendor && driver.vendorId !== selectedVendor)) return null;
                  return (
                    <Marker key={index} position={{ lat: driver.driverLocationLatitude, lng: driver.driverLocationLongitude }} label={driver.driverName} />
                  );
                })}
              </GoogleMap>
            ) : (
              <div>Loading Map...</div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EagleViewPage;

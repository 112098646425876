import React from 'react';
import EmployeeNavbar from '../../components/site/EmployeeNavbar';
import { Link } from 'react-router-dom';
import { RiFileCopy2Line, RiWhatsappFill } from 'react-icons/ri';
/** Helper Components **/

// Component for items with a single title and content
const ItemWithTitle = ({ title, content }) => (
    <>
        <h2 className='text-sm font-bold'>{title}</h2>
        <p className='text-gray-700'>{content}</p>
    </>
);

// Component for items with a title and multiple lines of content
const ItemWithMultipleLines = ({ title, lines }) => (
    <>
        <h2 className='text-sm font-bold'>{title}</h2>
        {lines.map((line, index) => (
            <p key={index} className='text-gray-700'>{line}</p>
        ))}
    </>
);

// Component for items with a single title and content
const TripButton = ({ name }) => (
    <>
        <button key={name} className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded">{name}</button>
    </>
);

function TripAdd() {
    return (
        <div className='bg-gray-200  min-h-screen'>
            <EmployeeNavbar />
            <div className='container mx-auto my-1 px-2'>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                    {/* First Column */}
                    <div className="p-4 bg-white rounded-sm shadow-md">
                        <textarea
                            className="w-full  border border-gray-300 rounded-md px-3 py-2 mb-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            rows="2"
                            placeholder="Enter your Review"
                        ></textarea>
                        <div className="flex flex-wrap gap-2 mb-3">
                            <TripButton name='WellDrive' />
                            <TripButton name='Most ans Grant' />
                            <TripButton name='Safety drive' />
                            <TripButton name='Ontime Reach' />
                            <TripButton name='Good Services' />

                        </div>
                    </div>

                    {/* Second Column */}
                    <div className="p-4 bg-white rounded-sm shadow-md">
                        <div className="flex items-center gap-2">
                            <input
                                type="text"
                                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Value..."
                            />
                            <input
                                type="text"
                                className="w-full border border-gray-300 rounded-md px-3 py-2 mb-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Value..."
                            />
                        </div>
                        <div className="flex items-center mb-2">
                            <input
                                type="checkbox"
                                id="promoCheckbox"
                                className="mr-2"
                            />
                            <label htmlFor="promoCheckbox">PromoCode</label>
                        </div>
                        <input
                            type="text"
                            className="w-1/3 border border-gray-300 rounded-md px-3 py-2 mx-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="code"
                        />
                        <TripButton name='Apply' />
                        <input
                            type="text"
                            className="w-1/3 border border-gray-300 rounded-md px-3 py-2 mx-2 mb-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="0"
                        />
                        <div className="flex items-center mb-2">
                            <input
                                type="checkbox"
                                id="promoValueCheckbox"
                                className="mr-2"
                            />
                            <label htmlFor="promoValueCheckbox">Promo Value</label>
                        </div>
                        <input
                            type="text"
                            className="w-1/3 border border-gray-300 rounded-md px-3 py-2 mx-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="value"
                        />
                        <TripButton name='Apply' />
                    </div>

                    {/* Third Column */}
                    <div className="p-4 bg-white rounded-sm shadow-md">
                        <h2 className="text-lg font-bold mb-4">Details</h2>
                        <textarea
                            className="w-full  border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            rows="4"
                            placeholder="Booking mode"
                        ></textarea>
                    </div>
                </div>
            </div>
            {/* this is booking details */}
            <div className='container mx-auto p-2'>
                <div className='bg-white shadow-lg rounded-t-md px-4 py-5'>
                    <div className='grid grid-cols-2 md:grid-cols-6 gap-2'>
                        {/** Coupon Code */}
                        <div className='border border-gray-300 p-4 rounded-md'>
                            <ItemWithTitle title="Coupon Code" content="FIX123" />
                        </div>
                        {/** Coupon Value */}
                        <div className='border border-gray-300 p-4 rounded-md'>
                            <ItemWithTitle title="Coupon Value" content="100" />
                        </div>
                        {/** Vehicle Details */}
                        <div className='border border-gray-300 p-4 rounded-md'>
                            <ItemWithMultipleLines title="Vehicle Details" lines={["Toyota white Etios", "ABC123"]} />
                        </div>
                        {/** Driver Details */}
                        <div className='border border-gray-300 p-4 rounded-md'>
                            <ItemWithMultipleLines title="Driver Details" lines={["Sundharam", "+91 1234567890"]} />
                        </div>
                        {/** Action Icons */}
                        <div className='border border-gray-300 p-4 rounded-md flex items-center justify-center'>
                            <div className='flex items-center gap-2 cursor-pointer'>
                                <RiFileCopy2Line className='text-orange-500 text-2xl' />
                                <RiWhatsappFill className='text-green-500 text-2xl' />
                            </div>
                        </div>
                        {/** Source URL */}
                        <div className='border border-gray-300 p-4 rounded-md'>
                            <h2 className='text-sm font-bold mb-2'>Source URL</h2>
                            <p className='text-gray-700'>
                                <Link to={''} className='text-blue-500 hover:underline text-xl'>Fixryde</Link>
                            </p>
                        </div>
                    </div>
                  
                    {/* Exra fare design */}
                    <div className='container mx-auto mt-4'>
                        <div className='flex justify-center items-center'>
                            <div className='grid grid-cols-2 sm:grid-cols-3 gap-2'>
                                <div className='w-full sm:max-w-sm border border-gray-300 shadow-sm rounded-md p-4'>
                                    <ItemWithTitle title="Toll Fare" content="0.00" />
                                </div>
                                <div className='w-full sm:max-w-sm border border-gray-300 shadow-sm rounded-md p-4'>
                                    <ItemWithTitle title="State Tax Fare" content="0.00" />
                                </div>
                                <div className='w-full sm:max-w-sm border border-gray-300 shadow-sm rounded-md p-4'>
                                    <ItemWithTitle title="Hill Charge" content="0.00" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-end items-end'>
                            <TripButton name='Get Auto Fit Fare' />
                        </div>
                    <hr className="my-2  border-b-[0.5px] border-fixblack" />
                    {/* this is all button */}
                    <div className="flex flex-wrap gap-2 justify-evenly">
                            <TripButton name='Driect Assign' />
                            <TripButton name='Driver Assign' />
                            <TripButton name='Operator Assign' />
                            <TripButton name='Update' />
                            <TripButton name='Cancel' />
                        </div>
                </div>

            </div>
        </div>
    );
}
export default TripAdd;


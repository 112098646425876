import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isAuthenticated } from "../../route/Auth"; // Adjust based on your folder structure

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLA,  // Use Employee API base URL
});

function EmployeeLogin() {
  const initialStateErrors = {
    email: { required: false },
    password: { required: false },
    custom_error: null,
  };

  const [errors, setErrors] = useState(initialStateErrors);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleInput = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let errors = initialStateErrors;
    let hasError = false;

    if (inputs.email === "") {
      errors.email.required = true;
      hasError = true;
    }
    if (inputs.password === "") {
      errors.password.required = true;
      hasError = true;
    }

    if (!hasError) {
      setLoading(true);
      try {
        const response = await api.post('/authentication/adminauth/login', inputs);

        const token = response.data.accessToken;
        const decodedToken = jwtDecode(token);

        localStorage.setItem("user", JSON.stringify(decodedToken));
        localStorage.setItem("accessToken", token);

        const accessToken = localStorage.getItem("accessToken");
        const userData = JSON.parse(localStorage.getItem("user"));

        if (isAuthenticated() && accessToken && userData) {
          if (userData.role !== 'ROLE_EMPLOYEE') {
            toast.error(`You are registered as ${userData.role}, please contact support for further assistance.`);
          } else {
            navigate('/employee/dashboard');  // Redirect to the employee dashboard
          }
        }
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 400 && err.response.status === 401) {
          setErrors({ ...errors, custom_error: "Invalid Credentials." });
        } else {
          toast.error(err.response.data.message);
        }
      } finally {
        setLoading(false);
      }
    }
    setErrors({ ...errors });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-fixblack">
      <ToastContainer />
      <div className="max-w-sm w-full bg-white py-4 px-6 rounded-lg shadow-lg">
        <div className="text-center">
          <img
            className="object-cover max-w-xs max-h-44 mx-auto"
            src="/images/Employee.png"
            alt="People"
          />
        </div>
        <form onSubmit={handleSubmit} className="mt-2 space-y-4">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={inputs.email}
              onChange={handleInput}
              className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500 ${
                errors.email.required ? "border-2 border-red-500" : ""
              }`}
              placeholder="Enter your email"
              required
            />
            {errors.email.required && (
              <span className="text-red-500 text-sm">Email is required.</span>
            )}
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={inputs.password}
              onChange={handleInput}
              className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500 ${
                errors.password.required ? "border-2 border-red-500" : ""
              }`}
              placeholder="Enter your password"
              required
            />
            {errors.password.required && (
              <span className="text-red-500 text-sm">Password is required.</span>
            )}
            {errors.custom_error && (
              <span className="text-red-500 text-sm">{errors.custom_error}</span>
            )}
          </div>
          <div>
            <button
              type="submit"
              className="w-full py-3 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              disabled={loading}
            >
              {loading ? "Logging in..." : "Login"}
            </button>
          </div>
          <div className="text-center text-sm mt-4">
            <button
              type="button"
              onClick={() => {
                // Handle forgot password functionality here
              }}
              className="text-blue-500 hover:text-blue-700 focus:outline-none"
            >
              Forgot Password?
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EmployeeLogin;
import React from 'react';

const DriverProfile = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* Header */}
      <header className="bg-fixblue p-4 text-white flex justify-between items-center">
        {/* <button className="text-white">←</button> */}
        <h1 className="text-lg lg:text-2xl font-semibold">DRIVER PROFILE</h1>
        <div></div>
      </header>

      {/* Profile Section */}
      <div className="flex flex-col items-center bg-white p-6">
        <div className="rounded-full border-4 border-fixblue w-24 h-24 flex items-center justify-center">
          {/* Profile Image Placeholder */}
          <div className="bg-gray-200 rounded-full w-full h-full"></div>
        </div>
        <p className="mt-4 font-bold text-lg lg:text-xl italic">
          WALLET BALANCE: ₹ 0
        </p>
      </div>

      {/* Details Section */}
      <div className="bg-white shadow-md rounded-lg mx-4 mt-4 p-4 lg:max-w-4xl lg:mx-auto">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex items-center justify-between bg-gray-100 p-2 rounded-md">
            <span>Shivanand Hiregouder</span>
            <div className="flex items-center">
              <span className="text-blue-500">★</span>
              <span className="ml-2">5</span>
            </div>
          </div>
          <div className="bg-gray-100 p-2 rounded-md">
            <span>Firmware Ver</span>
            <span className="float-right">:3.23.3</span>
          </div>
          <div className="bg-gray-100 p-2 rounded-md">
            <span>Driver ID</span>
            <span className="float-right">:7499514881</span>
          </div>
          <div className="bg-gray-100 p-2 rounded-md">
            <span>IMEI Number</span>
            <span className="float-right">:edd19123855add0d</span>
          </div>
          <div className="bg-gray-100 p-2 rounded-md">
            <span>Vendor Name</span>
            <span className="float-right">:Lazer Mishel Deva</span>
          </div>
          <div className="bg-gray-100 p-2 rounded-md">
            <span>Driver Score</span>
            <span className="float-right">:NA</span>
          </div>
          <div className="bg-gray-100 p-2 rounded-md">
            <span>Vehicle Score</span>
            <span className="float-right">:NA</span>
          </div>
          <div className="bg-gray-100 p-2 rounded-md">
            <span>Change Language</span>
            <span className="float-right">:English</span>
          </div>
          <div className="bg-gray-100 p-2 rounded-md">
            <span>Set Location</span>
            <span className="float-right">:Vascodagama Goa</span>
          </div>
          <div className="bg-gray-100 p-2 rounded-md flex flex-col">
            <span>Documents</span>
            <span className="text-blue-500 underline cursor-pointer">
              Driver Document
            </span>
            <span className="text-blue-500 underline cursor-pointer">
              Audit Document
            </span>
          </div>
        </div>
      </div>

      {/* Logout Button */}
      <div className="flex items-center justify-center mt-4 p-4">
        <button className="w-full max-w-sm bg-fixblue text-white py-2 rounded-lg hover:bg-purple-800">
          Logout
        </button>
      </div>
    </div>
  );
};

export default DriverProfile;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DirectAssignDetails = ({ show, onClose, initialState }) => {
    const [vendorData, setVendorData] = useState([]);
    const [filteredVendorData, setFilteredVendorData] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);

    // State for filters
    const [searchVendorId, setSearchVendorId] = useState("");
    const [searchVendorName, setSearchVendorName] = useState("");
    const [searchVendorPhoneNumber, setSearchVendorPhoneNumber] = useState("");
    const [searchServiceArea, setSearchServiceArea] = useState("");
    const [searchTripType, setSearchTripType] = useState("");

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items per page

    // Fetch Vendor Data on Component Load
    useEffect(() => {
        if (show) {
            fetchVendorData();
        }
    }, [show]);

    const fetchVendorData = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URLE}/drivervehicle/vendors/directassignlist`,
                {
                    vehicleCategory: initialState.vehicleType || '',
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'ngrok-skip-browser-warning': 'true',
                        'Content-Type': 'application/json'
                    }
                }
            );
            setVendorData(response.data);
            setFilteredVendorData(response.data);
            setSearchTripType(initialState.tripType);
        } catch (error) {
            console.error("Error fetching vendor data:", error);
        }
    };

    const handleAssignClick = async () => {
        if (!selectedVendor) {
            toast.warn("Please select a vendor to assign.");
            return;
        }

        // Create the request payload
        const requestPayload = {
            bookingId: initialState.bookId || '',
            vendorId: selectedVendor.vendorId,
            vehicleCategory: initialState.vehicleType || '',
            updateBy: JSON.parse(localStorage.getItem('user'))?.sub || null,
        };

        // Log the payload and headers for debugging
        console.log("Request Payload:", requestPayload);
        console.log("Authorization Header:", `Bearer ${localStorage.getItem('accessToken')}`);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URLB}/bookings/assign/directassignbooking`,
                requestPayload,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'ngrok-skip-browser-warning': 'true',
                        'Content-Type': 'application/json'
                    }
                }
            );
            toast.success("Vendor assigned successfully!");
            onClose({ selectedVendor, bookId: initialState.bookId });
        } catch (error) {
            console.error("Error assigning vendor:", error.response.data);
            // toast.error("Failed to assign vendor. Please try again.");
            toast.error(error.response.data);
        }
    };



    // Filter data based on search inputs
    const filterVendorData = () => {
        let filtered = vendorData;

        // Filter based on Vendor ID
        if (searchVendorId) {
            filtered = filtered.filter(vendor =>
                vendor.vendorId.toLowerCase().includes(searchVendorId.toLowerCase())
            );
        }

        // Filter based on Vendor Name
        if (searchVendorName) {
            filtered = filtered.filter(vendor =>
                vendor.vendorName.toLowerCase().includes(searchVendorName.toLowerCase())
            );
        }

        // Filter based on Phone Number
        if (searchVendorPhoneNumber) {
            filtered = filtered.filter(vendor =>
                vendor.phoneNumber.includes(searchVendorPhoneNumber)
            );
        }

        // Filter based on Service Area
        if (searchServiceArea) {
            filtered = filtered.filter(vendor =>
                vendor.serviceArea.toLowerCase().includes(searchServiceArea.toLowerCase())
            );
        }

        // Filter based on Trip Type
        if (searchTripType) {
            filtered = filtered.filter(vendor =>
                vendor.tripType.toLowerCase().includes(searchTripType.toLowerCase())
            );
        }

        setFilteredVendorData(filtered);
        setCurrentPage(1); // Reset to the first page when filtering
    };

    // Automatically filter data when any filter input changes
    useEffect(() => {
        filterVendorData();
    }, [searchVendorId, searchVendorName, searchVendorPhoneNumber, searchServiceArea, searchTripType]);

    // Reset filters and show all data
    const handleResetFilters = () => {
        setSearchVendorId("");
        setSearchVendorName("");
        setSearchVendorPhoneNumber("");
        setSearchServiceArea("");
        setSearchTripType("");
        setFilteredVendorData(vendorData); // Show all records
        setCurrentPage(1); // Reset to the first page
    };

    // Pagination logic
    const paginatedData = filteredVendorData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredVendorData.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    if (!show) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white w-full max-w-4xl rounded-lg shadow-lg p-6 relative">
                <button
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
                    onClick={() => onClose({ selectedVendor, bookId: initialState.bookId })}
                >
                    &#x2715;
                </button>
                <h2 className="text-2xl font-semibold mb-6">Direct Assign Vendor</h2>

                <div className="mb-4 flex flex-col md:flex-row gap-2">
                    <input
                        type="text"
                        placeholder="Search Vendor ID"
                        value={searchVendorId}
                        onChange={(e) => setSearchVendorId(e.target.value)}
                        className="p-2 border border-gray-300 rounded-md  md:w-1/5"
                    />
                    <input
                        type="text"
                        placeholder="Search Vendor Name"
                        value={searchVendorName}
                        onChange={(e) => setSearchVendorName(e.target.value)}
                        className="p-2 border border-gray-300 rounded-md md:w-1/5"
                    />
                    <input
                        type="text"
                        placeholder="Search Vendor Phone Number"
                        value={searchVendorPhoneNumber}
                        onChange={(e) => setSearchVendorPhoneNumber(e.target.value)}
                        className="p-2 border border-gray-300 rounded-md md:w-1/5"
                    />
                    <input
                        type="text"
                        placeholder="Search Service Area"
                        value={searchServiceArea}
                        onChange={(e) => setSearchServiceArea(e.target.value)}
                        className="p-2 border border-gray-300 rounded-md md:w-1/5"
                    />
                    <input
                        type="text"
                        placeholder="Search Trip Type"
                        value={searchTripType}
                        onChange={(e) => setSearchTripType(e.target.value)}
                        className="p-2 border border-gray-300 rounded-md md:w-1/5"
                    />

                    <div className="mb-4 flex gap-4">
                        <button
                            onClick={handleResetFilters}
                            className="bg-blue-500 text-white p-2 rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300 transition duration-200"
                        >
                            Reset
                        </button>
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr className="w-full bg-gray-100 border-b">
                                <th className="py-3 px-4 text-left">Vendor ID</th>
                                <th className="py-3 px-4 text-left">Vendor Name</th>
                                <th className="py-3 px-4 text-left">Phone Number</th>
                                <th className="py-3 px-4 text-left">Service Area</th>
                                <th className="py-3 px-4 text-left">Trip Type</th>
                            </tr>
                        </thead>
                        {/* <tbody>
                            {paginatedData.map((vendor, index) => (
                                <tr
                                    key={index}
                                    className={`cursor-pointer ${selectedVendor?.vendorId === vendor.vendorId ? "bg-yellow-100" : ""
                                        } hover:bg-gray-50 transition`}
                                    onClick={() => setSelectedVendor(vendor)}
                                >
                                    <td className="py-2 px-4 border-b">{vendor.vendorId}</td>
                                    <td className="py-2 px-4 border-b">{vendor.vendorName}</td>
                                    <td className="py-2 px-4 border-b">{vendor.phoneNumber}</td>
                                    <td className="py-2 px-4 border-b">{vendor.serviceArea}</td>
                                    <td className="py-2 px-4 border-b">{vendor.tripType}</td>
                                </tr>
                            ))}
                        </tbody> */}
                        <tbody>
                            {paginatedData.map((vendor, index) => {
                                const isSelected = selectedVendor && (
                                    selectedVendor.vendorId === vendor.vendorId &&
                                    selectedVendor.vendorName === vendor.vendorName &&
                                    selectedVendor.phoneNumber === vendor.phoneNumber &&
                                    selectedVendor.serviceArea === vendor.serviceArea &&
                                    selectedVendor.tripType === vendor.tripType
                                );

                                return (
                                    <tr
                                        key={index}
                                        className={`cursor-pointer ${isSelected ? "bg-yellow-100" : ""} hover:bg-gray-50 transition`}
                                        onClick={() => setSelectedVendor(vendor)}
                                    >
                                        <td className="py-2 px-4 border-b">{vendor.vendorId}</td>
                                        <td className="py-2 px-4 border-b">{vendor.vendorName}</td>
                                        <td className="py-2 px-4 border-b">{vendor.phoneNumber}</td>
                                        <td className="py-2 px-4 border-b">{vendor.serviceArea}</td>
                                        <td className="py-2 px-4 border-b">{vendor.tripType}</td>
                                    </tr>
                                );
                            })}
                        </tbody>

                    </table>
                </div>

                {/* Pagination Controls */}
                <div className="mt-4 flex justify-center">
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="px-4 py-2 mx-2 border rounded-md bg-gray-200"
                    >
                        Previous
                    </button>
                    <span className="mx-4">Page {currentPage} of {totalPages}</span>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="px-4 py-2 mx-2 border rounded-md bg-gray-200"
                    >
                        Next
                    </button>
                </div>

                <div className="mt-6 flex justify-end gap-4">
                    <button
                        onClick={() => onClose({ selectedVendor, bookId: initialState.bookId })}
                        className="bg-gray-500 text-white p-2 rounded-md hover:bg-gray-600"
                    >
                        Close
                    </button>
                    <button
                        onClick={handleAssignClick}
                        className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600"
                    >
                        Assign
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DirectAssignDetails;

import React from 'react'; 

const LoadingRyde = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div 
        className="w-16 h-16 border-4 rounded-full animate-spin 
        border-t-transparent border-r-transparent border-l-transparent 
        border-b-red-500 border-t-blue-500 border-solid relative"
        role="status"
        aria-label="Loading"
      > 
        {/* Fixed black circle in the center */}
        <div className="absolute inset-0 m-auto w-10 h-10 rounded-full bg-gradient-to-r from-fixblue to-fixred"></div>
      </div>
    </div>
  );
};

export default LoadingRyde;

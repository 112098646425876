import React from 'react';

function B2BCard({ imageSrc, heading }) {
    return (
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 px-2 font-sans">
        <div className="flex flex-col items-center shadow-lg border-2 my-4 p-4">
            <img className="w-16 h-16 object-cover" src={imageSrc} alt="Card" />
            <div className="text-center mt-2 font-semibold">{heading}</div>
        </div>
    </div>
    );
}

export default B2BCard;

import React, { Fragment, useState, useEffect } from "react";
import axios from 'axios';
import Footer from "../../components/site/Footer";
import Navbar from "../../components/site/Navbar";
import { useNavigate, useLocation } from "react-router-dom";

const BookingHistory = () => {
  const [bookings, setBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [filter, setFilter] = useState("all");

  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("user"));
  const initialMobileNumber = userData?.sub || "";
  const initialUserId = userData?.id || "";

  const [mobileNumber] = useState(initialMobileNumber);
  const [businessType] = useState("B2C");
  const [userId] = useState(initialUserId);

  useEffect(() => {
    fetchBookings(userId, businessType);
  }, [userId, businessType]);

  const fetchBookings = async (userId, businessType) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URLB}/bookings/customerbookings`,
        {
          params: { userId, businessType },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'ngrok-skip-browser-warning': 'true'
          },
        }
      );
      let bookingsData = Array.isArray(response.data) ? response.data : [response.data];
      bookingsData = bookingsData.filter(booking => ["CANCELLED", "CONFIRMED", "COMPLETED"].includes(booking.booking.status));
      setBookings(sortBookings(bookingsData));
      setFilteredBookings(sortBookings(bookingsData));

      console.log("bookingsDataHistory:", bookingsData);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  const sortBookings = (bookingsArray) => {
    return bookingsArray.sort((a, b) => b.booking.bookId - a.booking.bookId);
  };

  const handleFilterChange = (e) => {
    const filterValue = e.target.value.toLowerCase();
    setFilter(filterValue);

    let filtered = [];

    if (filterValue === "completed") {
      filtered = bookings.filter(booking => booking.booking.status === "COMPLETED");
    } else if (filterValue === "cancelled") {
      filtered = bookings.filter(booking => booking.booking.status === "CANCELLED");
    } else if (filterValue === "upcoming") {
      filtered = bookings.filter(booking => booking.booking.status === "CONFIRMED");
    } else {
      filtered = bookings;
    }
    setFilteredBookings(sortBookings(filtered));
  };

  const mapBookingData = (bookingData) => {
    const booking = bookingData.booking;
    const tripDetails = bookingData.tripDetails;

    return {
      bookId: booking.bookId,
      pickupLocation: booking.pickupLocation,
      dropLocation: booking.dropLocation,
      bookingDate: booking.bookingDate,
      bookingTime: booking.bookingTime,
      tripType: booking.tripType,
      vehicleType: booking.vehicleType,
      fare: booking.fare,
      paidAmount: booking.paidAmount,
      remainingAmount: booking.remainingToBePaid,
      paymentMethod: booking.paymentType,
      name: tripDetails?.name || "N/A",
      contact: mobileNumber || "N/A",
      distanceKm: booking.distanceKm,
      tripDuration: booking.tripDuration,
      tripStatus: tripDetails?.tripStatus || "N/A",
      bookingStatus: booking.status,
      vehicleNumber: tripDetails?.vehicleNumber || "N/A",
      driverId: tripDetails?.driverId || "N/A",
      tripOtp: tripDetails?.tripOtp || "N/A",
      paymentStatus: "SUCCESS"
    };
  };

  const handleBookNewClick = () => {
    navigate('/user/dashboard');
  };

  return (
    <Fragment>
      <Navbar />
      <div className="container mx-auto p-4 bg-gray-100">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <h1 className="text-2xl font-semibold">Booking History</h1>
          <button onClick={handleBookNewClick} className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-fixblue">
            + Book New
          </button>
        </div>
        <div className="mb-4">
          <select className="border border-gray-300 rounded-md px-4 py-2 mb-4" value={filter} onChange={handleFilterChange}>
            <option value="all">Sort by All</option>
            <option value="upcoming">Upcoming</option>
            <option value="completed">Completed</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>

        {filteredBookings.length > 0 ? (
          filteredBookings.map((bookingData, index) => {
            const booking = mapBookingData(bookingData);
            return (
              <div
                key={index}
                className={`p-4 mb-4 border-l-4 ${
                  booking.bookingStatus === "COMPLETED"
                    ? "border-fixgreen"
                    : booking.bookingStatus === "CANCELLED"
                    ? "border-fixred"
                    : "border-fixblue"
                } bg-white rounded-lg shadow-sm`}
              >
                <div className="flex justify-between">
                  <div>
                    <h2 className="text-lg font-semibold">{booking.tripType} {booking.bookId}</h2>
                    <p className="text-gray-500">{booking.pickupLocation} ( to ) {booking.dropLocation}</p>
                    <p className="text-gray-500">Driver: {booking.driverId}</p>
                    <p className="text-gray-500">Vehicle Number: {booking.vehicleNumber}</p>
                    <p className="text-gray-500">Trip OTP: {booking.tripOtp}</p>
                  </div>
                  <div>
                    <span
                      className={`font-bold ${
                        booking.bookingStatus === "COMPLETED"
                          ? "text-green-500"
                          : booking.bookingStatus === "CANCELLED"
                          ? "text-red-500"
                          : "text-fixblue"
                      }`}
                    >
                      {booking.bookingStatus}
                    </span>
                    <p className="text-gray-400">{booking.bookingDate}</p>
                    <p className="text-gray-400">{booking.bookingTime}</p>
                  </div>
                </div>
                <div className="mt-2 text-right">
                  {booking.bookingStatus !== "COMPLETED" && booking.bookingStatus !== "CANCELLED" && (
                    <button
                      className="text-fixblue hover:underline"
                      onClick={() => {
                        const bookingData1 = booking;
                        navigate(`/user/confirm`, { state: { bookingData1 } });
                      }}
                    >
                      View details
                    </button>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <p>No bookings found</p>
        )}
      </div>
      <Footer />
    </Fragment>
  );
};

export default BookingHistory;

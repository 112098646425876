import React, { Fragment, useState, useEffect } from "react";
import { FaUpload } from "react-icons/fa";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import AdminLayout from "../../../components/admin/AdminLayout";

// Create an Axios instance with the custom header
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    'ngrok-skip-browser-warning': 'true' // Skip ngrok warning page
  }
});

const VendorForm = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [vendorName, setVendorName] = useState("");
  const [gender, setGender] = useState("male");
  const [dob, setDob] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [vendorUserid, setVendorUserid] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [alternateMobileNumber, setAlternateMobileNumber] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [companyGstNumber, setCompanyGstNumber] = useState("");
  const [companyMail, setCompanyMail] = useState("");
  const [driverCount, setDriverCount] = useState("");
  const [vehicleCount, setVehicleCount] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("India");
  const [address, setAddress] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [vendorData, setVendorData] = useState("");

  const cities = ["Chennai", "Krishnagiri", "Vellore"];
  const states = ["Tamil Nadu", "Andhra Pradesh"];

  const [vendorPhoto1, setVendorPhoto1] = useState(null);
  const [uploadedVendorPhoto1Url, setUploadedVendorPhoto1Url] = useState(null);
  const [uploadedVendorPhoto1Id, setUploadedVendorPhoto1Id] = useState(null);

  const [vendorPhoto2, setVendorPhoto2] = useState(null);
  const [uploadedVendorPhoto2Url, setUploadedVendorPhoto2Url] = useState(null);
  const [uploadedVendorPhoto2Id, setUploadedVendorPhoto2Id] = useState(null);

  const [vendorPhoto3, setVendorPhoto3] = useState(null);
  const [uploadedVendorPhoto3Url, setUploadedVendorPhoto3Url] = useState(null);
  const [uploadedVendorPhoto3Id, setUploadedVendorPhoto3Id] = useState(null);

  const [companyPhoto, setCompanyPhoto] = useState(null);
  const [uploadedCompanyPhotoUrl, setUploadedCompanyPhotoUrl] = useState(null);
  const [uploadedCompanyPhotoId, setUploadedCompanyPhotoId] = useState(null);

  useEffect(() => {
    setIsSubmitting(false);
  }, [
    vendorName, gender, dob, mobileNumber, alternateMobileNumber, companyName, companyGstNumber,
    companyMail, driverCount, vehicleCount, pincode, city, state, country, address, termsAccepted, vendorUserid
  ]);

  const handleFileChange = (event, setImage, imageTypeCodeDescription, setImageUrl, setImageId) => {
    const file = event.target.files[0];
    setImage(file);
    handleImageUpload(file, imageTypeCodeDescription, setImageUrl, setImageId);
  };

  const handleImageUpload = (file, imageTypeCodeDescription, setImageUrl, setImageId) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('imageTypeCodeDescription', imageTypeCodeDescription);
    formData.append('entityType', 'vendor');
    if (vendorId) {
      formData.append('entityId', vendorId);
    } else {
      console.log("vendorID is null");
      toast.error("Find the Vendor first...");
      return;
    }

    api.post('/drivervehicle/images/upload', formData)
      .then(response => {
        const imageUrl = `${process.env.REACT_APP_API_BASE_URLE}/drivervehicle/images/${response.data.imageId}`;
        setImageUrl(imageUrl);
        setImageId(response.data.imageId);
        toast.success("Image uploaded successfully");
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error("Image upload failed");
      });
  };

  const handleRemoveImage = (imageId, setImageUrl, setImageId) => {
    api.delete(`/drivervehicle/images/${imageId}`)
      .then(response => {
        setImageUrl(null);
        setImageId(null);
        toast.success("Image removed successfully");
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error("Failed to remove image");
      });
  };

  const handleSubmit = (event) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    if (!vendorId) {
      if (vendorUserid) {
        event.preventDefault();
        handleSearch();
      } else {
        event.preventDefault();
        toast.error("Please find Vendor ID by Mobile Number/vendorUserid");
        return;
      }
    } else {
      event.preventDefault();
      const vendorData = {
        vendorId: vendorId,
        vendorName: vendorName,
        gender: gender,
        dob: dob,
        vendorUserid: vendorUserid,
        alternateMobileNumber: alternateMobileNumber,
        companyName: companyName,
        companyGstNumber: companyGstNumber,
        companyMail: companyMail,
        driverCount: driverCount,
        vehicleCount: vehicleCount,
        pincode: pincode,
        city: city,
        state: state,
        country: country,
        address: address,
        termsAccepted: termsAccepted,
      };

      api.post('/drivervehicle/vendors/add', vendorData)
        .then(response => {
          toast.success("Vendor information saved successfully.");
        })
        .catch(error => {
          console.error('Error:', error);
          toast.error("Failed to save Vendor information.");
        });
    }
  };

  const handleSearch = async () => {
    try {
      const response = await api.get(`/drivervehicle/vendors/mobile/${vendorUserid}`);
      if (response.status === 200) {
        const data = response.data;
        if (data.id && data.username) {
          setVendorId(data.id);
          setMobileNumber(data.username);
          setVendorUserid(data.username);
          handleSearchById(data.id);
          console.log("handleSearch /mobile/mobileNumber/vendoruserid:", data.id);
        } else {
          toast.error("Incomplete Vendor data received.");
        }
      } else {
        toast.error("Vendor not pre-registered yet.");
      }
    } catch (error) {
      console.error("Error fetching Vendor data:", error);
      toast.error("Failed to fetch Vendor data.");
    }
  };

  const handleSearchById = async (id) => {
    try {
      const response = await api.get(`/drivervehicle/vendors/${id}`);
      if (response.status === 200) {
        const vendorData1 = response.data;
        setVendorData(vendorData1);
        console.log(vendorData1);
        handleExistingFields(vendorData1);
      } else {
        toast.error("Vendor not fully registered.");
      }
    } catch (error) {
      console.error("Error fetching Vendor data:", error);
    }
  };

  const handleExistingFields = (vendorData) => {
    if (vendorData) {
      setVendorName(vendorData.vendorName || '');
      setGender(vendorData.gender || '');
      setDob(vendorData.dob || '');
      setMobileNumber(vendorData.mobileNumber || '');
      setVendorUserid(vendorData.vendorUserid || '');
      setVendorId(vendorData.id || '');
      setAlternateMobileNumber(vendorData.alternateMobileNumber || '');
      setCompanyName(vendorData.companyName || '');
      setCompanyGstNumber(vendorData.companyGstNumber || '');
      setCompanyMail(vendorData.companyMail || '');
      setDriverCount(vendorData.driverCount || '');
      setVehicleCount(vendorData.vehicleCount || '');
      setPincode(vendorData.pincode || '');
      setCity(vendorData.city || '');
      setState(vendorData.state || '');
      setCountry(vendorData.country || '');
      setAddress(vendorData.address || '');
      setTermsAccepted(vendorData.termsAccepted || '');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleFormKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleTextAreaKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setAddress((prevAddress) => prevAddress + '\n');
    }
  };

  return (
    <Fragment>
      <AdminLayout>
      <ToastContainer />
      <div className="max-w-2xl mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Add Vendor</h2>
            <form className="space-y-4" onSubmit={handleSubmit} onKeyDown={handleFormKeyDown}>
              <section className="border p-4 rounded-md">
                <h3 className="text-xl font-semibold mb-2">Basic Details</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium">
                      Vendor's Name
                    </label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter vendor's name"
                      value={vendorName}
                      onChange={(e) => setVendorName(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Select Gender
                    </label>
                    <div className="mt-1">
                      <select
                        className="w-full border rounded-md p-2"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="others">Others</option>
                      </select>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      className="w-full border rounded-md p-2"
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Vendor UserID
                    </label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Vendor UserID / Username"
                      value={vendorUserid}
                      onChange={(e) => setVendorUserid(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                    <button
                      type="button"
                      className="bg-fixblue text-white py-2 px-6 font-bold rounded-md mt-2"
                      onClick={handleSearch}
                    >
                      Find
                    </button>
                  </div>
                  <div>
                    <label>Vendor ID:</label>
                    <input
                      type="text"
                      readOnly
                      className="w-full border rounded-md p-2"
                      placeholder="Vendor id"
                      value={vendorId}
                      onChange={(e) => setVendorId(e.target.value)} //{handleVendorIdChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Alternate Mobile Number
                    </label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter Alternate Mobile Number"
                      value={alternateMobileNumber}
                      onChange={(e) => setAlternateMobileNumber(e.target.value)}
                    />
                  </div>
                </div>
              </section>

              <section className="border p-4 rounded-md">
                <h3 className="text-xl font-semibold mb-2">Vendor Details</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium">
                      Company Name
                    </label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Company Name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Company Photo
                    </label>
                    <div className="mt-1 flex items-center">
                      <input
                        type="file"
                        className="hidden"
                        id="vendor_company_photo"
                        onChange={(e) => handleFileChange(e, setCompanyPhoto, 'vendor_company_photo', setUploadedCompanyPhotoUrl, setUploadedCompanyPhotoId)}
                      />
                      <label
                        htmlFor="vendor_company_photo"
                        className="flex items-center cursor-pointer text-blue-600 hover:underline"
                      >
                        <FaUpload className="mr-2" /> Upload a file
                      </label>
                      {uploadedCompanyPhotoUrl && (
                        <div className="mt-2 relative">
                          <img src={uploadedCompanyPhotoUrl} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedCompanyPhotoId, setUploadedCompanyPhotoUrl, setUploadedCompanyPhotoId)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Company GST Number
                    </label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="GST Number"
                      value={companyGstNumber}
                      onChange={(e) => setCompanyGstNumber(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Company Mail
                    </label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter company mail"
                      value={companyMail}
                      onChange={(e) => setCompanyMail(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Driver count
                    </label>
                    <input
                      type="number"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter Driver Count"
                      value={driverCount}
                      onChange={(e) => setDriverCount(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Vehicle count
                    </label>
                    <input
                      type="number"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter Vehicle Count"
                      value={vehicleCount}
                      onChange={(e) => setVehicleCount(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Pincode</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter Pin Code"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">City</label>
                    <select
                      className="w-full border rounded-md p-2"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    >
                      <option>Select City</option>
                      {cities.map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">State</label>
                    <select
                      className="w-full border rounded-md p-2"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    >
                      <option>Select State</option>
                      {states.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Country</label>
                    <select
                      className="w-full border rounded-md p-2"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option value="India">India</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Address</label>
                    <textarea
                      className="w-full border rounded-md p-2"
                      placeholder="Enter Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      onKeyDown={handleTextAreaKeyDown}
                    ></textarea>
                  </div>
                </div>
              </section>

              <section className="border p-4 rounded-md">
                <h3 className="text-xl font-semibold mb-2">Other Photos</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div>
                    <label className="block text-sm font-medium">
                      Photo 1
                    </label>
                    <div className="mt-1 flex items-center">
                      <input
                        type="file"
                        className="hidden"
                        id="vendor_photo1"
                        onChange={(e) => handleFileChange(e, setVendorPhoto1, 'vendor_photo1', setUploadedVendorPhoto1Url, setUploadedVendorPhoto1Id)}
                      />
                      <label
                        htmlFor="vendor_photo1"
                        className="flex items-center cursor-pointer text-blue-600 hover:underline"
                      >
                        <FaUpload className="mr-2" /> Upload a file
                      </label>
                      {uploadedVendorPhoto1Url && (
                        <div className="mt-2 relative">
                          <img src={uploadedVendorPhoto1Url} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedVendorPhoto1Id, setUploadedVendorPhoto1Url, setUploadedVendorPhoto1Id)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Photo 2
                    </label>
                    <div className="mt-1 flex items-center">
                      <input
                        type="file"
                        className="hidden"
                        id="vendor_photo2"
                        onChange={(e) => handleFileChange(e, setVendorPhoto2, 'vendor_photo2', setUploadedVendorPhoto2Url, setUploadedVendorPhoto2Id)}

                      />
                      <label
                        htmlFor="vendor_photo2"
                        className="flex items-center cursor-pointer text-blue-600 hover:underline"
                      >
                        <FaUpload className="mr-2" /> Upload a file
                      </label>
                      {uploadedVendorPhoto2Url && (
                        <div className="mt-2 relative">
                          <img src={uploadedVendorPhoto2Url} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedVendorPhoto2Id, setUploadedVendorPhoto2Url, setUploadedVendorPhoto2Id)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Photo 3
                    </label>
                    <div className="mt-1 flex items-center">
                      <input
                        type="file"
                        className="hidden"
                        id="vendor_photo3"
                        onChange={(e) => handleFileChange(e, setVendorPhoto3, 'vendor_photo3', setUploadedVendorPhoto3Url, setUploadedVendorPhoto3Id)}
                      />
                      <label
                        htmlFor="vendor_photo3"
                        className="flex items-center cursor-pointer text-blue-600 hover:underline"
                      >
                        <FaUpload className="mr-2" /> Upload a file
                      </label>
                      {uploadedVendorPhoto3Url && (
                        <div className="mt-2 relative">
                          <img src={uploadedVendorPhoto3Url} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedVendorPhoto3Id, setUploadedVendorPhoto3Url, setUploadedVendorPhoto3Id)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <div>
                <input
                  type="checkbox"
                  className="border rounded-md p-2 mr-2"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
                <label className="block text-sm font-medium">
                  Accept terms & conditions
                </label>
              </div>

              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-6 font-bold rounded-md"
                disabled={isSubmitting} onClick={handleSubmit}
              >
                Add Vendor
              </button>
            </form>
          </div>
      </AdminLayout>
    </Fragment>
  );
};

export default VendorForm;

import React, { Fragment, useState } from 'react';
import Filters from '../../../components/admin/Filters';
import CabTypeSelection from '../../../components/admin/CabTypeSelection';
import ActiveRates from '../../../components/admin/ActiveRates';
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import {  FaCar, FaCarAlt, FaCarSide, } from 'react-icons/fa';

function AllRate() {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };
  const cabTypes = [
    { id: 'radio1', name: 'SUV', icon: <FaCar className='pointer-events-none z-10 ml-2 text-xl' /> },
    { id: 'radio2', name: 'Hatchback', icon: <FaCarSide className='pointer-events-none z-10 ml-2 text-xl' /> },
    { id: 'radio3', name: 'Sedan', icon: <FaCarAlt className='pointer-events-none z-10 ml-2 text-xl' /> },
    { id: 'radio4', name: 'Innova', icon: <FaCarAlt className='pointer-events-none z-10 ml-2 text-xl' /> },
    { id: 'radio5', name: 'Innova Crysta', icon: <FaCarAlt className='pointer-events-none z-10 ml-2 text-xl' /> }
  ];

  return (
    <Fragment>
      <AdminSidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
      <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? 'ml-0' : 'ml-48'}`}>
        <AdminNavbar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
        {/* Main content */}
        <div className="bg-zinc-200 min-h-screen p-4">
          <div className="bg-white shadow-lg p-4 rounded-lg">
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <div className="text-gray-700">
                <h1 className="text-xl font-semibold">All Rates</h1>
                <span>{new Date().toLocaleDateString()}</span>
              </div>
            </div>

          </div>
          <div className="container mx-auto p-4 bg-fixwhite my-2">

            <div className="border-b-2 mb-4">
              <div className="pb-2 border-b-2 border-fixblue inline-block">Online Rates</div>
            </div>
            <div>
            </div>
            <Filters />
            <div className="flex flex-col sm:flex-row items-center justify-center m-4 space-x-2 ">
              {cabTypes.map((cabType) => (
                <div key={cabType.id} className="relative flex w-48 items-center justify-center rounded-xl bg-gray-50 px-4 py-3 m-2 font-medium text-gray-700">
                  <input className="peer hidden" type="radio" name="radio" id={cabType.id} />
                  <label className="peer-checked:border-blue-400 peer-checked:bg-blue-200 absolute top-0 h-full w-full cursor-pointer rounded-xl border" htmlFor={cabType.id}></label>
                  <div className="peer-checked:border-transparent peer-checked:bg-fixblue peer-checked:ring-2 absolute left-4 h-5 w-5 rounded-full border-2 border-gray-300 bg-gray-200 ring-blue-400 ring-offset-2"></div>
                  <span className="pointer-events-none z-10">{cabType.name}</span>
                  {cabType.icon}
                </div>
              ))}
            </div>
            <ActiveRates />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AllRate;

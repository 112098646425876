import React, { Fragment, useState } from "react";
import { FaCar, FaCarAlt, FaCarSide } from "react-icons/fa";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";

const InventoryDashboard = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);

  // Example data for cab types
  const cabTypes = [
    { id: 'radio1', name: 'SUV', icon: <FaCar className='pointer-events-none z-10 ml-2 text-xl' /> },
    { id: 'radio2', name: 'Hatchback', icon: <FaCarAlt className='pointer-events-none z-10 ml-2 text-xl' /> },
    { id: 'radio3', name: 'Sedan', icon: <FaCarSide className='pointer-events-none z-10 ml-2 text-xl' /> },
    { id: 'radio4', name: 'Innova', icon: <FaCarSide className='pointer-events-none z-10 ml-2 text-xl' /> },
    { id: 'radio5', name: 'Innova Crysta', icon: <FaCarSide className='pointer-events-none z-10 ml-2 text-xl' /> }
  ];
  // Example data
  const rows = [
    { day: 1, consumption: 10 },
    { day: 2, consumption: 12 },
    // Add more rows up to day 30 as needed
  ];

  return (
    <Fragment>
      <AdminSidebar
        sidebarToggle={sidebarToggle}
        setSidebarToggle={setSidebarToggle}
      />
      <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
        <AdminNavbar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
        />
        {/* Main content */}
        <div className="bg-zinc-200 min-h-screen p-4">
          <div className="bg-white shadow-lg p-4 rounded-lg">
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <div className="text-gray-700">
                <h1 className="text-xl font-semibold">Inventory Panel</h1>
                <span>{new Date().toLocaleDateString()}</span>
              </div>
            </div>
          </div>
          <div className="bg-fixwhite p-4 my-4">
            <h1 className="text-2xl font-bold mb-4">Inventory Dashboard</h1>
            <div className="flex flex-wrap mb-4">
              <div className="w-full md:w-1/2 lg:w-1/4 p-2">
                <label htmlFor="partnerType" className="block mb-1">Partner Type</label>
                <select id="partnerType" className="w-full p-2 border rounded">
                  <option>Retail</option>
                  <option>Wholesale</option>
                </select>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 p-2">
                <label htmlFor="priceType" className="block mb-1">Price Type</label>
                <select id="priceType" className="w-full p-2 border rounded">
                  <option>Outstation</option>
                  <option>Local</option>
                </select>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 p-2">
                <label htmlFor="tripType" className="block mb-1">Trip Type</label>
                <select id="tripType" className="w-full p-2 border rounded">
                  <option>One Way Outstation</option>
                  <option>Round Trip</option>
                </select>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 p-2">
                <label htmlFor="subcategory" className="block mb-1">Subcategory</label>
                <select id="subcategory" className="w-full p-2 border rounded">
                  <option>Basic</option>
                  <option>Premium</option>
                </select>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 p-2">
                <label htmlFor="route1" className="block mb-1">Route 1</label>
                <select id="route1" className="w-full p-2 border rounded">
                  <option>Baga</option>
                  <option>Calangute</option>
                </select>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 p-2">
                <label htmlFor="route2" className="block mb-1">Route 2</label>
                <select id="route2" className="w-full p-2 border rounded">
                  <option>Dabolim</option>
                  <option>Panaji</option>
                </select>
              </div>
            </div>
          </div>

          {/* Radio buttons for cab types */}
          <div className="bg-fixwhite p-1">
            <div className="flex flex-col sm:flex-row items-center justify-center gap-2 m-4 space-x-2">
              <h1 className="text-lg font-bold text-fixblack">Selected Car Type :</h1>
              {cabTypes.map((cabType, index) => (
                <div key={cabType.id} className="relative flex w-48 items-center justify-center rounded-xl bg-gray-50 px-4 py-3 m-2 font-medium text-gray-700">
                  <input className="peer hidden" type="radio" name="radio" id={cabType.id} />
                  <label className="peer-checked:border-blue-400 peer-checked:bg-blue-200 absolute top-0 h-full w-full cursor-pointer rounded-xl border" htmlFor={cabType.id}></label>
                  <div className="peer-checked:border-transparent peer-checked:bg-fixblue peer-checked:ring-2 absolute left-4 h-5 w-5 rounded-full border-2 border-gray-300 bg-gray-200 ring-blue-400 ring-offset-2"></div>
                  <span className="pointer-events-none z-10">{cabType.name}</span>
                  {cabType.icon}
                </div>
              ))}
            </div>
          </div>

          <div className="bg-fixwhite p-4 my-2">
            <div className="overflow-x-auto">
              <div className="flex my-4">
                <button className="p-2 border rounded mr-2">
                  18 February, Sunday
                </button>
                <button className="p-2 border rounded mr-2">
                  19 February, Monday
                </button>
                <button className="p-2 border rounded mr-2">
                  20 February, Tuesday
                </button>
                <button className="p-2 border rounded mr-2">
                  21 February, Wednesday
                </button>
                <button className="p-2 border rounded mr-2">
                  22 February, Thursday
                </button>
                <button className="p-2 border rounded mr-2">
                  23 February, Friday
                </button>
                <button className="p-2 border rounded">
                  24 February, Saturday
                </button>
              </div>
              {/* <div className="text-center p-4">No data found</div> */}
              <div className="overflow-x-auto ">
                <table className="table-auto min-w-full border-collapse border border-gray-200">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="border border-gray-300 px-2 py-1">Sedan</th>
                      <th className="border border-gray-300 px-2 py-1">1</th>
                      <th className="border border-gray-300 px-2 py-1">2</th>
                      <th className="border border-gray-300 px-2 py-1">3</th>
                      <th className="border border-gray-300 px-2 py-1">4</th>
                      <th className="border border-gray-300 px-2 py-1">5</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-gray-300 px-2 py-1">ABC123</td>
                      <th className="border border-gray-300 px-2 py-1">Commit</th>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 px-2 py-1">ABC456</td>
                      <td className="border border-gray-300 px-2 py-1"></td>
                      <th className="border border-gray-300 px-2 py-1"></th>
                      <th className="border border-gray-300 px-2 py-1">Commit</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default InventoryDashboard;

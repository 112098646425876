// src/components/common/AutocompleteInput.jsx

import React, { useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { FaTimes } from 'react-icons/fa'; // Import the 'x' icon

const AutocompleteInput = ({
  id,
  label,
  value,
  onChange,
  placeholder,
  autocompleteOptions,
  onPlaceChanged,
}) => {
  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const handlePlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place && place.formatted_address) {
        onPlaceChanged(place.formatted_address);
      } else {
        // Keep the current value if no place is selected
        onPlaceChanged(value);
      }
    }
  };

  // Clear input handler
  const handleClear = () => {
    onChange('');
  };

  return (
    <div className="lg:col-span-1">
      <label htmlFor={id} className="block text-sm text-gray-700 font-bold mb-2">
        {label}
      </label>
      <div className="relative">
        <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={handlePlaceChanged}
          options={autocompleteOptions}
        >
          <input
            id={id}
            type="text"
            className="mt-2 block w-full px-3 py-2 pr-10 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handlePlaceChanged();
              }
            }}
            onBlur={handlePlaceChanged}
            required
          />
        </Autocomplete>
        {value && (
          <button
            type="button"
            className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 focus:outline-none"
            onClick={handleClear}
          >
            <FaTimes />
          </button>
        )}
      </div>
    </div>
  );
};

export default AutocompleteInput;

// src/pages/sitePages/Main.js

import React, { Fragment, useEffect } from 'react';
import AirportDrive from '../../components/site/core/AirportDrive';
import RentalDrive from '../../components/site/core/RentalDrive';
import OutstationDrive from '../../components/site/core/OutstationDrive';
import { useGoogleMaps } from './GoogleMapsProvider';

// Import useSelector and useDispatch
import { useSelector, useDispatch } from 'react-redux';
import { updateTripField, setTripData } from '../../reducers/tripReducer';

// Import utility functions
import {
    parseLocation,
    calculateDistance,
    checkIfAirport,
    baseKmRequest,
    formatDate,
    formatTimeTo24Hour,
} from '../../utils/tripUtils';
import LoadingRyde from '../../components/site/LoadingRyde';

const Main = ({ onSearch }) => {
    const dispatch = useDispatch();
    const {
        tripType,
        activeField,
        sourceInput,
        destinationInput,
        date,
        time,
        availableTimes,
        redate,
        retime,
        roundTrip,
        distance, 
    } = useSelector((state) => state.trip);

    const isLoaded = useGoogleMaps();

    useEffect(() => {
        // Initialize date and time options
        const today = new Date();
        const currentHour = today.getHours();

        if (currentHour >= 22) {
            today.setDate(today.getDate() + 1);
        }

        const formattedDate = formatDate(today);
        dispatch(updateTripField({ field: 'date', value: formattedDate }));
        dispatch(updateTripField({ field: 'redate', value: formattedDate }));

        generateTimeOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        generateTimeOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, redate]);

    const generateTimeOptions = () => {
        const times = [];
        const currentTime = new Date();
        const minAllowedTime = new Date(currentTime.getTime() + 2 * 60 * 60 * 1000);
        const selectedDate = new Date(date);
        let foundFirstEnabledTime = false;

        for (let hour = 0; hour < 24; hour++) {
            for (let minutes = 0; minutes < 60; minutes += 15) {
                const timeslotDateTime = new Date(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth(),
                    selectedDate.getDate(),
                    hour,
                    minutes
                );

                const amPm = hour < 12 ? 'AM' : 'PM';
                const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
                const timeSlot = `${formattedHour}:${minutes === 0 ? '00' : minutes} ${amPm}`;

                let isEnabled = true;

                if (timeslotDateTime.getTime() < minAllowedTime.getTime()) {
                    isEnabled = false;
                }

                times.push({ time: timeSlot, isEnabled });

                if (isEnabled && !foundFirstEnabledTime) {
                    dispatch(updateTripField({ field: 'time', value: timeSlot }));
                    foundFirstEnabledTime = true;
                }
            }
        }
        dispatch(updateTripField({ field: 'availableTimes', value: times }));
    };

    const handleDateChange = (selectedDate) => {
        const today = new Date();
        const selectedDateObj = new Date(selectedDate);

        today.setHours(0, 0, 0, 0);
        selectedDateObj.setHours(0, 0, 0, 0);

        if (selectedDateObj < today) {
            alert("Past dates cannot be selected.");
            return;
        }

        dispatch(updateTripField({ field: 'date', value: formatDate(selectedDateObj) }));
        dispatch(updateTripField({ field: 'time', value: "" }));
        generateTimeOptions();
    };

    const handleTimeChange = (selectedTime) => {
        dispatch(updateTripField({ field: 'time', value: selectedTime }));
    };

    const handleReturnDateChange = (selectedReturnDate) => {
        const departureDate = new Date(date);
        const returnDateObj = new Date(selectedReturnDate);

        departureDate.setHours(0, 0, 0, 0);
        returnDateObj.setHours(0, 0, 0, 0);

        if (returnDateObj < departureDate) {
            alert("Return date cannot be before departure date.");
            return;
        }

        dispatch(updateTripField({ field: 'redate', value: formatDate(returnDateObj) }));
        dispatch(updateTripField({ field: 'retime', value: "" }));
        generateTimeOptions();
    };

    const determineActiveFieldAndTripType = (sourceDetails, destinationDetails) => {
        let updatedActiveField = activeField;
        let updatedTripType = tripType;

        const isSourceAirport = checkIfAirport(sourceInput);
        const isDestinationAirport = checkIfAirport(destinationInput);

        const isSameCity = sourceDetails.city && destinationDetails.city && sourceDetails.city.toLowerCase() === destinationDetails.city.toLowerCase();
        const isSameDistrict = sourceDetails.district && destinationDetails.district && sourceDetails.district.toLowerCase() === destinationDetails.district.toLowerCase();

        if (activeField === 'RentalDrive') {
            // No changes in activeField
            return { updatedActiveField, updatedTripType };
        }

        if (activeField === 'AirportDrive') {
            // Can only change to OutstationDrive if cities/districts differ
            const isDifferentCityOrDistrict = !isSameCity && !isSameDistrict;

            if (isDifferentCityOrDistrict) {
                updatedActiveField = 'OutstationDrive';
                alert("You are being redirected to Outstation Drive");
                if (tripType !== 'OnewayTrip' && tripType !== 'RoundTrip') {
                    updatedTripType = 'OnewayTrip';
                }
            }
            return { updatedActiveField, updatedTripType };
        }

        if (activeField === 'OutstationDrive') {
            if (isSameCity || isSameDistrict) {
                if (isSourceAirport) {
                    // Scenario 2
                    alert("You are being redirected to Airport Pickup Drive");
                    updatedActiveField = 'AirportDrive';
                    updatedTripType = 'AirportPickup';
                } else if (isDestinationAirport) {
                    // Scenario 3
                    alert("You are being redirected to Airport Drop Drive");
                    updatedActiveField = 'AirportDrive';
                    updatedTripType = 'AirportDrop';
                } else {
                    // Scenario 1
                    alert("You are being redirected to Rental Drive");
                    updatedActiveField = 'RentalDrive';
                    updatedTripType = '8 hrs 80 kms';
                }
            }
            // Else, remain in OutstationDrive with current tripType
            return { updatedActiveField, updatedTripType };
        }

        // Default return
        return { updatedActiveField, updatedTripType };
    };

    const handleSearch = async () => {
        if ((activeField === "AirportDrive" || activeField === "OutstationDrive") && (!sourceInput || !destinationInput)) {
            alert("Please fill in all fields.");
            return;
        } else if (activeField === "RentalDrive" && !sourceInput) {
            alert("Please fill in all fields.");
            return;
        }

        try {
            let distance = '0 km';
            let updatedTripType = tripType;
            let updatedActiveField = activeField;

            let userData = {
                sourceInput: "",
                sourceDetails: "",
                destinationInput: "",
                destinationDetails: "",
                tripType: "",
                distance: "0 km",
                date: date,
                time: time,
                redate: null, 
                retime: null, 
            };

            let redateLocal = redate;
            let retimeLocal = retime;

            if (activeField !== "RentalDrive") {
                distance = await calculateDistance(sourceInput, destinationInput);
                const sourceDetails = await parseLocation(sourceInput);
                const destinationDetails = await parseLocation(destinationInput);

                // Use the updated determineActiveFieldAndTripType function
                const result = determineActiveFieldAndTripType(sourceDetails, destinationDetails);
                updatedActiveField = result.updatedActiveField;
                updatedTripType = result.updatedTripType;

                // Update state
                dispatch(updateTripField({ field: 'activeField', value: updatedActiveField }));
                dispatch(updateTripField({ field: 'tripType', value: updatedTripType }));
                dispatch(updateTripField({ field: 'distance', value: distance })); 

                // Parse distance to a number
                const distanceKm = parseFloat(distance.replace(" km", ""));

                // Implement 1: Adjust return date if distance > 320 km
                console.log("our distance:", distanceKm);
                if (distanceKm > 320 && updatedTripType === 'RoundTrip') {
                    const newRedate = new Date(date);
                    newRedate.setDate(newRedate.getDate() + 1);
                    const formattedRedate = formatDate(newRedate);
                    dispatch(updateTripField({ field: 'redate', value: formattedRedate }));
                    dispatch(updateTripField({ field: 'retime', value: "" }));
                    redateLocal = formattedRedate;
                    retimeLocal = "";
                    alert("Distance exceeds 320 km. Return date has been set to the next day.");
                }

                userData = {
                    sourceInput,
                    sourceDetails,
                    destinationInput,
                    destinationDetails,
                    tripType: updatedTripType,
                    distance,
                    date,
                    time: await formatTimeTo24Hour(time),
                    redate: updatedTripType === 'RoundTrip' ? redateLocal : null, // Implement 2
                    retime: updatedTripType === 'RoundTrip' ? retimeLocal : null, // Implement 2
                };
            } else {
                const sourceDetails = await parseLocation(sourceInput);
                userData = {
                    sourceInput,
                    sourceDetails,
                    destinationInput: sourceInput,
                    destinationDetails: sourceDetails,
                    tripType: tripType,
                    distance: "0 km",
                    date,
                    time: await formatTimeTo24Hour(time),
                    redate: null, // Implement 2
                    retime: null, // Implement 2
                };
            }

            // Fetch baseKmResponses
            const baseKmResponses = await baseKmRequest(userData);
            userData.baseKmResponses = baseKmResponses;

            // Dispatch trip data
            dispatch(setTripData(userData));

            // Call onSearch callback if it exists
            if (typeof onSearch === 'function') {
                onSearch(userData);
            }
        } catch (error) {
            console.error('Error in handleSearch:', error);
            alert('Error processing search. Please try again.');
        }
    };


    const handleTripChange = (type) => {
        dispatch(updateTripField({ field: 'activeField', value: type }));
        switch (type) {
            case "AirportDrive":
                dispatch(updateTripField({ field: 'tripType', value: "AirportPickup" }));
                break;
            case "RentalDrive":
                dispatch(updateTripField({ field: 'tripType', value: "8 hrs 80 kms" }));
                break;
            case "OutstationDrive":
                dispatch(updateTripField({ field: 'tripType', value: "OnewayTrip" }));
                break;
            default:
                break;
        }
    };

    if (!isLoaded) {
        return <LoadingRyde />;
    }

    return (
        <Fragment>
            <div className="flex justify-center">
                <div className="flex items-center space-x-1 text-[12px]">
                    {[
                        { type: 'AirportDrive', imgSrc: '/images/AirportCabs.png' },
                        { type: 'RentalDrive', imgSrc: '/images/RentalCabs.png' },
                        { type: 'OutstationDrive', imgSrc: '/images/OutsideCabs.png' }
                    ].map(({ type, imgSrc }) => (
                        <button
                            key={type}
                            onClick={() => handleTripChange(type)}
                            className={`py-2 px-4 ${activeField === type ? "bg-gray-100 border-2 border-fixgold" : "bg-gray-200"} rounded-sm flex flex-col items-center justify-center`}
                        >
                            <img className="max-h-14 max-w-full object-cover" src={imgSrc} alt={type} />
                            <span>{type.replace("Drive", "")}</span>
                            <span>Ride</span>
                        </button>
                    ))}
                </div>
            </div>


            {activeField === "AirportDrive" && (
                <AirportDrive
                    tripType={tripType}
                    setTripType={(value) => dispatch(updateTripField({ field: 'tripType', value }))}
                    sourceInput={sourceInput}
                    setSourceInput={(value) => dispatch(updateTripField({ field: 'sourceInput', value }))}
                    destinationInput={destinationInput}
                    setDestinationInput={(value) => dispatch(updateTripField({ field: 'destinationInput', value }))}
                    date={date}
                    setDate={handleDateChange}
                    time={time}
                    setTime={handleTimeChange}
                    availableTimes={availableTimes}
                    handleSearch={handleSearch}
                />
            )}

            {activeField === "RentalDrive" && (
                <RentalDrive
                    tripType={tripType}
                    setTripType={(value) => dispatch(updateTripField({ field: 'tripType', value }))}
                    sourceInput={sourceInput}
                    setSourceInput={(value) => dispatch(updateTripField({ field: 'sourceInput', value }))}
                    date={date}
                    setDate={handleDateChange}
                    time={time}
                    setTime={handleTimeChange}
                    availableTimes={availableTimes}
                    handleSearch={handleSearch}
                />
            )}

            {activeField === "OutstationDrive" && (
                <OutstationDrive
                    tripType={tripType}
                    setTripType={(value) => dispatch(updateTripField({ field: 'tripType', value }))}
                    sourceInput={sourceInput}
                    setSourceInput={(value) => dispatch(updateTripField({ field: 'sourceInput', value }))}
                    destinationInput={destinationInput}
                    setDestinationInput={(value) => dispatch(updateTripField({ field: 'destinationInput', value }))}
                    date={date}
                    setDate={handleDateChange}
                    time={time}
                    setTime={handleTimeChange}
                    availableTimes={availableTimes}
                    redate={redate}
                    setRedate={handleReturnDateChange}
                    retime={retime}
                    setRetime={(value) => dispatch(updateTripField({ field: 'retime', value }))}
                    handleSearch={handleSearch}
                    distance={distance} 
                />
            )}
        </Fragment>
    );

};

export default Main;

import React, { Fragment, useState } from "react";
import { FaImage, FaRegTimesCircle } from "react-icons/fa";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

// Create an Axios instance with the custom header
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    'ngrok-skip-browser-warning': 'true', // Skip ngrok warning page
    'Content-Type': 'application/json'
  }
});

const ViewVendor = () => {
  const { vendorUsername } = useParams();

  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [searchFromMyVendor, setSearchFromMyVendor] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [gender, setGender] = useState("male");
  const [dob, setDob] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [vendorUserid, setVendorUserid] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [alternateMobileNumber, setAlternateMobileNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyGstNumber, setCompanyGstNumber] = useState("");
  const [companyMail, setCompanyMail] = useState("");
  const [driverCount, setDriverCount] = useState("");
  const [vehicleCount, setVehicleCount] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("India");
  const [address, setAddress] = useState("");

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [vendorData, setVendorData] = useState("");

  const cities = ["Chennai", "Krishnagiri", "Vellore"];
  const states = ["Tamil Nadu", "Andhra Pradesh"];
  const genders = ["male", "female", "others"];
  const countries = ["India", "Sri Lanka", "Malaysia", "Singapore"];

  const [vendorPhoto1, setVendorPhoto1] = useState(null);
  const [uploadedVendorPhoto1Url, setUploadedVendorPhoto1Url] = useState(null);
  const [uploadedVendorPhoto1Id, setUploadedVendorPhoto1Id] = useState(null);

  const [vendorPhoto2, setVendorPhoto2] = useState(null);
  const [uploadedVendorPhoto2Url, setUploadedVendorPhoto2Url] = useState(null);
  const [uploadedVendorPhoto2Id, setUploadedVendorPhoto2Id] = useState(null);

  const [vendorPhoto3, setVendorPhoto3] = useState(null);
  const [uploadedVendorPhoto3Url, setUploadedVendorPhoto3Url] = useState(null)
  const [uploadedVendorPhoto3Id, setUploadedVendorPhoto3Id] = useState(null);

  const [companyPhoto, setCompanyPhoto] = useState(null);
  const [uploadedCompanyPhotoUrl, setUploadedCompanyPhotoUrl] = useState(null);
  const [uploadedCompanyPhotoId, setUploadedCompanyPhotoId] = useState(null);

  useEffect(() => {
    if (vendorUsername) {
      setVendorUserid(vendorUsername);  // Set the username to the state
      setSearchFromMyVendor(vendorUsername);  // Set the username to the state
    }
  }, [vendorUsername]);
  
  useEffect(() => {
    if (vendorUserid) {
      handleSearch();  // Automatically trigger the search once vendorUserid is set
    }
  }, [searchFromMyVendor]);

  const handleImageClick = (imageUrl) => {
    if (!imageUrl) {
      toast.error("No image Available");
      return;
    }
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const ImageModal = () => (
    <div className="fixed top-0 left-0 min-w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="max-w-full mx-auto bg-white p-4 rounded-lg">
        <img src={selectedImage} alt="Preview" className="w-full" />
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          onClick={() => setShowModal(false)}
        >
          <FaRegTimesCircle size={30} className="text-fixred font-extrabold" />
        </button>
      </div>
    </div>
  );

  const handleSearch = async () => {
    try {
      const response = await api.get(`/drivervehicle/vendors/mobile/${vendorUserid}`);
      if (response.status === 200) {
        const data = response.data;
        if (data.id && data.username) {
          setVendorId(data.id);
          setMobileNumber(data.username);
          setVendorUserid(data.username);
          handleSearchById(data.id);
          console.log("handleSearch /mobile/vendorUserid:", data.id);
          const imagesResponse = await api.get(`/drivervehicle/images/vendor/${data.id}`);
          if (imagesResponse.status === 200) {
            const imagesMetadata = imagesResponse.data;
            mapImagesMetadataToFields(imagesMetadata);
            console.log("got Metadata :::");
          } else {
            toast.error(`Failed to fetch images: ${imagesResponse.statusText}`);
            console.log("Error fetching images:", imagesResponse.statusText);
          }
        } else {
          toast.error("Incomplete Vendor data received.");
        }
      } else {
        toast.error("Vendor not pre-registered yet.");
      }
    } catch (error) {
      console.error("Error fetching Vendor data:", error);
      toast.error("Failed to fetch Vendor data.");
    }
  };

  const handleSearchById = async (id) => {
    try {
      const response = await api.get(`/drivervehicle/vendors/${id}`);
      if (response.status === 200) {
        const vendorData1 = response.data;
        setVendorData(vendorData1);
        console.log(vendorData1);
        console.log("handleSearchById IF:", id);
        handleExistingFields(vendorData1);
      } else {
        toast.error("Vendor not fully registered.");
        console.log("handleSearchById else:", id);
      }
    } catch (error) {
      console.error("Error fetching Vendor data:", error);
    }
  };

  const handleExistingFields = (vendorData) => {
    if (vendorData) {
      setVendorName(vendorData.vendorName || '');
      setGender(vendorData.gender || '');
      setDob(vendorData.dob || '');
      setVendorUserid(vendorData.vendorUserid || '');
      setVendorId(vendorData.id || '');
      setAlternateMobileNumber(vendorData.alternateMobileNumber || '');
      setCompanyName(vendorData.companyName || '');
      setCompanyGstNumber(vendorData.companyGstNumber || '');
      setCompanyMail(vendorData.companyMail || '');
      setDriverCount(vendorData.driverCount || '');
      setVehicleCount(vendorData.vehicleCount || '');
      setPincode(vendorData.pincode || '');
      setCity(vendorData.city || '');
      setState(vendorData.state || '');
      setCountry(vendorData.country || '');
      setAddress(vendorData.address || '');
      setTermsAccepted(vendorData.termsAccepted || '');
    } else {
      console.log("NOT PRINNNT");
    }
  };

  const handleApprove = (event, isApproved) => {
    event.preventDefault();

    const vendorData = {
      id: vendorId,
      approved: isApproved,
    };

    api.put(`/drivervehicle/vendors/approve/${vendorId}?approved=${isApproved}`, vendorData)
      .then(response => {
        if (response.status === 200) {
          if (isApproved) {
            toast.success("Vendor approved successfully.");
          } else {
            toast.warning("Vendor rejected successfully.");
          }
        } else {
          return response.text().then(text => { throw new Error(text) });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error("Failed to update vendor approval status.");
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  const mapImagesMetadataToFields = (imagesMetadata) => {
    imagesMetadata.forEach(image => {
      fetchImageData(image.id, image.imageTypeCodeDescription);
    });
  };


  const fetchImageData = async (imageId, imageTypeCodeDescription) => {
    try {
      const response = await api.get(`/drivervehicle/images/${imageId}`, { responseType: 'blob' });
      if (response.status === 200) {
        const imageUrl = URL.createObjectURL(response.data);
        switch (imageTypeCodeDescription) {
          case 'vendor_company_photo':
            setUploadedCompanyPhotoUrl(imageUrl);
            setUploadedCompanyPhotoId(imageId);
            break;
          case 'vendor_photo1':
            setUploadedVendorPhoto1Url(imageUrl);
            setUploadedVendorPhoto1Id(imageId);
            break;
          case 'vendor_photo2':
            setUploadedVendorPhoto2Url(imageUrl);
            setUploadedVendorPhoto2Id(imageId);
            break;
          case 'vendor_photo3':
            setUploadedVendorPhoto3Url(imageUrl);
            setUploadedVendorPhoto3Id(imageId);
            break;
          default:
            break;
        }
      } else {
        toast.error(`Failed to fetch image: ${response.statusText}`);
        console.log("Error fetching image:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching image data:", error);
      toast.error("Failed to fetch image data.");
    }
  };
  return (
    <Fragment>
      <ToastContainer />
      <div className="flex">
        <AdminSidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
        <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
          <AdminNavbar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
          {showModal && <ImageModal />}
          <div className="max-w-2xl mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">View Vendor</h2>
            <form className="space-y-4">
              <section className="border p-4 rounded-md">
                <h3 className="text-xl font-semibold mb-2">Basic Details</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium">Vendor's Name</label>
                    <input type="text" className="w-full border rounded-md p-2" placeholder="Enter vendor's name" value={vendorName} readOnly />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Vendor UserID</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Vendor UserID / Username"
                      value={vendorUserid}
                      onChange={(e) => setVendorUserid(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                    <button type="button" className="bg-fixblue text-white py-1 px-4 font-bold rounded-md mt-1"
                      onClick={handleSearch}
                    >Find</button>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Date of Birth</label>
                    <input type="date" className="w-full border rounded-md p-2" value={dob} readOnly />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Select Gender</label>
                    <div className="mt-1">
                      <select className="w-full border rounded-md p-2" value={gender} disabled>
                        {genders.map((gender) => (
                          <option key={gender} value={gender}>{gender}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div>
                    <label>Vendor ID:</label>
                    <input type="text" readOnly value={vendorId} className="w-full border rounded-md p-2" placeholder="Enter Vendor id" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Alternate Mobile Number</label>
                    <input type="text" className="w-full border rounded-md p-2" placeholder="Enter Alternate Mobile Number" readOnly value={alternateMobileNumber} />
                  </div>
                </div>
              </section>
              <section className="border p-4 rounded-md">
                <h3 className="text-xl font-semibold mb-2">Vendor Details</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium">Company Name</label>
                    <input type="text" className="w-full border rounded-md p-2" placeholder="Company Name" readOnly value={companyName} />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Company Photo</label>
                    <div className="flex items-center justify-center cursor-pointer"
                      id="vendor_company_photo"
                      onClick={() => handleImageClick(uploadedCompanyPhotoUrl)}
                    >
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Company GST Number</label>
                    <input type="text" className="w-full border rounded-md p-2" placeholder="GST Number" readOnly value={companyGstNumber} />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Company Mail</label>
                    <input type="text" className="w-full border rounded-md p-2" placeholder="Enter company mail" readOnly value={companyMail} />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Driver count</label>
                    <input type="number" className="w-full border rounded-md p-2" placeholder="Enter Driver Count" readOnly value={driverCount} />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Vehicle count</label>
                    <input type="number" className="w-full border rounded-md p-2" placeholder="Enter Vehicle Count" readOnly value={vehicleCount} />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Pincode</label>
                    <input type="text" className="w-full border rounded-md p-2" placeholder="Enter Pin Code" readOnly value={pincode} />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">City</label>
                    <div className="mt-1">
                      <select className="w-full border rounded-md p-2" disabled value={city} >
                        {cities.map((city) => (
                          <option key={city} value={city}>{city}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">State</label>
                    <div className="mt-1">
                      <select className="w-full border rounded-md p-2" disabled value={state}>
                        {states.map((state) => (
                          <option key={state} value={state}>{state}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Country</label>
                    <div className="mt-1">
                      <select className="w-full border rounded-md p-2" disabled value={country} >
                        {countries.map((country) => (
                          <option key={country} value={country}>{country}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Address</label>
                    <textarea id="address" name="address" rows="3" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" readOnly value={address} />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Others1 Photo</label>
                    <div className="flex items-center justify-center cursor-pointer"
                      id="vendor_photo1"
                      onClick={() => handleImageClick(uploadedVendorPhoto1Url)}
                    >

                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Other2 Photo</label>
                    <div className="flex items-center justify-center cursor-pointer"
                      id="vendor_photo2"
                      onClick={() => handleImageClick(uploadedVendorPhoto2Url)}
                    >
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Others3 Photo</label>
                    <div className="flex items-center justify-center cursor-pointer"
                      id="vendor_photo3"
                      onClick={() => handleImageClick(uploadedVendorPhoto3Url)}

                    >
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                </div>
              </section>
              {!searchFromMyVendor && (
              <div className="flex items-center justify-center text-[1rem]">
                <button
                  type="button"
                  onClick={(e) => handleApprove(e, true)}
                  className="bg-fixblue text-white py-2 px-6 font-bold rounded-md"
                >
                  Approve
                </button>
                <button
                  type="button"
                  onClick={(e) => handleApprove(e, false)}
                  className="bg-fixred text-white py-2 px-6 font-bold rounded-md ml-4"
                >
                  Reject
                </button>
              </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ViewVendor;

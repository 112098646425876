import React from 'react';

const ProcessCard = ({ color, titleData, headingData, spanData }) => {
  const colorClass = {
    gray: 'bg-gray-400',
    blue: 'bg-blue-400',
    green: 'bg-green-400',
    yellow: 'bg-yellow-400',
    red: 'bg-red-400',
    pink: 'bg-pink-400',
    orange: 'bg-orange-400',
    teal: 'bg-teal-400',
    lime: 'bg-lime-400'
  }[color];
  return (
    <div className="bg-white shadow-lg px-4 py-4 m-1 ">
      <div className='flex justify-end'>
        <h2 className={`text-sm text-white font-semibold ${colorClass} px-2 uppercase`}>{titleData}</h2>
      </div>
      <h2 className='font-bold text-2xl text-gray-500'>{headingData}</h2>
      <span className='font-mono font-semibold text-gray-700'>{spanData}</span>
    </div>
  );
};

export default ProcessCard;

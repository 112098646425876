import React from "react";

function VenDash({ title, countno, color, icon }) {
  // Define a mapping of colors to Tailwind CSS classes
  const colorClasses = {
    red: "bg-red-100 text-red-600",
    green: "bg-green-100 text-green-600",
    blue: "bg-blue-100 text-blue-600",
    orange: "bg-orange-100 text-orange-600",
    teal: "bg-teal-100 bg-teal-600",
    yellow: "bg-yellow-100 text-yellow-600",
    // Add more colors as needed
  };

  // Select the CSS class based on the color prop
  const textColorClass = colorClasses[color] || "bg-gray-100 text-gray-600"; // Default to gray if color is not found

  return (
    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/5 mb-4 px-2">
      <div className={`h-full rounded-lg shadow-lg overflow-hidden ${textColorClass} flex flex-col justify-center items-center px-2 py-4 cursor-pointer transform hover:scale-105 transition duration-300 ease-in-out m-2`}>
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-2">{countno}</h1>
          <h2 className="text-[1rem] font-semibold">{title}</h2>
        </div>
        <div className="mt-4 text-2xl">{icon}</div>
      </div>
    </div>
  );
}

export default VenDash;

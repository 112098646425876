import React, { useState } from 'react';

const DateTimePicker = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showPicker, setShowPicker] = useState(false);
    const [selectedTime, setSelectedTime] = useState({
        hours: selectedDate.getHours(),
        minutes: selectedDate.getMinutes(),
        period: selectedDate.getHours() < 12 ? 'AM' : 'PM'
    });

    const togglePicker = () => {
        setShowPicker(!showPicker);
    };

    const updateDateTime = (date) => {
        setSelectedDate(date);
        setSelectedTime({
            hours: date.getHours(),
            minutes: date.getMinutes(),
            period: date.getHours() < 12 ? 'AM' : 'PM'
        });
        setShowPicker(false);
    };

    const daysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const generateDaysGrid = () => {
        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth();
        const firstDayOfWeek = new Date(year, month, 1).getDay();
        const days = daysInMonth(year, month);

        let daysArray = [];
        // Create empty cells for preceding days
        for (let i = 0; i < firstDayOfWeek; i++) {
            daysArray.push(<div key={`empty-${i}`} className="empty-cell"></div>);
        }
        // Create day cells
        for (let day = 1; day <= days; day++) {
            daysArray.push(
                <div
                    key={`day-${day}`}
                    className={`day ${selectedDate.getDate() === day && selectedDate.getMonth() === month ? 'selected' : ''}`}
                    onClick={() => updateDateTime(new Date(year, month, day))}
                >
                    {day}
                </div>
            );
        }
        return daysArray;
    };

    const handleTimeChange = (event) => {
        const { name, value } = event.target;
        setSelectedTime(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handlePeriodChange = () => {
        setSelectedTime(prevState => ({
            ...prevState,
            period: prevState.period === 'AM' ? 'PM' : 'AM'
        }));
    };

    const formatTime = () => {
        let { hours, minutes, period } = selectedTime;
        hours = hours % 12;
        hours = hours ? hours : 12; // Handle midnight (0 hours)
        minutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero for minutes < 10
        return `${hours}:${minutes} ${period}`;
    };

    return (
        <div className="container mx-auto p-8 bg-white rounded-lg shadow-lg">
            <h1 className="text-2xl font-bold mb-4">Custom Date & Time Picker</h1>
            <div className="relative">
                <input
                    type="text"
                    value={`${selectedDate.toLocaleDateString()} ${formatTime()}`}
                    readOnly
                    placeholder="Select date and time"
                    onClick={togglePicker}
                    className="max-w-sm py-2 px-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500 cursor-pointer"
                />
                <div
                    className={`absolute left-0 mt-1 bg-white border border-gray-300 rounded-lg shadow-lg w-80 ${showPicker ? '' : 'hidden'}`}
                >
                    <div className="p-4">
                        <div className="flex items-center justify-between mb-4">
                            <button className="text-gray-500" onClick={() => setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1, selectedDate.getDate()))}>&lt;</button>
                            <span className="font-semibold">{selectedDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</span>
                            <button className="text-gray-500" onClick={() => setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, selectedDate.getDate()))}>&gt;</button>
                        </div>
                        <div className="grid grid-cols-7 gap-1">
                            <div className="day-header">Sun</div>
                            <div className="day-header">Mon</div>
                            <div className="day-header">Tue</div>
                            <div className="day-header">Wed</div>
                            <div className="day-header">Thu</div>
                            <div className="day-header">Fri</div>
                            <div className="day-header">Sat</div>
                            {generateDaysGrid()}
                        </div>
                    </div>
                    <div className="p-4">
                        <div className="flex items-center justify-between mb-2">
                            <input
                                type="number"
                                name="hours"
                                value={selectedTime.hours}
                                onChange={handleTimeChange}
                                min="1"
                                max="12"
                                className="w-1/3 py-2 px-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
                            />
                            <span className="mx-2">:</span>
                            <input
                                type="number"
                                name="minutes"
                                value={selectedTime.minutes}
                                onChange={handleTimeChange}
                                min="0"
                                max="59"
                                className="w-1/3 py-2 px-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
                            />
                            <button
                                type="button"
                                onClick={handlePeriodChange}
                                className="ml-2 px-3 py-2 bg-blue-500 text-white rounded-lg shadow-sm hover:bg-blue-600 focus:outline-none"
                            >
                                {selectedTime.period}
                            </button>
                        </div>
                        <button
                            onClick={() => updateDateTime(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), selectedTime.hours, selectedTime.minutes))}
                            className="w-full mt-4 px-4 py-2 bg-green-500 text-white rounded-lg shadow-sm hover:bg-green-600 focus:outline-none"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DateTimePicker;

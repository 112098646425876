import React, { Fragment, useState } from 'react';
import AdminSidebar from '../../components/admin/AdminSidebar';
import AdminNavbar from '../../components/admin/AdminNavbar';
import Table from '../../components/admin/Table';

function Help() {
    const [sidebarToggle, setSidebarToggle] = useState(false); // Sidebar toggle state

    const fields = [
        { key: 'Slno', label: 'Serial Number' },
        { key: 'BDname', label: 'BD Name' },
        { key: 'BDPhone', label: 'BD Phone' },
        { key: 'GeoLocation', label: 'Geo Location' },
    ];

    const tableData = [
        {
            id: 1,
            Slno: 1,
            BDname: "RAHUL KUMAR",
            BDPhone: "+91 98765 43210",
            GeoLocation: "MUMBAI",
        },
        {
            id: 2,
            Slno: 2,
            BDname: "PRIYA SHARMA",
            BDPhone: "+91 87654 32109",
            GeoLocation: "DELHI",
        },
        {
            id: 3,
            Slno: 3,
            BDname: "AMIT PATEL",
            BDPhone: "+91 76543 21098",
            GeoLocation: "BANGALORE",
        },
        {
            id: 4,
            Slno: 4,
            BDname: "SNEHA GUPTA",
            BDPhone: "+91 65432 10987",
            GeoLocation: "KOLKATA",
        },
    ];

    return (
        <Fragment>
            <AdminSidebar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
            />
            <div className={`flex flex-col ${sidebarToggle ? "ml-0" : "ml-48"}`}>
                <AdminNavbar
                    sidebarToggle={sidebarToggle}
                    setSidebarToggle={setSidebarToggle}
                />
                <main className="bg-gray-100 p-2">
                    <div className="max-w-full mx-auto">
                        <div className="bg-white rounded-lg shadow-lg p-4 my-2">
                            <div className="flex flex-col sm:flex-row justify-between">
                                <div className="text-gray-800">
                                <h1 className="text-xl font-semibold text-gray-800">Help & Support</h1>
                                    <span className="text-lg">1234567890</span>
                                </div>
                                <div className="mt-2 sm:mt-0 text-gray-800">
                                    <p className="font-semibold">Email Support</p>
                                    <span>support@example.com</span>
                                </div>
                            </div>
                        </div>
                        <div className="bg-zinc-100 rounded-lg shadow-lg p-4">
                            <h2 className="text-lg font-semibold text-gray-800 mb-4">Contact List</h2>
                            <div className="overflow-x-auto text-center text-sm">
                                <Table data={tableData} fields={fields} />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </Fragment>
    );
}

export default Help;

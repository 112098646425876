import React, { Fragment, useState } from "react";
import SummaryCard from "../../../components/admin/SummaryCard";
import PieChart from "../../../components/admin/PieChart";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";

const FleetPerformance = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);

  const pickupSummaryData = [
    { label: 'On Time', value: 0 },
    { label: 'Late', value: 0 },
    { label: 'Early', value: 100 },
  ];

  const dropSummaryData = [
    { label: 'On Time', value: 43 },
    { label: 'Late', value: 29 },
    { label: 'Early', value: 29 },
  ];

  const extraTravelData = {
    labels: ['Less than 10Kms', 'Between 10-50 Kms', 'Between 51-100 Kms', 'More than 100 Kms'],
    datasets: [
      {
        data: [300, 50, 100, 40],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF6384'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF6384'],
      },
    ],
  };

  const driverAppUsageData = [
    { label: 'App used', value: 100 },
    { label: 'App not used', value: 0 },
    { label: 'Marked reached pickup', value: 100 },
    { label: 'Started trip', value: 100 },
    { label: 'Ended trip', value: 100 },
  ];

  return (
    <Fragment>
      <div className="flex">
        <AdminSidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
        <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
          <AdminNavbar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
          <div className="bg-zinc-200 min-h-screen p-4">
            <div className="bg-white shadow-lg p-4 rounded-lg mb-6">
              <div className="flex justify-between items-center mb-2">
                <h1 className="text-xl font-semibold text-gray-600">Fleet Performance</h1>
                <span>{new Date().toLocaleDateString()}</span>
              </div>
              <hr />
              <div className="flex flex-col md:flex-row items-center justify-between my-4">
                <div className="mb-4 md:mb-0">
                  <label className="block font-bold mb-2">Quick Filter</label>
                  <select className="border rounded p-2 w-full md:w-auto">
                    <option>This Week</option>
                    <option>Last Week</option>
                    <option>This Month</option>
                    <option>Last Month</option>
                  </select>
                </div>
                <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
                  <div>
                    <label className="block font-bold mb-2">From Date</label>
                    <input type="date" className="border rounded p-2 w-full md:w-auto" />
                  </div>
                  <div>
                    <label className="block font-bold mb-2 ">To Date</label>
                    <input type="date" className="border rounded p-2 w-full md:w-auto" />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <SummaryCard title="Pickup Summary" data={pickupSummaryData} />
              <SummaryCard title="Drop Summary" data={dropSummaryData} />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <PieChart
                data={extraTravelData}
                options={{ responsive: true, maintainAspectRatio: false }}
              />
              <SummaryCard title="Driver App Usage" data={driverAppUsageData} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FleetPerformance;

import React, { useState, useRef, Fragment } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { BsFillShieldLockFill, BsTelephoneFill } from 'react-icons/bs';
import { CgSpinner } from 'react-icons/cg';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DriverLogin = () => {
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '']);
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [driver, setDriver] = useState(null);
  const otpRefs = useRef([]);
  const navigate = useNavigate();

  // Handle OTP input changes
  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value !== '' && index < otp.length - 1) {
      otpRefs.current[index + 1].focus();
    }
  };

  // Handle key down events
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      otpRefs.current[index - 1].focus();
    }
  };

  // Handle OTP verification
  const handleVerifyOtp = () => {
    setLoading(true);
    const otpString = otp.join('');
    axios
      .post(`${process.env.REACT_APP_API_BASE_URLD}/otp/verifydriver`, null, {
        params: { phoneNumber, otp: otpString },
      })
      .then((response) => {
        const token = response.data.accessToken;
        const decodedToken = jwtDecode(token);
        localStorage.setItem('user', JSON.stringify(decodedToken));
        localStorage.setItem('accessToken', token);
        setDriver(true);
        setLoading(false);
        navigate('/driver/trip'); // Redirect after successful login
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
        toast.failed('OTP Verification Failed. Please try again.');
      });
  };

  // Handle OTP resend
  const handleResendCode = () => {
    setResendLoading(true);
    setTimeout(() => {
      setResendLoading(false);
      toast.success('OTP has been resent');
    }, 1000);
  };

  // Handle "GENERATE OTP" button click with validations
  const handleGenerateOtp = () => {
    const trimmedPhone = phoneNumber.trim();
    const trimmedVehicle = vehicleNumber.trim();

    // Validation: Vehicle Number is required
    if (trimmedVehicle === '') {
      toast.warn('Vehicle Number is required.');
      return;
    }

    // Validation: Vehicle Number length between 10 to 12 characters
    if (trimmedVehicle.length < 10 || trimmedVehicle.length > 12) {
      toast.warn('Vehicle Number must be between 10 to 12 characters.');
      return;
    }

    // Optional: Validate Vehicle Number format using Regex
    const vehicleNumberRegex = /^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/i;
    if (!vehicleNumberRegex.test(trimmedVehicle)) {
      toast.warn('Invalid Vehicle Number format.');
      return;
    }

    // Validation: Mobile Number is required
    if (trimmedPhone === '') {
      toast.success('Mobile Number is required.');
      return;
    }

    // Validation: Mobile Number must be exactly 10 digits
    if (!/^\d{10}$/.test(trimmedPhone)) {
      toast.warn('Mobile number must be exactly 10 digits.');
      return;
    }

    // Validation: Mobile Number should start with 6, 7, 8, or 9
    const firstDigit = trimmedPhone.charAt(0);
    if (!['6', '7', '8', '9'].includes(firstDigit)) {
      toast.warn('Mobile number should start with 6, 7, 8, or 9.');
      return;
    }

    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_BASE_URLD}/otp/generate`, null, {
        params: { phoneNumber: trimmedPhone },
      })
      .then((response) => {
        toast.success(response.data);
        setShowOTP(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
        toast.warn('Failed to generate OTP. Please try again.');
      });
  };

  return (
    <Fragment>
      <ToastContainer />
      <div className="min-h-screen flex items-center justify-center bg-fixblack">
        <div className="bg-white shadow-lg p-8 rounded-lg w-full max-w-md">
          {driver && <Navigate to="/driver/trip" />}
          {!driver && (
            <div className="flex flex-col gap-3 rounded-lg">
              {showOTP ? (
                <>
                  <div className="bg-white text-fixblue w-fit mx-auto p-3 rounded-full">
                    <BsFillShieldLockFill size={20} />
                  </div>
                  <label htmlFor="otp" className="font-bold text-fixblue text-xl text-center">
                    Enter Your OTP
                  </label>
                  <div className="flex justify-center space-x-2">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleOtpChange(e.target.value, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (otpRefs.current[index] = el)}
                        className="w-10 h-10 text-center border border-gray-300 rounded focus:outline-none focus:border-fixblue"
                      />
                    ))}
                  </div>
                  <button
                    className="bg-fixblue hover:bg-fixgold flex text-[1rem] items-center justify-center py-2 text-white rounded w-full"
                    onClick={handleVerifyOtp}
                  >
                    {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
                    <span>Verify OTP</span>
                  </button>
                  <button
                    className="text-fixblue text-[1rem] font-extrabold text-center w-full"
                    onClick={handleResendCode}
                    disabled={resendLoading}
                  >
                    {resendLoading ? 'Resending...' : 'Resend OTP'}
                  </button>
                </>
              ) : (
                <>
                  <div className="bg-white text-fixblue w-fit mx-auto p-4 rounded-full">
                    <BsTelephoneFill size={20} />
                  </div>
                  <label htmlFor="phone" className="font-bold text-fixblack text-[1rem] text-center">
                    Enter Your Vehicle & Mobile
                  </label>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="vehicle-number">
                      Vehicle Number
                    </label>
                    <input
                      id="vehicle-number"
                      type="text"
                      placeholder="Enter your registered vehicle number"
                      value={vehicleNumber}
                      onChange={(e) => setVehicleNumber(e.target.value.toUpperCase())} // Convert input to uppercase
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-fixblue focus:border-fixblue sm:text-sm"
                    />
                  </div>
                  <div className="flex justify-center w-full">
                    <div className="flex items-center w-full">
                      <span className="bg-gray-200 border border-gray-300 rounded-l p-4">
                        +91
                      </span>
                      <input
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => {
                          // Allow only digits
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            setPhoneNumber(value);
                          }
                        }}
                        maxLength="10"
                        className="border border-gray-300 rounded-r px-10 py-4 focus:outline-none w-full"
                        placeholder="Phone number"
                      />
                    </div>
                  </div>
                  <button
                    className="bg-fixblue hover:bg-fixgold flex items-center justify-center py-2 text-white rounded w-full"
                    onClick={handleGenerateOtp}
                  >
                    {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
                    <span className="text-[1rem]">Generate OTP</span>
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default DriverLogin;

import React, { Fragment, useState, useEffect } from "react";
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Create an Axios instance with the base URL and default headers
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URLA,
    headers: {
        'ngrok-skip-browser-warning': 'true',
    }
});

function RegisterAll() {

    const [access, setAccess] = useState(false);
    useEffect(() => {
        // Retrieve user data and token from local storage
        const accessToken = localStorage.getItem("accessToken");
        const userData = JSON.parse(localStorage.getItem("user"));

        // const {verifycd, active} =userData
        //verifycd active
        if (accessToken && userData) {
            if (userData.role !== 'ROLE_ADMIN') {
                if (userData.verifycd === false && userData.active === false) {
                    toast.error("User Blocked, Please contact FixRyde customer care!");
                } else {
                    toast.error(`You Are Registered ${userData.role}, Please Contact FixRyde ADMIN for Account Creation!`);
                }
                setAccess(false);
            } else {
                setAccess(true);
            }
        }
    }, []);


    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        role: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleRegister = () => {
        console.log(JSON.stringify(formData));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic client-side validation for email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            toast.error("Please enter a valid email address!");
            return;
        }

        // Basic client-side validation for password length and matching
        if (formData.password.length < 6) {
            toast.error("Password must be at least 6 characters long!");
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            toast.error("Passwords do not match!");
            return;
        }

        // Determine the correct endpoint based on the role
        let endpoint = '';
        switch (formData.role) {
            case 'admin':
                endpoint = '/authentication/adminauth/adminreg';
                break;
            case 'vendor':
                endpoint = '/authentication/adminauth/vendorreg';
                break;
            case 'employee':
                endpoint = '/authentication/adminauth/employeereg';
                break;
            default:
                alert('Please select a valid role');
                return;
        }

        try {
            console.log('IntoPost:');
            const response = await api.post(endpoint, {
                email: formData.email,
                password: formData.password
            });

            console.log('Registration successful:', response.data);
            toast.success(response.data.message);
        } catch (error) {
            console.error('Registration error:', error.response ? error.response.data : error.message);
            toast.error(`Registration failed: ${error.response ? error.response.data.message : error.message}`);
        }
    };

    return (
        <Fragment>
            <ToastContainer />
            <div className="min-h-screen flex items-center justify-center bg-fixblack">
                <div className="max-w-sm w-full bg-white py-4 px-6 rounded-lg shadow-lg m-2">
                    <div className="text-center">
                        <img
                            className="object-cover max-w-xs max-h-40 mx-auto"
                            src="/images/Register.png"
                            alt="People"
                        />
                    </div>
                    <form className="mt-2 space-y-4" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                                Select Role
                            </label>
                            <select
                                id="role"
                                name="role"
                                value={formData.role}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
                                required
                            >
                                <option value="">Select your role</option>
                                <option value="admin">Admin</option>
                                <option value="vendor">Vendor</option>
                                <option value="employee">Employee</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email Address
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
                                placeholder="Enter your email address"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
                                placeholder="Enter your password"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                                Confirm Password
                            </label>
                            <input
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
                                placeholder="Confirm your password"
                                required
                            />
                        </div>

                        <div>
                            <button
                                type="submit"
                                onClick={handleRegister}
                                className="w-full py-3 bg-blue-500 text-white font-semibold rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                            >
                                Register
                            </button>
                        </div>
                        <div className="text-center text-sm mt-4">
                            <span className="text-gray-600">Already have an account?</span>
                            <button
                                type="button"
                                className="text-blue-500 hover:text-blue-700 focus:outline-none"
                            >
                                Login here
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
}

export default RegisterAll;

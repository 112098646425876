// Toast.js

import React, { useEffect } from 'react';
import classNames from 'classnames';

const Toast = ({ message, onClose }) => {
  // Close the toast automatically after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  return (
    <div className="fixed top-0 right-0 m-4 p-4 bg-gray-800 text-white rounded-lg shadow-lg">
      <div className="flex items-center">
        <div className="font-bold">{message}</div>
        <button
          className="ml-auto text-white hover:text-gray-300"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default Toast;

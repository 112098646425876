import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import Tabs from "../../../components/admin/Tabs";
import TripCard from "../../../components/admin/TripCard";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AllTrip = () => {

  const [sidebarToggle, setSidebarToggle] = useState(false);

  // Helper function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  // State variables
  const [selectedOption, setSelectedOption] = useState("today");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [tripData, setTripData] = useState({ 0: [], 1: [], 2: [], 3: [], 4: [] });
  const [allTrips, setAllTrips] = useState([]); // Stores all fetched trips
  const [fromDate, setFromDate] = useState(getTodayDate()); // Default to today
  const [toDate, setToDate] = useState(getTodayDate()); // Default to today
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // New state variables for vendor user IDs
  const [vendorUserIds, setVendorUserIds] = useState([]); // List of vendor user IDs
  const [selectedVendorUserId, setSelectedVendorUserId] = useState(""); // Currently selected vendor user ID

  // Retrieve user data from localStorage
  const userData = JSON.parse(localStorage.getItem("user"));
  // Initialize selectedVendorUserId with userData.sub if available
  useEffect(() => {
    if (userData?.sub) {
      // setSelectedVendorUserId(userData.sub);
    }
  }, [userData]);

  // Function to fetch the list of vendor user IDs from the backend
  const fetchVendorUserIds = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URLB}/drivervehicle/vendors/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );
      const vendors = response.data;
      console.log("Vendor List:", vendors);
      setVendorUserIds(vendors.map((vendor) => vendor.vendorUserid));
      // If no vendor is selected yet, set to the first vendor in the list
      if (!selectedVendorUserId && vendors.length > 0) {
        setSelectedVendorUserId(vendors[0].vendorUserid);
      }
    } catch (error) {
      console.error("Error fetching vendor user IDs:", error);
      toast.error("Failed to fetch vendor user IDs");
    }
  };

  // Fetch vendor user IDs on component mount
  useEffect(() => {
    fetchVendorUserIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to fetch all trip data based on the selected vendor user ID
  const fetchTrips = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URLB}/bookings/vendorbookings`,
        {
          params: { vendorUserid: selectedVendorUserId },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );
      const trips = response.data;
      console.log("VendorBookings:AllTrips.js:", trips);

      setAllTrips(trips); // Store all trips for filtering
      applyDateFilter(trips, fromDate, toDate); // Apply initial date filter

      setLoading(false);
    } catch (error) {
      console.error("Error fetching trips:", error);
      toast.error("Failed to fetch trip data");
      setLoading(false);
    }
  };

  // Fetch trips whenever the selectedVendorUserId changes
  useEffect(() => {
    if (selectedVendorUserId) {
      setLoading(true);
      fetchTrips();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVendorUserId]);

  // Function to apply date filter and categorize trips
  const applyDateFilter = (tripsToFilter, from, to) => {
    // Filter trips based on bookingDate
    const filteredTrips = tripsToFilter.filter((trip) => {
      if (!trip.booking || !trip.booking.bookingDate) return false;
      const bookingDate = trip.booking.bookingDate;
      return bookingDate >= from && bookingDate <= to;
    });

    // Categorize filtered trips
    const upcomingTrips = [];
    const ongoingTrips = [];
    const pastTrips = [];
    const pendTrips = [];
    const cancelledTrips = [];

    filteredTrips.forEach((trip) => {
      const tripDetails = trip.tripDetails;
      
      // Filter out trips where tripDetails is empty or null
      if (!tripDetails || Object.keys(tripDetails).length === 0) {
        // Skip this trip if tripDetails is empty or missing
        return;
      }

      const status = tripDetails.tripStatus; // Get the status from tripDetails
      console.log("AllTrips.js: tripDetails.tripStatus:", status);

      if (status === "Assigned") {
        upcomingTrips.push(trip);
      } else if (status === "TripCompleted") {
        pastTrips.push(trip);
      } else if (
        status.toLowerCase() === "cancelled" ||
        status.toLowerCase() === "canceled"
      ) {
        cancelledTrips.push(trip);
      } else if (status.toLowerCase() === "pending") {
        pendTrips.push(trip);
      } else {
        // Any status not in the list above will be treated as ongoing
        ongoingTrips.push(trip);
      }
    });

    // Update state for each tab
    setTripData({
      0: upcomingTrips,
      1: ongoingTrips,
      2: pastTrips,
      3: cancelledTrips,
      4: pendTrips,
    });
  };

  // Handler for search button click
  const handleSearch = () => {
    // Validate dates if necessary
    if (fromDate > toDate) {
      toast.error("'From Date' cannot be later than 'To Date'.");
      return;
    }
    applyDateFilter(allTrips, fromDate, toDate);
  };

  // Handler for menu toggle
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Handle trip click to navigate to trip detail page
  const handleTripClick = (trip) => {
    navigate("/admin/tripdetails", { state: { bookingData1: trip } });
  };

  // Define tab names
  const tabs = ["Upcoming Trips", "Ongoing Trips", "Past Trips", "Cancelled Trips"];

  return (
    <Fragment>
      <div className="flex">
        <AdminSidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
        <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
          <AdminNavbar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
        <div className="container-fluid mx-auto py-2">
          <div className="bg-white shadow-lg p-4 m-2 rounded-lg">
            {/* Header Section with Date Filters and Vendor Dropdown */}
            <div className="flex flex-col sm:flex-row items-center justify-between">
              <div className="text-gray-800 text-sm mb-2 sm:mb-1">
                <h1 className="text-xl font-extrabold font-sans">All Trip</h1>
              </div>
              {/* Vendor Dropdown, Date Filters and Search Button */}
              <div className="flex flex-col sm:flex-row items-center space-x-2">
                {/* Vendor User ID Dropdown */}
                <div className="flex flex-col mb-2 sm:mb-0">
                  <label htmlFor="vendorUserId" className="mr-1">
                    Vendor User ID:
                  </label>
                  <select
                    id="vendorUserId"
                    value={selectedVendorUserId}
                    onChange={(e) => setSelectedVendorUserId(e.target.value)}
                    className="border rounded p-1"
                  >
                    <option value="">Select Vendor</option>
                    {vendorUserIds.map((vendorId, index) => (
                      <option key={index} value={vendorId}>
                        {vendorId}
                      </option>
                    ))}
                  </select>
                </div>
                {/* Date Filters */}
                <div className="flex items-center space-x-2">
                  <div>
                    <label htmlFor="fromDate" className="mr-1">
                      From:
                    </label>
                    <input
                      type="date"
                      id="fromDate"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                      className="border rounded p-1"
                    />
                  </div>
                  <div>
                    <label htmlFor="toDate" className="mr-1">
                      To:
                    </label>
                    <input
                      type="date"
                      id="toDate"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                      className="border rounded p-1"
                    />
                  </div>
                  <button
                    onClick={handleSearch}
                    className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition-colors"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            {/* Tabs Section */}
            <div className="p-2">
              <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
          </div>

          {/* Loading Indicator */}
          {loading ? (
            <p className="text-center text-gray-700">Loading trips...</p>
          ) : (
            // Trips Display Section
            <div className="m-2 grid gap-2 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {tripData[activeTab]?.length > 0 ? (
                tripData[activeTab].map((trip, index) => (
                  <div
                    key={index}
                    className="max-w-full cursor-pointer hover:shadow-md transition-shadow"
                    onClick={() => handleTripClick(trip)}
                  >
                    <TripCard trip={trip} />
                  </div>
                ))
              ) : (
                <p className="text-center text-gray-600">No trips available for this tab.</p>
              )}
            </div>
          )}
        </div>
      </div>
      </div>
    </Fragment>
  );
};

export default AllTrip;

import React, { Fragment, useState } from "react";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";

function NewMarkVehicle() {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [dayCheckboxes, setDayCheckboxes] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  });

  // Function to handle "All" checkbox click
  const handleAllCheckboxClick = () => {
    const newAllChecked = !allChecked;
    setAllChecked(newAllChecked);

    // Set all day checkboxes to the newAllChecked state
    const updatedCheckboxes = {};
    Object.keys(dayCheckboxes).forEach((day) => {
      updatedCheckboxes[day] = newAllChecked;
    });
    setDayCheckboxes(updatedCheckboxes);
  };

  // Function to handle individual day checkbox click
  const handleDayCheckboxClick = (day) => {
    const updatedCheckboxes = {
      ...dayCheckboxes,
      [day]: !dayCheckboxes[day],
    };
    setDayCheckboxes(updatedCheckboxes);

    // Check if all day checkboxes are checked
    const allChecked = Object.values(updatedCheckboxes).every((value) => value);
    setAllChecked(allChecked);
  };

  return (
    <Fragment>
      <AdminSidebar
        sidebarToggle={sidebarToggle}
        setSidebarToggle={setSidebarToggle}
      />
      <div
        className={`flex-1 transition-all duration-300 ${
          sidebarToggle ? "ml-0" : "ml-48"
        }`}
      >
        <AdminNavbar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
        />
        <div className="bg-zinc-200 min-h-screen p-2">
          <div className="bg-white shadow-lg p-4 rounded-md">
            <div className="flex flex-col sm:flex-row sm:flex-wrap justify-between items-center">
              <div className="text-gray-700">
                <h1 className="text-xs font-semibold sm:text-sm lg:text-xl">
                  New Mark Vehicle Unavailable
                </h1>
                <span>{new Date().toLocaleDateString()}</span>
              </div>
              <div className="flex flex-row xs:flex-col justify-center items-center gap-1">
                <button className="inline-block bg-fixblue text-fixwhite font-bold text-[0.8rem] px-4 py-2 border-l-4 border-fixred sm:mt-2">
                  View
                </button>
                <button className="inline-block bg-fixblue text-fixwhite font-bold text-[0.8rem] px-4 py-2 border-l-4 border-fixred sm:mt-2">
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className="bg-fixwhite my-4 shadow-sm p-6">
            <form>
              <div className="flex flex-wrap items-center justify-center gap-2">
                <div className="flex flex-col w-full md:w-1/3 lg:w-1/5">
                  <label htmlFor="category">Vehicle Category</label>
                  <select
                    id="category"
                    className="px-2 py-1 border border-gray-300 rounded-md w-full"
                  >
                    <option value="all">All</option>
                    <option value="Sedan">Sedan</option>
                    <option value="SUV">SUV</option>
                    <option value="Hatchback">Hatchback</option>
                    <option value="Innova">Innova</option>
                    <option value="Innova Crysta">Innova Crysta</option>
                  </select>
                </div>
                <div className="flex flex-col w-full md:w-1/3 lg:w-1/5">
                  <label htmlFor="vehiclenumber">Vehicle Number</label>
                  <select
                    id="vehiclenumber"
                    className="px-2 py-1 border border-gray-300 rounded-md w-full"
                  >
                    <option value="Adbc123">ABC123</option>
                    <option value="Adbc45c">ABC456</option>
                  </select>
                </div>
                <div className="flex flex-col w-full md:w-1/3 lg:w-1/5">
                  <label htmlFor="fromDate">From Date</label>
                  <input
                    type="date"
                    id="fromDate"
                    className="px-2 py-1 border border-gray-300 rounded-md w-full"
                  />
                </div>
                <div className="flex flex-col w-full md:w-1/3 lg:w-1/5">
                  <label htmlFor="toDate">To Date</label>
                  <input
                    type="date"
                    id="toDate"
                    className="px-2 py-1 border border-gray-300 rounded-md w-full"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="bg-fixwhite my-4 shadow-sm p-4  ">
            <label className="block mb-2 text-fixblack font-bold">
              Select Days :
            </label>
            <div className="flex flex-wrap flex-row xs:flex-col gap-2 items-center justify-center">
              <input
                type="checkbox"
                checked={allChecked}
                onChange={handleAllCheckboxClick}
              />
              All
              {Object.keys(dayCheckboxes).map((day, index) => (
                <Fragment key={index}>
                  <input
                    type="checkbox"
                    checked={dayCheckboxes[day]}
                    onChange={() => handleDayCheckboxClick(day)}
                  />
                  {day}
                </Fragment>
              ))}
            </div>
          </div>
          <div className="bg-fixwhite shadow-sm p-4 my-4">
            <label className="block font-bold ">Select Time:</label>
            <div className="flex flex-row justify-center items-center gap-1">
              <label className="">From Date:</label>
              <input
                type="time"
                className="px-2 py-1 border border-gray-300 rounded-md"
              />

              <label className="">To Date:</label>
              <input
                type="time"
                className="px-2 py-1 border border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default NewMarkVehicle;

import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import { FaArrowRightArrowLeft, FaCableCar, FaCar, FaCircleChevronDown, FaCircleChevronUp, FaTrash } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function TripDetails() {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [updateVendorTrip, setUpdateVendorTrip] = useState(true);
  const [tripStatusOpen, setTripStatusOpen] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVehicleEditing, setIsVehicleEditing] = useState(false);
  const [isDriverEditing, setIsDriverEditing] = useState(false);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [selectedDriverId, setSelectedDriverId] = useState("");
  const [selectedDriverName, setSelectedDriverName] = useState(" ");
  const [selectedDriverMobileNumber, setSelectedDriverMobileNumber] = useState("");

  const [isEditingAllowed, setIsEditingAllowed] = useState(true); // New state to track editing permission

  const [tripDetails, setTripDetails] = useState({}); // Initially an empty object
  const [booking, setBooking] = useState({}); // Initially an empty object

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleTripStatus = () => setTripStatusOpen(!tripStatusOpen);

  const location = useLocation();
  const { bookingData1 } = location.state || {};
  const bookingData = bookingData1?.booking || {};
  const tripData = bookingData1?.tripDetails || {};

  // console.log("TripDetails:Admin:bookingData1:",bookingData1);

  useEffect(() => {
    setBooking(bookingData);
    setTripDetails(tripData);
    checkEditingAllowed(); // Check if editing is allowed on component mount
  }, [bookingData, tripData]);

  const userData = JSON.parse(localStorage.getItem("user"));
  // const vendorUserid = userData?.sub || "vendor123"; // Retrieve userId from localStorage
  const vendorUserid = bookingData1.booking.vendorUserid;
  // console.log("TripDetails:Admin:bookingData1:VendorUserIDDD:",vendorUserid);

  const calculateTripEndingTime = () => {
    if (!booking?.bookingTime || !tripDetails?.tripDuration) return "Unknown End Time";
    const [hours, minutes, seconds] = booking.bookingTime.split(":").map(Number);
    const bookingDate = new Date();
    bookingDate.setHours(hours, minutes, seconds);
    const durationMatch = tripDetails.tripDuration.match(/(\d+)\s*mins/);
    const tripDurationMinutes = durationMatch ? parseInt(durationMatch[1], 10) : 0;
    bookingDate.setMinutes(bookingDate.getMinutes() + tripDurationMinutes);
    return bookingDate.toTimeString().slice(0, 5);
  };

  const vendorId = vendorUserid;
  const vendorPhoneNumber = vendorUserid;

// Check if editing is allowed (15 minutes before booking time)
const checkEditingAllowed = () => {
  if (booking?.bookingDate && booking?.bookingTime) {
    const bookingDateTime = new Date(`${booking.bookingDate}T${booking.bookingTime}`);
    const allowedEditTime = new Date(bookingDateTime.getTime() - 15 * 60 * 1000);
    const currentTime = new Date();

    console.log("allowedEditTime:TripDetails:", allowedEditTime);
    // console.log("currentTime:TripDetails:", currentTime);

    const canEdit = currentTime < allowedEditTime; // Determine if editing is allowed

    if (canEdit) {
      setIsEditingAllowed(true); // Set editing permission
    } else {
      if (isEditingAllowed) { // Only show the toast if we are changing from allowed to not allowed
        toast.error("Editing is not allowed within 15 minutes of the booking time.");
      }
      setIsEditingAllowed(false); // Set editing permission
    }
  }
};

// Call checkEditingAllowed on component mount or whenever booking details change
useEffect(() => {
  checkEditingAllowed();
}, [booking]);


  // Handle the delete button click for Vehicle
  const handleVehicleDeleteClick = () => {
    checkEditingAllowed(); // Ensure latest check
    if (isEditingAllowed) {
      setIsVehicleEditing(true); // Allow editing
      fetchVehicleOptions(); // Fetch vehicle options
    } else {
      toast.error("Editing is not allowed within 15 minutes of the booking time!");
    }
  };

  // Handle the delete button click for Driver
  const handleDriverDeleteClick = () => {
    checkEditingAllowed(); // Ensure latest check
    if (isEditingAllowed) {
      setIsDriverEditing(true); // Allow editing
      fetchDriverOptions(); // Fetch driver options
    } else {
      toast.error("Editing is not allowed within 15 minutes of the booking time!");
    }
  };

  const saveVendorTrip = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URLB}/bookings/vendordriver/updatevendortriptrue`,
        null,
        {
          params: { tripId: tripDetails.tripId },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );
      setTripDetails((prevDetails) => ({
        ...prevDetails,
        tripAssignedByVendor: true,
      }));
      setUpdateVendorTrip(false);
    } catch (error) {
      console.error("Error updating vendor trip approval:", error);
    }
  };

  const fetchVehicleOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URLB}/drivervehicle/vehicle/searchbyvendor/tripvehicle`,
        {
          params: { vendorPhoneNumber },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );
      setVehicleOptions(response.data);
    } catch (error) {
      console.error("Error fetching vehicle options:", error);
    }
  };

  const fetchDriverOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URLB}/drivervehicle/drivers/vendordriver`,
        {
          params: { vendorId },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );
      setDriverOptions(response.data);
    } catch (error) {
      console.error("Error fetching driver options:", error);
    }
  };

  const saveVehicleDetails = async () => {
    const selectedVehicleObj = vehicleOptions.find(
      (vehicle) => vehicle.vehicleNumber === selectedVehicle
    );
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URLB}/bookings/vendorvehicle/updatetotripandbooking`,
        null,
        {
          params: {
            vendorUserid,
            vehicleId: selectedVehicleObj.id,
            vehicleNumber: selectedVehicleObj.vehicleNumber,
            vehicleCategory: selectedVehicleObj.vehicleCategory,
            bookingId: booking.bookId,
            tripId: tripDetails.tripId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );

      setTripDetails((prevDetails) => ({
        ...prevDetails,
        vehicleNumber: selectedVehicleObj.vehicleNumber,
      }));

      setBooking((prevBooking) => ({
        ...prevBooking,
        vehicleType: selectedVehicleObj.vehicleCategory,
      }));
      setIsVehicleEditing(false); // Hide dropdown and show details again
      toast.success("Vehicle updated successfully!");
    } catch (error) {
      console.error("Error saving vehicle details:", error);
      toast.error("Vehicle update failed!");
    }
  };

  const saveDriverDetails = async () => {
    const selectedDriverObj = driverOptions.find((driver) => driver.id === selectedDriverId);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URLB}/bookings/vendordriver/updatetotripdetails`,
        null,
        {
          params: {
            vendorUserid,
            driverId: selectedDriverObj.mobileNumber,
            bookingId: booking.bookId,
            tripId: tripDetails.tripId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );

      setTripDetails((prevDetails) => ({
        ...prevDetails,
        driverId: selectedDriverId,
      }));

      setSelectedDriverName(selectedDriverObj.name); // Store the driver name in the state
      setSelectedDriverMobileNumber(selectedDriverObj.mobileNumber); // Store the driver mobile number in the state

      setIsDriverEditing(false); // Hide dropdown and show details again
      toast.success("Driver updated successfully!");
    } catch (error) {
      console.error("Error saving driver details:", error);
      toast.error("Driver update failed!");
    }
  };

  const Detail = ({ label, value }) => (
    <div className="flex justify-between mb-2">
      <p className="text-gray-600">{label}</p>
      <p className="font-semibold">{value}</p>
    </div>
  );

  return (
    <Fragment>
      <ToastContainer />
      <div className="flex">
        <AdminSidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
        <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
          <AdminNavbar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
      <div className="bg-zinc-300 min-h-screen p-2">
        <div className="bg-white shadow-lg py-2 px-4 rounded-lg">
          <div className="flex flex-col sm:flex-row items-center justify-between">
            <div className="text-gray-800 text-sm mb-2 sm:mb-1">
              <h1 className="text-lg font-bold uppercase">Trip Details : Booking ID : {booking?.bookId || "N/A"}</h1>
            </div>
            <span>{new Date().toLocaleDateString()}</span>
          </div>
        </div>

        {/* Trip Summary */}
        <div className="bg-fixwhite p-2 my-2 sm:p-3">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-1">
            <div className="flex flex-wrap flex-row sm:flex-col justify-center items-center">
              <FaCar className="text-fixblue text-xl sm:text-4xl" />
              <div className="flex flex-col text-sm">
                <h6>{booking?.vehicleType || "Unknown Vehicle"}</h6>
                <div className="flex flex-col sm:flex-row flex-wrap gap-1 items-center mt-2">
                  <h5>Paid: </h5>
                  <span className="bg-fixblack text-fixwhite p-1 rounded">
                    ₹ {booking?.paidAmount || "0.00"}
                  </span>
                  <span className="bg-fixblack text-fixwhite p-1 rounded">
                    Remaining: ₹ {booking?.remainingToBePaid || "0.00"}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-row sm:flex-col items-center">
              <button className="bg-fixblue text-fixwhite p-1 rounded font-bold">
                Contact Support
              </button>
            </div>
          </div>
          <hr className="my-4" />
          <div className="flex justify-around gap-2 my-4 flex-wrap sm:flex-nowrap relative">
            <div className="flex flex-col text-sm">
              <span>{booking?.bookingTime || "Unknown Time"}</span>
              <span>{booking?.bookingDate || "Unknown Date"}</span>
              <address className="flex flex-wrap">
                {booking?.pickupLocation || "Unknown Pickup Location"}
              </address>
            </div>
            <div className="text-fixred text-2xl hidden sm:flex">
              <FaArrowRightArrowLeft />
            </div>
            <div className="flex flex-col text-sm">
              <span>{`${calculateTripEndingTime()}:00`}</span>
              <span>{booking?.bookingDate || "Unknown End Time"}</span>
              <address className="flex flex-wrap">
                {booking?.dropLocation || "Unknown Drop Location"}
              </address>
            </div>
          </div>
        </div>

        {/* Trip Details */}
        <div className="bg-gray-50 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-0 p-2 sm:p-4">
          <div className="lg:col-span-2 h-auto md:min-h-screen p-4 relative">
            <div className="border border-gray-300 rounded-lg p-4 my-2">
              <div className="flex justify-between cursor-pointer" onClick={toggleTripStatus}>
                <h5 className="text-lg font-semibold text-gray-800">Trip Details : Booking ID : {booking?.bookId || "N/A"}</h5>
                {tripStatusOpen ? (
                  <FaCircleChevronUp className="h-6 w-6 text-gray-500" />
                ) : (
                  <FaCircleChevronDown className="h-6 w-6 text-gray-500" />
                )}
              </div>
              {tripStatusOpen && (
                <div>
                  <Detail label="Trip Type" value={booking?.tripType || "Unknown"} />
                  <Detail label="Trip Status" value={tripDetails?.tripStatus || "Unknown"} />
                  <Detail label="Total Distance" value={`${tripDetails?.totalDistanceKm || 0} km`} />
                  <Detail label="Duration" value={`${tripDetails?.tripDuration || 0} `} />
                  <Detail label="Fare" value={`₹ ${tripDetails?.fare || 0}`} />
                </div>
              )}
            </div>

            {/* Booking Details */}
            <div className="bg-gray-100 max-h-screen flex items-center justify-center">
              <div className="bg-white shadow-md rounded-lg overflow-hidden min-w-full">
                <div className="bg-fixblue text-fixwhite text-center py-3">
                  <h2 className="text-lg font-bold">Booking Details</h2>
                </div>
                <div className="p-4">
                  <Detail label="Date of Booking" value={booking?.bookingDate || "Unknown"} />
                  <Detail label="Date of Journey" value={tripDetails?.approxStartTime || "Unknown"} />
                  <Detail label="Vehicle Type" value={booking?.vehicleType || "Unknown"} />
                  <Detail label="Total Fare" value={`₹ ${booking?.fare || 0}`} />
                  <Detail label="Paid Amount" value={`₹ ${booking?.paidAmount || 0}`} />
                  <Detail label="Remaining Amount" value={`₹ ${booking?.remainingToBePaid || 0}`} />
                </div>
              </div>
            </div>

            {/* Customer Details */}
            <div className="bg-gray-100 max-h-screen flex items-center justify-center">
              <div className="bg-white shadow-md rounded-lg overflow-hidden min-w-full">
                <div className="bg-fixblue text-fixwhite text-center py-3">
                  <h2 className="text-lg font-bold">Customer Details</h2>
                </div>
                <div className="p-4">
                  <Detail label="Customer Name:" value={tripDetails?.name || "Unknown"} />
                  <Detail label="Gender:" value={tripDetails?.gender || "Unknown"} />
                  <Detail label="Customer Contact:" value={tripDetails?.customerContact || "Unknown"} />
                  <Detail label="Customer Email:" value={tripDetails?.emailID || "Unknown"} />
                  <Detail label="Driver Notes:" value={booking?.driverNotes || "Unknown"} />
                  <Detail label="Reason For Cancellation:" value={booking?.reason || "N/A"} />
                </div>
              </div>
            </div>

            <div className="text-fixred my-2">
              <h3 className="text-xl font-bold">Cancellation Policy</h3>
              <span className="text-green-600 font-bold">SUPER FLEX !</span>
              <ul className="list-disc list-inside mt-2">
                <li className="ml-2">Free cancellation up till 1hr before start time</li>
                <li className="ml-2">No refund post 1hr before start time</li>
              </ul>
            </div>
            <div className="bg-gray-100 border-2 border-gray-500 flex flex-row sm:flex-col p-4">
              <h4 className="font-bold text-lg">Customer Feedback</h4>
              <p className="text-fixblack">No Review yet..</p>
            </div>
          </div>

          {/* Vehicle Details */}
          <div className="lg:col-span-1 h-auto md:min-h-screen p-4">
            {!tripDetails.tripAssignedByVendor && (
              <button
                className="bg-fixblue px-4 py-2 w-full text-white mb-2 rounded-lg"
                onClick={saveVendorTrip}
              >
                Approve Trip
              </button>
            )}
            
            <div className="bg-white shadow-md rounded-md p-4 relative">
              {isVehicleEditing ? (
                <Fragment>
                  <select
                    className="w-full mb-4"
                    value={selectedVehicle}
                    onChange={(e) => setSelectedVehicle(e.target.value)}
                  >
                    <option value="">Select Vehicle</option>
                    {vehicleOptions.map((vehicle) => (
                      <option key={vehicle.id} value={vehicle.vehicleNumber}>
                        {vehicle.vehicleNumber} - {vehicle.vehicleCategory}
                      </option>
                    ))}
                  </select>
                  <button className="bg-fixblue text-white px-4 py-2 rounded" onClick={saveVehicleDetails}>
                    Save
                  </button>
                </Fragment>
              ) : (
                <Fragment>
                  <button
                    className="absolute top-2 right-2 p-1 text-red-500 hover:text-red-700"
                    onClick={handleVehicleDeleteClick} // Updated to handle time check
                  >
                    <FaTrash className="h-4 w-4" />
                  </button>

                  <p className="text-xl font-bold mb-4">Vehicle Details</p>
                  <div className="flex items-center border-b pb-4 mb-4">
                    <FaCableCar className="text-4xl text-red-500 mr-4" />
                    <div>
                      <h3 className="text-lg font-semibold mb-1">{tripDetails?.vehicleNumber || "Unknown"}</h3>
                      <p className="text-gray-600 text-sm">{booking?.vehicleType || "Unknown Vehicle"}</p>
                    </div>
                  </div>
                  <p className="text-sm text-gray-600 mb-4">
                    This section contains additional details about the driver.
                  </p>
                </Fragment>
              )}
            </div>

            {/* Driver Details */}
            <hr className="my-2" />
            <div className="bg-white shadow-md rounded-md p-4 relative">
              {isDriverEditing ? (
                <Fragment>
                  <select
                    className="w-full mb-4"
                    value={selectedDriverId}
                    onChange={(e) => {
                      const selectedDriverObj = driverOptions.find(
                        (driver) => driver.id === parseInt(e.target.value)
                      );
                      setSelectedDriverId(selectedDriverObj.id);
                      setSelectedDriverName(selectedDriverObj.name);
                    }}
                  >
                    <option value="">Select Driver</option>
                    {driverOptions.map((driver) => (
                      <option key={driver.id} value={driver.id}>
                        {driver.name} - {driver.id}
                      </option>
                    ))}
                  </select>
                  <button className="bg-fixblue text-white px-4 py-2 rounded" onClick={saveDriverDetails}>
                    Save
                  </button>
                </Fragment>
              ) : (
                <Fragment>
                  <button
                    className="absolute top-2 right-2 p-1 text-red-500 hover:text-red-700"
                    onClick={handleDriverDeleteClick} // Updated to handle time check
                  >
                    <FaTrash className="h-4 w-4" />
                  </button>

                  <p className="text-xl font-bold mb-4">Driver Details</p>
                  <div className="flex items-center border-b pb-4 mb-4">
                    <FaCableCar className="text-4xl text-red-500 mr-4" />
                    <div>
                      <h3 className="text-lg font-semibold mb-1">
                        {selectedDriverName
                          ? `${selectedDriverName} - ${tripDetails?.driverId}`
                          : "Unknown Driver"}
                      </h3>
                      <h3 className="text-lg font-semibold mb-1">
                        {selectedDriverName
                          ? `${selectedDriverMobileNumber}`
                          : "Unknown Driver"}
                      </h3>
                    </div>
                  </div>
                  <p className="text-sm text-gray-600 mb-4">
                    This section contains additional details about the driver.
                  </p>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </Fragment>
  );
}

export default TripDetails;

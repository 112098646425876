import React, { useState } from "react";
import { FaBars, FaBell, FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { removeUserData } from "../../route/Storage";

function AdminNavbar({ sidebarToggle, setSidebarToggle }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const usernameData = JSON.parse(localStorage.getItem("user"));
  const username = usernameData.sub

  const handleLogout = () => {
    // const userRole = localStorage.getItem("roles");
    const userData = JSON.parse(localStorage.getItem("user"));
    removeUserData();
    navigate(userData.role === "ROLE_ADMIN" ? "/admin/login" : "/vendor/login");
  };

  const handleProfile = () => {
    console.log("Profile clicked");
    // Implement navigation logic for profile
  };

  const handleSettings = () => {
    console.log("Settings clicked");
    // Implement navigation logic for settings
  };

  return (
    <nav className="bg-gray-800 px-4 py-3 flex justify-between items-center">
      <div className="flex items-center text-xl">
        <FaBars
          className="text-white me-2 cursor-pointer"
          onClick={() => setSidebarToggle(!sidebarToggle)}
        />
        <span className="text-white font-mono font-bold hidden sm:block">FIXRYDE</span>
      </div>
      <div className="flex items-center gap-x-5">
        <div className="text-white cursor-pointer">
          <FaBell className="w-6 h-6" />
        </div>

        <span className="text-white">Welcome.. :{username}</span>

        <div className="relative" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <FaUserCircle className="w-6 h-6 text-white cursor-pointer" />
          {isDropdownOpen && (
            <div className="absolute top-full right-0 bg-white rounded-lg shadow p-2 mt-2 hover:bg-green-300 transition duration-300 cursor-pointer">
              <ul className="py-2 text-md text-red-500 hover:text-red-900 transition duration-300 cursor-pointer">
                {/* <li onClick={handleProfile}>Profile</li> */}
                {/* <li onClick={handleSettings}>Settings</li> */}
                <li onClick={handleLogout}>Logout</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default AdminNavbar;

// src/components/site/core/OutstationDrive.jsx

import React from "react";
import AutocompleteInput from "../../common/AutocompleteInput";
import { getMinMaxDates, formatDate } from "../../../utils/tripUtils";

const OutstationDrive = ({
  tripType,
  setTripType,
  sourceInput,
  setSourceInput,
  destinationInput,
  setDestinationInput,
  date,
  setDate,
  time,
  setTime,
  availableTimes,
  redate,
  setRedate,
  retime,
  setRetime,
  handleSearch,
  distance, // Added distance as a prop
}) => {
  const options = {
    componentRestrictions: { country: "in" },
  };

  const { minDateString, maxDateString } = getMinMaxDates();

  // Calculate min and max for return date based on distance
  let minReturnDate = formatDate(new Date(date));
  if (distance) {
    const distanceKm = parseFloat(distance.replace(" km", ""));
    if (distanceKm > 320) {
      const datePlusOne = new Date(date);
      datePlusOne.setDate(datePlusOne.getDate() + 1);
      minReturnDate = formatDate(datePlusOne);
    }
  }
  // Max return date is date +7
  const dateObj = new Date(date);
  const maxReturnDateObj = new Date(dateObj);
  maxReturnDateObj.setDate(maxReturnDateObj.getDate() + 7);
  const maxReturnDate = formatDate(maxReturnDateObj);

  return (
    <div className="container mx-auto">
      <div className="my-2 bg-white rounded-md shadow-lg p-4 grid grid-cols-1 lg:grid-cols-4 gap-2">
        <div className="lg:col-span-1">
          <label htmlFor="TripType" className="block text-sm text-gray-700 font-bold mb-2">
            Trip Type
          </label>
          <select
            id="TripType"
            className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
            value={tripType}
            onChange={(e) => setTripType(e.target.value)}
          >
            <option value="OnewayTrip">One Way</option>
            <option value="RoundTrip">Round Trip</option>
          </select>
        </div>

        <AutocompleteInput
          id="source"
          label="Source"
          value={sourceInput}
          onChange={setSourceInput}
          placeholder="Enter source"
          autocompleteOptions={options}
          onPlaceChanged={setSourceInput}
        />

        <AutocompleteInput
          id="destination"
          label="Destination"
          value={destinationInput}
          onChange={setDestinationInput}
          placeholder="Enter destination"
          autocompleteOptions={options}
          onPlaceChanged={setDestinationInput}
        />

        <div className="lg:col-span-1">
          <label htmlFor="Date" className="block text-sm text-gray-700 font-bold mb-2">
            Date
          </label>
          <input
            id="date"
            type="date"
            className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            min={minDateString}
            max={maxDateString}
            required
          />
        </div>

        <div className="lg:col-span-1">
          <label htmlFor="Time" className="block text-sm text-gray-700 font-bold mb-2">
            Time
          </label>
          <select
            id="time"
            className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            required
          >
            {availableTimes.map((slot, index) => (
              <option key={index} value={slot.time} disabled={!slot.isEnabled}>
                {slot.time}
              </option>
            ))}
          </select>
        </div>

        {tripType === "RoundTrip" && (
          <>
            <div className="lg:col-span-1">
              <label htmlFor="ReturnDate" className="block text-sm text-gray-700 font-bold mb-2">
                Return Date
              </label>
              <input
                id="returnDate"
                type="date"
                className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
                value={redate || ''} // Prevent null value
                onChange={(e) => setRedate(e.target.value)}
                min={minReturnDate} // Updated min date based on distance
                max={maxReturnDate} // Updated max date to allow up to 7 days
                required
              />
            </div>

            <div className="lg:col-span-1">
              <label htmlFor="ReturnTime" className="block text-sm text-gray-700 font-bold mb-2">
                Return Time
              </label>
              <select
                id="returnTime"
                className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
                value={retime || ''} // Prevent null value
                onChange={(e) => setRetime(e.target.value)}
                required
              >
                {availableTimes.map((slot, index) => (
                  <option key={index} value={slot.time} disabled={!slot.isEnabled}>
                    {slot.time}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}
      </div>

      <div className="flex justify-center">
        <button
          onClick={handleSearch}
          className="px-20 bg-fixblue border-2 border-white hover:bg-yellow-500 text-white font-bold py-3 rounded-full"
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default OutstationDrive;

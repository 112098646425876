import React, { Fragment, useState } from "react";
import ProcessCard from "../../../components/admin/ProcessCard";
import AdminLayout from "../../../components/admin/AdminLayout";

const FleetPanel = () => {
 
  // Dummy data for Drivers and Vehicles
  const driverData = [
    { titleData: "total", headingData: "40", spanData: "Drivers", color: "teal" },
    { titleData: "available", headingData: "44", spanData: "Drivers", color: "lime" },
    { titleData: "reg in progress", headingData: "06", spanData: "Drivers", color: "yellow" },
    { titleData: "checkup pending", headingData: "05", spanData: "Drivers", color: "red" },
    { titleData: "on trip", headingData: "04", spanData: "Drivers", color: "green" },
    { titleData: "logged out", headingData: "06", spanData: "Drivers", color: "orange" },
    // Add more driver data as needed
  ];

  const vehicleData = [
    { titleData: "total", headingData: "47", spanData: "Vehicles", color: "teal" },
    { titleData: "un assigned", headingData: "23", spanData: "Vehicles", color: "yellow" },
    { titleData: "reg in progress", headingData: "23", spanData: "Vehicles", color: "lime" },
    { titleData: "checkup pending", headingData: "23", spanData: "Vehicles", color: "pink" },
    { titleData: "on trip", headingData: "23", spanData: "Vehicles", color: "green" },
    { titleData: "logged out", headingData: "23", spanData: "Vehicles", color: "orange" },
    { titleData: "blocked for trip", headingData: "23", spanData: "Vehicles", color: "gray" },
    { titleData: "marked un available", headingData: "23", spanData: "Vehicles", color: "red" },
    { titleData: "driver login pending", headingData: "23", spanData: "Vehicles", color: "yellow" },
    // Add more vehicle data as needed
  ];

  return (
    <Fragment>
       <AdminLayout>
       <div className="bg-zinc-200 min-h-screen p-4">
          <div className="bg-white shadow-lg p-4 rounded-lg mb-6">
            <div className="flex justify-between items-center">
              <h1 className="text-xl font-semibold text-gray-600">Fleet Panel</h1>
              <span>{new Date().toLocaleDateString()}</span>
            </div>
          </div>
            <div className="px-4 py-6">
              <div className="flex flex-wrap justify-center lg:justify-start">
                <div className="m-4">
                  <h2 className="font-bold text-2xl">Drivers</h2>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                {driverData.map((data, index) => (
                  <ProcessCard
                    key={index}
                    color={data.color}
                    titleData={data.titleData}
                    headingData={data.headingData}
                    spanData={data.spanData}
                  />
                ))}
              </div>
              <hr className="my-8 border-b-2 border-black" />
              <div className="flex flex-wrap justify-center lg:justify-start">
                <div className="m-4">
                  <h2 className="font-bold text-2xl">Vehicles</h2>
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                {vehicleData.map((data, index) => (
                  <ProcessCard
                    key={index}
                    color={data.color}
                    titleData={data.titleData}
                    headingData={data.headingData}
                    spanData={data.spanData}
                  />
                ))}
              </div>
            </div>
          </div>
       </AdminLayout>
          
    </Fragment>
  );
};

export default FleetPanel;

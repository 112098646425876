import React, { Fragment, useState, useRef, useEffect } from "react";
import Navbar from "../../components/site/Navbar";
import Footer from "../../components/site/Footer";
import { Link, Navigate } from "react-router-dom";
import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import { FcGoogle } from "react-icons/fc";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UserLogin() {
  const [redirectToProfile, setRedirectToProfile] = useState(false);
  const [error, setError] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [ph, setPh] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [resendLoading, setResendLoading] = useState(false);

  const otpRefs = useRef([]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const userData = JSON.parse(localStorage.getItem("user"));

    if (accessToken && userData) {
      if (
        userData.role === "ROLE_DRIVER" &&
        userData.verifycd === false &&
        userData.active === false
      ) {
        toast.error("User Blocked, Please contact FixRyde customer care!");
        setRedirectToProfile(false);
        setLoading(false);
      } else {
        setUser(true);
        setLoading(false);
        setRedirectToProfile(true);
        toast.success("Login Successful");
      }
    }
  }, []);

  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < otp.length - 1) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      otpRefs.current[index - 1].focus();
    }
  };

  const validatePhoneNumber = (phone) => {
    const trimmedPhone = phone.trim();

    if (trimmedPhone === "") {
      toast.warn("Mobile Number is required.");
      return false;
    }

    if (!/^\d{10}$/.test(trimmedPhone)) {
      toast.warn("Mobile number must be exactly 10 digits.");
      return false;
    }

    const firstDigit = trimmedPhone.charAt(0);
    if (!["6", "7", "8", "9"].includes(firstDigit)) {
      toast.warn("Mobile number should start with 6, 7, 8, or 9.");
      return false;
    }

    return true;
  };

  const handleVerifyOtp = () => {
    if (!validatePhoneNumber(ph)) {
      return;
    }

    if (otp.some((digit) => digit === "")) {
      toast.warn("Please enter all OTP digits.");
      return;
    }

    setLoading(true);
    const otpString = otp.join("");
    axios
      .post(`${process.env.REACT_APP_API_BASE_URLD}/otp/verifyuser`, null, {
        params: { phoneNumber: ph.trim(), otp: otpString },
      })
      .then((response) => {
        const token = response.data.accessToken;
        const decodedToken = jwtDecode(token);
        console.log("Decoded Token:", decodedToken);

        localStorage.setItem("user", JSON.stringify(decodedToken));
        localStorage.setItem("accessToken", token);

        if (decodedToken.role === "ROLE_DRIVER") {
          toast.error(
            "You Are Registered as Driver, Please use FixRyde Driver App to login"
          );
          setRedirectToProfile(false);
          setLoading(false);
        } else {
          setUser(true);
          setLoading(false);
          setRedirectToProfile(true);
          toast.success("Login Successful");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        toast.error("OTP Verification Failed. Please try again.");
      });
  };

  const handleResendCode = () => {
    if (!validatePhoneNumber(ph)) {
      return;
    }

    setResendLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_BASE_URLD}/otp/generate`, null, {
        params: { phoneNumber: ph.trim() },
      })
      .then((response) => {
        toast.success("OTP has been resent.");
        setResendLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setResendLoading(false);
        toast.error("Failed to resend OTP. Please try again.");
      });
  };

  const handleSendCode = () => {
    if (!validatePhoneNumber(ph)) {
      return;
    }

    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_BASE_URLD}/otp/generate`, null, {
        params: { phoneNumber: ph.trim() },
      })
      .then((response) => {
        toast.success("OTP has been sent to your mobile number.");
        toast.success("OTP IS:" + response.data);
        console.log("OTP IS:" + response.data);
        setShowOTP(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        toast.error("Failed to send OTP. Please try again.");
      });
  };

  const handleKeyDownVerify = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleVerifyOtp();
    }
  };

  useEffect(() => {
    if (showOTP) {
      otpRefs.current[0]?.focus();
    }
  }, [showOTP]);

  return (
    <Fragment>
      <ToastContainer />
      <Navbar />
      <div className="flex flex-col items-center justify-center bg-customer bg-right-bottom bg-cover bg-no-repeat p-10">
        <div className="w-full max-w-sm border-fixwhite border-2">
          <div className="bg-fixblack bg-opacity-50 p-6 rounded-lg">
            <div className="flex items-center justify-center pt-2">
              <Link to={"/"}>
                <button className="bg-fixwhite hover:bg-blue-600 text-fixblack font-bold py-2 px-4 rounded-md flex items-center">
                  <FcGoogle size={20} className="mr-2" />
                  <span>Sign in with Google</span>
                </button>
              </Link>
            </div>

            <hr className="my-4 border-gray-300" />

            <div className="flex flex-col items-center justify-center pb-4">
              {redirectToProfile && <Navigate to="/user/dashboard" />}
              {!user && (
                <div className="flex flex-col gap-3 rounded-lg">
                  {showOTP ? (
                    <>
                      <div className="bg-white text-fixblue w-fit mx-auto p-3 rounded-full">
                        <BsFillShieldLockFill size={20} />
                      </div>
                      <label
                        htmlFor="otp"
                        className="font-bold text-fixblue text-xl text-center"
                      >
                        Enter Your OTP
                      </label>
                      <div className="flex justify-center space-x-2">
                        {otp.map((digit, index) => (
                          <input
                            key={index}
                            type="text"
                            maxLength="1"
                            value={digit}
                            onChange={(e) =>
                              handleOtpChange(e.target.value, index)
                            }
                            onKeyDown={(e) => {
                              handleKeyDown(e, index);
                              handleKeyDownVerify(e);
                            }}
                            ref={(el) => (otpRefs.current[index] = el)}
                            className="w-10 h-10 text-center border border-gray-300 rounded focus:outline-none focus:border-fixblue"
                          />
                        ))}
                      </div>
                      <button
                        className="bg-fixblue hover:bg-fixgold flex text-[1rem] items-center justify-center py-2 text-white rounded w-full"
                        onClick={handleVerifyOtp}
                        onKeyDown={handleKeyDownVerify}
                      >
                        {loading && (
                          <CgSpinner size={20} className="mt-1 animate-spin" />
                        )}
                        <span>Verify OTP</span>
                      </button>
                      <button
                        className="text-fixblue text-[1rem] font-extrabold text-center w-full"
                        onClick={handleResendCode}
                        disabled={resendLoading}
                      >
                        {resendLoading ? "Resending..." : "Resend OTP"}
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="bg-white text-fixblue w-fit mx-auto p-4 rounded-full">
                        <BsTelephoneFill size={20} />
                      </div>
                      <label
                        htmlFor="phone"
                        className="font-bold text-fixwhite text-[1rem] text-center"
                      >
                        Enter Your Phone Number
                      </label>
                      <div className="flex justify-center w-full">
                        <div className="flex items-center w-full">
                          <span className="bg-gray-200 border border-gray-300 rounded-l p-4">
                            +91
                          </span>
                          <input
                            type="tel"
                            value={ph}
                            onChange={(e) => {
                              const inputValue = e.target.value
                                .replace(/[^0-9]/g, "")
                                .slice(0, 10);
                              setPh(inputValue);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                handleSendCode();
                              }
                            }}
                            className="border border-gray-300 rounded-r px-10 py-4 focus:outline-none w-full"
                            placeholder="Phone number"
                            maxLength="10"
                            pattern="[6-9]{1}[0-9]{9}"
                            title="Phone number must start with 6, 7, 8, or 9 and contain exactly 10 digits."
                            required
                          />
                        </div>
                      </div>

                      <button
                        className="bg-fixblue hover:bg-fixgold flex items-center justify-center py-2 text-white rounded w-full"
                        onClick={handleSendCode}
                        disabled={loading || ph.length !== 10}
                      >
                        {loading && (
                          <CgSpinner size={20} className="mt-1 animate-spin" />
                        )}
                        <span className="text-[1rem]">Send Code Via SMS</span>
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default UserLogin;

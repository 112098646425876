import React from 'react';
import Home from '../pages/sitePages/Home';
import B2BForm from '../pages/sitePages/B2BForm';
import UserLogin from '../pages/userPages/UserLogin';
import About from '../pages/sitePages/About';
import Terms from '../pages/sitePages/Terms';
import Policy from '../pages/sitePages/Policy';
import Contact from '../pages/sitePages/Contact';
import Blogs from '../pages/sitePages/Blogs';
import BlogDetails from '../pages/sitePages/BlogDetails';
import Page404 from '../pages/sitePages/Page404';
import Operator from '../pages/sitePages/Operator';
// import UserProfile from '../pages/sitePages/UserProfile';
import HowItWorks from '../pages/sitePages/HowItWork';
// import EmployeePanel from '../pages/employeePages/EmployeePanel';
// import EmployeeLogin from '../pages/employeePages/EmployeeLogin';
// import EmployeeRegister from '../pages/employeePages/EmployeeRegister';
import DateTimePicker from '../pages/sitePages/DateTimePicker';
import DemoToast from '../pages/sitePages/DemoToast';
import TripAdd from '../pages/sitePages/TripAdd';
// import EditTripDetails from '../pages/employeePages/EditTripDetails';
// import GoogleMapComponent from '../components/GoogleMapComponent';
//import UserPanel from '../pages/sitePages/UserPanel';
// import DriverLogin from '../pages/driverPages/DriverLogin';
// import DriverProfile from '../pages/driverPages/DriverProfile';
// import TripDashboard from '../pages/driverPages/TripDashboard';
// import PaymentPage from '../pages/userPages/PaymentPage';
// import PaymentOptions from '../pages/userPages/PaymentOptions';
// import TripStage from '../pages/driverPages/TripStage';
import BookingHistory from '../pages/userPages/BookingHistory';
import ConfirmationPage from '../pages/userPages/ConfirmationPage';
// import MyComponent from '../pages/sitePages/MyComponent';

const siteRoutes = [
  { path: '/home', element: <Home /> },
  {path: '/works',element:<HowItWorks />},
  // { path: '/userlogin', element: <UserLogin /> },
  { path: '/b2b', element: <B2BForm /> },
  { path: '/about', element: <About /> },
  { path: '/terms', element: <Terms /> },
  { path: '/policy', element: <Policy /> },
  { path: '/contact', element: <Contact /> },
  { path: '/blogs', element: <Blogs /> },
  { path: '/blogdetails', element: <BlogDetails /> },
  // { path: '/userprofile', element: <UserProfile /> },
  //{ path: '/userpanel', element: <UserPanel /> },
  //{ path: '/history', element: <BookingHistory /> },
  // { path: '/confirm', element: <ConfirmationPage /> },
  // { path: '/userprofile1', element: <MyComponent /> },
  { path: '/operator', element: <Operator /> },
  { path: '/', element: <Home /> }, // Default route
  { path: '*', element: <Page404 /> }, // 404 Not Found route


  // {path:'/payment' ,element:<PaymentPage />},
  // {path:'/payoption' ,element:<PaymentOptions />},


  // {path:'/employee/login' , element:<EmployeeLogin/>},
  // {path:'/employee/dashboard',element:<EmployeePanel/>},
 // {path:'/employee/add',element:<TripAdd/>},
  // {path:'/employee/edit',element:<EditTripDetails/>},
  // {path:'/employee/register' ,element:<EmployeeRegister/>},
  {path:'/date' ,element:<DateTimePicker/>},
  {path:'/toast' ,element:<DemoToast/>},
  // {path:'/map' ,element:<GoogleMapComponent/>}


//driver
  // {path:'/driverlogin' ,element:<DriverLogin/>},
  // {path:'/driverprofile' ,element:<DriverProfile/>},
  // {path:'/drivertrip' ,element:<TripDashboard/>},
  // {path:'/tripstage' ,element:<TripStage />},
];

export default siteRoutes;

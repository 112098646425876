import React, { Fragment, useState } from "react";
import { FaImage, FaRegTimesCircle } from "react-icons/fa";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

// Create an Axios instance with the custom header
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    'ngrok-skip-browser-warning': 'true' // Skip ngrok warning page
  }
});

const ViewVehicle = () => {
  const { driverUsername } = useParams();
  const [searchFromMyDriver, setSearchFromMyDriver] = useState("");
  const [searchFromMyDriverGot, setSearchFromMyDriverGot] = useState("");

  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [vehicleNumber, setVehicleNumber] = useState('');
  const [vehicleCategory, setVehicleCategory] = useState('');
  const [brand, setBrand] = useState('');
  const [modelType, setModelType] = useState('');
  const [fuelType, setFuelType] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [color, setColor] = useState('');
  const [seats, setSeats] = useState('');
  const [ownership, setOwnership] = useState('');
  const [location, setLocation] = useState('');
  const [luggages, setLuggages] = useState('');
  const [stickerNumber, setStickerNumber] = useState('');
  const [chassisNumber, setChassisNumber] = useState('');
  const [engineNumber, setEngineNumber] = useState('');
  const [transmissionType, setTransmissionType] = useState('');
  const [vendorPhoneNumber, setVendorPhoneNumber] = useState('');
  const [driverPhoneNumber, setDriverPhoneNumber] = useState('');
  const [registrationDate, setRegistrationDate] = useState('');
  const [rcValidUptoDate, setRcValidUptoDate] = useState('');
  const [insuranceValidUptoDate, setInsuranceValidUptoDate] = useState('');
  const [vehicleID, setVehicleID] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [approved, setApproved] = useState(false);
  const [isImageAvailable, setIsImageAvailable] = useState(false);

  const vehicleCategories = ["Sedan", "SUV", "Hatchback", "Innova", "Innova Crysta"];
  const brands = ["Toyota", "Honda", "Ford", "Tesla"];
  const fuelTypes = ["Petrol", "Diesel", "Electric", "Hybrid", "CNG", "LPG", "Hydrogen", "Oxygen", "Methene", "Natural GAS"];
  const colors = ["Red", "Blue", "Black", "White", "Silver", "Grey", "Yellow", "Green", "Orange", "Brown", "Beige", "Maroon", "Gold"];
  const transmissionTypes = ["Manual", "Automatic", "CVT", "DCT", "AMT"];
  const seatsOptions = [2, 4, 5, 6, 7, 8];
  const luggagesOptions = [0, 1, 2, 3, 4, 5];

  const brandsWithModels = {
    "Maruti Suzuki": ["Dzire", "Ertiga", "Celerio", "Wagon R", "Any"],
    "Hyundai": ["Aura", "Grand i10 Nios", "Santro", "i10", "Xcent", "Grand i10", "Any"],
    "Honda": ["Amaze", "City", "Any"],
    "Tata Motors": ["Tigor", "Tiago", "Indica", "Indigo", "Any"],
    "Toyota": ["Innova", "Innova Crysta", "Qualis", "Any"],
    "Mahindra": ["Marazzo", "Scorpio", "XUV500", "e2o Plus", "Any"],
    "Kia": ["Carens", "Carnival", "Any"],
    "MG Motor": ["Hector Plus", "Any"],
  };
  const [modelTypes, setModelTypes] = useState([]);

  useEffect(() => {
    if (brand) {
      setModelTypes(brandsWithModels[brand]);
    } else {
      setModelTypes([]);
    }
  }, [brand]);

  const [vehicleData, setVehicleData] = useState(null);

  const [uploadedInsuranceFrontUrl, setUploadedInsuranceFrontUrl] = useState(null);
  const [uploadedInsuranceFrontId, setUploadedInsuranceFrontId] = useState(null);
  const [uploadedInsuranceBackUrl, setUploadedInsuranceBackUrl] = useState(null);
  const [uploadedInsuranceBackId, setUploadedInsuranceBackId] = useState(null);
  const [uploadedRcFrontUrl, setUploadedRcFrontUrl] = useState(null);
  const [uploadedRcFrontId, setUploadedRcFrontId] = useState(null);
  const [uploadedRcBackUrl, setUploadedRcBackUrl] = useState(null);
  const [uploadedRcBackId, setUploadedRcBackId] = useState(null);
  const [uploadedVehicleNumberFrontUrl, setUploadedVehicleNumberFrontUrl] = useState(null);
  const [uploadedVehicleNumberFrontId, setUploadedVehicleNumberFrontId] = useState(null);
  const [uploadedVehicleNumberBackUrl, setUploadedVehicleNumberBackUrl] = useState(null);
  const [uploadedVehicleNumberBackId, setUploadedVehicleNumberBackId] = useState(null);
  const [uploadedSeatFrontUrl, setUploadedSeatFrontUrl] = useState(null);
  const [uploadedSeatFrontId, setUploadedSeatFrontId] = useState(null);
  const [uploadedSeatBackUrl, setUploadedSeatBackUrl] = useState(null);
  const [uploadedSeatBackId, setUploadedSeatBackId] = useState(null);


  useEffect(() => {
    if (driverUsername) {
      setDriverPhoneNumber(driverUsername);
      setSearchFromMyDriver(driverUsername);
    }
  }, [driverUsername]);

  useEffect(() => {
    if (driverPhoneNumber) {
      handleSearchFromDriver();
    }
  }, [searchFromMyDriver]);
  useEffect(() => {
    if (driverPhoneNumber) {
      handleSearch();
    }
  }, [searchFromMyDriverGot]);

  const handleImageClick = (imageUrl) => {
    if (!imageUrl) {
      toast.error("No image Available");
      return;
    }
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const ImageModal = () => (
    <div className="fixed top-0 left-0 min-w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="max-w-full mx-auto bg-white p-4 rounded-lg">
        <img src={selectedImage} alt="Preview" className="w-full" />
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          onClick={() => setShowModal(false)}
        >
          <FaRegTimesCircle size={30} className="text-fixred font-extrabold" />
        </button>
      </div>
    </div>
  );

  const handleClear = () => {
    setVehicleID("");
    setVehicleData("");
    setVehicleNumber("");
    setVehicleCategory("");
    setBrand("");
    setModelType("");
    setFuelType("");
    setOwnerName("");
    setColor("");
    setSeats("");
    setLuggages("");
    setOwnership("");
    setLocation("");
    setStickerNumber("");
    setChassisNumber("");
    setEngineNumber("");
    setTransmissionType("");
    setRegistrationDate("");
    setRcValidUptoDate("");
    setInsuranceValidUptoDate("");
    setVendorPhoneNumber("");
    setDriverPhoneNumber("");
  };

  const handleSearchFromDriver = async () => {
    try {

      let formattedMobileNumber = driverPhoneNumber;

      if (driverPhoneNumber.length === 12 && driverPhoneNumber.startsWith('91')) {
        // Remove '91' if it's already there
        formattedMobileNumber = driverPhoneNumber.slice(2);
      } else if (driverPhoneNumber.length === 10) {
        // Add '91' if it's a 10-digit number
        formattedMobileNumber = driverPhoneNumber;
      }


      const drivervehicleresponse = await api.get(`/drivervehicle/vehicle/searchbydriver/${formattedMobileNumber}`);
      if (drivervehicleresponse.status === 200) {
        const drivervehiclenum = drivervehicleresponse.data.vehicleNumber;
        setVehicleNumber(drivervehiclenum);
        setSearchFromMyDriverGot(drivervehiclenum);
      } else {
        toast.error(`Failed to get VehicleNumber: ${drivervehicleresponse.statusText}`);
        console.log("Error getting VehicleNumber:", drivervehicleresponse.statusText);
      }
    } catch (error) {
      console.error("Failed to get VehicleNumber:", error);
      toast.error("VehicleNumber not found for driver mobile number:" + driverUsername);
      handleClear();
    }
  };


  const handleSearch = async () => {
    try {
      if (!vehicleNumber) {
        toast.error("Enter Vehicle Number");
        return;
      }

      // Fetch vehicle data
      const response = await api.get(`/drivervehicle/vehicle/${vehicleNumber}`);
      if (response.status === 200) {
        const vehicleData1 = response.data;
        if (vehicleData1.id && vehicleData1.vehicleNumber) {
          setVehicleID(vehicleData1.id);
          setVehicleData(vehicleData1);
          handleExistingFields(vehicleData1);

          // Fetch image metadata
          const imagesResponse = await api.get(`/drivervehicle/images/vehicle/${vehicleNumber}`);
          if (imagesResponse.status === 200) {
            const imagesMetadata = imagesResponse.data;
            mapImagesMetadataToFields(imagesMetadata);
            console.log("got Metadata :::");
          } else {
            toast.error(`Failed to fetch images: ${imagesResponse.statusText}`);
            console.log("Error fetching images:", imagesResponse.statusText);
          }
        } else {
          toast.error("Incomplete Vehicle data received.");
          handleClear();
        }
      } else {
        toast.error(`Vehicle not found: ${response.statusText}`);
        handleClear();
      }
    } catch (error) {
      console.error("Error fetching vehicle data:", error);
      toast.error("Failed to fetch vehicle data.");
      handleClear();
    }
  };

  const mapImagesMetadataToFields = (imagesMetadata) => {
    imagesMetadata.forEach(image => {
      fetchImageData(image.id, image.imageTypeCodeDescription);
    });
  };

  const fetchImageData = async (imageId, imageTypeCodeDescription) => {
    try {
      const response = await api.get(`/drivervehicle/images/${imageId}`, { responseType: 'blob' });
      if (response.status === 200) {
        const imageUrl = URL.createObjectURL(response.data);
        setIsImageAvailable(true);
        switch (imageTypeCodeDescription) {
          case 'vehicle_insurance_front':
            setUploadedInsuranceFrontUrl(imageUrl);
            setUploadedInsuranceFrontId(imageId);
            break;
          case 'vehicle_insurance_back':
            setUploadedInsuranceBackUrl(imageUrl);
            setUploadedInsuranceBackId(imageId);
            break;
          case 'vehicle_rc_front':
            setUploadedRcFrontUrl(imageUrl);
            setUploadedRcFrontId(imageId);
            break;
          case 'vehicle_rc_back':
            setUploadedRcBackUrl(imageUrl);
            setUploadedRcBackId(imageId);
            break;
          case 'vehicle_number_front':
            setUploadedVehicleNumberFrontUrl(imageUrl);
            setUploadedVehicleNumberFrontId(imageId);
            break;
          case 'vehicle_number_back':
            setUploadedVehicleNumberBackUrl(imageUrl);
            setUploadedVehicleNumberBackId(imageId);
            break;
          case 'vehicle_seat_front':
            setUploadedSeatFrontUrl(imageUrl);
            setUploadedSeatFrontId(imageId);
            break;
          case 'vehicle_seat_back':
            setUploadedSeatBackUrl(imageUrl);
            setUploadedSeatBackId(imageId);
            break;
          default:
            setIsImageAvailable(false);
            break;
        }
      } else {
        toast.error(`Failed to fetch image: ${response.statusText}`);
        console.log("Error fetching image:", response.statusText);
        setIsImageAvailable(false);
      }
    } catch (error) {
      console.error("Error fetching image data:", error);
      toast.error("Failed to fetch image data.");
      setIsImageAvailable(false);
    }
  };

  const handleExistingFields = (vehicleData) => {
    if (vehicleData) {
      setVehicleNumber(vehicleData.vehicleNumber || '');
      setVehicleCategory(vehicleData.vehicleCategory || '');
      setBrand(vehicleData.brand || '');
      setModelType(vehicleData.modelType || '');
      setFuelType(vehicleData.fuelType || '');
      setOwnerName(vehicleData.ownerName || '');
      setColor(vehicleData.color || '');
      setSeats(vehicleData.seats || '');
      setLuggages(vehicleData.luggages || '');
      setOwnership(vehicleData.ownership || '');
      setLocation(vehicleData.location || '');
      setStickerNumber(vehicleData.stickerNumber || '');
      setChassisNumber(vehicleData.chassisNumber || '');
      setEngineNumber(vehicleData.engineNumber || '');
      setTransmissionType(vehicleData.transmissionType || '');
      setRegistrationDate(vehicleData.registrationDate || '');
      setRcValidUptoDate(vehicleData.rcValidUptoDate || '');
      setInsuranceValidUptoDate(vehicleData.insuranceValidUptoDate || '');
      setVendorPhoneNumber(vehicleData.vendorPhoneNumber || '');
      setDriverPhoneNumber(vehicleData.driverPhoneNumber || '');
      setTermsAccepted(vehicleData.termsAccepted || '');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleApprove = (event, isApproved) => {
    event.preventDefault();

    fetch(`${process.env.REACT_APP_API_BASE_URLE}/drivervehicle/vehicle/approve/${vehicleID}?approved=${isApproved}`, {
      method: 'PUT',
      headers: {
        'ngrok-skip-browser-warning': 'true' // Custom header
      },
    })
      .then(response => {
        if (response.ok) {
          if (isApproved) {
            toast.success("Vehicle approved successfully.");
          } else {
            toast.warning("Vehicle rejected successfully.");
          }
        } else {
          return response.text().then(text => { throw new Error(text) });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error("Failed to update vehicle approval status.");
      });
  };
  return (
    <Fragment>
      <div className="flex">
        <AdminSidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
        <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
          <AdminNavbar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
          {showModal && <ImageModal />}
          <div className="max-w-2xl mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">View Vehicle</h2>
            <form className="space-y-4">
              <section className="border p-4 rounded-md">
                <h3 className="text-xl font-semibold mb-2">Basic Details</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium">Vehicle Number</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter Vehicle Number"
                      value={vehicleNumber}
                      onChange={(e) => setVehicleNumber(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                    <div>
                      <button type="button" onClick={handleSearch} className="bg-fixblue text-white py-1 px-4 font-bold rounded-md mt-1">Find</button>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium">Vehicle Category</label>
                    <select className="w-full border rounded-md p-2" disabled value={vehicleCategory}>
                      <option>Select Vehicle</option>
                      {vehicleCategories.map((category) => (
                        <option key={category} value={category}>{category}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Vendor UserID</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter Vendor Phone Number"
                      value={vendorPhoneNumber}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Driver Phone Number</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter Driver Phone Number"
                      value={driverPhoneNumber}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Brand</label>
                    <select className="w-full border rounded-md p-2" value={brand} disabled>
                      <option>Select Brand</option>
                      {brands.map((brand) => (
                        <option key={brand} value={brand}>{brand}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Model Type</label>
                    <select className="w-full border rounded-md p-2" disabled value={modelType}>
                      <option>Select Model</option>
                      {modelTypes.map((model) => (
                        <option key={model} value={model}>{model}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Fuel Type</label>
                    <select className="w-full border rounded-md p-2" disabled value={fuelType}>
                      <option>Select Fuel Type</option>
                      {fuelTypes.map((fuel) => (
                        <option key={fuel} value={fuel}>{fuel}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Owner's Name</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter Owner's Name"
                      value={ownerName}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Color</label>
                    <select className="w-full border rounded-md p-2" disabled value={color}>
                      <option>Select Color</option>
                      {colors.map((color) => (
                        <option key={color} value={color}>{color}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Seats</label>
                    <select className="w-full border rounded-md p-2" disabled value={seats}>
                      <option>Select Vehicle Seats</option>
                      {seatsOptions.map((seat) => (
                        <option key={seat} value={seat}>{seat}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Luggage Capacity</label>
                    <select
                      className="w-full border rounded-md p-2"
                      value={luggages}
                      onChange={(e) => setLuggages(e.target.value)}
                    >
                      <option>Select Luggage Capacity</option>
                      {luggagesOptions.map((luggage) => (
                        <option key={luggage} value={luggage}>{luggage}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Vehicle Ownership</label>
                    <div className="mt-1 flex items-center space-x-4">
                      <label className="flex items-center">
                        <input type="radio" name="ownership" className="mr-2" value="owned" disabled checked={ownership === 'owned'} />
                        Owned
                      </label>
                      <label className="flex items-center">
                        <input type="radio" name="ownership" className="mr-2" value="lease" disabled checked={ownership === 'lease'} />
                        Lease
                      </label>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Location</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Location"
                      value={location}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Sticker Number</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter Sticker Number"
                      value={stickerNumber}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Chassis Number</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter Chassis Number"
                      value={chassisNumber}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Engine Number</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter Engine Number"
                      value={engineNumber}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Transmission Type</label>
                    <select className="w-full border rounded-md p-2" disabled value={transmissionType}>
                      <option>Select Transmission Type</option>
                      {transmissionTypes.map((type) => (
                        <option key={type} value={type}>{type}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </section>

              <section className="border p-4 rounded-md">
                <h3 className="text-xl font-semibold mb-2">Vehicle Documents</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium">Insurance Valid Upto</label>
                    <input type="date" className="w-full border rounded-md p-2" readOnly value={insuranceValidUptoDate} />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Upload Insurance Copy</label>
                    <div
                      className="flex items-center justify-center cursor-pointer" id="vehicle-insurance-front"
                      onClick={() => handleImageClick(uploadedInsuranceFrontUrl)}
                    >
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Upload Insurance Back Copy</label>
                    <div
                      className="flex items-center justify-center cursor-pointer" id="vehicle-insurance-back"
                      onClick={() => handleImageClick(uploadedInsuranceBackUrl)}
                    >
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Registration Date</label>
                    <input type="date" className="w-full border rounded-md p-2" readOnly value={registrationDate} />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">RC Valid Upto</label>
                    <input type="date" className="w-full border rounded-md p-2" readOnly value={rcValidUptoDate} />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Registration Certificate Front</label>
                    <div
                      className="flex items-center justify-center cursor-pointer" id="vehicle-rc-front"
                      onClick={() => handleImageClick(uploadedRcFrontUrl)}
                    >
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Registration Certificate Back</label>
                    <div
                      className="flex items-center justify-center cursor-pointer" id="vehicle-rc-back"
                      onClick={() => handleImageClick(uploadedRcBackUrl)}
                    >
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Car Photo with Number Plate Front</label>
                    <div
                      className="flex items-center justify-center cursor-pointer" id="vehicle-number-front"
                      onClick={() => handleImageClick(uploadedVehicleNumberFrontUrl)}
                    >
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Car Photo with Number Plate Back</label>
                    <div
                      className="flex items-center justify-center cursor-pointer" id="vehicle-number-back"
                      onClick={() => handleImageClick(uploadedVehicleNumberBackUrl)}
                    >
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Seat Front Photo</label>
                    <div
                      className="flex items-center justify-center cursor-pointer" id="vehicle-seat-front"
                      onClick={() => handleImageClick(uploadedSeatFrontUrl)}
                    >
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Seat Back Photo</label>
                    <div
                      className="flex items-center justify-center cursor-pointer" id="vehicle-seat-back"
                      onClick={() => handleImageClick(uploadedSeatBackUrl)}
                    >
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                </div>
              </section>

              {!searchFromMyDriver && (
                <div className="flex items-center justify-center text-[1rem]">
                  <button
                    type="button"
                    onClick={(e) => handleApprove(e, true)}
                    className="bg-fixblue text-white py-2 px-6 font-bold rounded-md"
                  >
                    Approve
                  </button>
                  <button
                    type="button" //setApproved
                    onClick={(e) => handleApprove(e, false)}
                    className="bg-fixred text-white py-2 px-6 font-bold rounded-md ml-4"
                  >
                    Reject
                  </button>
                </div>
              )}
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ViewVehicle;
import React, { Fragment, useState } from "react";
import { FaImage, FaRegTimesCircle } from "react-icons/fa";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

// Create an Axios instance with the custom header
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    'ngrok-skip-browser-warning': 'true', // Skip ngrok warning page
    'Content-Type': 'application/json'
  }
});

const ViewDriver = () => {
  const { driverUsername } = useParams();
  const [searchFromMyDriverList, setSearchFromMyDriverList] = useState("");

  const [vendorId, setVendorId] = useState('');
  const [alternateMobileNumber, setAlternateMobileNumber] = useState('');
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [driverData, setDriverData] = useState({});
  const [driverId, setDriverId] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [name, setName] = useState('');
  const [genderType, setGenderType] = useState('');
  const [dob, setDob] = useState('');
  const [experience, setExperience] = useState('');
  const [drivingSinceDate, setDrivingSinceDate] = useState('');
  const [licenseExpiryDate, setLicenseExpiryDate] = useState('');
  const [licenseIdNumber, setLicenseIdNumber] = useState('');
  const [idProofNumber, setIdProofNumber] = useState('');
  const [pccDate, setPccDate] = useState('');
  const [nearestPoliceStation, setNearestPoliceStation] = useState('');
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [uploadedLicenceFrontUrl, setUploadedLicenceFrontUrl] = useState(null);
  const [uploadedLicenceBackUrl, setUploadedLicenceBackUrl] = useState(null);
  const [uploadedAadhaarFrontUrl, setUploadedAadhaarFrontUrl] = useState(null);
  const [uploadedAadhaarBackUrl, setUploadedAadhaarBackUrl] = useState(null);
  const [uploadedPassportFrontUrl, setUploadedPassportFrontUrl] = useState(null);
  const [uploadedPassportBackUrl, setUploadedPassportBackUrl] = useState(null);
  const [uploadedPccType1Url, setUploadedPccType1Url] = useState(null);
  const [uploadedPccType2Url, setUploadedPccType2Url] = useState(null);
  const [uploadedOtherDocumentFrontUrl, setUploadedOtherDocumentFrontUrl] = useState(null);
  const [uploadedOtherDocumentBackUrl, setUploadedOtherDocumentBackUrl] = useState(null);

  useEffect(() => {
    if (driverUsername) {
      setMobileNumber(driverUsername);  // Set the username to the state
      setSearchFromMyDriverList(driverUsername);  // Set the username to the state
    }
  }, [driverUsername]);

  useEffect(() => {
    if (mobileNumber) {
      handleSearch();  // Automatically trigger the search once vendorUserid is set
    }
  }, [searchFromMyDriverList]);


  const handleImageClick = (imageUrl) => {
    if (!imageUrl) {
      toast.error("No image Available");
      return;
    }
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const ImageModal = () => (
    <div className="fixed top-0 left-0 min-w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="max-w-full mx-auto bg-white p-4 rounded-lg">
        <img src={selectedImage} alt="Preview" className="w-full" />
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          onClick={() => setShowModal(false)}
        >
          <FaRegTimesCircle size={30} className="text-fixred font-extrabold" />
        </button>
      </div>
    </div>
  );

  const handleSearch = async () => {
    try {
      let formattedMobileNumber = mobileNumber;

      if (mobileNumber.length === 12 && mobileNumber.startsWith('91')) {
        // Remove '91' if it's already there
        formattedMobileNumber = mobileNumber;
      } else if (mobileNumber.length === 10) {
        // Add '91' if it's a 10-digit number
        formattedMobileNumber = `91${mobileNumber}`;
      }


      const response = await api.get(`/drivervehicle/drivers/mobile/${formattedMobileNumber}`);
      // const response = await api.get(`/drivervehicle/drivers/mobile/${mobileNumber}`);
      if (response.status === 200) {
        const data = response.data;
        if (data.id) {
          setDriverId(data.id);
          handleSearchById(data.id);
          console.log("handleSearch /mobile/mobileNumber:", data.id);
          const imagesResponse = await api.get(`/drivervehicle/images/driver/${data.id}`);
          if (imagesResponse.status === 200) {
            const imagesMetadata = imagesResponse.data;
            mapImagesMetadataToFields(imagesMetadata);
            console.log("got Metadata :::", imagesMetadata);
          } else {
            toast.error(`Failed to fetch images: ${imagesResponse.statusText}`);
            console.log("Error fetching images:", imagesResponse.statusText);
          }
        } else {
          toast.error("Incomplete driver data received.");
        }
      } else {
        toast.error("Driver not pre-registered yet.");
      }
    } catch (error) {
      console.error("Error fetching driver data:", error);
      toast.error("Failed to fetch driver data.");
    }
  };

  const handleSearchById = async (id) => {
    try {
      const response = await api.get(`/drivervehicle/drivers/${id}`);
      if (response.status === 200) {
        const data = response.data;
        setDriverData(data);
        handleExistingFields(data);
      } else {
        toast.error("Driver not fully registered.");
      }
    } catch (error) {
      console.error("Error fetching driver data:", error);
    }
  };

  const handleExistingFields = (driverData) => {
    setVendorId(driverData.vendorId || '');
    setDriverId(driverData.id || '');
    setMobileNumber(driverData.mobileNumber || '');
    setAlternateMobileNumber(driverData.alternateMobileNumber || '');
    setGenderType(driverData.gender || '');
    setName(driverData.name || '');
    setDob(driverData.dob || '');
    setExperience(driverData.experience || '');
    setDrivingSinceDate(driverData.drivingSinceDate || '');
    setLicenseExpiryDate(driverData.licenseExpiryDate || '');
    setLicenseIdNumber(driverData.licenseIdNumber || '');
    setIdProofNumber(driverData.idProofNumber || '');
    setPccDate(driverData.pccIssueDate || '');
    setNearestPoliceStation(driverData.nearestPoliceStation || '');
  };

  const mapImagesMetadataToFields = (imagesMetadata) => {
    imagesMetadata.forEach(image => {
      fetchImageData(image.id, image.imageTypeCodeDescription);
    });
  };

  const fetchImageData = async (imageId, imageTypeCodeDescription) => {
    try {
      const response = await api.get(`/drivervehicle/images/${imageId}`, { responseType: 'blob' });
      if (response.status === 200) {
        const imageUrl = URL.createObjectURL(response.data);
        console.log(imageId, imageTypeCodeDescription);
        switch (imageTypeCodeDescription) {
          case 'driver_photo':
            setUploadedImageUrl(imageUrl);
            break;
          case 'driver_licence_front':
            setUploadedLicenceFrontUrl(imageUrl);
            break;
          case 'driver_licence_back':
            setUploadedLicenceBackUrl(imageUrl);
            break;
          case 'driver_aadhaar_front':
            setUploadedAadhaarFrontUrl(imageUrl);
            break;
          case 'driver_aadhaar_back':
            setUploadedAadhaarBackUrl(imageUrl);
            break;
          case 'driver_passport_front':
            setUploadedPassportFrontUrl(imageUrl);
            break;
          case 'driver_passport_back':
            setUploadedPassportBackUrl(imageUrl);
            break;
          case 'driver_pcc_type1':
            setUploadedPccType1Url(imageUrl);
            break;
          case 'driver_pcc_type2':
            setUploadedPccType2Url(imageUrl);
            break;
          case 'driver_other_document_front':
            setUploadedOtherDocumentFrontUrl(imageUrl);
            break;
          case 'driver_other_document_back':
            setUploadedOtherDocumentBackUrl(imageUrl);
            break;
          default:
            break;
        }
      } else {
        toast.error(`Failed to fetch image: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching image data:", error);
      toast.error("Failed to fetch image data.");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleApprove = (event, isApproved) => {
    event.preventDefault();

    api.put(`/drivervehicle/drivers/approve/${driverId}?approved=${isApproved}`)
      .then(response => {
        if (response.status === 200) {
          if (isApproved) {
            toast.success("Driver approved successfully.");
          } else {
            toast.warning("Driver rejected successfully.");
          }
        } else {
          return response.text().then(text => { throw new Error(text) });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error("Failed to update driver approval status.");
      });
  };

  return (
    <Fragment>
      <ToastContainer />
      <div className="flex">
        <AdminSidebar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
        />
        <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
          <AdminNavbar
            sidebarToggle={sidebarToggle}
            setSidebarToggle={setSidebarToggle}
          />
          {showModal && <ImageModal />}
          <div className="max-w-2xl mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">View Driver</h2>
            <form className="space-y-4">
              <section className="border p-4 rounded-md">
                <h3 className="text-xl font-semibold mb-2">Basic Details</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium">Driver's Name</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Driver's name"
                      value={name}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Mobile Number</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Search Mobile Number"
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                    <button type="button" className="bg-fixblue text-white py-1 px-4 font-bold rounded-md mt-1" onClick={handleSearch}>Find</button>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Driver ID</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Driver ID"
                      value={driverId}
                      readOnly
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium">Associated Vendor UserID</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Vendor ID"
                      value={vendorId}
                      readOnly
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium">Alternate Mobile Number</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Alternate Mobile Number"
                      value={alternateMobileNumber}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Experience Years</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Experience Years"
                      value={experience}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Date of Birth</label>
                    <input
                      type="date"
                      className="w-full border rounded-md p-2"
                      value={dob}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Select Gender
                    </label>
                    <div className="mt-1">
                      <select className="w-full border rounded-md p-2" value={genderType} disabled>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="others">Others</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Driving Since</label>
                    <input
                      type="date"
                      className="w-full border rounded-md p-2"
                      value={drivingSinceDate}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">License Expiry Date</label>
                    <input
                      type="date"
                      className="w-full border rounded-md p-2"
                      value={licenseExpiryDate}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">ID Proof Number</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="ID Proof number"
                      value={idProofNumber}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">PCC Date</label>
                    <input
                      type="date"
                      className="w-full border rounded-md p-2"
                      value={pccDate}
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Nearest Police Station</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Nearest police station"
                      value={nearestPoliceStation}
                      readOnly
                    />
                  </div>
                </div>
              </section>
              <section className="border p-4 rounded-md">
                <h3 className="text-xl font-semibold mb-2">Driver Photos</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium">Driver Photo</label>
                    <div className="flex items-center justify-center cursor-pointer"
                      id="driver_photo"
                      onClick={() => handleImageClick(uploadedImageUrl)}>
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Licence Front</label>
                    <div className="flex items-center justify-center cursor-pointer"
                      id="driver_licence_front"
                      onClick={() => handleImageClick(uploadedLicenceFrontUrl)}>
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Licence Back</label>
                    <div className="flex items-center justify-center cursor-pointer" onClick={() => handleImageClick(uploadedLicenceBackUrl)}>
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Aadhaar Front</label>
                    <div className="flex items-center justify-center cursor-pointer" onClick={() => handleImageClick(uploadedAadhaarFrontUrl)}>
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Aadhaar Back</label>
                    <div className="flex items-center justify-center cursor-pointer" onClick={() => handleImageClick(uploadedAadhaarBackUrl)}>
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Passport Front</label>
                    <div className="flex items-center justify-center cursor-pointer" onClick={() => handleImageClick(uploadedPassportFrontUrl)}>
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Passport Back</label>
                    <div className="flex items-center justify-center cursor-pointer" onClick={() => handleImageClick(uploadedPassportBackUrl)}>
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">PCC Type 1</label>
                    <div className="flex items-center justify-center cursor-pointer" onClick={() => handleImageClick(uploadedPccType1Url)}>
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">PCC Type 2</label>
                    <div className="flex items-center justify-center cursor-pointer" onClick={() => handleImageClick(uploadedPccType2Url)}>
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Other Document Front</label>
                    <div className="flex items-center justify-center cursor-pointer" onClick={() => handleImageClick(uploadedOtherDocumentFrontUrl)}>
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Other Document Back</label>
                    <div className="flex items-center justify-center cursor-pointer" onClick={() => handleImageClick(uploadedOtherDocumentBackUrl)}>
                      <FaImage className="text-fixblue" size={50} />
                    </div>
                  </div>
                </div>
              </section>

              {!searchFromMyDriverList && (
                <div className="flex items-center justify-center text-[1rem]">
                  <button type="button" onClick={(e) => handleApprove(e, true)} className="bg-fixblue text-white py-2 px-6 font-bold rounded-md">
                    Approve
                  </button>
                  <button type="button" onClick={(e) => handleApprove(e, false)} className="bg-fixred text-white py-2 px-6 font-bold rounded-md ml-4">
                    Reject
                  </button>
                </div>
              )}

            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ViewDriver;

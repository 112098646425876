import React, { useState } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import AdminLayout from "../../../components/admin/AdminLayout";

function MarkVehicles() {
  const data = [
    {
      slNo: 1,
      vehicleNumber: "ABC123",
      vehicleId: "V123",
      vehicleCategory: "SUV",
      startDate: "2024-06-01",
      endDate: "2024-06-30",
      startTime: "09:00",
      endTime: "17:00",
      blockoutDays: "None",
    },
    {
      slNo: 2,
      vehicleNumber: "XYZ456",
      vehicleId: "V456",
      vehicleCategory: "Sedan",
      startDate: "2024-06-05",
      endDate: "2024-06-25",
      startTime: "08:00",
      endTime: "16:00",
      blockoutDays: "Saturday, Sunday",
    },
    {
      slNo: 3,
      vehicleNumber: "DEF789",
      vehicleId: "V789",
      vehicleCategory: "Hatchback",
      startDate: "2024-06-10",
      endDate: "2024-06-20",
      startTime: "10:00",
      endTime: "18:00",
      blockoutDays: "Monday",
    },
   
   
  ];

  return (
    <Fragment>
      <AdminLayout>
        <div className="bg-zinc-200 min-h-screen p-2">
          <div className="bg-white shadow-lg p-4 rounded-md">
            <div className="flex flex-wrap justify-between items-center">
              <div className="text-gray-700">
                <h1 className="text-xl font-semibold">
                  Mark Vehicle Unavailable{" "}
                </h1>
                <span>{new Date().toLocaleDateString()}</span>
              </div>

              <Link
                to="/admin/newmarkvehicle"
                className="inline-block bg-fixblue text-fixwhite font-bold text-[0.8rem] px-4 py-2 border-l-4 border-fixred sm:mt-2"
              >
                Add New
              </Link>
            </div>
          </div>
          <div className="bg-fixwhite my-4 shadow-sm p-2">
            <div className="overflow-x-auto">
              <table className="min-w-full bg-slate-100">
                <thead className="hover:bg-gray-400">
                  <tr className="text-left bg-zinc-300 text-gray-800 uppercase">
                    <th className="py-2 px-4">Sl. No.</th>
                    <th className="py-2 px-4">Vehicle Number</th>
                    <th className="py-2 px-4">Vehicle ID</th>
                    <th className="py-2 px-4">Vehicle Category</th>
                    <th className="py-2 px-4">Start Date</th>
                    <th className="py-2 px-4">End Date</th>
                    <th className="py-2 px-4">Start Time</th>
                    <th className="py-2 px-4">End Time</th>
                    <th className="py-2 px-4">Blockout Days</th>
                    <th className="py-2 px-4 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr
                      key={index}
                      className={`${
                        index % 2 === 0
                          ? "bg-fixwhite hover:bg-gray-100"
                          : "bg-gray-50 hover:bg-gray-100"
                      } border-b border-gray-400`}
                    >
                      <td className="py-2 px-4">{item.slNo}</td>
                      <td className="py-2 px-4">{item.vehicleNumber}</td>
                      <td className="py-2 px-4">{item.vehicleId}</td>
                      <td className="py-2 px-4">{item.vehicleCategory}</td>
                      <td className="py-2 px-4">{item.startDate}</td>
                      <td className="py-2 px-4">{item.endDate}</td>
                      <td className="py-2 px-4">{item.startTime}</td>
                      <td className="py-2 px-4">{item.endTime}</td>
                      <td className="py-2 px-4">{item.blockoutDays}</td>
                      <td className="py-2 px-4 inline-flex gap-2 items-center justify-center">
                        <button className="bg-fixblue text-fixwhite py-1 px-4 rounded">
                          Edit
                        </button>
                        <button className="bg-fixred text-fixwhite py-1 px-2 rounded">
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </AdminLayout>
    </Fragment>
  );
}

export default MarkVehicles;

import React, { useState } from "react";
import { FaRegUserCircle, FaAngleDown, FaBars, FaTimesCircle } from "react-icons/fa";
import { NavLink, useNavigate, useLocation } from "react-router-dom"; // Changed Link to NavLink and imported useLocation
import { removeUserData } from "../../route/Storage";

const VendorNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Added useLocation to get current path
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null); // To track which dropdown is active

  const usernameData = JSON.parse(localStorage.getItem("user"));
  const username = usernameData.sub

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setActiveDropdown(null); // Close any open dropdown when toggling menu
  };

  const toggleDropdown = (dropdownName) => {
    setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
    // Do not close mobile menu when toggling dropdown
  };

  // Define active states for main menus with submenus
  const isMyFleetActive =
    location.pathname.startsWith("/vendor/mydrivers") ||
    location.pathname.startsWith("/vendor/myvehicles");

  const isRateDashboardActive =
    location.pathname.startsWith("/vendor/rate") ||
    location.pathname.startsWith("/vendor/addrate");

  const isMyTripActive =
    location.pathname.startsWith("/vendor/mytrippanel") ||
    location.pathname.startsWith("/vendor/myalltrip"); // Ensure all relevant paths are included

  const NavItem = ({ linkTo, text }) => (
    <li>
      <NavLink
        to={linkTo}
        className={({ isActive }) =>
          `hover:text-fixblue focus:outline-none ${
            isActive ? "text-fixblue" : "text-white"
          }`
        }
        onClick={toggleMenu}
      >
        {text}
      </NavLink>
    </li>
  );

  const DropdownItem = ({ linkTo, text }) => (
    <li>
      <NavLink
        to={linkTo}
        className={({ isActive }) =>
          `block px-4 py-2 hover:bg-gray-700 ${
            isActive ? "text-green-500" : "text-white"
          }`
        }
        onClick={() => {
          toggleDropdown(activeDropdown === null ? null : activeDropdown);
        }}
      >
        {text}
      </NavLink>
    </li>
  );

  const handleLogout = () => {
    // const userRole = localStorage.getItem("roles");
    const userData = JSON.parse(localStorage.getItem("user"));
    removeUserData();
    navigate(userData.role === "ROLE_ADMIN" ? "/admin/login" : "/vendor/login");
  };

  return (
    <nav className="bg-fixred">
      <div className="container mx-auto flex justify-between items-center p-2">
        <div className="text-[1rem] flex flex-col items-center text-white font-serif border-[1px] px-2 py-1 border-fixblack">
          FIXRYDE
          <span className="text-[0.8rem] font-extrabold text-fixblue"> VENDOR </span>
        </div>
        <div className="flex items-center space-x-4 lg:hidden">
          <button className="text-white text-2xl" onClick={toggleMenu}>
            {menuOpen ? "" : <FaBars />}
          </button>
        </div>
        <ul className="hidden lg:flex space-x-6 text-[1rem]">
          {/* Dashboard */}
          <NavItem linkTo="/vendor/alltrip" text="Dashboard" />
          {/* Invoice */}
          <NavItem linkTo="/vendor/invoiceview" text="Invoice" />

          {/* MyFleet Main Menu with Submenus */}
          <li className="relative group">
            <button
              className={`hover:text-fixblue focus:outline-none flex items-center ${
                isMyFleetActive ? "text-fixblue" : "text-white"
              }`}
              onClick={() => toggleDropdown("fleet")}
            >
              MyFleet <FaAngleDown className="ml-1" />
            </button>
            <ul
              className={`absolute ${
                activeDropdown === "fleet" ? "block" : "hidden"
              } bg-gray-800 text-white pt-2 min-w-max shadow-lg rounded-md text-[0.8rem]`}
            >
              <DropdownItem linkTo="/vendor/mydrivers" text="MyDriver" />
              <DropdownItem linkTo="/vendor/myvehicles" text="MyVehicle" />
            </ul>
          </li>

          {/* RateDashboard Main Menu with Submenus */}
          <li className="relative group">
            <button
              className={`hover:text-fixblue focus:outline-none flex items-center ${
                isRateDashboardActive ? "text-fixblue" : "text-white"
              }`}
              onClick={() => toggleDropdown("rate")}
            >
              RateDashboard <FaAngleDown className="ml-1" />
            </button>
            <ul
              className={`absolute ${
                activeDropdown === "rate" ? "block" : "hidden"
              } bg-gray-800 text-white pt-2 min-w-max shadow-lg rounded-md text-[0.8rem]`}
            >
              <DropdownItem linkTo="/vendor/rate" text="Rate Rank&Edit" />
              <DropdownItem linkTo="/vendor/addrate" text="Add Rates" />
            </ul>
          </li>

          {/* MyTrip Main Menu with Submenus */}
          <li className="relative group">
            <button
              className={`hover:text-fixblue focus:outline-none flex items-center ${
                isMyTripActive ? "text-fixblue" : "text-white"
              }`}
              onClick={() => toggleDropdown("trip")}
            >
              MyTrip <FaAngleDown className="ml-1" />
            </button>
            <ul
              className={`absolute ${
                activeDropdown === "trip" ? "block" : "hidden"
              } bg-gray-800 text-white pt-2 min-w-max shadow-lg rounded-md text-[0.8rem]`}
            >
              <DropdownItem linkTo="/vendor/mytrippanel" text="MyAllTrip" />
            </ul>
          </li>

          {/* MyPerformance */}
          <NavItem linkTo="/vendor/myperformance" text="MyPerformance" />
        </ul>
        <div className="flex justify-end">
        <span className="text-fixblue font-bold text-[1.2rem]">Welcome.. : {username}</span>
        </div>
        
        {/* Profile Dropdown */}
        <div className="relative group">
          <button
            className="flex items-center space-x-2 px-4 py-2 border-2 border-white rounded-full text-white hover:bg-fixgold hover:text-fixblue font-medium text-[1rem] focus:outline-none"
            onClick={() => toggleDropdown("profile")}
          >
            <FaRegUserCircle className="text-xl" />
            <span>Profile</span>
            <FaAngleDown className="ml-1" />
          </button>
          <ul
            className={`absolute right-0 ${
              activeDropdown === "profile" ? "block" : "hidden"
            } bg-gray-800 text-white shadow-lg rounded-md text-[0.8rem]`}
          >
            <DropdownItem linkTo="/vendor/activity" text="Activity" />
            <li
              className="px-4 py-2 hover:bg-gray-700 cursor-pointer"
              onClick={handleLogout} // Call the logout function here
            >
              Logout
            </li>
          </ul>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed top-0 right-0 h-full w-3/4 bg-fixred transform ${
          menuOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 lg:hidden z-10`}
      >
        {/* Icons Mobile View */}
        <div className="flex justify-end items-center p-4">
          <button className="text-white text-2xl" onClick={toggleMenu}>
            <FaTimesCircle />
          </button>
        </div>
        {/* Navbar Mobile View */}
        <ul className="flex flex-col space-y-4 p-4 text-[1rem]">
          {/* Dashboard */}
          <NavItem linkTo="/vendor/alltrip" text="Dashboard" />
          {/* Invoice */}
          <NavItem linkTo="/vendor/invoiceview" text="Invoice" />

          {/* MyFleet Mobile Menu with Submenus */}
          <li>
            <button
              className={`flex items-center justify-between w-full hover:text-fixblue focus:outline-none ${
                isMyFleetActive ? "text-fixblue" : "text-white"
              }`}
              onClick={() => toggleDropdown("fleet")}
            >
              <span>MyFleet</span> <FaAngleDown className="ml-1" />
            </button>
            <ul
              className={`bg-gray-800 text-white pt-2 min-w-max shadow-lg rounded-md text-[0.8rem] ${
                activeDropdown === "fleet" ? "block" : "hidden"
              }`}
            >
              <DropdownItem linkTo="/vendor/mydrivers" text="MyDriver" />
              <DropdownItem linkTo="/vendor/myvehicles" text="MyVehicle" />
            </ul>
          </li>

          {/* RateDashboard Mobile Menu with Submenus */}
          <li>
            <button
              className={`flex items-center justify-between w-full hover:text-fixblue focus:outline-none ${
                isRateDashboardActive ? "text-fixblue" : "text-white"
              }`}
              onClick={() => toggleDropdown("rate")}
            >
              <span>RateDashboard</span> <FaAngleDown className="ml-1" />
            </button>
            <ul
              className={`bg-gray-800 text-white pt-2 min-w-max shadow-lg rounded-md text-[0.8rem] ${
                activeDropdown === "rate" ? "block" : "hidden"
              }`}
            >
              <DropdownItem linkTo="/vendor/rate" text="Rate Rank&Edit" />
              <DropdownItem linkTo="/vendor/addrate" text="Add Rates" />
            </ul>
          </li>

          {/* MyTrip Mobile Menu with Submenus */}
          <li>
            <button
              className={`flex items-center justify-between w-full hover:text-fixblue focus:outline-none ${
                isMyTripActive ? "text-fixblue" : "text-white"
              }`}
              onClick={() => toggleDropdown("trip")}
            >
              <span>MyTrip</span> <FaAngleDown className="ml-1" />
            </button>
            <ul
              className={`bg-gray-800 text-white pt-2 min-w-max shadow-lg rounded-md text-[0.8rem] ${
                activeDropdown === "trip" ? "block" : "hidden"
              }`}
            >
              <DropdownItem linkTo="/vendor/mytrippanel" text="MyAllTrip" />
            </ul>
          </li>

          {/* MyPerformance */}
          <NavItem linkTo="/vendor/myperformance" text="MyPerformance" />
        </ul>
      </div>
    </nav>
  );
};

export default VendorNavbar;

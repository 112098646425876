// src/store/fixStore.js

import { configureStore } from '@reduxjs/toolkit';
import tripReducer from '../reducers/tripReducer';

const fixStore = configureStore({
  reducer: {
    trip: tripReducer,
    // Add other reducers here if needed
  },
});

export default fixStore;

// src/reducers/tripReducer.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tripType: 'AirportPickup',
  activeField: 'AirportDrive',
  sourceInput: '',
  destinationInput: '',
  date: '',
  time: '',
  redate: '',
  retime: '',
  roundTrip: false,
  userData: {},
  fareDetailsList: [],
  availableTimes: [],
  distance: "0 km",
  // Add other fields as needed
};

const tripSlice = createSlice({
  name: 'trip',
  initialState,
  reducers: {
    setTripData(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateTripField(state, action) {
      const { field, value } = action.payload;
      state[field] = value;
    },
    resetTripData() {
      return initialState;
    },
  },
});

export const { setTripData, updateTripField, resetTripData } = tripSlice.actions;

export default tripSlice.reducer;

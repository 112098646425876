import React, { Fragment, useState, useEffect } from "react";
import {
  FaBook,
  FaBuilding,
  FaCar,
  FaMoneyBillAlt,
  FaRegTimesCircle,
  FaSync,
  FaUserCircle,
  FaUserCog,
} from "react-icons/fa";
import AdminSidebar from "../../components/admin/AdminSidebar";
import AdminNavbar from "../../components/admin/AdminNavbar";

import DashCard from "../../components/admin/DashCard";
import ProfitCard from "../../components/admin/ProfitCard";
import MiniDash from "../../components/admin/MiniDash";

import Charts from "../../components/admin/Charts";
import axios from 'axios';
import AdminLayout from "../../components/admin/AdminLayout";

function DashboardPanel() {
  // Toggle for sidebar
  const [sidebarToggle, setSidebarToggle] = useState(false);
  // Date select option
  const [selectedOption, setSelectedOption] = useState("today");

  const [finBookingCount, setfinBookingCount] = useState(0);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    // Make a GET request to your backend API endpoint
    axios.get(`${process.env.REACT_APP_API_BASE_URLB}/bookings/bookingcount`)
      .then(response => {
        // Set the fetched data to the state
        setData(response.data);
        const bookingCount = response.data.reduce((bcc, booking) => {
          const status = booking.status;
          bcc[status] = (bcc[status] || 0) + 1;
          return bcc;
        }, {});
        const finBookingCount = bookingCount["pending"] || 0;
        setfinBookingCount(finBookingCount);

        console.log(response.data); // Log the fetched data
        console.log(finBookingCount); // Log the calculated count
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);



  // Profit card data
  const profitData = [
    {
      countno: "102",
      title: "Total Rides",
      color: "green",
      icon: <FaUserCircle fontSize={33} color="green" />,
    },
    {
      countno: "117",
      title: "Total Drivers",
      color: "brown",
      icon: <FaUserCog fontSize={33} color="brown" />,
    },
    {
      countno: "106",
      title: "Total No of Ride",
      color: "blue",
      icon: <FaSync fontSize={33} color="blue" />,
    },
    {
      countno: "167",
      title: "Cancelled Ride",
      color: "red",
      icon: <FaRegTimesCircle fontSize={33} color="red" />,
    },
    {
      countno: "04",
      title: "Vehicle Types",
      color: "orange",
      icon: <FaCar fontSize={33} color="orange" />,
    },
    {
      countno: "$629.40",
      title: "Revenue",
      color: "green",
      icon: <FaMoneyBillAlt fontSize={33} color="green" />,
    },
    {
      countno: "167",
      title: "Ontrip Ride",
      color: "yellow",
      icon: <FaBuilding fontSize={33} color="yellow" />,
    },
    {
      countno: "167",
      title: "Completed Ride",
      color: "orange",
      icon: <FaBook fontSize={33} color="orange" />,
    },
  ];

  // DashCard data
  const cardData = [
    { title: "New Booking(DOC)", data: finBookingCount.toString() },
    { title: "All Trip(DOJ)", data: "05" },
    { title: "Vacant Drivers", data: "20" },
    { title: "Vacant Cabs", data: "50" },
    { title: "Total Booking(Price)", data: "Rs.11011.00" },
  ];

  return (
    <Fragment>
      <AdminLayout>
          {/* Main content */}
          <div className="bg-zinc-100 min-h-screen p-4">
            <div className="bg-white shadow-lg p-4 rounded-lg">
              <div className="flex flex-col sm:flex-row justify-between items-center">
                <div className="text-gray-700">
                  <h1 className="text-xl font-semibold">Dashboard</h1>
                  <span>{new Date().toLocaleDateString()}</span>
                </div>
                <div className="flex flex-col items-center mt-2 sm:mt-0">
                  <label htmlFor="date-select" className="text-gray-700 mr-2 mb-1 text-xl font-semibold ">Select View:</label>
                  <select
                    id="date-select"
                    value={selectedOption}
                    onChange={handleOptionChange}

                    className="border border-gray-300 bg-white rounded-md px-3 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="today">Today</option>
                    <option value="tomorrow">Tomorrow</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="lastweek">Last Week</option>
                    <option value="lastmonth">Last Month</option>
                  </select>
                </div>
              </div>
            </div>
            <hr className="my-4 border-b-2 border-black" />
            <div className="flex flex-wrap justify-center sm:justify-start">
              {profitData.map((data, index) => (
                <ProfitCard
                  key={index}
                  title={data.title}
                  countno={data.countno}
                  color={data.color}
                  icon={data.icon}
                />
              ))}
            </div>
            <hr className="my-4 border-b-2 border-black" />

            <div className="flex flex-wrap justify-center sm:justify-start">
              {cardData.map((card, index) => (
                <DashCard key={index} title={card.title} data={card.data} />
              ))}
            </div>
            <hr className="my-4 border-b-2 border-black" />

            {/* Mini Dashboards */}
            <div className="container mx-auto px-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                <div className="flex flex-col bg-white rounded-lg shadow-lg p-2 w-full">
                  <MiniDash
                    tripPanelHeading="Trip Panel"
                    viewMoreHeading="View More"
                    miniData={[
                      { color: "teal", heading: "Ongoing", data: "04" },
                      { color: "green", heading: "Completed", data: "06" },
                      { color: "red", heading: "Cancelled", data: "01" },
                      { color: "yellow", heading: "Unassigned", data: "00" },
                      { color: "orange", heading: "Upcoming", data: "00" },
                      { color: "gray", heading: "GRT", data: "00" },
                    ]}
                  />
                </div>
                <div className="flex flex-col bg-white rounded-lg shadow-lg p-2 w-full">
                  <MiniDash
                    tripPanelHeading="Fleet Panel"
                    viewMoreHeading="View More"
                    miniData={[
                      { color: "teal", heading: "Total", data: "40" },
                      { color: "pink", heading: "Available", data: "34" },
                      { color: "green", heading: "Reg Progress", data: "06" },
                      { color: "gray", heading: "Checkup Pending", data: "04" },
                      { color: "blue", heading: "On trip", data: "08" },
                      { color: "orange", heading: "Logout", data: "04" },
                    ]}
                  />
                </div>
                <div className="flex flex-col bg-white rounded-lg shadow-lg p-2 w-full">
                  <MiniDash
                    tripPanelHeading="Vehicles Panel"
                    viewMoreHeading="View More"
                    miniData={[
                      { color: "teal", heading: "Total", data: "48" },
                      { color: "yellow", heading: "Unassign", data: "26" },
                      { color: "green", heading: "On trip", data: "05" },
                      { color: "orange", heading: "Logged out", data: "08" },
                      { color: "green", heading: "Reg progress", data: "03" },
                      { color: "gray", heading: "Checkup pending", data: "03" },
                      { color: "red", heading: "Blocked for trip", data: "02" },
                      { color: "pink", heading: "Driver login pending", data: "03" },
                    ]}
                  />
                </div>
                <div className="flex flex-col bg-white rounded-lg shadow-lg p-2 w-full">
                  <Charts />
                </div>
                <div className="flex flex-col bg-white rounded-lg shadow-lg p-2 w-full">
                  <MiniDash
                    tripPanelHeading="Review"
                    viewMoreHeading="View More"
                    miniData={[

                      { color: "yellow", heading: "kumar", data: "3" },
                      { color: "teal", heading: "ram", data: "4" },
                      { color: "green", heading: "raja", data: "5" },
                    ]}
                  />
                </div>
              </div>
            </div>
            <hr className="my-4 border-b-2 border-black" />
          </div>
      </AdminLayout>
      
    </Fragment>
  );
}

export default DashboardPanel;

import React, { Fragment, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import VehicleCard from '../../../components/admin/VehicleCard';
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import AdminLayout from '../../../components/admin/AdminLayout';

const VehicleList = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [activeTab, setActiveTab] = useState('All');

  const vehicles = [
    { id: 1, type: 'Sedan', model: 'MARUTI', number: 'GA11T4392', status: 'Verified' },
    { id: 2, type: 'SUV', model: 'TOYOTA', number: 'GA12B7394', status: 'Verified' },
    { id: 3, type: 'Hatchback', model: 'HYUNDAI', number: 'GA13C8395', status: 'Verified' },
    // Add more vehicles as needed
  ];

  const renderVehicles = (type) => {
    return vehicles
      .filter(vehicle => type === 'All' || vehicle.type === type)
      .map((vehicle) => (
        <VehicleCard key={vehicle.id} vehicle={vehicle} />
      ));
  };

  return (
    <Fragment>
      <AdminLayout>
      <div className="bg-fixsilver min-h-screen p-4">
          <div className="bg-white shadow-lg p-4 rounded-lg">
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <div className="text-gray-700">
                <h1 className="text-xl font-semibold">All Rates</h1>
                <span>{new Date().toLocaleDateString()}</span>
              </div>
            </div>
          </div>

          <div className="container mx-auto p-4">
            <div className="flex justify-between items-center mb-4">
              <h1 className="text-2xl font-bold">My Vehicles</h1>
              <button className="bg-purple-600 text-white py-2 px-4 rounded flex items-center">
                <FaPlus className="mr-2" /> ADD VEHICLE
              </button>
            </div>

            <div className="border-b-2 mb-4 flex">
              {['All', 'Sedan', 'SUV', 'Hatchback', 'Innova', 'Innova Crysta'].map(tab => (
                <button
                  key={tab}
                  className={`pb-2 mr-4 ${activeTab === tab ? 'border-b-2 border-purple-600' : ''}`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>

            <div className="flex justify-between items-center mb-4">
              <span className="text-gray-700">Blocked for trip (1)</span>
              <button className="bg-gray-200 py-2 px-4 rounded">Apply Filter</button>
            </div>

            {renderVehicles(activeTab)}
          </div>
        </div>
      </AdminLayout>
    </Fragment>
  );
};

export default VehicleList;

import React, { createContext, useContext, useState } from 'react';
import { LoadScript } from '@react-google-maps/api';

const GoogleMapsContext = createContext();

export const useGoogleMaps = () => {
    return useContext(GoogleMapsContext);
};

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const libraries = ["places"];

export const GoogleMapsProvider = ({ children }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const onLoad = () => {
        setIsLoaded(true);
    };

    return (
        <GoogleMapsContext.Provider value={isLoaded}>
            <LoadScript
                googleMapsApiKey={GOOGLE_MAPS_API_KEY}
                libraries={libraries}
                onLoad={onLoad}
            >
                {children}
            </LoadScript>
        </GoogleMapsContext.Provider>
    );
};

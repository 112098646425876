// src/components/site/core/RentalDrive.jsx

import React from "react";
import AutocompleteInput from "../../common/AutocompleteInput";
import { getMinMaxDates } from "../../../utils/tripUtils";

const RentalDrive = ({
  tripType,
  setTripType,
  sourceInput,
  setSourceInput,
  date,
  setDate,
  time,
  setTime,
  availableTimes,
  handleSearch,
}) => {
  const options = {
    componentRestrictions: { country: "in" },
  };

  const { minDateString, maxDateString } = getMinMaxDates();

  return (
    <div className="container mx-auto">
      <div className="my-2 bg-white rounded-md shadow-lg p-4 grid grid-cols-1 lg:grid-cols-4 gap-2">
        <div className="lg:col-span-1">
          <label htmlFor="PackageType" className="block text-sm text-gray-700 font-bold mb-2">
            Package
          </label>
          <select
            id="PackageType"
            className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
            value={tripType}
            onChange={(e) => setTripType(e.target.value)}
          >
            <option value="4 hrs 40 kms">4 Hours 40 Kilometers</option>
            <option value="5 hrs 50 kms">5 Hours 50 Kilometers</option>
            <option value="6 hrs 60 kms">6 Hours 60 Kilometers</option>
            <option value="7 hrs 70 kms">7 Hours 70 Kilometers</option>
            <option value="8 hrs 80 kms">8 Hours 80 Kilometers</option>
            <option value="9 hrs 90 kms">9 Hours 90 Kilometers</option>
            <option value="10 hrs 100 kms">10 Hours 100 Kilometers</option>
            <option value="11 hrs 110 kms">11 Hours 110 Kilometers</option>
            <option value="12 hrs 120 kms">12 Hours 120 Kilometers</option>
          </select>
        </div>

        <AutocompleteInput
          id="source"
          label="Source"
          value={sourceInput}
          onChange={setSourceInput}
          placeholder="Enter source"
          autocompleteOptions={options}
          onPlaceChanged={setSourceInput}
        />

        <div className="lg:col-span-1">
          <label htmlFor="Date" className="block text-sm text-gray-700 font-bold mb-2">
            Date
          </label>
          <input
            id="date"
            type="date"
            className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            min={minDateString}
            max={maxDateString}
            required
          />
        </div>

        <div className="lg:col-span-1">
          <label htmlFor="Time" className="block text-sm text-gray-700 font-bold mb-2">
            Time
          </label>
          <select
            id="time"
            className="mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            required
          >
            {availableTimes.map((slot, index) => (
              <option key={index} value={slot.time} disabled={!slot.isEnabled}>
                {slot.time}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          onClick={handleSearch}
          className="px-20 bg-fixblue border-2 border-white hover:bg-yellow-500 text-white font-bold py-3 rounded-full"
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default RentalDrive;

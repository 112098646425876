import React from 'react';
import { FaCheckCircle, FaTimes, FaUserCircle } from 'react-icons/fa';
import { MdInfo } from 'react-icons/md';
import { FiAlertTriangle } from 'react-icons/fi';

const CancelModal = ({ isOpen, onClose }) => {
  if (!isOpen) return;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6 relative">
        {/* Close Button */}
        <button 
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600" 
          onClick={onClose}
        >
          <FaTimes className="h-6 w-6" />
        </button>
        
        {/* Modal Content */}
        <div className="flex flex-col">
          {/* Header */}
          <div className="mb-4">
            <h2 className="text-lg font-semibold">Cancellation Charges</h2>
          </div>

          {/* Refund Policy Section */}
          <div className="grid grid-cols-3 gap-4 border-b pb-4">
            <div className="text-center">
              <p className="text-sm text-gray-600">24 hours <br /> before pickup time</p>
              <p className="font-bold mt-2">FULL REFUND</p>
            </div>
            <div className="text-center">
              <p className="text-sm text-gray-600">24 - 4 hours <br /> before pickup time</p>
              <p className="font-bold mt-2">15% of the TRIP FARE</p>
            </div>
            <div className="text-center">
              <p className="text-sm text-gray-600">Within 4 hours <br /> before pickup time</p>
              <p className="font-bold mt-2">NO REFUND</p>
            </div>
          </div>

          {/* Trip Information */}
          <div className="mt-4">
            <div className="flex items-start mb-2">
              <FaCheckCircle className="text-yellow-500 mr-2 mt-1" />
              <p className="text-sm">
                Your trip will be assigned to the selected partner after successful payment.
              </p>
            </div>
            <div className="flex items-start mb-2">
              <FaUserCircle className="text-yellow-500 mr-2 mt-1" />
              <p className="text-sm">
                Driver details will be shared 4hrs before the pick up time.
              </p>
            </div>
          </div>

          {/* Other Information */}
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-2">Other Information</h3>
            <div className="flex items-start mb-2">
              <MdInfo className="text-yellow-500 mr-2 mt-1" />
              <p className="text-sm">
                In case you've opted for partial payment, please pay the balance amount to the driver within 45 minutes of your pickup time.
              </p>
            </div>
            <div className="flex items-start mb-2">
              <FiAlertTriangle className="text-yellow-500 mr-2 mt-1" />
              <p className="text-sm">
                AC will be switched off in hilly areas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelModal;

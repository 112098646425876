import React, { Fragment, useState } from "react";
import VendorNavbar from "../../../components/vendor/VendorNavbar";
import PerformCard from "../../../components/vendor/PerformCard";
import PieChart from "../../../components/admin/PieChart";
import { FaSearch, FaSync } from "react-icons/fa";
import { FaArrowsLeftRight } from "react-icons/fa6";


const MyPerformance = () => {
  const [selectedOption, setSelectedOption] = useState("today");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const pickupSummaryData = [
    { label: 'On Time', value: 0 },
    { label: 'Late', value: 0 },
    { label: 'Early', value: 100 },
  ];

  const dropSummaryData = [
    { label: 'On Time', value: 43 },
    { label: 'Late', value: 29 },
    { label: 'Early', value: 29 },
  ];

  const extraTravelData = {
    labels: ['Less than 10Kms', 'Between 10-50 Kms', 'Between 51-100 Kms', 'More than 100 Kms'],
    datasets: [
      {
        data: [300, 50, 100, 40],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF6384'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF6384'],
      },
    ],
  };

  const driverAppUsageData = [
    { label: 'App used', value: 100 },
    { label: 'App not used', value: 0 },
    { label: 'Marked reached pickup', value: 100 },
    { label: 'Started trip', value: 100 },
    { label: 'Ended trip', value: 100 },
  ];

  return (
    <Fragment>
      <VendorNavbar onToggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
      <div className="bg-zinc-200 min-h-screen p-2">
        <div className="bg-white shadow-lg p-4 rounded-lg mb-4">
          <div className="flex justify-between items-center mb-2">
            <h1 className="text-xl font-semibold text-gray-600">My Performance</h1>
            <span className="text-sm font-semibold">{new Date().toLocaleDateString()}</span>
          </div>
          <hr className="my-2" />
          <div className="flex flex-col md:flex-row items-center justify-evenly">
            <div className="mb-4 md:mb-0">
              <label className="flex-inline font-bold m-2 text-sm">Quick Filter :</label>
              <select className="border rounded p-2 w-full md:w-auto">
                <option>This Week</option>
                <option>Last Week</option>
                <option>This Month</option>
                <option>Last Month</option>
              </select>
            </div>
            <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
              <div className="flex items-center space-x-2">
                <input
                  type="date"
                  className="p-2 border border-gray-300 rounded-md"
                />
                <span><FaArrowsLeftRight className="w-4 h-4" /></span>
                <input
                  type="date"
                  className="p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="flex space-x-4 mt-2 sm:mt-0 cursor-pointer">
                <button className="p-2 border border-gray-300 rounded-md flex items-center justify-center">
                  <FaSearch className="w-4 h-4" />
                </button>
                <button className="p-2 border border-gray-300 rounded-md flex items-center justify-center">
                  <FaSync className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <PerformCard title="Pickup Summary" data={pickupSummaryData} />
          <PerformCard title="Drop Summary" data={dropSummaryData} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <PieChart
            data={extraTravelData}
            options={{ responsive: true, maintainAspectRatio: false }}
          />
          <PerformCard title="Driver App Usage" data={driverAppUsageData} />
        </div>
      </div>
    </Fragment>
  );
};

export default MyPerformance;

// MapUtils.js
import { useState, useEffect, useRef } from 'react';
// import { Loader } from '@googlemaps/js-api-loader';

export const useGoogleMaps = (googleMaps) => {
  const [map, setMapInstance] = useState(null);
  const [source, setSource] = useState(null);
  const [destination, setDestination] = useState(null);
  const [distance, setDistance] = useState('');
  const [sourceState, setSourceState] = useState('');
  const [sourceDistrict, setSourceDistrict] = useState('');
  const [destinationState, setDestinationState] = useState('');
  const [destinationDistrict, setDestinationDistrict] = useState('');
  // const [isMapsApiLoaded, setIsMapsApiLoaded] = useState(false);
  // const [autocompleteSource, setAutocompleteSource] = useState(null);
  // const [autocompleteDestination, setAutocompleteDestination] = useState(null);
  // const [googleMaps, setGoogleMaps] = useState(null);
  const sourceMarkerRef = useRef(null);
  const destinationMarkerRef = useRef(null);
  const directionsRendererRef = useRef(null);

  // useEffect(() => {
  //   const loader = new Loader({
  //     apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  //     version: 'weekly',
  //     libraries: ['places', 'marker'],
  //   });

  //   loader.load().then((google) => {
  //     window.google = google; // Ensure google is available globally
  //     setGoogleMaps(google);
  //     setIsMapsApiLoaded(true);
  //   }).catch(error => {
  //     console.error('Error loading Google Maps API:', error);
  //   });

  //   return () => {
  //     if (googleMaps) {
  //       googleMaps.maps = null; // Reset the global google variable when unmounting
  //     }
  //   };
  // }, []);

  const handlePlaceSelect = (autocomplete, type) => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place && place.geometry && place.geometry.location) {
        const location = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          formatted_address: place.formatted_address // Capture formatted address
        };
      const districtComponent = place.address_components?.find(component => component.types.includes('administrative_area_level_3'));
      const district = districtComponent ? districtComponent.long_name : null;
      
      const cityComponent = place.address_components?.find(component => component.types.includes('locality'));
      const district1 = cityComponent ? cityComponent.long_name : 'Unknown city';

      console.log("District:", district || district1);

      const stateComponent = place.address_components?.find(component => component.types.includes('administrative_area_level_1'));
      const state = stateComponent ? stateComponent.long_name : 'Unknown district';
      console.log("State:", state);

      if (type === 'source') {
        setSource(location);
        console.log("1district"+ district +"district1"+ district1 +""+  +"");
        if((district && district1) || district1){
        setSourceDistrict(district1);
        }else{setSourceDistrict(district);};
        setSourceState(state);
        console.log("2district"+ district +"district1"+ district1 +""+  +"");
      } else {
        setDestination(location);
        if((district && district1) || district1){
          setDestinationDistrict(district1);
          }else{setDestinationDistrict(district);};
        setDestinationState(state);
      }

      // console.log("Type:", type);
      // console.log("Location:", location);
      // console.log("District:", district || district1);
      // console.log("State:", state);

      } else {
        console.error('Invalid place object:', place);
      }
    }
  };

  const calculateDistance = () => {
    return new Promise((resolve, reject) => {
      // if (source && destination && isMapsApiLoaded) {
      if (source && destination ) {
        const service = new googleMaps.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [source],
            destinations: [destination],
            travelMode: googleMaps.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (status === 'OK' && response.rows[0].elements[0].distance) {
              const distanceText = response.rows[0].elements[0].distance.text;
              setDistance(distanceText);
              resolve(distanceText); // Resolve the Promise with the distance value
            } else {
              setDistance('Error calculating distance');
              console.error('Distance Matrix Service error:', status, response);
              reject('Error calculating distance'); // Reject the Promise in case of an error
            }
          }
        );
      } else {
        reject('Source or destination not set or Maps API not loaded');
      }
    });
  };


  const updateMap = () => {
    if (map && source && destination) {
      if (sourceMarkerRef.current) {
        sourceMarkerRef.current.setMap(null);
      }
      if (destinationMarkerRef.current) {
        destinationMarkerRef.current.setMap(null);
      }
      if (directionsRendererRef.current) {
        directionsRendererRef.current.setMap(null);
      }

      map.setCenter(source);
      const bounds = new googleMaps.maps.LatLngBounds();
      bounds.extend(source);
      bounds.extend(destination);
      map.fitBounds(bounds);

      googleMaps.maps.importLibrary('marker').then(({ AdvancedMarkerElement }) => {
        sourceMarkerRef.current = new AdvancedMarkerElement({
          map,
          position: source,
          title: 'Source',
        });

        destinationMarkerRef.current = new AdvancedMarkerElement({
          map,
          position: destination,
          title: 'Destination',
        });
      });

      const directionsService = new googleMaps.maps.DirectionsService();
      directionsRendererRef.current = new googleMaps.maps.DirectionsRenderer();
      directionsRendererRef.current.setMap(map);

      directionsService.route(
        {
          origin: source,
          destination: destination,
          travelMode: googleMaps.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            directionsRendererRef.current.setDirections(response);
          } else {
            console.error('Directions request failed due to ' + status);
          }
        }
      );
    }
  };

  const setMap = (mapInstance) => {
    setMapInstance(mapInstance);
  };

  return {
    map,
    source,
    destination,
    distance,
    sourceDistrict,
    sourceState,
    destinationDistrict,
    destinationState,
    // isMapsApiLoaded,
    // autocompleteSource,
    // autocompleteDestination,
    googleMaps,
    // setAutocompleteSource,
    // setAutocompleteDestination,
    handlePlaceSelect,
    calculateDistance,
    updateMap,
    setMap, // Make sure setMap is exposed
    setSource, // Expose setSource
    setDestination, // Expose setDestination
    setSourceDistrict,
    setDestinationDistrict,
    setSourceState,
    setDestinationState,
    setDistance,
  };
};

// src/components/Tabs.js
import React from 'react';

const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div className="flex border-b">
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={`py-1 px-2 text-sm font-semibold ${activeTab === index ? 'border-b-2 border-fixblue' : 'border-b-2 border-transparent'} focus:outline-none`}
          onClick={() => setActiveTab(index)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default Tabs;

import React, { Fragment, useState, useEffect } from "react";
import { FaUpload } from "react-icons/fa";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

// Create an Axios instance with the custom header
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    'ngrok-skip-browser-warning': 'true' // Skip ngrok warning page
  }
});

const VehicleForm = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [vehicleCategory, setVehicleCategory] = useState('');
  const [brand, setBrand] = useState('');
  const [modelType, setModelType] = useState('');
  const [fuelType, setFuelType] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [color, setColor] = useState('');

  // 2. **Convert `seatsOptions` to a State Variable**
  const [seatsOptions, setSeatsOptions] = useState([]); // Initialize as empty array
  const [seats, setSeats] = useState('');

  const [luggages, setLuggages] = useState('');
  const [ownership, setOwnership] = useState('');
  const [location, setLocation] = useState('');
  const [stickerNumber, setStickerNumber] = useState('');
  const [chassisNumber, setChassisNumber] = useState('');
  const [engineNumber, setEngineNumber] = useState('');
  const [transmissionType, setTransmissionType] = useState('');
  const [vendorPhoneNumber, setVendorPhoneNumber] = useState('');
  const [driverPhoneNumber, setDriverPhoneNumber] = useState('');
  const [registrationDate, setRegistrationDate] = useState('');
  const [rcValidUptoDate, setRcValidUptoDate] = useState('');
  const [insuranceValidUptoDate, setInsuranceValidUptoDate] = useState('');
  const [vehicleID, setVehicleID] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // 1. **Define Vehicle Categories:** Removed "Innova" and "Innova Crysta" as they are specific models, not categories.
  const vehicleCategories = ["Sedan", "SUV", "Hatchback", "Innova", "Innova Crysta"];

  // 2. **Expand Brands Array:** Included all brands present in brandsWithModels.
  const brands = ["Toyota", "Honda", "Ford", "Tesla", "Maruti Suzuki", "Hyundai", "Mahindra", "Kia", "MG Motor", "Volkswagen", "Jeep"];

  const fuelTypes = ["Petrol", "Diesel", "Electric", "Hybrid", "CNG", "LPG", "Hydrogen", "Oxygen", "Methane", "Natural GAS"];
  const colors = ["Red", "Blue", "Black", "White", "Silver", "Grey", "Yellow", "Green", "Orange", "Brown", "Beige", "Maroon", "Gold"];
  const transmissionTypes = ["Manual", "Automatic", "CVT", "DCT", "AMT"];

  const luggagesOptions = [0, 1, 2, 3, 4, 5];

  // **brandsWithModels:** Nested structure mapping brand -> category -> models
  const brandsWithModels = {
    "Maruti Suzuki": {
      "Sedan": ["Dzire", "Ciaz", "Any"],
      "Hatchback": ["Celerio", "Wagon R", "Swift", "Any"],
      "SUV": ["Vitara Brezza", "S-Cross", "Ertiga", "Any"]
    },
    "Hyundai": {
      "Sedan": ["Verna", "Elantra", "Aura", "Any"],
      "Hatchback": ["i10", "i20", "Grand i10 Nios", "Santro", "Any"],
      "SUV": ["Creta", "Venue", "Tucson", "Santa Fe", "Any"]
    },
    "Honda": {
      "Sedan": ["City", "Amaze", "Any"],
      "Hatchback": ["Jazz", "Any"],
      "SUV": ["WR-V", "HR-V", "Any"]
    },
    "Tata Motors": {
      "Sedan": ["Tigor", "Zest", "Any"],
      "Hatchback": ["Tiago", "Bolt", "Altroz", "Any"],
      "SUV": ["Nexon", "Harrier", "Safari", "Seltos", "Punch", "Any"]
    },
    "Toyota": {
      "Sedan": ["Yaris", "Any"],
      "Hatchback": ["Etios Liva", "Any"],
      "SUV": ["Fortuner", "Land Cruiser", "Yaris Cross", "Any"],
      "Innova": ["Innova", "Any"],
      "Innova Crysta": ["Innova Crysta", "Any"]
    },
    "Mahindra": {
      "Sedan": ["Any"],
      "Hatchback": ["eKUV100", "Any"],
      "SUV": ["Scorpio", "XUV500", "XUV300", "Bolero", "Thar", "Bolero Neo", "Alturas G4", "Any"]
    },
    "Kia": {
      "Sedan": ["Any"],
      "Hatchback": ["Any"],
      "SUV": ["Seltos", "Sonet", "Carnival", "Telluride", "Any"]
    },
    "MG Motor": {
      "Sedan": ["ZS Sedan", "Any"],
      "Hatchback": ["Any"],
      "SUV": ["Hector", "Hector Plus", "Gloster", "ZS EV", "Any"]
    },
    "Ford": {
      "Sedan": ["Fusion", "Fiesta Sedan", "Any"],
      "Hatchback": ["Figo", "Figo Aspire", "Any"],
      "SUV": ["EcoSport", "Endeavour", "Everest", "Freestyle", "Any"]
    },
    "Volkswagen": {
      "Sedan": ["Vento", "Passat", "Jetta", "Any"],
      "Hatchback": ["Polo", "Up!", "Any"],
      "SUV": ["Tiguan Allspace", "T-Roc", "Any"]
    },
    "Nissan": {
      "Sedan": ["Sunny", "Any"],
      "Hatchback": ["Any"],
      "SUV": ["Any"]
    },
    "Jeep": {
      "Sedan": ["Any"],
      "Hatchback": ["Any"],
      "SUV": ["Compass", "Renegade", "Wrangler", "Grand Cherokee", "Any"]
    },
    // Add other brands and their models as needed
  };

  const [modelTypes, setModelTypes] = useState([]);

  // 4. **Modify useEffect:** Now depends on both brand and vehicleCategory
  useEffect(() => {
    if (brand && vehicleCategory) {
      const models = brandsWithModels[brand]?.[vehicleCategory] || [];
      setModelTypes(models);
      setModelType(''); // Reset modelType when brand or category changes
      console.log(`Selected Brand: ${brand}, Selected Category: ${vehicleCategory}`);
      console.log(`Available Models: ${models}`);
    } else {
      setModelTypes([]);
      setModelType('');
    }
  }, [brand, vehicleCategory]);

  // 5. **Define Seat Options Based on Vehicle Category**
  const seatOptionsByCategory = {
    "Sedan": [2, 3, 4],
    "SUV": [2, 3, 4, 5, 6, 7, 8],
    "Hatchback": [2, 3, 4],
    "Innova": [2, 3, 4, 5, 6, 7, 8],
    "Innova Crysta": [2, 3, 4, 5, 6, 7, 8],
  };

  // 6. **Implement useEffect for Seat Options Based on Vehicle Category**
  useEffect(() => {
    if (vehicleCategory) {
      const options = seatOptionsByCategory[vehicleCategory] || [];
      setSeatsOptions(options);
      setSeats(''); // Reset selected seat when category changes
      console.log(`Vehicle Category changed to: ${vehicleCategory}`);
      console.log(`Available Seats: ${options}`);
    } else {
      setSeatsOptions([]);
      setSeats('');
    }
  }, [vehicleCategory]);

  const [insuranceFront, setInsuranceFront] = useState(null);
  const [insuranceBack, setInsuranceBack] = useState(null);
  const [rcFront, setRcFront] = useState(null);
  const [rcBack, setRcBack] = useState(null);
  const [vehicleNumberFront, setVehicleNumberFront] = useState(null);
  const [vehicleNumberBack, setVehicleNumberBack] = useState(null);
  const [seatFront, setSeatFront] = useState(null);
  const [seatBack, setSeatBack] = useState(null);

  const [vehicleData, setVehicleData] = useState(null);

  const [uploadedInsuranceFrontUrl, setUploadedInsuranceFrontUrl] = useState(null);
  const [uploadedInsuranceFrontId, setUploadedInsuranceFrontId] = useState(null);
  const [uploadedInsuranceBackUrl, setUploadedInsuranceBackUrl] = useState(null);
  const [uploadedInsuranceBackId, setUploadedInsuranceBackId] = useState(null);

  const [uploadedRcFrontUrl, setUploadedRcFrontUrl] = useState(null);
  const [uploadedRcFrontId, setUploadedRcFrontId] = useState(null);
  const [uploadedRcBackUrl, setUploadedRcBackUrl] = useState(null);
  const [uploadedRcBackId, setUploadedRcBackId] = useState(null);

  const [uploadedVehicleNumberFrontUrl, setUploadedVehicleNumberFrontUrl] = useState(null);
  const [uploadedVehicleNumberFrontId, setUploadedVehicleNumberFrontId] = useState(null);
  const [uploadedVehicleNumberBackUrl, setUploadedVehicleNumberBackUrl] = useState(null);
  const [uploadedVehicleNumberBackId, setUploadedVehicleNumberBackId] = useState(null);

  const [uploadedSeatFrontUrl, setUploadedSeatFrontUrl] = useState(null);
  const [uploadedSeatFrontId, setUploadedSeatFrontId] = useState(null);
  const [uploadedSeatBackUrl, setUploadedSeatBackUrl] = useState(null);
  const [uploadedSeatBackId, setUploadedSeatBackId] = useState(null);

  const handleFileChange = (event, setImage, imageTypeCodeDescription, setImageUrl, setImageId) => {
    const file = event.target.files[0];
    setImage(file);
    handleImageUpload(file, imageTypeCodeDescription, setImageUrl, setImageId);
  };

  const handleImageUpload = (file, imageTypeCodeDescription, setImageUrl, setImageId) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('imageTypeCodeDescription', imageTypeCodeDescription);
    formData.append('entityType', 'vehicle');
    if (vehicleNumber) {
      formData.append('entityId', vehicleNumber);
    } else {
      console.log("Vehicle Number is null");
      toast.error("Enter Vehicle Number (or) Find the Vehicle Before Uploading...");
      return;
    };

    api.post('/drivervehicle/images/upload', formData)
      .then(response => {
        const imageUrl = `${process.env.REACT_APP_API_BASE_URLE}/drivervehicle/images/${response.data.imageId}`;
        setImageUrl(imageUrl);
        setImageId(response.data.imageId);
        toast.success("Image uploaded successfully");
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error("Image upload failed");
      });
  };

  const handleRemoveImage = (imageId, setImageUrl, setImageId) => {
    api.delete(`/drivervehicle/images/${imageId}`)
      .then(response => {
        setImageUrl(null);
        setImageId(null);
        toast.error("Image Deleted...");
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const handleClear = () => {
    setVehicleID("");
    setVehicleData("");
    setVehicleNumber("");
    setVehicleCategory("");
    setBrand("");
    setModelType("");
    setFuelType("");
    setOwnerName("");
    setColor("");
    setSeats("");
    setLuggages("");
    setOwnership("");
    setLocation("");
    setStickerNumber("");
    setChassisNumber("");
    setEngineNumber("");
    setTransmissionType("");
    setRegistrationDate("");
    setRcValidUptoDate("");
    setInsuranceValidUptoDate("");
    setVendorPhoneNumber("");
    setDriverPhoneNumber("");
    setModelTypes([]); // Clear modelTypes as well
    setSeatsOptions([]); // **Reset seat options**
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [vehicleNumber, vehicleCategory, brand, modelType, fuelType, ownerName, color, seats, luggages, ownership, location, stickerNumber, chassisNumber, engineNumber, transmissionType, vendorPhoneNumber, driverPhoneNumber, registrationDate, rcValidUptoDate, insuranceValidUptoDate, termsAccepted]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!vehicleNumber || !vehicleCategory || !brand || !modelType || !fuelType || !ownerName || !color || !seats || !luggages || !ownership || !location || !chassisNumber || !engineNumber || !transmissionType || !registrationDate || !rcValidUptoDate || !insuranceValidUptoDate) {
      toast.error("Please fill out all required fields.");
      return;
    }

    // Validate and format the driver phone number
    let formattedDriverPhoneNumber = driverPhoneNumber;
    if (driverPhoneNumber.length === 10) {
      formattedDriverPhoneNumber = '91' + driverPhoneNumber; // Add '91' prefix for 10-digit numbers
    } else if (driverPhoneNumber.length === 12) {
      formattedDriverPhoneNumber = driverPhoneNumber; // Use as is for 12-digit numbers
    } else {
      toast.error("Driver phone number must be either 10 or 12 digits.");
      return; // Stop the form submission if the phone number is invalid
    }

    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const vehicleData = {
      vehicleNumber,
      vendorPhoneNumber,
      driverPhoneNumber: formattedDriverPhoneNumber,
      vehicleCategory,
      brand,
      modelType,
      fuelType,
      ownerName,
      color,
      seats,
      luggages,
      ownership,
      location,
      stickerNumber,
      chassisNumber,
      engineNumber,
      transmissionType,
      registrationDate,
      rcValidUptoDate,
      insuranceValidUptoDate,
      termsAccepted,
    };

    api.post('/drivervehicle/vehicle/add', vehicleData)
      .then(response => {
        toast.success("Vehicle information saved successfully.");
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error("Failed to save Engine Chassis Vehicle Number.");
        handleClear();
      });
  };

  const handleSearch = async () => {
    try {
      if (!vehicleNumber) {
        toast.error("Enter Vehicle Number");
        return;
      }

      const response = await api.get(`/drivervehicle/vehicle/${vehicleNumber}`);
      if (response.status === 200) {
        const vehicleData1 = response.data;
        if (vehicleData1.id && vehicleData1.vehicleNumber) {
          setVehicleID(vehicleData1.id);
          setVehicleData(vehicleData1);
          handleExistingFields(vehicleData1);
        } else {
          toast.error("Incomplete Vehicle data received.");
          handleClear();
        }
      } else {
        toast.error("Vehicle not there yet.");
        handleClear();
      }
    } catch (error) {
      console.error("Error fetching Vehicle data:", error);
      toast.error("Failed to fetch Vehicle data.");
      handleClear();
    }
  };

  const handleExistingFields = (vehicleData) => {
    if (vehicleData) {
      setVehicleNumber(vehicleData.vehicleNumber || '');
      setVehicleCategory(vehicleData.vehicleCategory || '');
      setBrand(vehicleData.brand || '');
      setModelType(vehicleData.modelType || '');
      setFuelType(vehicleData.fuelType || '');
      setOwnerName(vehicleData.ownerName || '');
      setColor(vehicleData.color || '');
      setSeats(vehicleData.seats || '');
      setOwnership(vehicleData.ownership || '');
      setLocation(vehicleData.location || '');
      setStickerNumber(vehicleData.stickerNumber || '');
      setChassisNumber(vehicleData.chassisNumber || '');
      setEngineNumber(vehicleData.engineNumber || '');
      setTransmissionType(vehicleData.transmissionType || '');
      setRegistrationDate(vehicleData.registrationDate || '');
      setRcValidUptoDate(vehicleData.rcValidUptoDate || '');
      setInsuranceValidUptoDate(vehicleData.insuranceValidUptoDate || '');
      setVendorPhoneNumber(vehicleData.vendorPhoneNumber || '');
      setDriverPhoneNumber(vehicleData.driverPhoneNumber || '');
      setTermsAccepted(vehicleData.termsAccepted || '');

      // **Update seat options based on the fetched vehicle category**
      if (vehicleData.vehicleCategory) {
        const options = seatOptionsByCategory[vehicleData.vehicleCategory] || [];
        setSeatsOptions(options);
        console.log(`Fetched Vehicle Category: ${vehicleData.vehicleCategory}`);
        console.log(`Fetched Available Seats: ${options}`);
      }

      // **Update model types based on fetched brand and category**
      if (vehicleData.brand && vehicleData.vehicleCategory) {
        const models = brandsWithModels[vehicleData.brand]?.[vehicleData.vehicleCategory] || [];
        setModelTypes(models);
        console.log(`Fetched Brand: ${vehicleData.brand}, Category: ${vehicleData.vehicleCategory}`);
        console.log(`Fetched Available Models: ${models}`);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleFormKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <Fragment>
      <div className="flex">
        <AdminSidebar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
        />
        <div
          className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"
            }`}
        >
          <AdminNavbar
            sidebarToggle={sidebarToggle}
            setSidebarToggle={setSidebarToggle}
          />
          <div className="max-w-2xl mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Add Vehicle</h2>
            <form onSubmit={handleSubmit} className="space-y-4" onKeyDown={handleFormKeyDown}>
              <section className="border p-4 rounded-md">
                <h3 className="text-xl font-semibold mb-2">Basic Details</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium">Vehicle Number</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      value={vehicleNumber}
                      onChange={(e) => setVehicleNumber(e.target.value)}
                      placeholder="Enter Vehicle Number"
                      onKeyDown={handleKeyDown}
                    />
                    <div>
                      <button type="button" onClick={handleSearch} className="bg-fixblue text-white py-1 px-4 font-bold rounded-md mt-2">Find</button>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium">Vehicle Category</label>
                    <select
                      className="w-full border rounded-md p-2"
                      value={vehicleCategory}
                      onChange={(e) => setVehicleCategory(e.target.value)}
                    >
                      <option value="">Select Vehicle Category</option>
                      {vehicleCategories.map((category) => (
                        <option key={category} value={category}>{category}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Vendor UserID</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      value={vendorPhoneNumber}
                      onChange={(e) => setVendorPhoneNumber(e.target.value)}
                      placeholder="Enter Vendor Phone Number"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Driver Phone Number</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      value={driverPhoneNumber}
                      onChange={(e) => setDriverPhoneNumber(e.target.value)}
                      placeholder="Enter Driver Phone Number"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Brand</label>
                    <select
                      className="w-full border rounded-md p-2"
                      value={brand}
                      onChange={(e) => setBrand(e.target.value)}
                    >
                      <option value="">Select Brand</option>
                      {brands.map((brandItem) => (
                        <option key={brandItem} value={brandItem}>{brandItem}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Model Type</label>
                    <select
                      className="w-full border rounded-md p-2"
                      value={modelType}
                      onChange={(e) => setModelType(e.target.value)}
                      disabled={!brand || !vehicleCategory} // **Disable until brand and category are selected**
                    >
                      <option value="">Select Model</option>
                      {modelTypes.map((model) => (
                        <option key={model} value={model}>{model}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Fuel Type</label>
                    <select
                      className="w-full border rounded-md p-2"
                      value={fuelType}
                      onChange={(e) => setFuelType(e.target.value)}
                    >
                      <option value="">Select Fuel Type</option>
                      {fuelTypes.map((fuel) => (
                        <option key={fuel} value={fuel}>{fuel}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Owner's Name</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      value={ownerName}
                      onChange={(e) => setOwnerName(e.target.value)}
                      placeholder="Enter Owner's Name"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Color</label>
                    <select
                      className="w-full border rounded-md p-2"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                    >
                      <option value="">Select Color</option>
                      {colors.map((colorItem) => (
                        <option key={colorItem} value={colorItem}>{colorItem}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Seats</label>
                    <select
                      className="w-full border rounded-md p-2"
                      value={seats}
                      onChange={(e) => setSeats(e.target.value)}
                    >
                      <option value="">Select Vehicle Seats</option>
                      {seatsOptions.map((seat) => (
                        <option key={seat} value={seat}>{seat}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Luggage Capacity</label>
                    <select
                      className="w-full border rounded-md p-2"
                      value={luggages}
                      onChange={(e) => setLuggages(e.target.value)}
                    >
                      <option value="">Select Luggage Capacity</option>
                      {luggagesOptions.map((luggage) => (
                        <option key={luggage} value={luggage}>{luggage}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Vehicle Ownership</label>
                    <div className="mt-1 flex items-center space-x-4">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="ownership"
                          className="mr-2"
                          value="owned"
                          checked={ownership === 'owned'}
                          onChange={(e) => setOwnership(e.target.value)}
                        />
                        Owned
                      </label>
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="ownership"
                          className="mr-2"
                          value="lease"
                          checked={ownership === 'lease'}
                          onChange={(e) => setOwnership(e.target.value)}
                        />
                        Lease
                      </label>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Location</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      placeholder="Location"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Sticker Number</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      value={stickerNumber}
                      onChange={(e) => setStickerNumber(e.target.value)}
                      placeholder="Enter Sticker Number"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Chassis Number</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      value={chassisNumber}
                      onChange={(e) => setChassisNumber(e.target.value)}
                      placeholder="Enter Chassis Number"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Engine Number</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      value={engineNumber}
                      onChange={(e) => setEngineNumber(e.target.value)}
                      placeholder="Enter Engine Number"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Transmission Type</label>
                    <select
                      className="w-full border rounded-md p-2"
                      value={transmissionType}
                      onChange={(e) => setTransmissionType(e.target.value)}
                    >
                      <option value="">Select Transmission Type</option>
                      {transmissionTypes.map((type) => (
                        <option key={type} value={type}>{type}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </section>

              <section className="border p-4 rounded-md">
                <h3 className="text-xl font-semibold mb-2">Vehicle Documents</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium">Insurance Valid Upto</label>
                    <input
                      type="date"
                      className="w-full border rounded-md p-2"
                      value={insuranceValidUptoDate}
                      onChange={(e) => setInsuranceValidUptoDate(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Upload Insurance Copy</label>
                    <div className="mt-1 flex items-center">
                      <input type="file" className="hidden" id="vehicle-insurance-front"
                        onChange={(e) => handleFileChange(e, setInsuranceFront, 'vehicle_insurance_front', setUploadedInsuranceFrontUrl, setUploadedInsuranceFrontId)}
                      />
                      <label htmlFor="vehicle-insurance-front" className="flex items-center cursor-pointer text-blue-600 hover:underline">
                        <FaUpload className="mr-2" /> Upload Front Photo
                      </label>

                      {uploadedInsuranceFrontUrl && (
                        <div className="mt-2 relative">
                          <img src={uploadedInsuranceFrontUrl} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedInsuranceFrontId, setUploadedInsuranceFrontUrl, setUploadedInsuranceFrontId)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}

                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Upload Insurance Back Copy</label>
                    <div className="mt-1 flex items-center">
                      <input type="file" className="hidden" id="vehicle-insurance-back"
                        onChange={(e) => handleFileChange(e, setInsuranceBack, 'vehicle_insurance_back', setUploadedInsuranceBackUrl, setUploadedInsuranceBackId)}
                      />
                      <label htmlFor="vehicle-insurance-back" className="flex items-center cursor-pointer text-blue-600 hover:underline">
                        <FaUpload className="mr-2" /> Upload Back Photo
                      </label>

                      {uploadedInsuranceBackUrl && (
                        <div className="mt-2 relative">
                          <img src={uploadedInsuranceBackUrl} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedInsuranceBackId, setUploadedInsuranceBackUrl, setUploadedInsuranceBackId)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}

                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Registration Date</label>
                    <input
                      type="date"
                      className="w-full border rounded-md p-2"
                      value={registrationDate}
                      onChange={(e) => setRegistrationDate(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">RC Valid Upto</label>
                    <input
                      type="date"
                      className="w-full border rounded-md p-2"
                      value={rcValidUptoDate}
                      onChange={(e) => setRcValidUptoDate(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Registration Certificate Front</label>
                    <div className="mt-1 flex items-center">
                      <input type="file" className="hidden" id="vehicle-rc-front"
                        onChange={(e) => handleFileChange(e, setRcFront, 'vehicle_rc_front', setUploadedRcFrontUrl, setUploadedRcFrontId)}
                      />
                      <label htmlFor="vehicle-rc-front" className="flex items-center cursor-pointer text-blue-600 hover:underline">
                        <FaUpload className="mr-2" /> Upload Front Photo
                      </label>

                      {uploadedRcFrontUrl && (
                        <div className="mt-2 relative">
                          <img src={uploadedRcFrontUrl} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedRcFrontId, setUploadedRcFrontUrl, setUploadedRcFrontId)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}

                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Registration Certificate Back</label>
                    <div className="mt-1 flex items-center">
                      <input type="file" className="hidden" id="vehicle-rc-back"
                        onChange={(e) => handleFileChange(e, setRcBack, 'vehicle_rc_back', setUploadedRcBackUrl, setUploadedRcBackId)}
                      />
                      <label htmlFor="vehicle-rc-back" className="flex items-center cursor-pointer text-blue-600 hover:underline">
                        <FaUpload className="mr-2" /> Upload Back Photo
                      </label>

                      {uploadedRcBackUrl && (
                        <div className="mt-2 relative">
                          <img src={uploadedRcBackUrl} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedRcBackId, setUploadedRcBackUrl, setUploadedRcBackId)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}

                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Car Photo with Number Plate Front</label>
                    <div className="mt-1 flex items-center">
                      <input type="file" className="hidden" id="vehicle-number-front"
                        onChange={(e) => handleFileChange(e, setVehicleNumberFront, 'vehicle_number_front', setUploadedVehicleNumberFrontUrl, setUploadedVehicleNumberFrontId)}
                      />
                      <label htmlFor="vehicle-number-front" className="flex items-center cursor-pointer text-blue-600 hover:underline">
                        <FaUpload className="mr-2" /> Upload Front Photo
                      </label>

                      {uploadedVehicleNumberFrontUrl && (
                        <div className="mt-2 relative">
                          <img src={uploadedVehicleNumberFrontUrl} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedVehicleNumberFrontId, setUploadedVehicleNumberFrontUrl, setUploadedVehicleNumberFrontId)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}

                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Car Photo with Number Plate Back</label>
                    <div className="mt-1 flex items-center">
                      <input type="file" className="hidden" id="vehicle-number-back"
                        onChange={(e) => handleFileChange(e, setVehicleNumberBack, 'vehicle_number_back', setUploadedVehicleNumberBackUrl, setUploadedVehicleNumberBackId)}
                      />
                      <label htmlFor="vehicle-number-back" className="flex items-center cursor-pointer text-blue-600 hover:underline">
                        <FaUpload className="mr-2" /> Upload Back Photo
                      </label>

                      {uploadedVehicleNumberBackUrl && (
                        <div className="mt-2 relative">
                          <img src={uploadedVehicleNumberBackUrl} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedVehicleNumberBackId, setUploadedVehicleNumberBackUrl, setUploadedVehicleNumberBackId)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}

                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Seat Front Photo</label>
                    <div className="mt-1 flex items-center">
                      <input type="file" className="hidden" id="vehicle-seat-front"
                        onChange={(e) => handleFileChange(e, setSeatFront, 'vehicle_seat_front', setUploadedSeatFrontUrl, setUploadedSeatFrontId)}
                      />
                      <label htmlFor="vehicle-seat-front" className="flex items-center cursor-pointer text-blue-600 hover:underline">
                        <FaUpload className="mr-2" /> Upload Front Photo
                      </label>
                      {uploadedSeatFrontUrl && (
                        <div className="mt-2 relative">
                          <img src={uploadedSeatFrontUrl} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedSeatFrontId, setUploadedSeatFrontUrl, setUploadedSeatFrontId)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Seat Back Photo</label>
                    <div className="mt-1 flex items-center">
                      <input type="file" className="hidden" id="vehicle-seat-back"
                        onChange={(e) => handleFileChange(e, setSeatBack, 'vehicle_seat_back', setUploadedSeatBackUrl, setUploadedSeatBackId)}
                      />
                      <label htmlFor="vehicle-seat-back" className="flex items-center cursor-pointer text-blue-600 hover:underline">
                        <FaUpload className="mr-2" /> Upload Back Photo
                      </label>

                      {uploadedSeatBackUrl && (
                        <div className="mt-2 relative">
                          <img src={uploadedSeatBackUrl} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedSeatBackId, setUploadedSeatBackUrl, setUploadedSeatBackId)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}

                    </div>
                  </div>
                </div>
              </section>
              <div>
                <input
                  type="checkbox"
                  className="border rounded-md p-2 mr-2"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
                <label className="block text-sm font-medium">
                  Accept terms & conditions
                </label>
              </div>
              <div className="flex items-center justify-center text-[1rem]">
                <button
                  type="submit"
                  className="bg-fixblue text-white py-2 px-6 font-bold rounded-md"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VehicleForm;

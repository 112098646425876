import React, { Fragment, useState, useEffect } from "react";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import axios from "axios";
import { Link } from "react-router-dom";
import {
    FaAngleDown,
    FaFilter,
    FaList,
    FaMapMarkerAlt,
    FaPlus,
} from "react-icons/fa";
import DriverMini from "../../../components/admin/DriverMini";
import { useGoogleMaps } from "../../../pages/sitePages/MapUtils";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URLE,
    headers: {
        'ngrok-skip-browser-warning': 'true',
        'Content-Type': 'application/json'
    }
});

function MyDrivers() {
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const [tab, setTab] = useState("list");
    const [driverOption, setDriverOption] = useState("driverlist");
    const [showFilters, setShowFilters] = useState(false);
    const [showFiltersPanel, setShowFiltersPanel] = useState(false);
    const [filters, setFilters] = useState({
        Checkup_In_Progress: false,
        Checkup_Pending: false,
        Checkup_Completed: false,
    });

    const [drivers, setDrivers] = useState([]);

    useEffect(() => {
        const fetchDrivers = async () => {
            try {
                // const response = await api.get('/drivervehicle/accountactivation/mydriverlist');
                const response = await api.get('/drivervehicle/accountactivation/'); //temp
                setDrivers(response.data);
            } catch (error) {
                console.error('Error fetching drivers:', error);
            }
        };

        fetchDrivers();
    }, []);

    const {
        map,
        setMap,
        source,
        setSource,
        destination,
        setDestination,
        isMapsApiLoaded,
        handlePlaceSelect,
        calculateDistance,
        updateMap,
        setAutocompleteSource,
        setAutocompleteDestination,
    } = useGoogleMaps();

    const showMapView = () => {
        setTab("map");
    };

    const showListView = () => {
        setTab("list");
    };

    const handleDriverChange = (e) => {
        setDriverOption(e.target.value);
    };

    const handleCheckboxChange = (filter) => {
        setFilters({
            ...filters,
            [filter]: !filters[filter], // Toggle the filter
        });
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const applyFilters = () => {
        // Apply filters logic here
        setShowFiltersPanel(false);
    };

    return (
        <Fragment>
            <AdminSidebar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
            />
            <div
                className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"
                    }`}
            >
                <AdminNavbar
                    sidebarToggle={sidebarToggle}
                    setSidebarToggle={setSidebarToggle}
                />
                <div className="bg-gray-200 min-h-screen p-2">
                    <div className="bg-white shadow-lg p-2 rounded-lg">
                        <div className="flex flex-col sm:flex-row justify-between items-center">
                            <div className="text-gray-700">
                                <h1 className="text-xl font-semibold">My Drivers</h1>
                                <span>{new Date().toLocaleDateString()}</span>
                            </div>
                            <div className="flex items-center mt-4 sm:mt-0">
                                <input
                                    type="text"
                                    placeholder="Search Driver"
                                    className="bg-gray-100 px-4 py-2 rounded-l-lg focus:outline-none w-full sm:w-auto"
                                />
                                <Link
                                    to="/admin/driverform"
                                    className="bg-blue-500 inline-flex items-center text-white px-2 py-2 rounded-r-lg focus:outline-none"
                                >
                                    <FaPlus className="mr-2" /> DRIVER
                                </Link>
                            </div>
                        </div>
                        <hr className="my-2" />
                        <div className="flex gap-4 items-center justify-center text-sm">
                            <h5
                                className={`cursor-pointer inline-flex items-center ${tab === "list"
                                        ? "text-fixblue"
                                        : "hover:bg-fixred hover:text-fixwhite px-2 py-1 rounded-sm text-fixblack"
                                    }`}
                                onClick={showListView}
                            >
                                <FaList className="mr-1" /> List View
                            </h5>
                            <h5
                                className={`cursor-pointer inline-flex items-center ${tab === "map"
                                        ? "text-fixblue"
                                        : "hover:bg-fixred hover:text-fixwhite px-2 py-1 rounded-sm text-fixblack"
                                    }`}
                                onClick={showMapView}
                            >
                                <FaMapMarkerAlt className="mr-1" /> Map View
                            </h5>
                        </div>
                    </div>
                    {/* {tab === "map" && (
                        <div className="mt-4">
                            <div style={{ height: "400px" }}>
                                <GoogleMap
                                    mapContainerStyle={{ height: "100%", width: "100%" }}
                                    center={{ lat: 20.5937, lng: 78.9629 }}
                                    zoom={4}
                                    onLoad={map => setMap(map)} // Set the map instance
                                >
                                  
                                    {source && (
                                        <Marker position={{ lat: source.lat, lng: source.lng }} />
                                    )}
                                </GoogleMap>
                            </div>
                        </div>
                    )} */}

                    {tab === "list" && (
                        <div className="container mx-auto">
                            <div className="m-2 flex justify-between items-center">
                                <div className="relative flex-1 sm:w-auto">
                                    <select
                                        value={driverOption}
                                        onChange={handleDriverChange}
                                        className="bg-white border border-gray-300 text-gray-700 px-2 py-1 rounded leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 sm:w-auto"
                                    >
                                        <option value="driverlist">Driver List</option>
                                        <option value="progress">Reg in Progress</option>
                                        <option value="available">Available</option>
                                        <option value="deactivated">Driver Deactivated</option>
                                        <option value="incabs">Reg On Incabs</option>
                                        <option value="ontrip">On Trip</option>
                                        <option value="loggedout">Logged Out</option>
                                    </select>
                                </div>

                                <button
                                    className="bg-white border border-gray-300 rounded-md px-2 py-1 flex items-center space-x-2 focus:outline-none"
                                    onClick={() => setShowFiltersPanel(!showFiltersPanel)}
                                >
                                    <FaFilter className="text-gray-500 text-[0.8rem]" />
                                    <span>Filters</span>
                                </button>

                                {showFiltersPanel && (
                                    <div className="absolute right-2 bg-gray-100 border border-gray-300 shadow-lg rounded-md z-10">
                                        <div className="px-4 py-2">
                                            <h4 className="text-[1rem] font-semibold mb-1">Filters</h4>
                                            {Object.keys(filters).map((filter) => (
                                                <label key={filter} className="flex items-center space-x-1">
                                                    <input
                                                        type="checkbox"
                                                        checked={filters[filter]}
                                                        onChange={() => handleCheckboxChange(filter)}
                                                        className="form-checkbox h-4 w-4 text-fixblue"
                                                    />
                                                    <span>{filter}</span>
                                                </label>
                                            ))}
                                            <div className="flex justify-end mt-2">
                                                <button
                                                    className="bg-fixblue w-full text-white px-4 py-2 rounded-lg"
                                                    onClick={applyFilters}
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <hr className="my-2 border-b-[1px] border-fixblack" />
                            <div className="container mx-auto">
                                <div className="flex justify-center">
                                    <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                                        {drivers.map(driver => (
                                            <DriverMini key={driver.id} driver={driver} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

export default MyDrivers;

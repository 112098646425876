import React, { Fragment, useState, useEffect } from "react";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import AdminLayout from "../../../components/admin/AdminLayout";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { FaCar } from "react-icons/fa";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    'ngrok-skip-browser-warning': 'true',
    'Content-Type': 'application/json'
  }
});

const VendorsVehicle = () => {
  const [tab, setTab] = useState("all");
  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const { vendorPhoneNumber } = useParams(); // Get vendorPhoneNumber from the route

  const handleTabChange = (type) => {
    setTab(type);
    filterVehicles(type);
  };

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await api.get(`/drivervehicle/vehicle/searchbyvendor/${vendorPhoneNumber}`);
        const vehicleData = response.data;
        console.log("vehicleData", vehicleData);

        const enrichedVehicles = vehicleData.map(vehicle => {
          const checkupStatus = determineCheckupStatus(vehicle.approved, vehicle.active);
          const bgColor = checkupStatus === "Active" ? "bg-green-500" : checkupStatus === "Blocked" ? "bg-red-500" : "bg-yellow-500";

          return {
            ...vehicle,
            checkupStatus: checkupStatus,
            bgColor: bgColor
          };
        });

        setVehicles(enrichedVehicles);
        setFilteredVehicles(enrichedVehicles); // Set initial filter
      } catch (error) {
        console.error('Error fetching vehicles:', error);
      }
    };

    fetchVehicles();
  }, [vendorPhoneNumber]);

  const determineCheckupStatus = (approved, active) => {
    if (approved && active) {
      return "Active";
    } else if (!approved && active) {
      return "Pending";
    } else {
      return "Blocked";
    }
  };

  const filterVehicles = (type) => {
    let filtered = vehicles;
    if (type === "active") {
      filtered = vehicles.filter(vehicle => vehicle.checkupStatus === "Active");
    } else if (type === "pending") {
      filtered = vehicles.filter(vehicle => vehicle.checkupStatus === "Pending");
    } else if (type === "blocked") {
      filtered = vehicles.filter(vehicle => vehicle.checkupStatus === "Blocked");
    }
    setFilteredVehicles(filtered);
  };

  return (
    <Fragment>
      <AdminLayout>
        <div className="bg-gray-200 min-h-screen p-2">
          <div className="bg-white shadow-lg p-2 rounded-lg">
            <div className="flex flex-col justify-between sm:flex-row items-center">
              <div className="text-gray-700">
                <h1 className="text-xl font-semibold">Vendor's Vehicles</h1>
                <span>{new Date().toLocaleDateString()}</span>
              </div>
              <div className="flex items-center mt-4 sm:mt-0">
                <input
                  type="text"
                  placeholder="Search Vehicle"
                  className="bg-gray-100 px-4 py-2 rounded-l-lg text-[0.8rem] focus:outline-none w-full sm:w-auto"
                />
              </div>
            </div>
            <hr className="my-2" />
            <div className="flex flex-col gap-1 items-center justify-evenly sm:flex-row text-sm">
              {["all", "active", "pending", "blocked"].map((type) => (
                <button
                  key={type}
                  className={`cursor-pointer inline-flex items-center ${tab === type
                      ? "text-blue-500 underline"
                      : "hover:bg-red-500 hover:text-white px-2 py-1 rounded-sm text-gray-700"
                    }`}
                  onClick={() => handleTabChange(type)}
                >
                  <FaCar className="mr-1" />
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </button>
              ))}
            </div>
          </div>

          <hr className="my-2 border-b-[1px] border-fixblack" />

          <div className="container mx-auto">
            <h2 className="text-xl font-semibold mb-4">Vehicle List</h2>
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2">Vehicle Number</th>
                  <th className="py-2">Model</th>
                  <th className="py-2">Brand</th>
                  <th className="py-2">Fuel Type</th>
                  <th className="py-2">Active</th>
                  <th className="py-2">Checkup Status</th>
                  <th className="py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredVehicles.map((vehicle) => (
                  <tr key={vehicle.id} className="border-b">
                    <td className="py-2">{vehicle.vehicleNumber}</td>
                    <td className="py-2">{vehicle.modelType}</td>
                    <td className="py-2">{vehicle.brand}</td>
                    <td className="py-2">{vehicle.fuelType}</td>
                    <td className="py-2">
                      <span
                        className={`px-2 py-1 rounded-full text-white ${vehicle.active ? 'bg-green-500' : 'bg-red-500'}`}
                      >
                        {vehicle.active ? "Active" : "Inactive"}
                      </span>
                    </td>
                    <td className="py-2">
                      <span className={`px-2 py-1 rounded-full text-white ${vehicle.bgColor}`}>
                        {vehicle.checkupStatus}
                      </span>
                    </td>
                    <td className="py-2">
                      <div className="flex space-x-2">
                        <Link to={`/admin/viewvehicle/${vehicle.driverPhoneNumber}`} className="bg-blue-500 text-white px-2 py-1 rounded">Profile</Link>
                        <Link to={`/admin/viewvendor/${vehicle.vendorPhoneNumber}`} className="bg-blue-500 text-white px-2 py-1 rounded">Vendor</Link>
                        <Link to={`/admin/viewdriver/${vehicle.driverPhoneNumber}`} className="bg-blue-500 text-white px-2 py-1 rounded">Driver</Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    </Fragment>
  );
};

export default VendorsVehicle;

import React, { Fragment, useState, useEffect } from "react";
import { FaUpload } from "react-icons/fa";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

// Create an Axios instance with the custom header
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    'ngrok-skip-browser-warning': 'true' // Skip ngrok warning page
  }
});

const DriverForm = () => {
  const [vendorId, setVendorId] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [alternateMobileNumber, setAlternateMobileNumber] = useState('');
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [experience, setExperience] = useState('');
  const [drivingSinceDate, setDrivingSinceDate] = useState('');
  const [licenseExpiryDate, setLicenseExpiryDate] = useState('');
  const [licenseIdNumber, setLicenseIdNumber] = useState('');
  const [idProofNumber, setIdProofNumber] = useState('');
  const [pccDate, setPccDate] = useState('');
  const [nearestPoliceStation, setNearestPoliceStation] = useState('');
  const [driverID, setDriverID] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [driverPhoto, setDriverPhoto] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [uploadedImageId, setUploadedImageId] = useState(null);

  const [driverLicenceFront, setDriverLicenceFront] = useState(null);
  const [uploadedLicenceFrontUrl, setUploadedLicenceFrontUrl] = useState(null);
  const [uploadedLicenceFrontId, setUploadedLicenceFrontId] = useState(null);

  const [driverLicenceBack, setDriverLicenceBack] = useState(null);
  const [uploadedLicenceBackUrl, setUploadedLicenceBackUrl] = useState(null);
  const [uploadedLicenceBackId, setUploadedLicenceBackId] = useState(null);

  const [driverAadhaarFront, setDriverAadhaarFront] = useState(null);
  const [uploadedAadhaarFrontUrl, setUploadedAadhaarFrontUrl] = useState(null);
  const [uploadedAadhaarFrontId, setUploadedAadhaarFrontId] = useState(null);

  const [driverAadhaarBack, setDriverAadhaarBack] = useState(null);
  const [uploadedAadhaarBackUrl, setUploadedAadhaarBackUrl] = useState(null);
  const [uploadedAadhaarBackId, setUploadedAadhaarBackId] = useState(null);

  const [driverPassportFront, setDriverPassportFront] = useState(null);
  const [uploadedPassportFrontUrl, setUploadedPassportFrontUrl] = useState(null);
  const [uploadedPassportFrontId, setUploadedPassportFrontId] = useState(null);

  const [driverPassportBack, setDriverPassportBack] = useState(null);
  const [uploadedPassportBackUrl, setUploadedPassportBackUrl] = useState(null);
  const [uploadedPassportBackId, setUploadedPassportBackId] = useState(null);

  const [driverPccType1, setDriverPccType1] = useState(null);
  const [uploadedPccType1Url, setUploadedPccType1Url] = useState(null);
  const [uploadedPccType1Id, setUploadedPccType1Id] = useState(null);

  const [driverPccType2, setDriverPccType2] = useState(null);
  const [uploadedPccType2Url, setUploadedPccType2Url] = useState(null);
  const [uploadedPccType2Id, setUploadedPccType2Id] = useState(null);

  const [idProofType, setIdProofType] = useState("");
  const [genderType, setGenderType] = useState("");

  const [pccType, setPccType] = useState("");
  const [driverData, setDriverData] = useState("");

  useEffect(() => {
    setIsSubmitting(false);
  }, [
    vendorId,
    driverID,
    mobileNumber,
    alternateMobileNumber,
    genderType,
    name,
    dob,
    experience,
    drivingSinceDate,
    licenseExpiryDate,
    licenseIdNumber,
    idProofNumber,
    pccDate,
    nearestPoliceStation,
    termsAccepted
  ]);

  const handleFileChange = (event, setImage, imageTypeCodeDescription, setImageUrl, setImageId) => {
    const file = event.target.files[0];
    setImage(file);
    handleImageUpload(file, imageTypeCodeDescription, setImageUrl, setImageId);
  };
  const handleNearestPoliceStationChange = (event) => {
    setNearestPoliceStation(event.target.value);
  };
  const handlePccDateChange = (event) => {
    setPccDate(event.target.value);
  };
  const handleIdProofNumberChange = (event) => {
    setIdProofNumber(event.target.value);
  };
  const handleLicenseIdNumberChange = (event) => {
    setLicenseIdNumber(event.target.value);
  };
  const handleMobileNumberChange = (event) => {
    let formattedValue = event.target.value.replace(/\D/g, '');
    formattedValue = formattedValue.slice(0, 12);
    setMobileNumber(formattedValue);
  };
  const handleAlternateMobileNumber = (event) => {
    let formattedValue = event.target.value.replace(/\D/g, '');
    formattedValue = formattedValue.slice(0, 12);
    setAlternateMobileNumber(formattedValue);
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleDobChange = (event) => {
    setDob(event.target.value);
  };
  const handleExperienceChange = (event) => {
    setExperience(event.target.value);
  };
  const handleDrivingSinceDateChange = (event) => {
    setDrivingSinceDate(event.target.value);
  };
  const handleLicenseExpiryDateChange = (event) => {
    setLicenseExpiryDate(event.target.value);
  };

  const handleIdProofTypeChange = (event) => {
    setIdProofType(event.target.value);
  };
  const handleGenderTypeChange = (event) => {
    setGenderType(event.target.value);
  };

  const handlePccTypeChange = (event) => {
    setPccType(event.target.value);
  };

  const handleVendorIdChange = (event) => {
    // let formattedValue = event.target.value.replace(/\D/g, '');
    // formattedValue = formattedValue.slice(0, 8);
    // setVendorId(formattedValue);
    setVendorId(event.target.value);
  };


  const handleImageUpload = (file, imageTypeCodeDescription, setImageUrl, setImageId) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('imageTypeCodeDescription', imageTypeCodeDescription);
    formData.append('entityType', 'driver');
    if (driverID) {
      formData.append('entityId', driverID);
    } else {
      console.log("driverID is null");
      toast.error("Find the Driver first...");
      return;
    }

    api.post('/drivervehicle/images/upload', formData)
      .then(response => {
        const imageUrl = `${process.env.REACT_APP_API_BASE_URLE}/drivervehicle/images/${response.data.imageId}`;
        setImageUrl(imageUrl);
        setImageId(response.data.imageId);
        toast.success("Image uploaded successfully");
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error("Image upload failed");
      });
  };

  const handleRemoveImage = (imageId, setImageUrl, setImageId) => {
    api.delete(`/drivervehicle/images/${imageId}`)
      .then(response => {
        setImageUrl(null);
        setImageId(null);
        toast.success("Image removed successfully");
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error("Failed to remove image");
      });
  };

  const handleSearch = async () => {
    try {

      let formattedMobileNumber = mobileNumber;

      if (mobileNumber.length === 12 && mobileNumber.startsWith('91')) {
        // Remove '91' if it's already there
        formattedMobileNumber = mobileNumber;
      } else if (mobileNumber.length === 10) {
        // Add '91' if it's a 10-digit number
        // console.log("dont have 91");
        formattedMobileNumber = `91${mobileNumber}`;
      }

      
      const response = await api.get(`/drivervehicle/drivers/mobile/${formattedMobileNumber}`);
      if (response.status === 200) {
        const data = response.data;
        if (data.id && data.username) {
          setDriverID(data.id);
          setMobileNumber(data.username);
          handleSearchById(data.id);
        } else {
          toast.error("Incomplete driver data received.");
        }
      } else {
        toast.error("Driver not pre-registered yet.");
      }
    } catch (error) {
      console.error("Error fetching driver data:", error);
      toast.error("Failed to fetch driver data.");
    }
  };

  const handleSearchById = async (id) => {
    try {
      const response = await api.get(`/drivervehicle/drivers/${id}`);
      if (response.status === 200) {
        const driverData1 = response.data;
        setDriverData(driverData1);
        handleExistingFields(driverData1);
      } else {
        toast.error("Driver not fully registered.");
      }
    } catch (error) {
      console.error("Error fetching driver data:", error);
    }
  };

  const handleExistingFields = (driverData) => {
    if (driverData) {
      setVendorId(driverData.vendorId || '');
      setDriverID(driverData.id || '');
      setMobileNumber(driverData.mobileNumber || '');
      setAlternateMobileNumber(driverData.alternateMobileNumber || '');
      setGenderType(driverData.gender || '');
      setName(driverData.name || '');
      setDob(driverData.dob || '');
      setExperience(driverData.experience || '');
      setDrivingSinceDate(driverData.drivingSinceDate || '');
      setLicenseExpiryDate(driverData.licenseExpiryDate || '');
      setLicenseIdNumber(driverData.licenseIdNumber || '');
      setIdProofNumber(driverData.idProofNumber || '');
      setPccDate(driverData.pccIssueDate || '');
      setNearestPoliceStation(driverData.nearestPoliceStation || '');
      setTermsAccepted(driverData.termsAccepted || '');
    }
  };

  const handleSubmit = (event) => {
    if (isSubmitting) {
      return; 
    }
    setIsSubmitting(true);
    if(!driverID) {
      if(mobileNumber){
        event.preventDefault();
        handleSearch();
      } else {
        event.preventDefault();
        toast.error("Please find driver ID by Mobile Number");
        return;
      }
    } else {
      event.preventDefault();
      const driverData = {
        driverID,
        name: name,
        gender: genderType,
        dob: dob,
        experience: experience,
        mobileNumber,
        alternateMobileNumber: alternateMobileNumber,
        drivingSinceDate: drivingSinceDate,
        licenseIdNumber: licenseIdNumber, 
        licenseExpiryDate: licenseExpiryDate, 
        idProofNumber: idProofNumber,
        pccIssueDate: pccDate,
        nearestPoliceStation: nearestPoliceStation,
        vendorId: vendorId,
        termsAccepted: termsAccepted,
      };

      api.post('/drivervehicle/drivers/add', driverData)
        .then(response => {
          toast.success("Driver information saved successfully.");
        })
        .catch(error => {
          console.error('Error:', error);
          toast.error("Failed to save driver information.");
        });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleFormKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };


  return (
    <Fragment>
      <ToastContainer />
      <div className="flex">
        <AdminSidebar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
        />
        <div
          className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}
        >
          <AdminNavbar
            sidebarToggle={sidebarToggle}
            setSidebarToggle={setSidebarToggle}
          />
          <div className="max-w-2xl mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Add Driver</h2>
            <form className="space-y-4" onSubmit={handleSubmit} onKeyDown={handleFormKeyDown}>
              <section className="border p-4 rounded-md">
                <h3 className="text-xl font-semibold mb-2">Basic Details</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium">
                      Select Gender
                    </label>
                    <div className="mt-1">
                      <select className="w-full border rounded-md p-2" value={genderType} onChange={handleGenderTypeChange}>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="others">Others</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Driver's Name
                    </label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter driver's name"
                      value={name}
                      onChange={handleNameChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      className="w-full border rounded-md p-2"
                      value={dob}
                      onChange={handleDobChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Mobile Number"
                      value={mobileNumber}
                      onChange={handleMobileNumberChange}
                      onKeyDown={handleKeyDown}
                    />
                    <button type="button" onClick={handleSearch} className="bg-fixblue text-white py-2 px-6 font-bold rounded-md mt-2">Find</button>
                  </div>
                  <div>
                    <label>Driver ID:</label>
                    <input type="text" value={driverID} readOnly className="w-full border rounded-md p-2" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Alternate Mobile Number
                    </label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter Alternate Mobile Number"
                      value={alternateMobileNumber}
                      onChange={handleAlternateMobileNumber}
                    />
                  </div>
                </div>
              </section>

              <section className="border p-4 rounded-md">
                <h3 className="text-xl font-semibold mb-2">Driver Details</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium">
                      Driver Photo
                    </label>
                    <div className="mt-1 flex items-center">
                      <input
                        type="file"
                        className="hidden"
                        id="driver-photo"
                        onChange={(e) => handleFileChange(e, setDriverPhoto, 'driver_photo', setUploadedImageUrl, setUploadedImageId)}
                      />
                      <label htmlFor="driver-photo" className="flex items-center cursor-pointer text-blue-600 hover:underline">
                        <FaUpload className="mr-2" /> Upload a file
                      </label>
                    </div>
                    {uploadedImageUrl && (
                      <div className="mt-2 relative">
                        <img src={uploadedImageUrl} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                        <button
                          type="button"
                          onClick={() => handleRemoveImage(uploadedImageId, setUploadedImageUrl, setUploadedImageId)}
                          className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                        >
                          &times;
                        </button>
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Driving Since
                    </label>
                    <input
                      type="date"
                      className="w-full border rounded-md p-2"
                      value={drivingSinceDate}
                      onChange={handleDrivingSinceDateChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      License ID Number
                    </label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter License ID number" 
                      value={licenseIdNumber}
                      onChange={handleLicenseIdNumberChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Experience Years
                    </label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Experience Years" 
                      value={experience}
                      onChange={handleExperienceChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      License Expiration Date
                    </label>
                    <input
                      type="date"
                      className="w-full border rounded-md p-2"
                      value={licenseExpiryDate}
                      onChange={handleLicenseExpiryDateChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Upload License Copy
                    </label>
                    <div className="mt-1 flex items-center space-x-2">
                      <input
                        type="file"
                        className="hidden"
                        id="license-front"
                        onChange={(e) => handleFileChange(e, setDriverLicenceFront, 'driver_licence_front', setUploadedLicenceFrontUrl, setUploadedLicenceFrontId)}
                      />
                      <label
                        htmlFor="license-front"
                        className="flex items-center cursor-pointer text-blue-600 hover:underline"
                      >
                        <FaUpload className="mr-2" /> Upload front photo
                      </label>
                      {uploadedLicenceFrontUrl && (
                        <div className="mt-2 relative">
                          <img src={uploadedLicenceFrontUrl} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedLicenceFrontId, setUploadedLicenceFrontUrl, setUploadedLicenceFrontId)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}
                      <input
                        type="file"
                        className="hidden"
                        id="license-back"
                        onChange={(e) => handleFileChange(e, setDriverLicenceBack, 'driver_licence_back', setUploadedLicenceBackUrl, setUploadedLicenceBackId)}
                      />
                      <label
                        htmlFor="license-back"
                        className="flex items-center cursor-pointer text-blue-600 hover:underline"
                      >
                        <FaUpload className="mr-2" /> Upload back photo
                      </label>
                      {uploadedLicenceBackUrl && (
                        <div className="mt-2 relative">
                          <img src={uploadedLicenceBackUrl} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedLicenceBackId, setUploadedLicenceBackUrl, setUploadedLicenceBackId)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      ID Proof type
                    </label>
                    <div className="mt-1">
                      <select className="w-full border rounded-md p-2" value={idProofType} onChange={handleIdProofTypeChange}>
                        <option value="">Select ID Proof Type</option>
                        <option value="Aadhaar">Aadhaar</option>
                        <option value="Passport">Passport</option>
                        <option value="Other Document">Other Document</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      ID Proof number
                    </label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter ID Proof number"
                      value={idProofNumber}
                      onChange={handleIdProofNumberChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Upload ID proof Copy
                    </label>
                    <div className="mt-1 flex items-center space-x-2">
                      <input
                        type="file"
                        className="hidden"
                        id="id-front"
                        onChange={(e) => handleFileChange(e, setDriverAadhaarFront, `driver_${idProofType.toLowerCase()}_front`, setUploadedAadhaarFrontUrl, setUploadedAadhaarFrontId)}
                      />
                      <label
                        htmlFor="id-front"
                        className="flex items-center cursor-pointer text-blue-600 hover:underline"
                      >
                        <FaUpload className="mr-2" /> Upload front photo
                      </label>
                      {uploadedAadhaarFrontUrl && (
                        <div className="mt-2 relative">
                          <img src={uploadedAadhaarFrontUrl} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedAadhaarFrontId, setUploadedAadhaarFrontUrl, setUploadedAadhaarFrontId)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}
                      <input
                        type="file"
                        className="hidden"
                        id="id-back"
                        onChange={(e) => handleFileChange(e, setDriverAadhaarBack, `driver_${idProofType.toLowerCase()}_back`, setUploadedAadhaarBackUrl, setUploadedAadhaarBackId)}
                      />
                      <label
                        htmlFor="id-back"
                        className="flex items-center cursor-pointer text-blue-600 hover:underline"
                      >
                        <FaUpload className="mr-2" /> Upload back photo
                      </label>
                      {uploadedAadhaarBackUrl && (
                        <div className="mt-2 relative">
                          <img src={uploadedAadhaarBackUrl} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedAadhaarBackId, setUploadedAadhaarBackUrl, setUploadedAadhaarBackId)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Pcc Type
                    </label>
                    <div className="mt-1">
                      <select className="w-full border rounded-md p-2" value={pccType} onChange={handlePccTypeChange}>
                        <option value="">Select PCC Type</option>
                        <option value="Type1">Type 1</option>
                        <option value="Type2">Type 2</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Pcc Date
                    </label>
                    <input
                      type="date"
                      className="w-full border rounded-md p-2"
                      value={pccDate}
                      onChange={handlePccDateChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Upload Copy
                    </label>
                    <div className="mt-1 flex items-center">
                      <input
                        type="file"
                        className="hidden"
                        id="pcc-copy"
                        onChange={(e) => handleFileChange(e, setDriverPccType1, `driver_pcc_${pccType.toLowerCase()}`, setUploadedPccType1Url, setUploadedPccType1Id)}
                      />
                      <label
                        htmlFor="pcc-copy"
                        className="flex items-center cursor-pointer text-blue-600 hover:underline"
                      >
                        <FaUpload className="mr-2" /> Upload copy
                      </label>
                      {uploadedPccType1Url && (
                        <div className="mt-2 relative">
                          <img src={uploadedPccType1Url} alt="Uploaded Preview" className="w-20 h-20 object-cover" />
                          <button
                            type="button"
                            onClick={() => handleRemoveImage(uploadedPccType1Id, setUploadedPccType1Url, setUploadedPccType1Id)}
                            className="absolute top-0 right-0 text-red-600 bg-white rounded-full p-1"
                          >
                            &times;
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium">
                      Nearest police station
                    </label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="Enter Nearest police station"
                      value={nearestPoliceStation} //nearestPoliceStation
                      onChange={handleNearestPoliceStationChange}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Associated Vendor UserID</label>
                    <input
                      type="text"
                      className="w-full border rounded-md p-2"
                      placeholder="VendorID"
                      value={vendorId}
                      onChange={handleVendorIdChange}
                    />
                  </div>
                </div>
              </section>
              <div>
                <input
                  type="checkbox"
                  className="border rounded-md p-2 mr-2"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
                <label className="block text-sm font-medium">
                I accept Terms & Conditions and undertake the full responsibility of this chauffeur
                </label>
              </div>
              <div className="flex items-center justify-center text-[1rem]">
                <button type="submit" disabled={isSubmitting} onClick={handleSubmit} className="bg-fixblue text-white py-2 px-6 font-bold rounded-md" >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DriverForm;
import React, { useState } from 'react';

const steps = [
  { title: 'Accept Trip', description: 'New Trip Request' },
  { title: 'Start Trip', description: 'Starting from the Garage' },
  { title: 'Reached Customer', description: 'Reached Customer Location' },
  { title: 'Collect OTP', description: 'Enter OTP to Start Trip' },
  { title: 'Complete Trip', description: 'Trip Completed' },
  { title: 'Payment', description: 'Proceed to Payment' }
];

function TripStage() {
  const [step, setStep] = useState(0);
  const [otp, setOtp] = useState('');

  const handleNextStep = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    }
  };

  const handleOtpSubmit = () => {
    if (otp) {
      handleNextStep();
    } else {
      alert('Please enter a valid OTP.');
    }
  };

  return (
    <div className="App p-6 bg-gray-100 min-h-screen flex flex-col items-center">
      <div className="w-full max-w-2xl flex flex-col md:flex-row">
        {/* Vertical Stepper */}
        <div className="flex flex-col items-center mr-8">
          {steps.map((stepItem, index) => (
            <div key={index} className="flex flex-col items-center">
              <div
                className={`w-10 h-10 flex items-center justify-center rounded-full border-2 mb-4 ${
                  index <= step ? 'bg-blue-500 border-blue-500 text-white' : 'bg-white border-gray-300 text-gray-400'
                }`}
              >
                {index + 1}
              </div>
              {index < steps.length - 1 && (
                <div
                  className={`w-px h-12 ${
                    index < step ? 'bg-blue-500' : 'bg-gray-300'
                  }`}
                />
              )}
            </div>
          ))}
        </div>

        {/* Step Content */}
        <div className="flex-1 bg-white p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-2xl font-bold mb-4">{steps[step].title}</h2>
          <p className="mb-4">{steps[step].description}</p>

          {step === 3 && (
            <div className="mb-4">
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="border p-2 w-full rounded-lg mb-4"
                placeholder="Enter OTP"
              />
            </div>
          )}

          <button
            onClick={step === 3 ? handleOtpSubmit : handleNextStep}
            className={`px-6 py-2 rounded-lg text-white ${
              step === steps.length - 1 ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-blue-500 hover:bg-blue-600'
            }`}
          >
            {step === steps.length - 1 ? 'Pay Now' : 'Next'}
          </button>

          {/* Back button to navigate to the previous step */}
          {step > 0 && (
            <button
              onClick={() => setStep(step - 1)}
              className="px-6 py-2 mt-4 text-blue-500"
            >
              Back
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default TripStage;

import { Fragment, React, useState, useEffect } from 'react';
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Create an Axios instance with the custom header
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    'ngrok-skip-browser-warning': 'true', // Skip ngrok warning page
    'Content-Type': 'application/json'
  }
});

function AddServiceArea() {
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const [vendors, setVendors] = useState([]);
    const [states, setStates] = useState([]);
    const [serviceArea, setServiceArea] = useState([]);

    const [selectedTripType, setSelectedTripType] = useState('');
    const [selectedVendor, setSelectedVendor] = useState('');
    const [selectedStates, setSelectedStates] = useState('');

    const handleVendorChange = (e) => {
        setSelectedVendor(e.target.value);
    };
    const handleStatesChange = (e) => {
        setSelectedStates(e.target.value);
    };
    const handleTripTypeChange = (e) => {
        setSelectedTripType(e.target.value);
    };

    useEffect(() => {
        api.get('/drivervehicle/vendors/all')
            .then(response => {
                console.log("Response received:", response);
                console.log("Vendor data:", response.data);
                setVendors(response.data);
            })
            .catch(error => console.error("There was an error fetching the vendors!", error));
    }, []);

    useEffect(() => {
        api.get('/drivervehicle/vendors/states')
            .then(response => {
                console.log("Response received:", response);
                console.log("States data:", response.data);
                setStates(response.data);
            })
            .catch(error => console.error("There was an error fetching the states!", error));
    }, []);
    
    useEffect(() => {
        // Fetch service areas based on the selected vendor
        if (selectedVendor) {
            console.log(selectedVendor);
            api.get(`/drivervehicle/vendors/servicearea?vendorUserid=${selectedVendor}`)
                .then(response => {
                    console.log("Service areas response:", response);
                    setServiceArea(response.data);
                })
                .catch(error => console.error("There was an error fetching the service areas!", error));
        } else {
            setServiceArea([]); // Clear service areas if no vendor is selected
        }
    }, [selectedVendor]);

    const handleAddRegionType = () => {
        // Prepare the data to send
        const data = {
            vendorUserid: selectedVendor,
            sourceStateName: selectedStates, 
            destinationStateName: selectedStates, 
            tripType: selectedTripType
        };

        // Log data to verify
        console.log("Data to be sent:", data);

        // Send POST request to save the service area
        api.post('/drivervehicle/vendors/servicearea/add', data)
            .then(response => {
                console.log("Response received:", response);
                toast.success("Vendor Service Area added successfully.");
            })
            .catch(error => {
                console.error("There was an error saving the service area!", error);
                toast.warn(error.response.data);
            });
    };

    // Group service areas by state
    const groupedServiceAreas = serviceArea.reduce((acc, curr) => {
        if (!acc[curr.sourceStateName]) {
            acc[curr.sourceStateName] = [];
        }
        acc[curr.sourceStateName].push(curr.tripType);
        return acc;
    }, {});
        
    return (
        <Fragment>
            <ToastContainer />
            <AdminSidebar
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
            />
            <div
                className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"
                    }`}
            >
                <AdminNavbar
                    sidebarToggle={sidebarToggle}
                    setSidebarToggle={setSidebarToggle}
                />
                {/* Main content */}
                <div className="bg-zinc-200 min-h-screen p-4">
                    <div className="bg-white shadow-lg p-4 rounded-lg">
                        <div className="flex flex-col sm:flex-row justify-between items-center">
                            <div className="text-gray-700">
                                <h1 className="text-xl font-semibold">Vendor ServiceArea List</h1>
                                <span>{new Date().toLocaleDateString()}</span>
                            </div>
                        </div>
                    </div>
                    <div className="p-6 bg-white my-4">
                        <div className="mb-6">
                            <h1 className="text-2xl font-semibold">ServiceArea</h1>
                            <p className="text-sm text-gray-600">List in Area</p>
                        </div>

                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
                            <div className=''>
                                <h2 className="text-lg font-semibold">Vendor State</h2>
                                <div className="grid grid-cols-1 gap-2">
                                    <div className="flex flex-col">
                                        <label>Vendor Name *</label>
                                        <select
                                            className="p-2 border rounded"
                                            value={selectedVendor}
                                            onChange={handleVendorChange}
                                        >
                                            <option value="">Select Vendor</option>
                                            {vendors.map(vendor => (
                                                <option key={vendor.vendorUserid} value={vendor.vendorUserid}>
                                                    {vendor.vendorUserid}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="flex flex-col">
                                        <label>State List *</label>
                                        <select 
                                        className="p-2 border rounded"
                                        value={selectedStates}
                                        onChange={handleStatesChange}
                                        >
                                            <option>Select</option>
                                            {states.map((state, index) => (
                                                <option key={index} value={state}>
                                                    {state}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="flex flex-col">
                                        <label>Trip Type *</label>
                                        <select className="p-2 border rounded"
                                         value={selectedTripType}
                                         onChange={handleTripTypeChange}
                                        >
                                            <option value="">Select TripType</option>
                                            <option value="AirportPickup">Airport Pickup</option>
                                            <option value="AirportDrop">Airport Drop</option>
                                            <option value="OnewayTrip">Oneway Trip</option>
                                            <option value="RoundTrip">Round Trip</option>
                                            <option value="RentalDrive">Rental Drive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {Object.keys(groupedServiceAreas).map((state, index) => (
                                <div className="col-span-1" key={index}>
                                    <div className="max-w-sm bg-zinc-100 p-2 text-fixblack">
                                        <p className="text-fixblack">Vendor ServiceArea</p>
                                        <hr className="bg-fixblack h-0.5 my-2" />
                                        <div className="flex flex-col flex-wrap justify-between py-1">
                                            <p className="text-fixblack"><span className='font-bold'>Name:</span> {state}</p>
                                            <span className='font-bold'>TripType :</span>
                                            <ul className='ml-4'>
                                                {Array.from(new Set(groupedServiceAreas[state])).map((tripType, idx) => (
                                                    <li key={idx}>{tripType}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex  justify-center">
                            <button className="bg-fixblue w-full max-w-sm text-white px-4 py-2 rounded"
                            onClick={handleAddRegionType}>
                                ADD REGION / TYPE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AddServiceArea

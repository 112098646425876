import React from "react";

const CommonSection = ({ title }) => {
  return (
    <section className="relative bg-drive bg-center bg-cover py-6 font-sans">
      <div className="absolute inset-0 bg-fixsilver opacity-25"></div>
      <div className="container mx-auto text-center relative z-10">
        <h1 className="text-fixwhite text-4xl">{title}</h1>
      </div>
    </section>
  );
};

export default CommonSection;

// src/App.js

import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import siteRoutes from './routes/siteRoutes';
import userRoutes from './routes/userRoutes';
import adminRoutes from './routes/adminRoutes';
import vendorRoutes from './routes/vendorRoutes';
import employeeRoutes from './routes/employeeRoutes';
import driverRoutes from './routes/driverRoutes';
import ProtectedRoutes from './routes/ProtectedRoutes';
import AdminLogin from './pages/adminPages/AdminLogin';
import VendorLogin from './pages/vendorPages/VendorLogin';
import EmployeeLogin from './pages/employeePages/EmployeeLogin';
import DriverLogin from './pages/driverPages/DriverLogin';
import UserLogin from './pages/userPages/UserLogin';
import { jwtDecode } from "jwt-decode";

const App = () => {

  const token = localStorage.getItem('accessToken');
  console.log("token:App.js");

  if (token) {
    const decodedToken = jwtDecode(token);
    localStorage.setItem("user", JSON.stringify(decodedToken));

    const userData = JSON.parse(localStorage.getItem("user"));
    console.log(userData.role);
  };

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Public Site Routes */}
          {siteRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}

          {/* User-specific routes, protected by "ROLE_USER" */}
          {userRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoutes allowedRoles={['ROLE_USER']}>
                  {route.element}
                </ProtectedRoutes>
              }
            />
          ))}

          {/* Admin-specific routes, protected by "ROLE_ADMIN" */}
          {adminRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoutes allowedRoles={['ROLE_ADMIN']}>
                  {route.element}
                </ProtectedRoutes>
              }
            />
          ))}

          {/* Vendor-specific routes, protected by "ROLE_VENDOR" */}
          {vendorRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoutes allowedRoles={['ROLE_VENDOR']}>
                  {route.element}
                </ProtectedRoutes>
              }
            />
          ))}

          {/* Employee-specific routes, protected by "ROLE_EMPLOYEE" */}
          {employeeRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoutes allowedRoles={['ROLE_EMPLOYEE']}>
                  {route.element}
                </ProtectedRoutes>
              }
            />
          ))}

          {/* Driver-specific routes, protected by "ROLE_DRIVER" */}
          {driverRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoutes allowedRoles={['ROLE_DRIVER']}>
                  {route.element}
                </ProtectedRoutes>
              }
            />
          ))}

          {/* Login pages */}
          <Route path="/user/login" element={<UserLogin />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/vendor/login" element={<VendorLogin />} />
          <Route path="/employee/login" element={<EmployeeLogin />} />
          <Route path="/driver/login" element={<DriverLogin />} />
        </Routes>
      </Suspense>
    </Router >
  );
};

export default App;

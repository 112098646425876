import React from "react";
import { FaEllipsisV } from "react-icons/fa";

const ActiveRates = ({ rates, onEditRate, onDeleteRate }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {rates.map((rate) => (
        <div key={rate.id} className="border p-2 rounded shadow">
          <div className="flex justify-between items-center space-x-2 mb-2">
            <span className="text-m font-bold">{rate.ratePlan} : {rate.vendorUserid}</span>
            <div>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2"
                onClick={() => onEditRate(rate)}
              >
                Edit
              </button>
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                onClick={() => onDeleteRate(rate)}
              >
                Delete
              </button>
            </div>
          </div>
          <h2 className="text-center text-xl my-2 font-bold text-fixblue">{rate.tripType} Details</h2>
          <div className="bg-gray-100 rounded-lg shadow-lg p-2 mb-4">
            <div className="flex flex-wrap justify-between">
            {(rate.ratePlan === "Holiday" || rate.ratePlan === "WeekDays") && (
              <>
                <Detail label="Date" value={`${rate.fromDate} - ${rate.toDate}`} />
                <Detail label="Work Days" value={rate.days} />
                </>
              )}
              <Detail label="Vehicle Category" value={rate.vehicleCategory} />
              <Detail label="Vehicle Model" value={rate.vehicleModel} />
              <Detail label="Fuel Type" value={rate.fuelType} />
              <Detail label="Base Distance" value={`${rate.baseDistance} kms`} />
              <Detail label="Base Fare/km" value={`₹ ${rate.farePerKm}`} />
              <Detail label="Extra Fare/km" value={`₹ ${rate.extraFarePerKm}`} />
              <Detail label="Insight(Min.) Fare" value={`₹ ${rate.insightFare}`} />
              <Detail label="Price Rank" value={rate.rank} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const Detail = ({ label, value }) => (
  <div className="w-full lg:w-1/2">
    <p className="text-sm font-bold mb-2">{label}</p>
    <p className="text-sm">{value}</p>
  </div>
);

export default ActiveRates;

import EditTripDetails from "../pages/employeePages/EditTripDetails";
import EmployeeLogin from "../pages/employeePages/EmployeeLogin";
import EmployeePanel from "../pages/employeePages/EmployeePanel";
import EmployeeRegister from "../pages/employeePages/EmployeeRegister";

const employeeRoutes = [
    //{path:'/employee/login' , element:<EmployeeLogin />},
    {path:'/employee/dashboard',element:<EmployeePanel/>},
    // {path:'/employee/add',element:<TripAdd/>},
    {path:'/employee/edit',element:<EditTripDetails />},
    {path:'/employee/register' ,element:<EmployeeRegister/>},
];

export default employeeRoutes;

import React, { Fragment, useState } from "react";
import Badge from "../../../components/admin/Badge";
import { Rating, Mismatch } from "../../../components/admin/Rating";
import Review from "../../../components/admin/Review";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";


// Sample data (you can replace this with real data from your API)
const badgesWon = [
  { title: "Tiptop ride", description: "", image: "/images/car5.png" },
  { title: "Customer ka Rockstar", description: "", image: "/images/car2.png" },
  { title: "Driver Number 1", description: "", image: "/images/car6.png" },
];

const badgesMissed = [
  { title: "Safety 100%", image: "/images/car3.png", isMissed: true },
  { title: "Super car", image: "/images/car4.png", isMissed: true },
];

const ratings = [
  { title: "Driver Rating", rating: 4.6 },
  { title: "Vehicle Rating", rating: 4.7 },
];

const mismatches = [
  { title: "Driver same as assigned", percentage: 69 },
  { title: "Vehicle same as assigned", percentage: 94 },
];

const reviews = [
  {
    name: "Keni",
    tripDetails: "Trip details here",
    rating: 4.5,
    feedback: "Driver well behaved...",
  },
  {
    name: "saikat pandit",
    tripDetails: "Trip details here",
    rating: 5,
    feedback: "No",
  },
  {
    name: "Suriya Hazarika",
    tripDetails: "Trip details here",
    rating: 5,
    feedback: "Very good driver",
  },
  // Add more reviews as needed
];

const ClientFeedback = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  return (
    <Fragment>
      <div className="flex">
        <AdminSidebar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
        />
        <div
          className={`flex-1 transition-all duration-300 ${
            sidebarToggle ? "ml-0" : "ml-48"
          }`}
        >
          <AdminNavbar
            sidebarToggle={sidebarToggle}
            setSidebarToggle={setSidebarToggle}
          />
          <div className="bg-zinc-200 min-h-screen p-4">
            <div className="bg-white shadow-lg p-4 rounded-lg mb-6">
              <div className="flex justify-between items-center mb-2">
                <h1 className="text-xl font-semibold text-gray-600">
                  Client Feedback
                </h1>
                <span>{new Date().toLocaleDateString()}</span>
              </div>
            </div>
            <div></div>
            <div className="container mx-auto p-4">
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Customer Feedback</h1>
                <div className="text-right">
                  <p className="text-xl">Average Rating</p>
                  <p className="text-4xl font-bold">4.6/5</p>
                </div>
              </div>

              <div className="mb-8">
                <h2 className="text-xl font-semibold mb-2">My Badges</h2>
                <div className="flex flex-wrap">
                  {badgesWon.map((badge, index) => (
                    <Badge key={index} {...badge} />
                  ))}
                </div>
              </div>

              <div className="mb-8">
                <h2 className="text-xl font-semibold mb-2">Badges Missed</h2>
                <div className="flex flex-wrap">
                  {badgesMissed.map((badge, index) => (
                    <Badge key={index} {...badge} />
                  ))}
                </div>
              </div>

              <div className="mb-8">
                <h2 className="text-xl font-semibold mb-2">
                  Driver & Vehicle Ratings
                </h2>
                <div className="flex flex-wrap">
                  {ratings.map((rating, index) => (
                    <Rating key={index} {...rating} />
                  ))}
                </div>
              </div>

              <div className="mb-8">
                <h2 className="text-xl font-semibold mb-2">
                  Driver & Vehicle Mismatch
                </h2>
                <div className="flex flex-wrap">
                  {mismatches.map((mismatch, index) => (
                    <Mismatch key={index} {...mismatch} />
                  ))}
                </div>
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-2">Customer Reviews</h2>
                {reviews.map((review, index) => (
                  <Review key={index} {...review} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ClientFeedback;

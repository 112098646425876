import React, { useState } from "react";
import { BsBriefcase, BsChevronDown, BsPeople, BsQuestionSquare } from "react-icons/bs";
import { RiUser2Fill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { removeUserData } from "../../route/Storage";

function EmployeeNavbar() {
  const [showTripListDropdown, setShowTripListDropdown] = useState(false);
  const [showBookingListDropdown, setShowBookingListDropdown] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const navigate = useNavigate();

  const usernameData = JSON.parse(localStorage.getItem("user"));
  const username = usernameData.sub

  const toggleTripListDropdown = () => {
    setShowTripListDropdown(!showTripListDropdown);
    setShowBookingListDropdown(false);
  };

  const toggleBookingListDropdown = () => {
    setShowBookingListDropdown(!showBookingListDropdown);
    setShowTripListDropdown(false);
  };

  const toggleProfileDropdown = () => {
    setShowProfileDropdown(!showProfileDropdown);
  };

  const handleLogout = () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    removeUserData();
    navigate(userData.role === "ROLE_EMPLOYEE" ? "/employee/login" : "/");
  };

  const handleSettings = () => {
    navigate("/employee/dashboard");
  };

  const closeDropdowns = () => {
    setShowTripListDropdown(false);
    setShowBookingListDropdown(false);
  };

  return (
    <nav className="bg-fixblack text-fixwhite p-1 shadow-xl">
      <div className="container mx-auto flex justify-between items-center text-[14px]">
        <div className="text-[0.8rem] flex flex-col font-serif border-[1px] px-2 py-1 border-fixred">
          FIXRYDE
          <span className="text-[0.6rem] font-sans text-center text-fixgold">
            Employee
          </span>
        </div>

        <div className="flex items-center space-x-2">
          <div className="relative group">
            <button
              className="flex space-x-2 items-center hover:text-fixgold focus:outline-none"
              onClick={toggleTripListDropdown}
            >
              <BsBriefcase size={18} />
              <span className="hidden sm:inline">TRIPLISTS</span>
              <BsChevronDown size={16} />
            </button>
            {showTripListDropdown && (
              <div
                className="absolute z-10 right-0 mt-2 w-36 sm:w-full bg-fixblack shadow-lg rounded-md text-fixwhite transition-all duration-300 ease-in-out"
                onClick={closeDropdowns}
              >
                <ul>
                  <li className="hover:bg-fixgold hover:text-fixblack rounded-t-md">
                    <Link to="/employee/dashboard" className="block px-4 py-2">
                      B2B List
                    </Link>
                  </li>
                  <hr className="border-fixred" />
                  <li className="hover:bg-fixgold hover:text-fixblack">
                    <Link to="/employee/dashboard" className="block px-4 py-2">
                      B2C List
                    </Link>
                  </li>
                  {/* <hr className="border-fixred" />
                  <li className="hover:bg-fixgold hover:text-fixblack rounded-b-md">
                    <Link to="/others" className="block px-4 py-2">
                      Others
                    </Link>
                  </li> */}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <div className="relative group">
            <button
              className="flex space-x-2 items-center hover:text-fixgold focus:outline-none"
              onClick={toggleBookingListDropdown}
            >
              <BsPeople size={18} />
              <span className="hidden sm:inline">BOOKINGS</span>
              <BsChevronDown size={16} />
            </button>
            {showBookingListDropdown && (
              <div
                className="absolute z-10 right-0 mt-2 w-36 sm:w-full bg-fixblack shadow-lg rounded-md text-fixwhite transition-all duration-300 ease-in-out"
                onClick={closeDropdowns}
              >
                <ul>
                  <li className="hover:bg-fixgold hover:text-fixblack rounded-t-md">
                    <Link to="/employee/dashboard" className="block px-4 py-2">
                      B2B Book
                    </Link>
                  </li>
                  <hr className="border-fixred" />
                  <li className="hover:bg-fixgold hover:text-fixblack">
                    <Link to="/employee/dashboard" className="block px-4 py-2">
                      B2C Book
                    </Link>
                  </li>
                  {/* <hr className="border-fixred" />
                  <li className="hover:bg-fixgold hover:text-fixblack rounded-b-md">
                    <Link to="/others" className="block px-4 py-2">
                      Others
                    </Link>
                  </li> */}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="hidden sm:inline">
          <Link to="#" className="flex items-center space-x-1 hover:text-fixgold">
            <BsQuestionSquare size={20} />
            <span>Support</span>
          </Link>
        </div>
        <div className="flex justify-end">
        <span className="text-white font-bold text-[1rem]">Welcome.. : {username}</span>
        </div>

        {/* Profile Button with Dropdown */}
        <div className="relative">
          <button
            className="flex items-center space-x-1 p-1 border-2 rounded-md text-fixwhite hover:bg-fixgold hover:text-fixblack focus:outline-none"
            onClick={toggleProfileDropdown}
          >
            <RiUser2Fill size={20} />
            <span className="inline">Profile</span>
          </button>

          {showProfileDropdown && (
            <div className="relative mt-2">
            <div className="absolute right-0 mt-2 w-40 bg-white rounded-md shadow-lg z-7">
              <ul className="text-sm text-black rounded-md">
                {/* Profile or settings item can go here */}
                <li
                  className="px-4 py-2 hover:bg-fixred hover:text-white hover:font-bold cursor-pointer hover:rounded-md"
                  onClick={handleLogout}
                >
                  Logout
                </li>
              </ul>
            </div>
          </div>
          
          )}
        </div>
      </div>
    </nav>
  );
}

export default EmployeeNavbar;

// Import necessary dependencies
import React, { Fragment, useEffect, useState } from "react";
import {  Link } from "react-router-dom";
import { RiArrowUpSLine } from "react-icons/ri"; // Import the Up arrow icon
import Navbar from "../../components/site/Navbar";
import Footer from "../../components/site/Footer";

// Define the BlogDetails component
const BlogDetails = ({ blog }) => {
  // State to show or hide the scroll button
  const [showScroll, setShowScroll] = useState(false);

  // Scroll to top function
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Check scroll top function
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 100) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 100) {
      setShowScroll(false);
    }
  };

  // Effect to scroll to top and add/remove scroll listener
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", checkScrollTop);
    return function cleanup() {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, );

  return (
    <Fragment>
      {/* Navbar */}
      <Navbar />

      {/* Main content */}
      <section>
        <div className="container mx-auto p-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Left side - Blog content */}
            <div className="col-span-2">
              {/* Content of the blog post */}
              <div className="blog__details">
                <img src={blog.imgUrl} alt="" className="w-full" />
                <h2 className="text-3xl font-bold mt-4">{blog.title}</h2>
                <div className="blog__publisher flex items-center gap-4 mb-4">
                  <span className="blog__author">
                    <i className="ri-user-line"></i> {blog.author}
                  </span>
                  <span className="flex items-center gap-1 text-sm">
                    <i className="ri-calendar-line"></i> {blog.date}
                  </span>
                  <span className="flex items-center gap-1 text-sm">
                    <i className="ri-time-line"></i> {blog.time}
                  </span>
                </div>
                <p className="text-base">{blog.description}</p>
                <h6 className="ps-5 font-normal">
                  <blockquote className="text-lg">{blog.quote}</blockquote>
                </h6>
                <p className="text-base">{blog.description}</p>
              </div>

              {/* Comment section */}
              <div className="comment__list mt-5">
                <h4 className="text-2xl mb-5">3 Comments</h4>
                {/* Individual comment */}
                <div className="single__comment flex gap-3">
                  <img src={""} alt="" />
                  <div className="comment__content">
                    <h6 className="font-bold">David Visa</h6>
                    <p className="text-sm mb-0">14 July, 2022</p>
                    <p className="text-base">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Eos nobis totam eius laborum molestias itaque minima
                      distinctio, quae velit tempore!
                    </p>
                    <span className="replay flex items-center gap-1">
                      <i className="ri-reply-line"></i> Reply
                    </span>
                  </div>
                </div>

                {/* Comment form */}
                <div className="mt-5">
                  <h4 className="text-lg">Leave a Comment</h4>
                  <p className="text-base">
                    You must sign-in to make or comment a post
                  </p>
                  <form className="mt-4">
                    <div className="md:flex md:gap-3">
                      <input
                        type="text"
                        placeholder="Full name"
                        className="border rounded px-3 py-2 w-full md:w-1/2"
                      />
                      <input
                        type="email"
                        placeholder="Email"
                        className="border rounded px-3 py-2 w-full md:w-1/2 mt-3 md:mt-0"
                      />
                    </div>
                    <textarea
                      rows="5"
                      placeholder="Comment..."
                      className="w-full py-2 px-3 mt-3 border rounded"
                    ></textarea>
                    <button className="btn comment__btn mt-3 w-full md:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      Post a Comment
                    </button>
                  </form>
                </div>
              </div>
            </div>

            {/* Right side - Recent posts */}
            <div className="col-span-1">
              <div className="recent__post mb-4">
                <h5 className="font-bold">Recent Posts</h5>
              </div>
              {/* Dummy recent blog posts */}
              {[...Array(3)].map((_, index) => (
                <div className="recent__blog-post mb-4" key={index}>
                  <div className="recent__blog-item flex gap-3">
                    <img
                      src="https://via.placeholder.com/150"
                      alt=""
                      className="w-1/4 rounded-lg"
                    />
                    <h6>
                      <Link to={`/blogs/post-${index}`}>
                        Dummy Post {index + 1}
                      </Link>
                    </h6>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Scroll to top button */}
        {showScroll && (
          <div
            className="fixed bottom-10 right-10 cursor-pointer z-50"
            onClick={scrollTop}
          >
            <RiArrowUpSLine size={32} />
          </div>
        )}
      </section>

      {/* Footer */}
      <Footer />
    </Fragment>
  );
};

export default BlogDetails;

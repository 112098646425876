import React, { Fragment, useState } from "react";
import VehicleData from "../../components/admin/VehicleData";
import AdminSidebar from "../../components/admin/AdminSidebar";
import AdminNavbar from "../../components/admin/AdminNavbar";

function VehicleAllow() {
  const [sidebarToggle, setSidebarToggle] = useState(false);

  const columns = [
    "Vehicle Number",
    "Vendor",
    "Driver",
    "Model",
    "Category(Type)",
    "Status",
    "Approval"
  ];

  return (
    <Fragment>
      <div className="flex">
        <AdminSidebar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
        />
        <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
          <AdminNavbar
            sidebarToggle={sidebarToggle}
            setSidebarToggle={setSidebarToggle}
          />
          <div className={`w-full flex justify-center transition-all duration-300 ${sidebarToggle ? "" : "pl-12"}`}>
            <div className="w-full text-xl flex justify-center items-center sm:overflow-x-auto mt-4">
              <VehicleData columns={columns} tableName="Vehicle Data" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default VehicleAllow;

// src/components/ConfirmationModal.js

import React from 'react';
import { FaTimes } from 'react-icons/fa'; // Ensure react-icons is installed

const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/3">
        <button 
          onClick={onCancel} 
          className="absolute top-3 right-3 p-1 text-gray-500 hover:text-gray-700"
          aria-label="Close"
        >
          <FaTimes className="h-6 w-6" />
        </button>
        <h2 className="text-lg font-bold mb-4">Confirm Go Back to List of available vehicles</h2>
        <p className="mb-4">{message}</p>
        <div className="flex justify-end">
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded mr-2"
            onClick={onCancel}
          >
            Stay on Page
          </button>
          <button
            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
            onClick={onConfirm}
          >
            Leave Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;

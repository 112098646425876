import React from 'react';

const SummaryCard = ({ title, data }) => (
  <div className="p-4 border rounded mb-4 bg-white shadow">
    <h3 className="text-lg font-bold mb-2">{title}</h3>
    {data.map((item, index) => (
      <div key={index} className="flex justify-between items-center mb-2">
        <span className="w-1/3">{item.label}</span>
        <div className="w-1/2 bg-gray-200 rounded-full h-2 mx-2 overflow-hidden">
          <div
            className={`bg-blue-500 h-full rounded-full transition-all duration-300 ease-in-out`}
            style={{ width: `${item.value}%` }}
          ></div>
        </div>
        <span className="w-1/6 text-right">{item.value}%</span>
      </div>
    ))}
  </div>
);

export default SummaryCard;

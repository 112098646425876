import { useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { storeUserData } from "../../route/Storage";
import { isAuthenticated } from "../../route/Auth";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLA,
});

function AdminLogin() {
  const initialStateErrors = {
    email: { required: false },
    password: { required: false },
    custom_error: null,
  };

  const [errors, setErrors] = useState(initialStateErrors);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleInput = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let errors = initialStateErrors;
    let hasError = false;

    if (inputs.email === "") {
      errors.email.required = true;
      hasError = true;
    }
    if (inputs.password === "") {
      errors.password.required = true;
      hasError = true;
    }

    if (!hasError) {
      setLoading(true);
      try {
        const response = await api.post('/authentication/adminauth/login', inputs);
        
        console.log(response.data); // Log the response for debugging

        const token = response.data.accessToken; // Get the access token
        const decodedToken = jwtDecode(token); // Decode the JWT token

        // Store user details in local storage
        localStorage.setItem("user", JSON.stringify(decodedToken));
        localStorage.setItem("accessToken", token);

        const accessToken = localStorage.getItem("accessToken");
        const userData = JSON.parse(localStorage.getItem("user"));

        if (isAuthenticated() && accessToken && userData) {
          // Check user role and status
          if (userData.role !== 'ROLE_ADMIN') {
            if (!userData.verifycd && !userData.active) {
              toast.error("User Blocked, Please contact FixRyde customer care!");
            } else {
              toast.error(`You Are Registered as ${userData.role}, Please contact ADMIN to login!`);
            }
          } else {
            navigate('/admin/dashboard'); // Redirect to the admin dashboard
          }
        }

      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 400 && err.response.status === 401) {
          setErrors({ ...errors, custom_error: "Invalid Credentials." });
        } else {
          toast.error(err.response.data.message);
        }
      } finally {
        setLoading(false);
      }
    }
    setErrors({ ...errors });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-zinc-200">
      <ToastContainer />
      <div className="w-full max-w-sm">
        <div className="shadow-lg rounded-lg bg-fixwhite">
          <div className="border-fixblue border-2 w-full rounded-t-lg"></div>
          <div className="flex flex-col items-center justify-center my-4">
            <img src="/images/Admin.png" alt="logo" className="max-h-20 max-w-20" />
            <h3 className="font-bold text-fixblue text-center font-serif">ADMIN</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="px-6 py-4">
              <div className="mb-4">
                <input
                  type="email"
                  className="w-full p-2 mb-2 border border-gray-300 rounded-md focus:border-blue-800 focus:ring focus:ring-blue-200 focus:outline-none"
                  name="email"
                  placeholder="Email"
                  onChange={handleInput}
                  required
                />
                {errors.email.required && (
                  <span className="text-danger">Email is required.</span>
                )}

                <input
                  type="password"
                  className="w-full p-2 border border-gray-300 rounded-md focus:border-blue-800 focus:ring focus:ring-blue-200 focus:outline-none"
                  name="password"
                  placeholder="Password"
                  onChange={handleInput}
                  required
                />
                {errors.password.required && (
                  <span className="text-danger">Password is required.</span>
                )}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <button
                  type="submit"
                  className="bg-fixblue text-white font-bold py-2 px-4 rounded-md"
                >
                  {loading ? "Loading..." : "Login"}
                </button>
                <button
                  type="reset"
                  className="bg-fixred text-white font-bold py-2 px-4 rounded-md"
                >
                  Reset
                </button>
              </div>

              <span className="text-danger">
                {errors.custom_error && <p>{errors.custom_error}</p>}
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;

import React, { Fragment, useState, useEffect } from "react";
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import AdminLayout from "../../../components/admin/AdminLayout";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaPlus, FaHandshake } from "react-icons/fa";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    'ngrok-skip-browser-warning': 'true',
    'Content-Type': 'application/json'
  }
});

const MyDriversList = () => {
  const [tab, setTab] = useState("all");
  const [drivers, setDrivers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);

  const handleTabChange = (type) => {
    setTab(type);
    filterDrivers(type);
  };

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await api.get('/drivervehicle/accountactivation/mydriverlist');
        const driverData = response.data;
        console.log("driverData");
        console.log(driverData);

        setDrivers(driverData);
        setFilteredDrivers(driverData); // Set initial filter
      } catch (error) {
        console.error('Error fetching drivers:', error);
      }
    };

    fetchDrivers();
  }, []);

  const filterDrivers = (type) => {
    let filtered = drivers;
    if (type === "approved") {
      filtered = drivers.filter(driver => driver.checkupStatus === "Completed");
    } else if (type === "pending") {
      filtered = drivers.filter(driver => driver.checkupStatus === "Pending");
    } else if (type === "blocked") {
      filtered = drivers.filter(driver => driver.checkupStatus === "Blocked");
    }
    setFilteredDrivers(filtered);
  };

  return (
    <Fragment>
      <AdminLayout>
        <div className="bg-gray-200 min-h-screen p-2">
          <div className="bg-white shadow-lg p-2 rounded-lg">
            <div className="flex flex-col justify-between sm:flex-row items-center">
              <div className="text-gray-700">
                <h1 className="text-xl font-semibold">My Drivers</h1>
                <span>{new Date().toLocaleDateString()}</span>
              </div>
              <div className="flex items-center mt-4 sm:mt-0">
                <input
                  type="text"
                  placeholder="Search Driver"
                  className="bg-gray-100 px-4 py-2 rounded-l-lg text-[0.8rem] focus:outline-none w-full sm:w-auto"
                />
                <Link
                  to="/admin/driverform"
                  className="bg-blue-500 inline-flex items-center text-white px-2 py-2 text-[0.8rem] rounded-r-lg focus:outline-none"
                >
                  <FaPlus className="mr-2" /> DRIVER
                </Link>
              </div>
            </div>
            <hr className="my-2" />
            <div className="flex flex-col gap-1 items-center justify-evenly sm:flex-row text-sm">
              {["all", "approved", "pending", "blocked"].map((type) => (
                <button
                  key={type}
                  className={`cursor-pointer inline-flex items-center ${tab === type
                      ? "text-blue-500 underline"
                      : "hover:bg-red-500 hover:text-white px-2 py-1 rounded-sm text-gray-700"
                    }`}
                  onClick={() => handleTabChange(type)}
                >
                  <FaHandshake className="mr-1" />
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </button>
              ))}
            </div>
          </div>

          <hr className="my-2 border-b-[1px] border-fixblack" />

          <div className="container mx-auto">
            <h2 className="text-xl font-semibold mb-4">Driver List</h2>
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2">UserID</th>
                  <th className="py-2">Name</th>
                  <th className="py-2">Mobile Number</th>
                  <th className="py-2">Experience</th>
                  <th className="py-2">Active</th>
                  <th className="py-2">Verify</th>
                  <th className="py-2">Checkup Status</th>
                  <th className="py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredDrivers.map((driver) => (
                  <tr key={driver.id} className="border-b">
                    <td className="py-2">{driver.username}</td>
                    <td className="py-2">{driver.name}</td>
                    <td className="py-2">{driver.mobileNumber}</td>
                    <td className="py-2">{driver.experience}</td>
                    <td className="py-2">
                      <span
                        className={`px-2 py-1 rounded-full text-white ${driver.active ? 'bg-green-500' : 'bg-red-500'}`}
                      >
                        {driver.active ? "Active" : "Inactive"}
                      </span>
                    </td>
                    <td className="py-2">
                      <span
                        className={`px-2 py-1 rounded-full text-white ${driver.verifycd ? 'bg-green-500' : 'bg-red-500'}`}
                      >
                        {driver.verifycd ? "Verified" : "Not Verified"}
                      </span>
                    </td>
                    <td className="py-2">{driver.checkupStatus}</td>
                    <td className="py-2">
                      <div className="flex space-x-2">
                        <Link to={`/admin/viewdriver/${driver.username}`} className="bg-blue-500 text-white px-2 py-1 rounded">Profile</Link>
                        <Link to={`/admin/viewvehicle/${driver.username}`} className="bg-blue-500 text-white px-2 py-1 rounded">Vehicle</Link>
                        <Link to={`/admin/rates/${driver.id}`} className="bg-blue-500 text-white px-2 py-1 rounded">Rates</Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    </Fragment>
  );
};

export default MyDriversList;

import React, { Fragment } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import Navbar from "../../components/site/Navbar";
import Footer from "../../components/site/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ConfirmationPage = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize navigate function
  const { bookingData1 = {} } = location.state || {};
  const { paymentStatus, confirmationMessage } = bookingData1;

  // Get user information from localStorage (as in the BookingHistory component)
  const userData = JSON.parse(localStorage.getItem("user"));
  const userId = userData?.id || ""; // Retrieve userId from localStorage

  console.log("confirmationMessage:" + paymentStatus);
  console.log(bookingData1);

  // Determine if the payment was successful
  const isSuccess = paymentStatus && paymentStatus.includes("SUCCESS");

  let failureReason = confirmationMessage;
  if (failureReason === "Booking not found") {
    failureReason = "Booking Expired";
  } else if (failureReason === "Trip not found") {
    failureReason = "Booking Expired";
  } else if (failureReason === "Invalid payment verification.") {
    failureReason = "Payment Invalid";
  } else {
    failureReason = failureReason || "An unexpected error occurred.";
  }

  // Function to handle cancel trip
  const handleCancelTrip = async () => {
    try {
      // Make a request to cancel the trip
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URLB}/bookings/customercancelB`, // API endpoint
        null, // No data is passed in the body
        {
          params: {
            bookId: bookingData1.bookId, // Send bookId as a parameter
            cancelReason: "Customer Cancelled confirmed Booking", // Send bookId as a parameter
            userId, // Send userId as a parameter
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Authorization token
            'ngrok-skip-browser-warning': 'true', // Custom header (if needed)
          },
        }
      );
      // alert(response.data); 
      toast.error('Your Booking is Cancelled.');
      navigate('/user/history');
    } catch (error) {
      console.error("Error canceling trip:", error);
      // alert("Failed to cancel the trip.");
      toast.error('!  ' + error.response.data);

    }
  };

  return (
    <Fragment>
      <ToastContainer />
      <Navbar />
      <div className="bg-gradient-to-b from-blue-100 to-white min-h-screen p-8">
        {/* Booking confirmation */}
        <div className="max-w-3xl mx-auto bg-white shadow-xl rounded-xl p-6 border border-blue-300">
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="mb-4 sm:mb-0">
              <h2 className={`text-2xl font-extrabold ${isSuccess ? "text-green-600" : "text-red-600"}`}>
                {isSuccess ? "Booking Confirmed!" : `Booking Failed! Reason : ${failureReason}`}
              </h2>
              <p className="text-sm text-gray-600">
                {isSuccess ? "A confirmation email has been sent to " : "Please try again."}
                <span className="font-semibold text-fixblue">{bookingData1.email || "abc123@gmail.com"}</span>
              </p>
            </div>
            <button
              onClick={() => isSuccess ? navigate('/user/dashboard') : navigate(-1)}
              className={`bg-${isSuccess ? "blue-500" : "red-600"} text-white py-2 px-4 rounded-lg shadow-md hover:bg-fixblue transition duration-200`}>
              {isSuccess ? "Add Booking" : "Retry"}
            </button>
          </div>

          {/* Booking ID */}
          <div className="mt-4 flex flex-col sm:flex-row justify-between items-center">
            <p className="font-medium text-lg inline-flex items-center">
              Booking ID:{" "}
              <span className="text-fixblue font-bold ml-1">{bookingData1.bookId || "N/A"}</span>
              <span className={`ml-2 ${isSuccess ? "text-green-500" : "text-red-500"}`}>
                {isSuccess ? <FaCheckCircle /> : <FaTimesCircle />}
              </span>
            </p>
            {isSuccess && (
              <button
                onClick={handleCancelTrip} // Attach the cancel function
                className="bg-red-600 text-white py-2 px-6 rounded-lg shadow-md hover:bg-red-700 transition duration-200">
                Cancel Trip
              </button>
            )}
          </div>

          {/* Trip details */}
          {isSuccess && (
            <div className="mt-6 border-t pt-4">
              <h3 className="font-bold text-xl text-fixblue">Trip Details</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                <UserCard title="Pickup" content={bookingData1.pickupLocation || "N/A"} />
                <UserCard title="Drop" content={bookingData1.dropLocation || "N/A"} />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
                <UserCard title="Date" content={bookingData1.bookingDate || "N/A"} />
                <UserCard title="Time" content={bookingData1.bookingTime || "N/A"} />
                <UserCard title="Trip Type" content={bookingData1.tripType || "N/A"} />
                <UserCard title="Vehicle Type" content={bookingData1.vehicleType || "N/A"} />
                <UserCard title="Distance" content={`${bookingData1.distanceKm || 0} km`} />
                <UserCard title="Duration" content={bookingData1.tripDuration || "N/A"} />
              </div>
            </div>
          )}

          {/* Passenger details */}
          {isSuccess && (
            <div className="mt-6 border-t pt-4">
              <h3 className="font-bold text-xl text-fixblue">Passenger Details</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                <UserCard title="Passenger Name" content={bookingData1.name || "N/A"} />
                <UserCard title="Contact Number" content={bookingData1.contact || "N/A"} />
              </div>
            </div>
          )}

          {/* Fare details */}
          {isSuccess && (
            <div className="mt-6 border-t pt-4">
              <h3 className="font-bold text-xl text-fixblue">Fare Details : Payment Mode : {bookingData1.paymentMethod || "N/A"}</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
                {/* <UserCard title="Total Fare" content={`₹${bookingData1.fare || 0}`} />
                <UserCard title="Paid" content={`₹${bookingData1.paidAmount || 0}`} />
                <UserCard title="Remaining Amount to Pay" content={`₹${bookingData1.remainingAmount || 0}`} /> */}
                <UserCard title="Total Fare" content={`₹${(bookingData1.fare || 0).toFixed(2)}`} />
                <UserCard title="Paid" content={`₹${(bookingData1.paidAmount || 0).toFixed(2)}`} />
                <UserCard title="Remaining Amount to Pay" content={`₹${(bookingData1.remainingAmount || 0).toFixed(2)}`} />
              </div>
            </div>
          )}

          {/* Important Notes */}
          {isSuccess && (
            <div className="mt-6 border-t pt-4">
              <h3 className="font-bold text-xl text-fixblue">Important Notes</h3>
              <ul className="list-disc list-inside mt-2 text-sm text-gray-600">
                <li>Please be ready 15 minutes before your pickup time.</li>
                <li>Driver and vehicle details will be shared 2 hours before your trip.</li>
              </ul>
              <p className="mt-4 text-md text-gray-600">
                Thank you for choosing us! Drive safely!
              </p>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

// UserCard component for consistent styling
const UserCard = ({ title, content }) => (
  <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200">
    <p className="text-sm text-gray-600">{title}</p>
    <p className="text-base font-semibold">{content}</p>
  </div>
);

export default ConfirmationPage;

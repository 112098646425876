import React from 'react';
import { FaTimes } from 'react-icons/fa';

const Modal = ({ closeModal, fullFare }) => {
  // Assuming the full fare includes CGST and SGST, which are both 9% each, totaling 18%.
  const gstRate = 0.18; // Total GST rate of 18% (CGST 9% + SGST 9%)

  // Calculate the base fare (excluding GST)
  const baseFare = fullFare / (1 + gstRate); // Subtracting 18% GST

  // Calculate the CGST and SGST amounts
  const cgst = baseFare * 0.09; // 9% CGST
  const sgst = baseFare * 0.09; // 9% SGST

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/3">
        <button 
          onClick={closeModal} 
          className="absolute top-3 right-3 p-1 text-gray-500 hover:text-gray-700"
          aria-label="Close"
        >
          <FaTimes className="h-6 w-6" />
        </button>
        <h2 className="text-lg font-bold mb-4">Fare Details</h2>
        <p className="mb-2">Base Fare: ₹ {baseFare.toFixed(2)}</p>
        <p className="mb-2">CGST (9%): ₹ {cgst.toFixed(2)}</p>
        <p className="mb-2">SGST (9%): ₹ {sgst.toFixed(2)}</p>
        <p className="mb-4">TOTAL: ₹ {fullFare.toFixed(2)}
          <span className="block text-sm text-gray-600">Rounded to the nearest value</span>
        </p>
      </div>
    </div>
  );
};

export default Modal;

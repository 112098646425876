// src/index.js

import React from 'react';
import { GoogleMapsProvider } from './pages/sitePages/GoogleMapsProvider';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Import Provider and store
import { Provider } from 'react-redux';
import fixStore from './store/fixStore';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={fixStore}>
      <GoogleMapsProvider>
        <App />
      </GoogleMapsProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


// src/components/site/core/AirportDrive.jsx

import React from "react";
import AutocompleteInput from "../../common/AutocompleteInput";
import { getMinMaxDates } from "../../../utils/tripUtils";
import { FaExchangeAlt } from 'react-icons/fa'; // Import the icon

const AirportDrive = ({
  tripType,
  setTripType,
  sourceInput,
  setSourceInput,
  destinationInput,
  setDestinationInput,
  date,
  setDate,
  time,
  setTime,
  availableTimes,
  handleSearch,
}) => {
  const sourceOptions = {
    types: ["airport"],
    componentRestrictions: { country: "in" },
  };
  const destinationOptions = { componentRestrictions: { country: "in" } };

  const { minDateString, maxDateString } = getMinMaxDates();

  // Handler to swap tripType and inputs
  const handleSwap = () => {
    // Swap tripType
    const newTripType = tripType === "AirportPickup" ? "AirportDrop" : "AirportPickup";
    setTripType(newTripType);

    // Swap source and destination inputs
    const tempInput = sourceInput;
    setSourceInput(destinationInput);
    setDestinationInput(tempInput);
  };

  return (
    <div className="container mx-auto">
      <div className="my-2 bg-white rounded-md shadow-lg p-4 flex items-center gap-2 flex-nowrap">


        <div className="flex-shrink-0">
          <label htmlFor="selectType" className="block text-sm text-gray-700 font-bold mb-1">
            Select Type
          </label>
          <select
            id="selectType"
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
            value={tripType}
            onChange={handleSwap}
          >
            <option value="AirportPickup">Airport Pickup</option>
            <option value="AirportDrop">Airport Drop</option>
          </select>
        </div>

        {tripType === "AirportPickup" && (
          <div className="flex-grow min-w-0 flex items-center gap-2">
            <div className="flex-grow min-w-0">
              <AutocompleteInput
                id="source"
                label="Source Airport"
                value={sourceInput}
                onChange={setSourceInput}
                placeholder="Enter source"
                autocompleteOptions={sourceOptions}
                onPlaceChanged={setSourceInput}
              />
            </div>

            <div className="flex items-center justify-center">
              <button
                onClick={handleSwap}
                className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 flex-shrink-0 mt-7"
                aria-label="Swap"
              >
                <FaExchangeAlt />
              </button>
            </div>

            <AutocompleteInput
              id="destination"
              label="Destination"
              value={destinationInput}
              onChange={setDestinationInput}
              placeholder="Enter destination"
              autocompleteOptions={destinationOptions}
              onPlaceChanged={setDestinationInput}
            />
          </div>
        )}

        {tripType === "AirportDrop" && (
          <div className="flex-grow min-w-0 flex items-center gap-2">
            <div className="flex-grow min-w-0">
            <AutocompleteInput
              id="source"
              label="Source"
              value={sourceInput}
              onChange={setSourceInput}
              placeholder="Enter source"
              autocompleteOptions={destinationOptions}
              onPlaceChanged={setSourceInput}
            />
            </div>

            <div className="flex items-center justify-center">
              <button
                onClick={handleSwap}
                className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 flex-shrink-0 mt-7"
                aria-label="Swap"
              >
                <FaExchangeAlt />
              </button>
            </div>

            <AutocompleteInput
              id="destination"
              label="Destination Airport"
              value={destinationInput}
              onChange={setDestinationInput}
              placeholder="Enter destination"
              autocompleteOptions={sourceOptions}
              onPlaceChanged={setDestinationInput}
            />
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <div className="lg:col-span-1">
            <label htmlFor="date" className="block text-sm text-gray-700 font-bold mb-2">
              Date
            </label>
            <input
              id="date"
              type="date"
              className="block mt-2 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              min={minDateString}
              max={maxDateString}
              required
            />
          </div>

          <div className="flex-grow min-w-0">
            <label htmlFor="time" className="block text-sm text-gray-700 font-bold mb-2">
              Time
            </label>
            <select
              id="time"
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              required
            >
              {availableTimes.map((slot, index) => (
                <option key={index} value={slot.time} disabled={!slot.isEnabled}>
                  {slot.time}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-4">
        <button
          onClick={handleSearch}
          className="px-20 bg-fixblue border-2 border-white hover:bg-yellow-500 text-white font-bold py-3 rounded-full"
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default AirportDrive;

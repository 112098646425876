import React from "react";
import { FaStar } from "react-icons/fa";

const ReviewCard = ({ name, review, rating, imageUrl, constRating }) => {
  const effectiveRating = rating || constRating || 0;

  const renderStars = (value) => (
    <div className="flex space-x-1">
      {Array.from({ length: 5 }, (_, i) => (
        <FaStar key={i} className={i < Math.floor(value) ? "text-yellow-500" : "text-gray-400"} />
      ))}
    </div>
  );

  return (
    <div className="max-w-sm bg-white border border-gray-300 rounded-lg shadow-md m-4">
      {/* Name and stars */}
      <div className="p-4 bg-gray-800 text-white flex justify-between items-center rounded-t-lg">
        <h3 className="text-lg font-bold">{name}</h3>
        {renderStars(effectiveRating)}
      </div>
      {/* Review content */}
      <div className="p-4">
        <p className="text-gray-800 text-base">{review}</p>
      </div>
    </div>
  );
};

export default ReviewCard;

import React, { Fragment, useState } from "react";
import ReCAPTCHA from "react-recaptcha";
import Footer from "../../components/site/Footer";
import B2BCard from "../../components/site/B2BCard";
import Navbar from "../../components/site/Navbar";
import CommonSection from "../../components/site/CommanSection";
function B2BForm() {
  const [option, setOption] = useState("");

  const handleOptionChange = (e) => {
    setOption(e.target.value);
  };

  const details = [
    {
      imageSrc: "/images/invoice.png",
      heading: "Adaptable Invoicing",
    },
    {
      imageSrc: "/images/dashboard.png",
      heading: "Detailed Dashboard",
    },
    {
      imageSrc: "/images/support.png",
      heading: "24/7 Support",
    },
    {
      imageSrc: "/images/tag.png",
      heading: "No Waves",
    },
    {
      imageSrc: "/images/id-verified.png",
      heading: "Verified Drivers",
    },
  ];

  // capcha
  const [isVerified, setVerified] = useState(false);

  const handleVerify = (response) => {
    if (response) {
      setVerified(true);
    }
  };

  return (
    <Fragment>
      <Navbar />
      <CommonSection title={"Business To Business"} />
      <div className="container-fulid mx-auto">
        <div className="bg-sarath bg-bottom bg-cover bg-no-repeat flex flex-col justify-center">
          <div className="flex items-center  justify-center mb-12 mt-2 mx-2">
            <div className="w-full max-w-sm">
              <form className="shadow-lg rounded-lg bg-white">
                <div className="h-1 bg-fixblue rounded-t-md"></div>
                <h3 className="text-center py-2 text-[1rem] font-fixopen font-bold">
                  Company Details
                </h3>
                <hr />
                <div className="px-10">
                  <label
                    className="block text-fixblack text-sm font-bold my-2"
                    htmlFor="companyName"
                  >
                    Company name *
                  </label>
                  <input
                    className="border w-full h-10 px-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-fixgold rounded-md"
                    id="companyName"
                    type="text"
                    placeholder="Enter your company name"
                  />
                </div>
                <div className="px-10">
                  <label
                    className="block text-fixblack text-sm font-bold my-2"
                    htmlFor="email"
                  >
                    Email ID *
                  </label>
                  <input
                    className="border w-full h-10 px-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-fixgold rounded-md"
                    id="email"
                    type="email"
                    placeholder="Enter your email id"
                  />
                </div>
                <div className="px-10 ">
                  <label
                    className="block text-fixblack text-sm font-bold my-2"
                    htmlFor="employees"
                  >
                    Number of employees *
                  </label>
                  <select
                    className="border w-full h-10 px-2  focus:ring-1 focus:ring-fixgold rounded-md"
                    id="employees"
                    value={option}
                    onChange={handleOptionChange}
                  >
                    <option>Select</option>
                    <option value={1 - 10}>1-10</option>
                    <option value={11 - 20}>11-20</option>
                    <option value={21 - 50}>21-50</option>
                    <option value={50 - 100}>50-100</option>
                  </select>
                </div>
                <div className="px-10">
                  <label
                    className="block text-fixblack text-sm font-bold my-2"
                    htmlFor="phone"
                  >
                    Phone number *
                  </label>
                  <input
                    className="border w-full h-10 px-2 text-fixblack leading-tight hover:outline-none focus:outline-none focus:ring-1 focus:ring-fixgold rounded-md"
                    id="phone"
                    type="tel"
                    placeholder="Enter phone number"
                  />
                </div>
                <div className="flex flex-col items-center justify-center">
                  <h1 className="text-xl font-bold mb-2">I am not a robot</h1>
                  <ReCAPTCHA sitekey="YOUR_SITE_KEY" onChange={handleVerify} />
                  {isVerified && (
                    <p className="text-green-500 mt-1">
                      Verification successful!
                    </p>
                  )}
                </div>
                <div className="px-10 py-2">
                <div className="py-2 flex justify-center">
                  <button
                    className="border-2 w-full border-fixblue border-solid bg-fixblue text-white font-bold py-2 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* card section start */}
      <div className="container mx-auto my-4">
        <div className="flex flex-wrap justify-center">
          {details.map((detail) => (
            <B2BCard
              key={detail.id}
              imageSrc={detail.imageSrc}
              heading={detail.heading}
            />
          ))}
        </div>
      </div>
      {/* End card section */}

      <Footer />
    </Fragment>
  );
}

export default B2BForm;

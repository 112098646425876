import React from 'react';
import AdminLogin from '../pages/adminPages/AdminLogin';
import DashboardPanel from '../pages/adminPages/DashboardPanel';
import CovidSafety from '../pages/adminPages/fleet&vendor/CovidSafety';
import TripPanel from '../pages/adminPages/trip/TripPanel';
import VehicleList from '../pages/adminPages/fleet&vendor/VehicleList';
import AllTrip from '../pages/adminPages/trip/AllTrip';
import AllRate from '../pages/adminPages/rate&inventory/AllRate';
import RateDashboard from '../pages/adminPages/rate&inventory/RateDashboard';
import AddRate from '../pages/adminPages/rate&inventory/AddRate';
import InventoryDashboard from '../pages/adminPages/rate&inventory/InventoryDashboard';
import FleetPanel from '../pages/adminPages/fleet&vendor/FleetPanel';
import FleetPerformance from '../pages/adminPages/performance/FleetPerformance';
import ClientFeedback from '../pages/adminPages/performance/ClientFeedback';
import InvoiceTable from '../pages/adminPages/invoice/InvoiceTable';
import MyDrivers from '../pages/adminPages/driver/MyDrivers';
import DriverForm from '../pages/adminPages/driver/DriverForm';
import VehicleForm from '../pages/adminPages/vehicle/VehicleForm';
import MyVehicles from '../pages/adminPages/vehicle/MyVehicles';
import AdminAllow from '../pages/adminPages/AdminAllow';
import DriverCheckup from '../pages/adminPages/driver/DriverCheckup';
import Invoice from '../pages/adminPages/invoice/Invoice';
import MarkVehicles from '../pages/adminPages/rate&inventory/MarkVehicles';
import NewMarkVehicle from '../pages/adminPages/rate&inventory/NewMarkVehicle';
import MyVendors from '../pages/adminPages/fleet&vendor/MyVendors';
import VendorForm from '../pages/adminPages/fleet&vendor/VendorForm';
import ViewVendor from '../pages/adminPages/fleet&vendor/ViewVendor';
import ViewDriver from '../pages/adminPages/driver/ViewDriver';
import ViewVehicle from '../pages/adminPages/vehicle/ViewVehicle';
import VendorAllow from '../pages/vendorPages/VendorAllow';
import EmployeeNavbar from '../components/site/EmployeeNavbar';
import Help from '../pages/adminPages/Help';
import TripDetails from '../pages/adminPages/trip/TripDetails';
import AddServiceArea from '../pages/adminPages/rate&inventory/AddServiceArea';
import BookingDashboardPanel from '../pages/adminPages/book&list/BookingDashboardPanel';
import RegisterAll from '../pages/adminPages/RegisterAll';
import DriverAllow from '../pages/vendorPages/DriverAllow';
import EmployeeAllow from '../pages/vendorPages/EmployeeAllow';
import UserAllow from '../pages/vendorPages/UserAllow';
import MyDriversList from '../pages/adminPages/driver/MyDriversList';
import VendorsVehicle from '../pages/adminPages/fleet&vendor/VendorsVehicle';
import VendorsDriver from '../pages/adminPages/fleet&vendor/VendorsDriver';
import VehicleAllow from '../pages/adminPages/VehicleAllow';
import EditRate from '../pages/adminPages/rate&inventory/EditRate';
import EagleViewPage from '../pages/adminPages/EagleViewPage';
import EditTripDetails from '../pages/adminPages/book&list/EditTripDetails';
import AdminVendorInvoice from '../pages/adminPages/invoice/AdminVendorInvoice';
import AdminVendorInvoiceView from '../pages/adminPages/invoice/AdminVendorInvoiceView';

const adminRoutes = [
  { path: '/admin/registerall', element: <RegisterAll /> },
  // { path: '/admin/login', element: <AdminLogin /> },
  { path: '/admin/dashboard', element: <DashboardPanel /> },
  { path: '/admin/bookingdashboard', element: <BookingDashboardPanel /> },
  // { path: '/admin/bookingdashboard', element: <EditTripDetails /> },
  { path: '/admin/editbooking', element: <EditTripDetails /> },
  { path: '/admin/covid', element: <CovidSafety /> },
  { path: '/admin/trip', element: <TripPanel /> },
  { path: '/admin/tripdetails', element: <TripDetails /> },
  { path: '/admin/vehicles', element: <VehicleList /> },
  { path: '/admin/vendorsvehicles/:vendorPhoneNumber', element: <VendorsVehicle /> },
  { path: '/admin/vendorsdrivers/:vendorId', element: <VendorsDriver /> },
  { path: '/admin/alltrip', element: <AllTrip /> },
  { path: '/admin/allrate', element: <AllRate /> },
  { path: '/admin/rate', element: <RateDashboard /> },
  { path: '/admin/addrate', element: <AddRate /> },
  { path: '/admin/editrate', element: <EditRate /> },
  { path: '/admin/inventory', element: <InventoryDashboard /> },
  { path: '/admin/fleet', element: <FleetPanel /> },
  { path: '/admin/fleetperform', element: <FleetPerformance /> },
  { path: '/admin/feedback', element: <ClientFeedback /> },
  // { path: '/admin/invoice', element: <InvoiceTable /> },
  { path: '/admin/vendorinvoice', element: <AdminVendorInvoice /> },
  { path: '/admin/vendorinvoiceview', element: <AdminVendorInvoiceView /> },
  { path: '/admin/myvendors', element: <MyVendors /> },
  { path: '/admin/vendorform', element: <VendorForm /> },
  { path: '/admin/viewvendor/:vendorUsername', element: <ViewVendor /> },
  { path: '/admin/viewvendor', element: <ViewVendor /> },
  { path: '/admin/mydrivers', element: <MyDrivers /> },
  { path: '/admin/mydriverslist', element: <MyDriversList /> },
  { path: '/admin/driverform', element: <DriverForm /> },
  { path: '/admin/viewdriver/:driverUsername', element: <ViewDriver /> },
  { path: '/admin/viewdriver', element: <ViewDriver /> },
  { path: '/admin/vehicleform', element: <VehicleForm /> },
  { path: '/admin/myvehicles', element: <MyVehicles /> },
  { path: '/admin/viewvehicle', element: <ViewVehicle /> },
  { path: '/admin/viewvehicle/:driverUsername', element: <ViewVehicle /> },
  { path: '/admin/markvehiclelist', element: <MarkVehicles /> },
  { path: '/admin/newmarkvehicle', element: <NewMarkVehicle /> },
  { path: '/admin/adminallow', element: <AdminAllow /> },
  { path: '/admin/vendorallow', element: <VendorAllow /> },
  { path: '/admin/driverallow', element: <DriverAllow /> },
  { path: '/admin/employeeallow', element: <EmployeeAllow /> },
  { path: '/admin/userallow', element: <UserAllow /> },
  { path: '/admin/vehicleallow', element: <VehicleAllow /> },
  { path: '/admin/help', element: <Help /> },
  { path: '/admin/servicearea', element: <AddServiceArea /> },
  // { path: '/admin/bookedlist', element: <BookedList /> },
  { path: '/admin/eagleview', element: <EagleViewPage /> },

  // { path: '/invoice', element: <Invoice /> },



  {path:'/admin/checkup' ,element:<DriverCheckup />},
  {path:'/empnav' ,element:<EmployeeNavbar />},

];

export default adminRoutes;

import React, { Fragment } from "react";
import Header from "../../components/site/Navbar";
import Footer from "../../components/site/Footer";
import CommonSection from "../../components/site/CommanSection";
import { FaPhone } from "react-icons/fa6";
import HowItWork from "./HowItWork";

function About() {
  return (
    <Fragment>
      <Header />
      <CommonSection title="About Us" />
      <section className="bg-gray-100 p-2">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <img
                src="/images/Blog_2.png"
                className="w-full rounded-md"
                alt="car"
              />
            </div>
            <div className="flex flex-col justify-center">
              <h2 className="text-2xl font-bold mb-4 text-fixblue">
                We Are Committed To Provide Safe Ride Solutions
              </h2>
              <p className="text-[1rem] mb-4">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Eveniet veniam assumenda aperiam accusantium ex autem
                perferendis repellendus nostrum delectus. Nemo et dolore est
                tempore rem minima adipisci magni dolorum ipsam.
              </p>
              <p className="text-[1rem] mb-4">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Eveniet veniam assumenda aperiam accusantium ex autem
                perferendis repellendus nostrum delectus. Nemo et dolore est
                tempore rem minima adipisci magni dolorum ipsam.
              </p>

              <div className="flex items-center gap-4 mt-4">
                <span className="inline-block text-3xl text-fixblue ">
                  <FaPhone />
                </span>
                  <h6 className="text-lg font-bold text-fixred">
                    Need Any Help?
                  </h6>
                  <h4 className="text-lg">+91 9087654321</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <BecomeDriverSection />  */}

      <section className="bg-gray-100 py-16">
        <div className="container mx-auto">
          <div className="text-center mb-5">
            <h6 className="text-lg font-bold">Experts</h6>
            <h2 className="text-3xl font-bold">Our Members</h2>
          </div>
          {/* <OurMembers />  */}
        </div>
      </section>

      <HowItWork />
      <Footer />
    </Fragment>
  );
}

export default About;

import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

// Register the components
Chart.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data, options }) => (
  <div className="p-4 border rounded mb-4 bg-white shadow">
    <Pie data={data} options={options} />
  </div>
);

export default PieChart;

import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; // Assuming React Router is being used for navigation
import AdminSidebar from "../../../components/admin/AdminSidebar";
import AdminNavbar from "../../../components/admin/AdminNavbar";
import ActiveRates from '../../../components/admin/ActiveRates'
import { FaCar, FaCarAlt, FaCarSide, FaCalendarAlt, FaCog, FaCalendarDay, FaClock } from "react-icons/fa";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URLE,
  headers: {
    'ngrok-skip-browser-warning': 'true'
  }
});

const RateDashboard = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const navigate = useNavigate();

  const cabTypes = [
    { id: 'SUV', name: 'SUV', icon: <FaCar className='pointer-events-none z-10 ml-2 text-xl' /> },
    { id: 'Hatchback', name: 'Hatchback', icon: <FaCarAlt className='pointer-events-none z-10 ml-2 text-xl' /> },
    { id: 'Sedan', name: 'Sedan', icon: <FaCarSide className='pointer-events-none z-10 ml-2 text-xl' /> },
    { id: 'Innova', name: 'Innova', icon: <FaCarSide className='pointer-events-none z-10 ml-2 text-xl' /> },
    { id: 'Innova Crysta', name: 'Innova Crysta', icon: <FaCarSide className='pointer-events-none z-10 ml-2 text-xl' /> }
  ];

  const ratePlans = [
    { id: 'Holiday', name: 'Holiday', icon: <FaCalendarAlt className='text-xl' /> },
    { id: 'General', name: 'General', icon: <FaClock className='text-xl' /> }
    // { id: 'WeekDays', name: 'WeekDays', icon: <FaCalendarDay className='text-xl' /> }
  ];

  const [sourceStates, setSourceStates] = useState([]);
  const [destinationStates, setDestinationStates] = useState([]);
  const [sourceDistricts, setSourceDistricts] = useState([]);
  const [destinationDistricts, setDestinationDistricts] = useState([]);

  const [sourceState, setSourceState] = useState("");
  const [destinationState, setDestinationState] = useState("");
  const [sourceDistrict, setSourceDistrict] = useState("");
  const [destinationDistrict, setDestinationDistrict] = useState("");

  const [priceType, setPriceType] = useState("AirportDrive");
  const [tripType, setTripType] = useState("AirportPickup");

  const [selectedCabType, setSelectedCabType] = useState("SUV");
  const [selectedRatePlan, setSelectedRatePlan] = useState("General");
  const [radioLoad, setRadioLoad] = useState(false);
  const [rankedFares, setRankedFares] = useState([]);
  const [openCardId, setOpenCardId] = useState(null);
  const [allFares, setAllFares] = useState([]); // Store all fares data here

  useEffect(() => {
    fetchAdminData();
  }, []);

  const handlePriceTypeChange = (event) => {
    const selectedPriceType = event.target.value;
    setPriceType(selectedPriceType);

    if (selectedPriceType === "RentalDrive") {
      setDestinationState(sourceState);
    }

    if (selectedPriceType === "AirportDrive") {
      setTripType("AirportPickup");
    } else if (selectedPriceType === "OutstationDrive") {
      setTripType("OnewayTrip");
    } else {
      setTripType("8 hrs 80 kms");
    }
  };

  const toggleDropdown = (cardId) => {
    setOpenCardId(openCardId === cardId ? null : cardId);
  };

  const handleSourceStateChange = (event) => {
    const value = event.target.value;
    setSourceState(value);
    if (priceType === "RentalDrive") {
      setDestinationState(value);
    }
  };

  const handleCabTypeChange = (event) => {
    setSelectedCabType(event.target.id);
    filterFares();
  };

  const handleRatePlanChange = (event) => {
    setSelectedRatePlan(event.target.id);
    filterFares();
  };

  const fetchAdminData = async () => {
    try {
      const response = await api.get("/drivervehicle/fares/admin/places/distinctvalue");
      const distinctValues = response.data;

      const sourceStates = new Set();
      const destinationStates = new Set();
      const sourceDistricts = new Set();
      const destinationDistricts = new Set();

      distinctValues.forEach(item => {
        if (item[0]) sourceStates.add(item[0]);
        if (item[1]) destinationStates.add(item[1]);
        if (item[2]) sourceDistricts.add(item[2]);
        if (item[3]) destinationDistricts.add(item[3]);
      });

      setSourceStates([...sourceStates]);
      setDestinationStates([...destinationStates]);
      setSourceDistricts([...sourceDistricts]);
      setDestinationDistricts([...destinationDistricts]);
    } catch (error) {
      console.error("Error fetching admin data:", error);
    }
  };

  const fetchRankedFares = async () => {
    if (tripType === "AirportPickup" || tripType === "AirportDrop") {
      setSourceState("");
      setDestinationState("");
    } else {
      setSourceDistrict("");
      setDestinationDistrict("");
    }
    if ((sourceState || sourceDistrict) && (destinationState || destinationDistrict) && tripType) {
      try {

        // Log the request parameters
        console.log("Request Parameters:");
        console.log({
          sourceState: sourceState.length > 0 ? sourceState : "",
          destinationState: destinationState.length > 0 ? destinationState : "",
          sourceDistrict: sourceDistrict.length > 0 ? sourceDistrict : "",
          destinationDistrict: destinationDistrict.length > 0 ? destinationDistrict : "",
          tripType
        });
        const response = await api.get("/drivervehicle/fares/ranked-fares", {
          params: {
            sourceState: sourceState.length > 0 ? sourceState : "",
            destinationState: destinationState.length > 0 ? destinationState : "",
            sourceDistrict: sourceDistrict.length > 0 ? sourceDistrict : "",
            destinationDistrict: destinationDistrict.length > 0 ? destinationDistrict : "",
            tripType
          },
        });

        console.log("ranked-fares");
        console.log(response.data);
        setAllFares(response.data);

        filterFares(); // Filter after fetching data
        setRadioLoad(true);

      } catch (error) {
        console.error("Error fetching ranked fares:", error);
      }
    }
  };

  const filterFares = () => {
    const filteredFares = allFares.filter(fare =>
      fare.vehicleCategory === selectedCabType && fare.ratePlan === selectedRatePlan
    );
    setRankedFares(filteredFares);
  };

  useEffect(() => {
    fetchRankedFares();
  }, [sourceState, destinationState, sourceDistrict, destinationDistrict, tripType]);

  useEffect(() => {
    filterFares();
  }, [selectedCabType, selectedRatePlan, radioLoad]);

  const handleEditRate = (fare) => {
    console.log("Editing Fare:", fare);
    // Navigate to the EditRate page and pass fare details
    navigate('/admin/editrate', { state: { fare } });
  };

  const handleDeleteRate = async (fare) => {
    try {
      await api.delete(`/drivervehicle/fares/deletefare/${fare.id}`);
      setRankedFares((prevFares) => prevFares.filter((f) => f.id !== fare.id));
      setAllFares((prevFares) => prevFares.filter((f) => f.id !== fare.id));
    } catch (error) {
      console.error("Error deleting fare:", error);
    }
  };

  return (
    <Fragment>
      <AdminSidebar
        sidebarToggle={sidebarToggle}
        setSidebarToggle={setSidebarToggle}
      />
      <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
        <AdminNavbar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
        />
        <div className="bg-zinc-100 min-h-screen p-4">
          <div className="bg-white shadow-lg p-4 rounded-lg">
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <div className="text-gray-700">
                <h1 className="text-xl font-semibold">Rate Dashboard</h1>
                <span>{new Date().toLocaleDateString()}</span>
              </div>
            </div>
          </div>
          <div className="bg-fixwhite p-4 my-4">
            <div className="mb-6">
              <h1 className="text-xl font-semibold">Rate Dashboard</h1>
              <p className="text-sm text-gray-600">Rates in INR</p>
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
              <div className="flex flex-col">
                <label>Price Type</label>
                <select
                  className="p-2 border rounded"
                  value={priceType}
                  onChange={handlePriceTypeChange}
                >
                  <option value="AirportDrive">AirportDrive</option>
                  <option value="OutstationDrive">OutstationDrive</option>
                  <option value="RentalDrive">RentalDrive</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label>Trip Type</label>
                <select className="p-2 border rounded" value={tripType} onChange={(e) => setTripType(e.target.value)}>
                  {priceType === "AirportDrive" && (
                    <>
                      <option value="AirportPickup">AirportPickup</option>
                      <option value="AirportDrop">AirportDrop</option>
                    </>
                  )}
                  {priceType === "OutstationDrive" && (
                    <>
                      <option value="OnewayTrip">OnewayTrip</option>
                      <option value="RoundTrip">RoundTrip</option>
                    </>
                  )}
                  {priceType === "RentalDrive" && (
                    <>
                      <option value="4 hrs 40 kms">4 Hours 40 Kilometers</option>
                      <option value="5 hrs 50 kms">5 Hours 50 Kilometers</option>
                      <option value="6 hrs 60 kms">6 Hours 60 Kilometers</option>
                      <option value="7 hrs 70 kms">7 Hours 70 Kilometers</option>
                      <option value="8 hrs 80 kms">8 Hours 80 Kilometers</option>
                      <option value="9 hrs 90 kms">9 Hours 90 Kilometers</option>
                      <option value="10 hrs 100 kms">10 Hours 100 Kilometers</option>
                      <option value="11 hrs 110 kms">11 Hours 110 Kilometers</option>
                      <option value="12 hrs 120 kms">12 Hours 120 Kilometers</option>
                    </>
                  )}
                </select>
              </div>
              {priceType === "AirportDrive" && (
                <>
                  <div className="flex flex-col">
                    <label>Source City / District</label>
                    <select className="p-2 border rounded" value={sourceDistrict} onChange={(e) => setSourceDistrict(e.target.value)}>
                      <option value="">Select Source District</option>
                      {sourceDistricts.map((district, index) => (
                        <option key={index} value={district}>{district}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col">
                    <label>Destination City / District</label>
                    <select className="p-2 border rounded" value={destinationDistrict} onChange={(e) => setDestinationDistrict(e.target.value)}>
                      <option value="">Select Destination District</option>
                      {destinationDistricts.map((district, index) => (
                        <option key={index} value={district}>{district}</option>
                      ))}
                    </select>
                  </div>
                </>
              )}
              {(priceType === "OutstationDrive" || priceType === "RentalDrive") && (
                <>
                  <div className="flex flex-col">
                    <label>Source State</label>
                    <select
                      className="p-2 border rounded"
                      value={sourceState}
                      onChange={handleSourceStateChange}
                    >
                      <option value="">Select Source State</option>
                      {sourceStates.map((state, index) => (
                        <option key={index} value={state}>{state}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col">
                    <label>Destination State</label>
                    <select
                      className="p-2 border rounded"
                      value={destinationState}
                      onChange={(e) => setDestinationState(e.target.value)}
                      disabled={priceType === "RentalDrive"}
                    >
                      <option value="">Select Destination State</option>
                      {destinationStates.map((state, index) => (
                        <option key={index} value={state}>{state}</option>
                      ))}
                    </select>
                  </div>
                </>
              )}
            </div>

            {/* Radio buttons for cab types and rate plans */}
            <div className="flex items-center mb-6">
              <div className="flex flex-wrap items-center">
                <div className="flex flex-col sm:flex-row items-center justify-center space-x-2 mb-3">
                  {cabTypes.map((cabType, index) => (
                    <div key={cabType.id} className="relative flex w-44 items-center justify-center rounded-xl bg-gray-50 px-4 py-3 m-2 font-medium text-gray-700">
                      <input
                        className="peer hidden"
                        type="radio"
                        name="radio"
                        id={cabType.id}
                        defaultChecked={cabType.id === selectedCabType}
                        onChange={handleCabTypeChange}
                      />
                      <label className="peer-checked:border-blue-400 peer-checked:bg-blue-200 absolute top-0 h-full w-full cursor-pointer rounded-xl border" htmlFor={cabType.id}></label>
                      <div className="peer-checked:border-transparent peer-checked:bg-fixblue peer-checked:ring-2 absolute left-3 h-4 w-4 rounded-full border-2 border-gray-300 bg-gray-200 ring-blue-400 ring-offset-2"></div>
                      <span className="pointer-events-none z-10">{cabType.name}</span>
                      {cabType.icon}
                    </div>
                  ))}
                </div>
                <div className="border-r-2 border-red-500 h-12 mx-2"></div> {/* Vertical red line */}
                <div className="flex flex-col sm:flex-row items-center justify-center space-x-2 mb-3">
                  {ratePlans.map((ratePlan, index) => (
                    <div key={ratePlan.id} className="relative flex w-44 items-center justify-center rounded-xl bg-gray-50 px-4 py-3 m-2 font-medium text-gray-700">
                      <input
                        className="peer hidden"
                        type="radio"
                        name="ratePlan"
                        id={ratePlan.id}
                        defaultChecked={ratePlan.id === selectedRatePlan}
                        onChange={handleRatePlanChange}
                      />
                      <label className="peer-checked:border-blue-400 peer-checked:bg-blue-200 absolute top-0 h-full w-full cursor-pointer rounded-xl border" htmlFor={ratePlan.id}></label>
                      <div className="peer-checked:border-transparent peer-checked:bg-fixblue peer-checked:ring-2 absolute left-3 h-4 w-4 rounded-full border-2 border-gray-300 bg-gray-200 ring-blue-400 ring-offset-2"></div>
                      <span className="pointer-events-none z-10">{ratePlan.name}</span>
                      {ratePlan.icon}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="my-6 bg-fixwhite p-2">
            <div className="flex justify-between items-center p-2">
              <div>
                <p className="font-semibold">
                  {rankedFares.length > 0 && rankedFares[0].fromDate && rankedFares[0].toDate && `${rankedFares[0].fromDate} - ${rankedFares[0].toDate}`}
                </p>
              </div>
            </div>

            <div className="my-4">
              <h1 className="text-center mb-4 text-xl font-bold">Ertika|All</h1>
              <ActiveRates
                rates={rankedFares}
                onEditRate={handleEditRate}
                onDeleteRate={handleDeleteRate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RateDashboard;

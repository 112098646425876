import React, { Fragment, useState } from "react";
import UserData from "../../components/admin/UserData";
import AdminSidebar from "../../components/admin/AdminSidebar";
import AdminNavbar from "../../components/admin/AdminNavbar";

function EmployeeAllow() {
  const [sidebarToggle, setSidebarToggle] = useState(false);

  const columns = [
    "id",
    "username",
    "email",
    "Status",
    "Approval",
  ];

  return (
    <Fragment>
      <div className="flex">
        <AdminSidebar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
        />
        <div className={`flex-1 transition-all duration-300 ${sidebarToggle ? "ml-0" : "ml-48"}`}>
          <AdminNavbar
            sidebarToggle={sidebarToggle}
            setSidebarToggle={setSidebarToggle}
          />
          <div className={`w-full flex justify-center transition-all duration-300 ${sidebarToggle ? "" : "pl-12"}`}>
            <div className="w-full text-xl flex justify-center items-center sm:overflow-x-auto mt-4">
              <UserData role="employee" columns={columns} tableName="Employee Data" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default EmployeeAllow;

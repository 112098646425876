import React from "react";
import { Link } from "react-router-dom";

function Page404() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-fixblue">
      <div className="bg-fixblack p-8 rounded-lg shadow-lg text-center text-fixwhite m-2">
        <h1 className="text-6xl font-extrabold text-fixred">404</h1>
        <p className="text-2xl font-medium mb-4">Oops! Page Not Found</p>
        <p className="text-lg mb-6">
          The page you are looking for might have been removed or does not
          exist.
        </p>
        <div className="mt-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 text-fixred animate-bounce mx-auto my-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 14l-7 7m0 0l-7-7m7 7V3"
            />
          </svg>
          
          <Link
            to={"/"}
            className="inline-block px-6 py-3 text-lg font-semibold text-fixwhite bg-fixred rounded-md shadow-md hover:bg-opacity-80 transition-all duration-200 ease-in-out"
          >
            Go to Home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Page404;

import React, { Fragment } from "react";
import Navbar from "../../components/site/Navbar";
import Footer from "../../components/site/Footer";
function Terms() {
  return (
    <Fragment>
      <Navbar />
      <div>
        <section>
          <h4>Fixryde Terms &amp; Conditions</h4>
          <p>
            <b>
              1. By making a booking with Fixryde it is deemed to have accepted
              these terms.
            </b>
          </p>
          <p>
            <b>2. You agree:</b>
          </p>
          <ul >
            <li>
              2.1. All the information that you have provided to us prior to,
              during, or after using our service is correct and accurate. This
              includes but not limited to online booking, email, or telephone
              bookings. Fixryde may update or correct any information at any
              time, as appropriate for the provision of reliable and quality
              service.
            </li>
            <br />
            <li>
              2.2. Your use of Fixryde services grants no intellectual rights.
            </li>
            <br />
            <li>
              2.3. You will not use the Fixryde Services for unauthorized or
              unlawful purposes and/or impair the proper operation of the
              Fixryde services.
            </li>
            <br />
            <li>
              2.4. You will not access or attempt to access any data that is not
              directly relevant to the services you have opted for as determined
              by Fixryde, including and not limited to the data of other Fixryde
              users.
            </li>
            <br />
            <li>
              2.5. To refrain from doing anything which we reasonably believe to
              be disreputable or capable of damaging our reputation.
            </li>
            <br />
            <li>
              2.6. To make payment in full for any ride you take, or book on
              behalf of another party, and/or in respect of using any Fixryde
              services provided to you.
            </li>
            <br />
            <li>
              2.7. To treat all Fixryde drivers with respect as they are obliged
              to treat you and not cause damage to their vehicle nor engage in
              any unlawful, threatening or abusive behavior. Any damage caused
              will result in the relevant charges to cover the damage.
            </li>
            <br />
            <li>
              2.8. Not to possess any material that is deemed to be illegal or
              hazardous, or is generally prohibited by law, at all times during
              your participation in any of the Fixryde services.
            </li>
          </ul>
          <p>
            <b>3. Charges and Payment:</b>
          </p>
          <ul>
            <li>
              3.1. If you choose to pay for a ride via payment card, the
              relevant fare will be charged upfront and you agree that Fixryde
              will debit your account for the amount shown for the relevant
              booking.
            </li>
            <br />
            <li>
              3.2. You agree that we may undertake relevant authorisation checks
              on any payment details you have provided to us.
              <br /> Note: for security reasons, payment details including card
              numbers will not be stored in our system, instead, we use the
              payment card tokens (internal identifiers) generated by the secure
              payment provider to process subsequent payments if and when you
              choose to use the same payment option for multiple rides in
              Fixryde.
            </li>
            <br />
            <li>
              3.3. Cancellation:
              <ul>
                <li>
                  (A) If you cancel the ride before 24 hours from the ride start
                  time, then we will not charge you.
                </li>
                <li>
                  (B) If you cancel a ride between 24 and 4 hours from the ride
                  start time, then we will charge 15% of the ride fare as a
                  cancellation charge.
                </li>
                <li>
                  (C) If you cancel the trip within 4 hours or abort a ride that
                  is in progress, you are liable for the full cost of the ride
                  and you are not entitled to any refunds.
                </li>
                <li>(D) All charges quoted are per vehicle.</li>
              </ul>
            </li>
          </ul>
          <p>
            <b>4. Extra Charges &amp; Fees:</b>
          </p>
          <ul>
            <li>
              4.1. We will charge you anywhere between 150-500rupees if the
              vehicle requires cleaning or restoration due to any customer
              incidents during or after the ride. Typically these incidents may
              include but are not limited to spillage of drinks, vomiting,
              unexpected urination, or any such events that result in the
              vehicle not being operable and/or not being able to provide a safe
              and pleasant ride to another customer.
            </li>
            <br />
            <li>
              4.2. We will charge you at cost plus any overhead incurred, for
              any damage caused by you or your fellow riders to the vehicle or
              any property belonging to the driver or Fixryde. This may include
              but is not limited to the breaking of windows, damage of interior
              or exterior in any shape or manner.
            </li>
            <br />
            <li>
              4.3. All charges that have been quoted are based upon the
              information given at the time of booking.
            </li>
          </ul>
          <p>
            <b>5. Charges are subject to change if:</b>
          </p>
          <ul>
            <li>
              5.1. The number of passengers or luggage is more at the time of
              the start of the ride than what was indicated at the time of
              booking.
            </li>
            <br />
            <li>
              5.2. The ride requires diversions from the route that was
              originally requested at the time of booking or if passengers have
              to be picked up or dropped off at various points in the ride.
            </li>
            <br />
            <li>
              5.3. Waiting time for a non-airport pick up is 15 minutes free,
              charges apply for additional time.
            </li>
          </ul>
          <p>
            <b>6. Airport Trip:</b>
          </p>
          <ul>
            <li>
              6.1. All pickups from Airports will have additional charges than
              outgoing. This for the driver to meet you inside the airport
              including 60 minutes of the car park.
            </li>
            <br />
            <li>
              6.2. You will be liable to pay any additional parking charges
              incurred if more than 60 minutes.
            </li>
            <br />
            <li>
              6.3. Your driver will meet you inside the airport terminal’s
              arrival hall. Just so you know, one hour of waiting time (from
              your flight’s arrival) is included in the charge. After this
              period, waiting charges will apply(100rs per hour). It is
              important that you let the driver know of any delays in your
              arrival, especially if you foresee a delay beyond an hour after
              landing. A quick call to your driver will avoid any confusion and
              ensure a smooth experience.
            </li>
            <br />
            <li>
              6.4. We&#x27;ll monitor your flight and adjust your pickup time in
              case of delays or early arrivals.
            </li>
            <br />
            <li>
              6.5. All flights are monitored by our team on the official
              relevant website of the Airport, therefore if a passenger does not
              board their flight, then our control room must be notified via
              email or telephone. This must be done two hours prior to the
              booking time and failure to do so will incur the full booking
              fare.
            </li>
            <br />
            <li>
              6.5. Flight landing time will only be accepted from the official
              airport website.
            </li>
            <br />
            <li>
              6.6. Passengers are instructed not to leave the airport without
              informing our office as this will be regarded as a Cancellation
              without Prior notice and result in a full fare charge.
            </li>
            <br />
            <li>
              6.7. If you have asked us to pick you up after one hour from the
              flight’s landing time, we will offer only 15 minutes of free
              waiting time. After this period waiting charges will apply.
            </li>
            <br />
            <li>
              6.8. Fixryde drivers do not accept any liability for loss or
              damage to passengers’ belongings. Passengers are advised to load
              and unload their own belongings.
            </li>
            <br />
            <li>
              6.9. We reserve the right to refuse any passenger our services due
              to passengers having excess luggage which would result in the
              vehicle being unsafe on the road.
            </li>
          </ul>
          <p>
            <b>7. Waiting charges:</b>
          </p>
          <ul>
            <li>
              7.1. The charges quoted for airport pick up include 60-minute free
              waiting time after the flight landing time.
            </li>
            <br />
            <li>
              7.2. After the free 60-minute waiting time, the relevant waiting
              charges will apply.
            </li>
            <br />
            <li>
              7.3. Currently:
              <ul>
                <li>(A) Standard car: 100rs for one hour</li>
              </ul>
            </li>
            <br />
            <li>
              7.4. Fixryde shall use all reasonable endeavors to get you to your
              destination on time, but shall not be liable for any loss due to
              delays caused by road traffic conditions beyond its control on the
              ride. Under no circumstances shall Fixryde be liable (in contract,
              tort or otherwise) for any loss of profits, business or for any
              indirect or consequential loss.
            </li>
          </ul>
          <p>
            <b>8. Promotions:</b>
          </p>
          <ul>
            <li>
              8.1. From time to time, Fixryde may create promotions offering
              voucher codes that may be redeemed for a discount when using our
              services. These promotions can be removed at any time when Fixryde
              wishes. You agree to the individual terms and conditions of that
              promotion and understand that; the promotion code cannot be sold
              or transferred in any other manner, redeemed for cash, may expire
              prior to use, and maybe limited for one-time use only. Fixryde
              reserves the right to remove any voucher code if it is determined
              that the redemption of the voucher code was done in error or
              violation of the applicable terms and conditions. All decisions
              taken by Fixryde in this matter are deemed final.
            </li>
          </ul>
          <p>
            <b>9. General:</b>
          </p>
          <ul>
            <li>
              9.1. A booking can only be amended or canceled by contacting us
              via telephone or email.
            </li>
            <br />
            <li>
              9.2. Illustrations, photographs and descriptions on the website,
              brochures, charge lists or documents serve merely as an indicative
              guide and will not be binding.
            </li>
            <br />
            <li>
              9.3. We are always looking for ways to improve and expand our
              service. We may amend these Terms from time to time to reflect
              changes to Fixryde in which case, we will give you reasonable
              notice of any changes to these Terms.
            </li>
          </ul>
          <p>
            <b>10. Lost property:</b>
          </p>
          <ul>
            <li>
              10.1. As part of the Booking Services, Fixryde will use reasonable
              endeavors to assist the passenger with any lost property inquiries
              that they may have in relation to a given Booking.
            </li>
            <br />
            <li>
              10.2. If any item(s) of lost property is returned to the Fixryde
              main office, we will hold such item(s) for a period of 6 months.
              If you wish to collect any item(s) of lost property, you must
              (within that time and during normal business hours) bring such
              evidence of identity as Fixryde deems appropriate for the purposes
              of identifying you as the relevant owner of the item(s).
            </li>
            <br />
            <li>
              10.3. User understands and agrees that Fixryde is only acting as a
              fulfilment platform and does not own any responsibility for thefts
              or loss of valuables during a ride. Customer is fully responsible
              for his / her own goods and valuables, and will not hold Fixryde
              or any of its representatives liable for any loss or theft that
              happens during a ride.
            </li>
          </ul>
          <p>
            <b>11. Limited Liability of Fixryde:</b>
          </p>
          <ul>
            <li>
              11.1. Unless Fixryde explicitly acts as a reseller in certain
              scenarios, Fixryde always acts as a facilitator by connecting the
              User with the respective service providers. Fixryde&#x27;s
              liability is limited to providing the User with a confirmed
              booking as selected by the User.
            </li>
            <br />
            <li>
              11.2. Any issues or concerns faced by the User at the time of
              availing any such services shall be the sole responsibility of the
              Service Provider. Fixryde will have no liability with respect to
              the acts, omissions, errors, representations, warranties, breaches
              or negligence on part of any Service Provider.
            </li>
            <br />
            <li>
              11.3. Unless explicitly committed by Fixryde as a part of any
              product or service:
              <ul>
                <br />
                <li>
                  Fixryde assumes no liability for the standard of services as
                  provided by the respective Service Providers.
                </li>
                <li>
                  Fixryde provides no guarantee with regard to their quality or
                  fitness as represented.
                </li>
                <li>
                  Fixryde doesn&#x27;t guarantee the availability of any
                  services as listed by a Service Provider.
                </li>
              </ul>
            </li>
            <br />
            <li>
              11.4. By making a booking, User understands Fixryde merely
              provides a technology platform for booking of services and
              products and the ultimate liability rests on the respective
              Service Provider and not Fixryde. Thus the ultimate contract of
              service is between User and Service Provider.
            </li>
            <br />
            <li>
              11.5. User further understands that the information displayed on
              the Website with respect to any service is displayed as furnished
              by the Service Provider. Fixryde, therefore, cannot be held liable
              in case if the information provided by the Service Provider is
              found to be inaccurate, inadequate or obsolete or in contravention
              of any laws, rules, regulations or directions in force.
            </li>
          </ul>
          <p>
            <b>12. Right to refusal:</b>
          </p>
          <ul>
            <li>
              12.1. Fixryde reserves the right to refuse to carry any persons or
              objects that the driver deems to be likely to cause his or her
              vehicle harm or damage in whatsoever form. The judgment of the
              driver will be final.
            </li>
          </ul>
          <p>
            <b>13. Queries:</b>
          </p>
          <ul>
            <li>
              13.1. All queries are to be raised in writing before 24 hours of
              the ride time.
            </li>
          </ul>
        </section>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Terms;

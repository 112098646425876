import React, { useState, useEffect } from 'react';
import { FaHome, FaListUl, FaGraduationCap, FaUser } from 'react-icons/fa';
import axios from 'axios';

const HomeContent = () => (
  <div className="p-6">
    <h2 className="text-2xl font-bold text-center">Home</h2>
    <p className="text-center">Welcome to the home page!</p>
  </div>
);

const TripsContent = ({ completedTrips, upcomingTrips, activeTripTab, onTripTabToggle }) => (
  <div className="p-6">
    <header className="flex justify-center items-center border-b border-gray-200 mb-4">
      <button
        className={`w-1/2 py-3 text-lg font-medium rounded-t-lg transition-colors duration-300 ${activeTripTab === 'completed' ? 'bg-fixblue text-white' : 'text-fixblue bg-white'}`}
        onClick={() => onTripTabToggle('completed')}
      >
        Completed Trips
      </button>
      <button
        className={`w-1/2 py-3 text-lg font-medium rounded-t-lg transition-colors duration-300 ${activeTripTab === 'upcoming' ? 'bg-fixblue text-white' : 'text-fixblue bg-white'}`}
        onClick={() => onTripTabToggle('upcoming')}
      >
        Upcoming Trips
      </button>
    </header>
    <div>
      <h2 className="text-2xl font-bold text-center">
        {activeTripTab === 'completed' ? 'Completed Trips' : 'Upcoming Trips'}
      </h2>
      <ul className="space-y-4">
        {(activeTripTab === 'completed' ? completedTrips : upcomingTrips).map(trip => (
          <li key={trip.bookingId} className="bg-gray-200 p-4 rounded-lg shadow-md transition-transform transform hover:scale-95 hover:bg-gray-300 hover:text-white">
            <div className="text-gray-800 text-sm p-2">
              <strong>Date:</strong> {trip.bookingDate} <strong>Time:</strong>{trip.bookingTime} <br />
              <strong>Pickup:</strong> {trip.pickupLocation} <br />
              <strong>Drop:</strong> {trip.dropLocation} <br />
            </div>
            
            {/* <button
              className="bg-fixblue rounded-md px-4 py-2 text-fixwhite"
            >
              Accept
            </button> */}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const TrainingContent = () => (
  <div className="p-6">
    <h2 className="text-2xl font-bold text-center">Training</h2>
    <p className="text-center">Training content goes here.</p>
  </div>
);

const ProfileContent = ({ driverProfile }) => {
  // Check if driverProfile is defined before rendering the content
  if (!driverProfile || Object.keys(driverProfile).length === 0) {
      return <div className="p-6 text-center">Loading profile...</div>;
  }

  return (
      <div className="min-h-screen bg-gray-100 flex flex-col">
          <div className="bg-white shadow-md rounded-lg mx-4 mt-4 p-4 lg:max-w-4xl lg:mx-auto">
              <div className="flex flex-col items-center bg-white ">
                  <div className="rounded-full border-4 border-fixblue w-24 h-24 flex items-center justify-center">
                      <div className="bg-gray-200 rounded-full w-full h-full"></div>
                  </div>
                  <p className="mt-4 font-bold text-lg lg:text-xl italic">
                      WALLET BALANCE: ₹ 0
                  </p>
              </div>
              <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center justify-between bg-gray-100 p-2 rounded-md">
                      <span>{driverProfile.name}</span>
                      <div className="flex items-center">
                          <span className="text-blue-500">★</span>
                          <span className="ml-2">5</span>
                      </div>
                  </div>
                  <div className="bg-gray-100 p-2 rounded-md">
                      <span>Firmware Ver</span>
                      <span className="float-right">:3.23.3</span>
                  </div>
                  <div className="bg-gray-100 p-2 rounded-md">
                      <span>Driver ID:</span>
                      <span className="float-right">{driverProfile.id}</span>
                  </div>
                  <div className="bg-gray-100 p-2 rounded-md">
                      <span>IMEI Number</span>
                      <span className="float-right">:edd19123855add0d</span>
                  </div>
                  <div className="bg-gray-100 p-2 rounded-md">
                      <span>Vendor Name:</span>
                      <span className="float-right">{driverProfile.vendorId}</span>
                  </div>
                  <div className="bg-gray-100 p-2 rounded-md">
                      <span>Driver Score</span>
                      <span className="float-right">:NA</span>
                  </div>
                  <div className="bg-gray-100 p-2 rounded-md">
                      <span>Driver Experiance:</span>
                      <span className="float-right">{driverProfile.experience}</span>
                  </div>
                  <div className="bg-gray-100 p-2 rounded-md">
                      <span>Change Language</span>
                      <span className="float-right">:English</span>
                  </div>
                  <div className="bg-gray-100 p-2 rounded-md">
                      <span>Set Location</span>
                      <span className="float-right">:Vascodagama Goa</span>
                  </div>
                  <div className="bg-gray-100 p-2 rounded-md flex flex-col">
                      <span>Documents</span>
                      {/* <span className="text-blue-500 underline cursor-pointer"> */}
                      <span className="text-blue-500 ">
                          Driver Document
                      </span>
                      {/* <span className="text-blue-500 underline cursor-pointer"> */}
                      <span className="text-blue-500 ">
                          Audit Document
                      </span>
                  </div>
              </div>
              {/* Logout Button */}
              {/* <div className="flex items-center justify-center mt-4 p-4">
        <button className="w-full max-w-sm bg-fixblue text-white py-2 rounded-lg">
          Logout
        </button>
      </div> */}
          </div>
      </div>
  );
};


const TripDashboard = () => {
  const [activeTab, setActiveTab] = useState('home');
  const [activeTripTab, setActiveTripTab] = useState('completed');
  const [driverId, setDriverId] = useState('');
  const [driverUid, setDriverUid] = useState('');
  const [onDuty, setOnDuty] = useState(false); 
  const [driverProfile, setDriverProfile] = useState({});
  const [completedTrips, setCompletedTrips] = useState([]);
  const [upcomingTrips, setUpcomingTrips] = useState([]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const userData = JSON.parse(localStorage.getItem("user"));

    if (accessToken && userData) {
      console.log("User Data:", userData);
      const { id, role, sub } = userData;

      if (role === 'ROLE_DRIVER') {
        setDriverId(id);
        const modifiedSub = sub;
        setDriverUid(modifiedSub);
      }
    }
  }, []);

  useEffect(() => {
    if (onDuty) {
      fetchDriverProfile();
      fetchDriverTrips();
    }
  }, [onDuty, driverUid]);

  const fetchDriverProfile = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URLB}/bookings/driverstatus/${driverId}/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'ngrok-skip-browser-warning': 'true',
        },
      });
      setDriverProfile(response.data);
      console.log("DriverProfile");
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching driver profile:", error);
    }
  };

  const fetchDriverTrips = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URLB}/bookings/driverstatus/${driverUid}/trips`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'ngrok-skip-browser-warning': 'true',
        },
      });
      const trips = response.data;
      const tripsFilteredByVendor = trips.filter(trip => trip.tripAssignedByVendor === true); 
    
      console.log("Trips");
      console.log(tripsFilteredByVendor);
      setCompletedTrips(tripsFilteredByVendor.filter(trip => trip.tripStatus === 'Completed'));
      setUpcomingTrips(tripsFilteredByVendor.filter(trip => trip.tripStatus !== 'Completed'));
      
    } catch (error) {
      console.error("Error fetching driver trips:", error);
    }
  };

  const handleTabToggle = (tab) => {
    setActiveTab(tab);
  };

  const handleTripTabToggle = (tab) => {
    setActiveTripTab(tab);
  };

  const handleDutyToggle = async () => {
    try {
      const newDutyStatus = !onDuty;
      setOnDuty(newDutyStatus);

      await axios.put(
        `${process.env.REACT_APP_API_BASE_URLB}/bookings/driverstatus/updateduty/${driverId}`,
        null,
        {
          params: { availabilityStatus: newDutyStatus },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'ngrok-skip-browser-warning': 'true',
          },
        }
      );
    } catch (error) {
      console.error("Error updating duty status:", error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex flex-col flex-grow">
        <div className='flex justify-around items-center'>
          <div className="flex items-center space-x-4 mb-4 m-5">
            <span className={`text-lg font-medium ${onDuty ? 'text-green-500' : 'text-gray-500'}`}>
              {onDuty ? 'On Duty' : 'Off Duty'}
            </span>
            <button
              onClick={handleDutyToggle}
              aria-pressed={onDuty}
              className={`relative inline-flex items-center w-16 h-8 rounded-full transition-colors duration-300 ease-in-out ${onDuty ? 'bg-green-500' : 'bg-gray-300'}`}
            >
              <span
                className={`absolute top-1 left-1 w-6 h-6 bg-white rounded-full transition-transform duration-300 ease-in-out ${onDuty ? 'translate-x-full' : ''}`}
              />
            </button>
          </div>
          {onDuty && (
            <div className="flex items-center justify-center mt-4 p-4 text-[14px] font-semibold">
              <button className="w-full min-w-lg bg-fixblue text-white py-2 px-4 rounded-lg">
                Logout
              </button>
            </div>
          )}
        </div>

        {onDuty && (
          <div className="flex-grow">
            {activeTab === 'home' && <HomeContent />}
            {activeTab === 'trips' && (
              <TripsContent
                completedTrips={completedTrips}
                upcomingTrips={upcomingTrips}
                activeTripTab={activeTripTab}
                onTripTabToggle={handleTripTabToggle}
              />
            )}
            {activeTab === 'training' && <TrainingContent />}
            {activeTab === 'profile' && <ProfileContent 
            // const [driverProfile, setDriverProfile] = useState({});
            driverProfile={driverProfile}
            />}
          </div>
        )}
      </div>

      {onDuty && (
        <nav className="bg-white border-t border-gray-300 fixed bottom-0 left-0 right-0 flex justify-between items-center px-4 py-2 shadow-lg">
          <div
            className={`flex-1 text-center py-2 rounded-md cursor-pointer transition-colors duration-300 ${activeTab === 'home' ? 'bg-fixblue text-white' : 'text-fixblue'}`}
            onClick={() => handleTabToggle('home')}
            aria-label="Home"
          >
            <FaHome className="w-6 h-6 mx-auto" />
            <span className="text-xs lg:text-sm">HOME</span>
          </div>
          <div
            className={`flex-1 text-center py-2 rounded-md cursor-pointer transition-colors duration-300 ${activeTab === 'trips' ? 'bg-fixblue text-white' : 'text-fixblue'}`}
            onClick={() => handleTabToggle('trips')}
            aria-label="My Trips"
          >
            <FaListUl className="w-6 h-6 mx-auto" />
            <span className="text-xs lg:text-sm">MY TRIPS</span>
          </div>
          <div
            className={`flex-1 text-center py-2 rounded-md cursor-pointer transition-colors duration-300 ${activeTab === 'training' ? 'bg-fixblue text-white' : 'text-fixblue'}`}
            onClick={() => handleTabToggle('training')}
            aria-label="Training"
          >
            <FaGraduationCap className="w-6 h-6 mx-auto" />
            <span className="text-xs lg:text-sm">TRAINING</span>
          </div>
          <div
            className={`flex-1 text-center py-2 rounded-md cursor-pointer transition-colors duration-300 ${activeTab === 'profile' ? 'bg-fixblue text-white' : 'text-fixblue'}`}
            onClick={() => handleTabToggle('profile')}
            aria-label="Profile"
          >
            <FaUser className="w-6 h-6 mx-auto" />
            <span className="text-xs lg:text-sm">PROFILE</span>
          </div>
        </nav>
      )}
    </div>
  );
};

export default TripDashboard;

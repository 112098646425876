import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { FaPrint, FaDownload, FaEye, FaTimes, FaCheck } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VendorNavbar from '../../components/vendor/VendorNavbar';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URLE,
    headers: {
        'ngrok-skip-browser-warning': 'true'
    }
});

function VendorInvoiceView() {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [invoiceData, setInvoiceData] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [vendor, setVendor] = useState('');
    const [selectedVendor, setSelectedVendor] = useState('');
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const [isGenerateDisabled, setIsGenerateDisabled] = useState(false);
    const [invoiceViewData, setInvoiceViewData] = useState(null);
    const [isSettleModalOpen, setIsSettleModalOpen] = useState(false);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [vendorPaymentReferenceId, setVendorPaymentReferenceId] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("user"));
        const vendorUserid = userData?.sub || "vendor123";
        setVendor(vendorUserid);
    }, []);

    useEffect(() => {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(today.getMonth() - 3);

        document.getElementById('fromDate').setAttribute('min', threeMonthsAgo.toISOString().split('T')[0]);
        document.getElementById('fromDate').setAttribute('max', today.toISOString().split('T')[0]);
    }, []);

    const setDateRange = (selectedDate) => {
        const date = new Date(selectedDate);
        const month = date.getMonth();
        const year = date.getFullYear();

        const IST_OFFSET = 330;

        const firstDate = new Date(year, month, 1);
        firstDate.setMinutes(firstDate.getMinutes() - firstDate.getTimezoneOffset() + IST_OFFSET);

        const lastDate = new Date(year, month + 1, 0);
        lastDate.setMinutes(lastDate.getMinutes() - lastDate.getTimezoneOffset() + IST_OFFSET);

        if (lastDate.getMonth() !== month) {
            lastDate.setMonth(month);
            lastDate.setDate(0);
            lastDate.setHours(23, 59, 59, 999);
        }

        setFromDate(firstDate.toISOString().split('T')[0]);
        setToDate(lastDate.toISOString().split('T')[0]);

        console.log("From Date:", firstDate.toISOString().split('T')[0]);
        console.log("To Date:", lastDate.toISOString().split('T')[0]);

        setIsGenerateDisabled(false);
    };

    const handleFetchInvoices = async () => {
        if (fromDate && toDate) {
            fetchInvoices();
        } else {
            toast.error("Please select a vendor and Date Range");
        }
    };

    const fetchInvoices = async () => {
        // if (!selectedVendor) return;

        try {
            const response = await api.get(
                `${process.env.REACT_APP_API_BASE_URLE}/bookings/invoices/vendor/splitted/invoicelist/datemonth`,
                {
                    params: {
                        vendorUserid: vendor,
                        fromDate: fromDate,
                        toDate: toDate
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                    },
                }
            );
            setInvoiceData(response.data);
            console.log("setInvoiceData:already present:", response.data);
            setIsGenerateDisabled(response.data.length > 0);
            setInvoiceViewData(null);
            return response.data;
        } catch (error) {
            console.error('Error fetching invoices:', error);
            toast.error("Failed to fetch invoices");
            return null;
        }
    };

    const formatDateTime = (dateTime) => {
        const date = new Date(dateTime);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? String(hours).padStart(2, '0') : '12';

        return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
    };

    const handleView = async (invoiceId) => {
        try {
            const response = await api.get(
                `/bookings/invoices/vendor/generate/newmodel/view`,
                {
                    params: {
                        invoiceId: invoiceId
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                }
            );
            setInvoiceViewData(response.data);
            console.log("Invoice View Data:", response.data);
        } catch (error) {
            console.error('Error fetching invoice:', error);
            toast.error("Failed to fetch invoice data");
        }
    };

    const handlePrint = () => {
        window.print();
    };

    return (
        <Fragment>
            <ToastContainer />
            <VendorNavbar onToggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
            <div className={`min-h-screen px-4 transition-all duration-300 ease-in-out bg-zinc-200`}>
                <div className="bg-gradient-to-r from-blue-50 to-gray-100 min-h-screen p-6">
                    <div className="bg-white shadow-lg rounded-lg p-6">
                        <h2 className="text-3xl font-semibold text-gray-800 mb-6">Generate and View Invoices</h2>

                        <div className="flex gap-4 mb-6">
                            <div className="w-1/2">
                                <label className="text-lg font-medium text-gray-700">Vendor:</label>
                                <select
                                    className="w-full p-3 mt-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    value={vendor}
                                    // onChange={(e) => setSelectedVendor(e.target.value)}
                                    readOnly
                                >
                                    <option value="vendor">{vendor}</option>
                                    {/* {vendors.map((vendor) => (
                                        <option key={vendor.vendorUserid} value={vendor.vendorUserid}>
                                            {vendor.vendorUserid}
                                        </option>
                                    ))} */}
                                </select>
                            </div>
                            <div className="w-1/2">
                                <label className="block text-lg font-medium text-gray-700">From Date</label>
                                <input
                                    id="fromDate"
                                    type="date"
                                    value={fromDate}
                                    onChange={(e) => setDateRange(e.target.value)}
                                    className="w-full p-3 mt-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="w-1/2">
                                <label className="block text-lg font-medium text-gray-700">To Date</label>
                                <input
                                    type="date"
                                    value={toDate}
                                    readOnly
                                    className="w-full p-3 mt-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="w-1/2">
                                <label className="block text-lg font-medium text-gray-700">Get List</label>
                                <button
                                    onClick={handleFetchInvoices}
                                    className="w-full p-3 mt-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition-colors"
                                    disabled={isGenerateDisabled}
                                >
                                    Invoice List
                                </button>
                            </div>
                        </div>


                        <div className="mt-6">
                            {/* {invoiceData.length > 0 ? ( */}
                            {invoiceData.filter(invoice => invoice.status === 'PAID').length > 0 ? (
                                <table className="w-full table-auto border-collapse mt-4">
                                    <thead>
                                        <tr className="text-left text-sm font-semibold text-gray-600 bg-gray-200">
                                            <th className="py-3 px-4">Invoice ID</th>
                                            <th className="py-3 px-4">Vendor ID</th>
                                            <th className="py-3 px-4">Date Range</th>
                                            <th className="py-3 px-4">Created @</th>
                                            <th className="py-3 px-4">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {invoiceData.map((invoice) => ( */}
                                        {invoiceData.filter(invoice => invoice.status === 'PAID').map((invoice) => (
                                            <tr key={invoice.invoiceId} className="border-b text-gray-700">
                                                <td className="py-3 px-4">{invoice.invoiceId}</td>
                                                <td className="py-3 px-4">{invoice.vendorId}</td>
                                                <td className="py-3 px-4">{`${invoice.fromDate} - ${invoice.toDate}`}</td>
                                                <td className="py-3 px-4">{formatDateTime(invoice.createdAt)}</td>
                                                <td className="py-3 px-4 space-x-2">
                                                    <button
                                                        onClick={() => handleView(invoice.invoiceId)}
                                                        className="inline-flex items-center text-green-600 hover:text-green-800 font-medium"
                                                    >
                                                        <FaEye className="mr-1" /> View
                                                    </button>
                                                    {invoice.vendorPaymentReferenceId && invoice.status === 'PAID' && (
                                                        <div className="inline-flex items-center text-green-600 font-medium">
                                                            <FaCheck className="mr-1" />  Paid
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="text-center text-lg text-gray-600 mt-6">No invoices found.</p>
                            )}
                        </div>

                        {/* Invoice View Section */}
                        {invoiceViewData && (
                            <div id="invoice">
                                <div className="bg-white shadow-md rounded-lg p-6 mt-8">
                                    <div className="flex justify-between items-center border-b py-2">
                                        <div className='flex-col space-x-4 gap-2'>
                                            <h1 className="text-2xl font-bold text-fixblue">Fixryde Invoice</h1>
                                            <p className="text-gray-600"><b>Invoice ID:</b> {invoiceViewData.invoiceId}</p>
                                            <p className="text-gray-600"><b>Payment Reference ID:</b> {invoiceViewData.vendorPaymentReferenceId}</p>
                                            <p className="text-gray-600"><b>Invoice Date Range:</b> {invoiceViewData.startDate} to {invoiceViewData.endDate}</p>
                                            <p className="text-gray-600"><b>Invoice Generated At:</b> {new Date(invoiceViewData.generatedAt).toLocaleString()}</p>
                                        </div>
                                        <div className="flex flex-col sm:flex-row space-x-2 gap-2">
                                            <button
                                                onClick={() => {
                                                    const input = document.getElementById('invoice', 'invoiceView');
                                                    html2canvas(input).then((canvas) => {
                                                        const imgData = canvas.toDataURL('image/png');
                                                        const pdf = new jsPDF('landscape', 'pt', 'a4');
                                                        const pdfWidth = pdf.internal.pageSize.getWidth();
                                                        const pdfHeight = pdf.internal.pageSize.getHeight();
                                                        const imgWidth = canvas.width;
                                                        const imgHeight = canvas.height;
                                                        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
                                                        const newImgWidth = imgWidth * ratio;
                                                        const newImgHeight = imgHeight * ratio;
                                                        pdf.addImage(imgData, 'image', (pdfWidth - newImgWidth) / 2, 0, newImgWidth, newImgHeight);
                                                        pdf.save(`${invoiceViewData.invoiceId}.pdf`);
                                                    });
                                                }}
                                                className="flex items-center bg-fixgold text-white py-2 px-4 rounded-full"
                                            >
                                                <FaDownload className="mr-2" />  PDF
                                            </button>
                                            <button
                                            onClick={handlePrint}
                                            className="flex items-center bg-fixblue text-white py-2 px-4 rounded-full"
                                        >
                                            <FaPrint className="mr-2" /> Print
                                        </button>
                                        </div>
                                    </div>
                                    <div id="invoiceView">
                                        <div className="my-4 flex justify-between">
                                            {/* Vendor Information */}
                                            <div className="w-1/2">
                                                <h2 className="text-2xl font-bold text-fixblue">Vendor Information</h2>
                                                <p><b>Company Name:</b> {invoiceViewData.vendorInfo.companyName}</p>
                                                <p><b>Email:</b> {invoiceViewData.vendorInfo.companyMail}</p>
                                                <p><b>Phone:</b> {invoiceViewData.vendorInfo.alternateMobileNumber}</p>
                                                <p><b>Address:</b> {invoiceViewData.vendorInfo.address}, {invoiceViewData.vendorInfo.city}, {invoiceViewData.vendorInfo.state}, {invoiceViewData.vendorInfo.country}, {invoiceViewData.vendorInfo.pincode}</p>
                                                <p><b>GST Number:</b> {invoiceViewData.vendorInfo.companyGstNumber}</p>
                                            </div>

                                            {/* FixRyde Company Information */}
                                            <div className="w-1/2">
                                                <h2 className="text-2xl font-bold text-fixblue">FixRyde Company Information</h2>
                                                <p><b>Company Name:</b> FixRyde Company</p>
                                                <p><b>Email:</b> fixrydetravels@gmail.com</p>
                                                <p><b>Phone:</b> 9988776601</p>
                                                <p><b>Address:</b> Gandhi Street 1st Floor, Chennai, Tamil Nadu, India, 600113</p>
                                                <p><b>GST Number:</b> fixrydeGST</p>
                                            </div>
                                        </div>
                                        <div className="my-4">
                                            <h2 className="text-2xl font-bold text-fixblue">Booking Details</h2>
                                            <h2 className="text-2xl font-bold text-fixblue">_______________</h2>
                                            <div className="overflow-x-auto">
                                                <table className="min-w-full bg-white">
                                                    <thead>
                                                        <tr className='bg-gray-200'>
                                                            <th className="px-4 py-2">Booking ID</th>
                                                            <th className="px-4 py-2">Trip Type</th>
                                                            <th className="px-4 py-2">Booking Cost</th>
                                                            <th className="px-4 py-2">Online Paid</th>
                                                            <th className="px-4 py-2">Cash Collection</th>
                                                            <th className="px-4 py-2">Commission</th>
                                                            <th className="px-4 py-2">Payout</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {invoiceViewData.bookingDetails.length > 0 ? (
                                                            invoiceViewData.bookingDetails.map((booking, index) => (
                                                                <tr key={index}>
                                                                    <td className="border px-4 py-2">{booking.bookingId}</td>
                                                                    <td className="border px-4 py-2">{booking.tripType}</td>
                                                                    <td className="border px-4 py-2">INR ₹ {booking.cost.toFixed(2)}</td>
                                                                    <td className="border px-4 py-2">INR ₹ {booking.amountPaid.toFixed(2)}</td>
                                                                    <td className="border px-4 py-2">INR ₹ {booking.remainingAmount.toFixed(2)}</td>
                                                                    <td className="border px-4 py-2">INR ₹ {booking.commission.toFixed(2)}</td>
                                                                    <td className="border px-4 py-2">INR ₹ {booking.payout.toFixed(2)}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6" className="border px-4 py-2 text-center text-gray-600">
                                                                    No bookings found
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="mt-6 text-right">
                                            <h2 className="text-s text-fixblue">Online Paid: INR ₹ {invoiceViewData.amountPaid.toFixed(2)}</h2>
                                            <h2 className="text-s text-fixblue">Cash Collection: INR ₹ {invoiceViewData.remainingAmount.toFixed(2)}</h2>
                                            <h2 className="text-s text-fixblue">Total Commission: INR ₹ {invoiceViewData.totalCommission.toFixed(2)}</h2>
                                            <h2 className="text-xl font-bold text-fixblue">Total Payout: INR ₹ {invoiceViewData.payout.toFixed(2)}</h2>
                                            <h2 className="text-s text-fixblue">Total Booking Amount: INR ₹ {invoiceViewData.totalAmount.toFixed(2)}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default VendorInvoiceView;

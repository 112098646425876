import React from "react";
import { Link } from "react-router-dom";
import { FiUser, FiCalendar, FiClock } from "react-icons/fi";

// Assuming blogData is defined here or imported
const blogData = [
  {
    id: 1,
    title: "The best way to drive cars",
    author: "Jone doe",
    date: "12 Dec, 2020",
    time: "9pm",
    imgUrl: "/images/Blog_2.png",
    description: "Cab booking site blog. Learn about the latest trends and tips for booking cabs efficiently.it memorable and hassle-free.",
    quote: "Your quote here",
  },
  {
    id: 2,
    title: "If your car battery is down",
    author: "Emily Johnson",
    date: "12 Feb, 2019",
    time: "9pm",
    imgUrl: "/images/Blog_1.png",
    description: "Cab booking site blog. Discover how to handle a car battery emergency and still make it on time.it memorable and hassle-free.",
    quote: "Your quote here",
  },
  {
    id: 3,
    title: "The best way to give trip",
    author: "Alice Johnson",
    date: "14 Jan, 2020",
    time: "11pm",
    imgUrl: "/images/Blog_4.png",
    description: "Cab booking site blog. Planning your next trip? Read our expert advice on making it memorable and hassle-free.",
    quote: "Your quote here",
  },
];
const BlogList = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 m-2">
      {blogData.map((item) => (
        <BlogItem item={item} key={item.id} />
      ))}
    </div>
  );
};

const BlogItem = ({ item }) => {
  const { imgUrl, title, author, date, description, time } = item;

  return (
    <div className="bg-white rounded-lg shadow-md text-[1rem]">
      <img src={imgUrl} alt={title} className="w-full rounded-t-lg h-60" />
      <div className="px-4 py-2">
        <Link to={`/blogdetails/${item.id}`} className="text-xl font-bold text-fixblue hover:text-fixred">
          {title}
        </Link>
        <p className="mt-3">
          {description.length > 100 ? description.substr(0, 100) + "..." : description}
        </p>
        <Link to={`/blogdetails/${item.id}`} className="text-fixred text-md font-bold">
          Read More
        </Link>
        <hr className="mt-2" />
        <div className="pt-2 mt-1 flex items-center justify-between">
          <span className="flex items-center gap-1">
            <FiUser className="text-fixred" /> {author}
          </span>
          <div className="flex items-center gap-3">
            <span className="flex items-center gap-1">
              <FiCalendar className="text-fixred" /> {date}
            </span>
            <span className="flex items-center gap-1">
              <FiClock className="text-fixred" /> {time}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogList;

// VehicleCard.js
import React from 'react';
import { FaEllipsisV } from 'react-icons/fa';

const VehicleCard = ({ vehicle }) => {
  return (
    <div className="bg-white shadow-lg p-4 rounded-lg mb-4">
      <div className="flex justify-between items-center">
        <div>
          <span className="text-sm text-fixblue font-bold">Plus</span>
          <h2 className="text-lg font-bold">{vehicle.model}</h2>
          <p className="text-gray-700">{vehicle.number} | {vehicle.type.toLowerCase()}</p>
        </div>
        <FaEllipsisV />
      </div>
      <div className="mt-4">
        <span className="bg-green-100 text-green-700 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">Verified</span>
      </div>
      <button className="mt-4 w-full bg-purple-600 text-white py-2 px-4 rounded">View Assigned Drivers</button>
    </div>
  );
};

export default VehicleCard;

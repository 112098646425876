import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { RiMapPin2Line } from "react-icons/ri";

function TableEnquiry({ data, fields }) {
  const navigate = useNavigate();

  const handleMapClick = (item) => {
    const { sourceLatitude, sourceLongitude, destinationLatitude, destinationLongitude } = item;

    // Construct the Google Maps URL with source and destination coordinates
    const mapUrl = `https://www.google.com/maps/dir/${sourceLatitude},${sourceLongitude}/${destinationLatitude},${destinationLongitude}`;

    // Open in a new tab
    window.open(mapUrl, "_blank");
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);

  const handleDraftLineClick = (item) => {
    setSelectedTrip(item);
    setModalOpen(true);
  };

  const sortedData = [...data].sort((a, b) => b.enquiryId - a.enquiryId); //descending order if EnquiryId is Number
  // const sortedData = [...data].sort((a, b) => a.enquiryId - b.enquiryId); //ascending order if EnquiryId is Number

  return (
    <Fragment>
      <table className="min-w-full bg-fixwhite text-[0.8rem]">
        <thead className="bg-gray-400">
          <tr>
            {fields.map((field) => (
              <th key={field.key} className="py-2 px-4 border">
                {field.label}
              </th>
            ))}
            <th className="py-2 px-4 border">Location Map</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item) => (
            <tr key={item.enquiryId || item.id}>
              {fields.map((field) => (
                <td key={`${item.enquiryId || item.id}-${field.key}`} className="py-2 px-4 border">
                  {field.key === "tripStatus" && (
                    <span className="flex flex-col justify-center items-center gap-1">
                      {item.tripStatus}
                    </span>
                  )}
                  {field.key === "date" && (
                    <span>{new Date(item.date).toLocaleDateString()}</span>
                  )}
                  {field.key !== "tripStatus" && field.key !== "date" && (
                    <span>{item[field.key]}</span>
                  )}
                </td>
              ))}
              <td className="py-2 px-4 border">
                <div className="flex justify-center space-x-2">
                  {/* Replace previous action buttons with a map icon */}
                  <button
                    className="text-blue-500 hover:text-blue-700 focus:outline-none"
                    onClick={() => handleMapClick(item)} // Handle the map click
                  >
                    <RiMapPin2Line size={20} />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );

}

export default TableEnquiry;

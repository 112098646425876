import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {
  RiEdit2Line,
  RiMessage2Line,
  RiFileCopyLine,
  RiDraftLine,
} from "react-icons/ri";
import UpdateTripStatusModal from "./UpdateTripStatusModal";

function Table({ data, fields }) {
  const navigate = useNavigate();

  const handleEditClick = async (item) => {
    console.log("item:");
    console.log(item);
    // let bookingId = item.bookId;
    const token = localStorage.getItem("accessToken");
    try {
      let bookingId = item.bookId;
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URLB}/bookings/tripbookings/trips/bbid`, {
        params: { bookingId },
        headers: {
          "Authorization": `Bearer ${token}`,
          'ngrok-skip-browser-warning': 'true',
          "Content-Type": "application/json"
        },
      }
      );

      // Extract only the required fields
      const additionalData = {
        tripAssignedByVendor: response.data.tripAssignedByVendor,
        vehicleNumber: response.data.vehicleNumber,
        tripOtp: response.data.tripOtp,
      };

      console.log("additionalData:", additionalData);

      // Navigate to /employee/edit with the trip details and additional data
      navigate('/employee/edit', { state: { ...item, additionalData } });
    } catch (error) {
      console.error("Error fetching additional data:", error);
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);

  const handleDraftLineClick = (item) => {
    setSelectedTrip(item);
    setModalOpen(true);
  };

  return (
    <Fragment>
    <table className="min-w-full bg-fixwhite text-[0.8rem]">
      <thead className="bg-gray-400">
        <tr>
          {fields.map((field) => (
            <th key={field.key} className="py-2 px-4 border">
              {field.label}
            </th>
          ))}
          <th className="py-2 px-4 border">Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.bookId || item.id}>
            {fields.map((field) => (
              <td key={`${item.bookId || item.id}-${field.key}`} className="py-2 px-4 border">
                {field.key === "tripStatus" && (
                  <span className="flex flex-col justify-center items-center gap-1">
                    {item.tripStatus}{" "}
                    <RiDraftLine
                      className={getStatusColorClass(item.tripStatus)} // Apply color conditionally
                      size={20}
                      onClick={() => handleDraftLineClick(item)}
                    />
                  </span>
                )}
                {field.key === "date" && (
                  <span>{new Date(item.date).toLocaleDateString()}</span>
                )}
                {field.key !== "tripStatus" && field.key !== "date" && (
                  <span>{item[field.key]}</span>
                )}
              </td>
            ))}
            <td className="py-2 px-4 border">
              <div className="flex justify-center space-x-2">
                <button
                  className="text-blue-500 hover:text-blue-700 focus:outline-none"
                  onClick={() => handleEditClick(item)}
                >
                  <RiEdit2Line size={20} />
                </button>
                <button className="text-green-500 hover:text-green-700 focus:outline-none">
                  <RiMessage2Line size={20} />
                </button>
                <button className="text-gray-500 hover:text-gray-700 focus:outline-none">
                  <RiFileCopyLine size={20} />
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <UpdateTripStatusModal
    isOpen={isModalOpen}
    onClose={() => setModalOpen(false)}
    trip={selectedTrip}
  />
  </Fragment>
  );

  function getStatusColorClass(status) {
    switch (status) {
      case "StartFromGarage":
      case "ReachedPickup":
      case "TripInProgress":
        return "text-fixblue hover:text-fixblue-600 cursor-pointer"; // Use fixblue
      case "ReachedDrop":
      case "TripCompleted":
      case "ManualFulfill":
        return "text-fixgreen hover:text-fixgreen-600 cursor-pointer"; // Use fixgreen
      case "CANCELLED":
      case "NotBoarded":
      case "ForceClose":
        return "text-fixred hover:text-fixred-600 cursor-pointer"; // Use fixred
      default:
        return "text-fixgold hover:text-fixgold-600 cursor-pointer"; // Default color (fixgold)
    }
  };

}

export default Table;

import React, { Fragment, useState } from "react";
import { FaBook, FaCarSide, FaMoneyBillAlt, FaSync, FaUserCog } from "react-icons/fa";
import VendorNavbar from "../../components/vendor/VendorNavbar";
import VenDash from "../../components/vendor/VenDash";
import Charts from "../../components/admin/Charts";
import MiniDetails from "../../components/vendor/MiniDetails";

function VendorDashboard() {
  // Date select option
  const [selectedOption, setSelectedOption] = useState("today");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Fragment>
      <VendorNavbar onToggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
      {/* Main content */}
      <div className={`min-h-screen p-4 transition-all duration-300 ease-in-out`}>
        <div className="bg-white shadow-lg p-4 rounded-lg">
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="text-gray-700">
              <h1 className="text-xl font-semibold">Vendor Panel</h1>
              <span>{new Date().toLocaleDateString()}</span>
            </div>
            <div className="flex flex-col items-center mt-2 sm:mt-0">
              <label
                htmlFor="date-select"
                className="text-gray-700 mr-2 mb-1 text-xl font-semibold "
              >
                Select View:
              </label>
              <select
                id="date-select"
                value={selectedOption}
                onChange={handleOptionChange}
                className="border border-gray-300 bg-white rounded-md px-3 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="today">Today</option>
                <option value="tomorrow">Tomorrow</option>
                <option value="yesterday">Yesterday</option>
                <option value="lastweek">Last Week</option>
                <option value="lastmonth">Last Month</option>
              </select>
            </div>
          </div>
        </div>
        <hr className="my-4 border-b-2 border-black" />
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center sm:justify-start">
            <VenDash
              title="New Booking(Doc)"
              countno="10"
              color="green"
              icon={<FaSync />}
            />
            <VenDash
              title="All Trips"
              countno="05"
              color="blue"
              icon={<FaBook />}
            />
            <VenDash
              title="Vacant Drivers"
              countno="37"
              color="red"
              icon={<FaUserCog />}
            />
            <VenDash
              title="Vacant Cabs"
              countno="89"
              color="blue"
              icon={<FaCarSide />}
            />
            <VenDash
              title="Total Booking Value"
              countno="$2,000"
              color="orange"

              icon={<FaMoneyBillAlt />}
            />
            {/* Add more cards as needed */}
          </div>
        </div>
        <hr className="my-4 border-b-2 border-black" />
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
            <div className="flex flex-col bg-white rounded-lg shadow-lg p-2 w-full">
              <MiniDetails
                tripPanelHeading="Trip Panel"
                viewMoreHeading="View More"
                miniData={[
                  { color: "teal", heading: "Ongoing", data: "04" },
                  { color: "green", heading: "Completed", data: "06" },
                  { color: "red", heading: "Cancelled", data: "01" },
                  { color: "yellow", heading: "Unassigned", data: "00" },
                  { color: "orange", heading: "Upcoming", data: "00" },
                  { color: "gray", heading: "GRT", data: "00" },
                ]}
              />
            </div>
            <div className="flex flex-col bg-white rounded-lg shadow-lg p-2 w-full">
              <MiniDetails
                tripPanelHeading="Fleet Panel"
                viewMoreHeading="View More"
                miniData={[
                  { color: "teal", heading: "Total", data: "40" },
                  { color: "pink", heading: "Available", data: "34" },
                  { color: "green", heading: "Reg Progress", data: "06" },
                  { color: "gray", heading: "Checkup Pending", data: "04" },
                  { color: "blue", heading: "On trip", data: "08" },
                  { color: "orange", heading: "Logout", data: "04" },
                ]}
              />
            </div>
            <div className="flex flex-col bg-white rounded-lg shadow-lg p-2 w-full">
              <MiniDetails
                tripPanelHeading="Vehicles Panel"
                viewMoreHeading="View More"
                miniData={[
                  { color: "teal", heading: "Total", data: "48" },
                  { color: "yellow", heading: "Unassign", data: "26" },
                  { color: "green", heading: "On trip", data: "05" },
                  { color: "orange", heading: "Logged out", data: "08" },
                  { color: "green", heading: "Reg progress", data: "03" },
                  { color: "gray", heading: "Checkup pending", data: "03" },
                  { color: "red", heading: "Blocked for trip", data: "02" },
                  { color: "pink", heading: "Driver login pending", data: "03" },
                ]}
              />
            </div>
            <div className="flex flex-col bg-white rounded-lg shadow-lg p-2 w-full">
              <Charts />
            </div>
            <div className="flex flex-col bg-white rounded-lg shadow-lg p-2 w-full">
              <MiniDetails
                tripPanelHeading="Review"
                viewMoreHeading="View More"
                miniData={[

                  { color: "yellow", heading: "kumar", data: "3" },
                  { color: "teal", heading: "ram", data: "4" },
                  { color: "green", heading: "raja", data: "5" },
                ]}
              />
            </div>
          </div>
        </div>
        <hr className="my-4 border-b-2 border-black" />
      </div>
    </Fragment>
  );
}

export default VendorDashboard;
